import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";

import AccountFilter from "./AccountFilter";
import DateFilter, { DateFilterValueType } from "./DateFilter";
import SortMenu from "./SortMenu";
import PLATFORMS from "constants/platforms";
import { UserApplicationsType } from "types";
import DialogValidationSaveFilters from "./DialogValidationSaveFilters";
import { logDefaultSearchParamsSaved } from "analytics";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
}));

export const useSearchFilters = (
  userApplications: UserApplicationsType | undefined,
  loading: boolean,
  savedParams?: any
) => {
  const supportedPlatforms = useRef(new Set(PLATFORMS.all()));
  const [loadedParams, setLoadedParams] = useState(false);
  const resetValues = useCallback(() => {
    const supportedApplications = Object.values(userApplications || {}).filter(({ platform }) =>
      supportedPlatforms.current.has(platform)
    );

    return {
      accountsToSearch: new Set(supportedApplications),
      dateFilter: { type: "Any" },
      sortType: null,
    };
  }, [userApplications]);

  const [accountsToSearch, setAccountsToSearch] = useState(() => {
    return resetValues().accountsToSearch;
  });
  const [dateFilter, setDateFilter] = useState<DateFilterValueType>({ type: "Any" });
  const [sortType, setSortType] = useState<string | null>(null);

  useEffect(() => {
    if (!loading && !loadedParams) {
      setAccountsToSearch(resetValues().accountsToSearch);
    }

    if (savedParams && savedParams.dateFilter && !loadedParams && userApplications) {
      const savedApp: any = new Set();
      const apps = Object.values(userApplications);

      apps.forEach((item) => {
        if (savedParams.accountsToSearch.includes(item.id)) {
          savedApp.add(item);
        }
      });

      setAccountsToSearch(savedApp);
      setDateFilter(savedParams.dateFilter);
      setSortType(savedParams.sortType);
      setLoadedParams(true);
    }
  }, [resetValues, loading, loadedParams, savedParams, userApplications]);

  const update = useMemo(() => {
    return {
      setAccountsToSearch,
      setDateFilter,
      setSortType,
    };
  }, []);

  const reset = useCallback(() => {
    const values = resetValues();
    setAccountsToSearch(values.accountsToSearch);
    setDateFilter(values.dateFilter);
    setSortType(values.sortType);
  }, [resetValues]);

  const res = useMemo(() => {
    return {
      accountsToSearch,
      dateFilter,
      sortType,
      update,
      reset,
    };
  }, [accountsToSearch, dateFilter, sortType, update, reset]);

  return res;
};

type UseSearchFilterType = ReturnType<typeof useSearchFilters>;

type SearchFiltersProps = {
  userApplications?: UserApplicationsType;
  loadingApplications: boolean;
  hasSortMenu: boolean;
  filters: UseSearchFilterType;
  savedParams?: any;
};

const SearchFilters = React.memo(
  ({
    userApplications,
    loadingApplications,
    hasSortMenu,
    filters,
    savedParams,
  }: SearchFiltersProps) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const [user] = useCurrentUser();

    const [open, setOpen] = React.useState(false);
    const [savingData, setSavingData] = useState(false);
    const [actualParams, setActualParams] = useState<typeof savedParams>();

    useEffect(() => {
      if (savedParams) {
        setActualParams({ ...savedParams });
      }
    }, [savedParams]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const { accountsToSearch, dateFilter, sortType, update, reset } = filters;
    const { setDateFilter, setAccountsToSearch, setSortType } = update;

    const resetFilters = useCallback(() => {
      reset();
    }, [reset]);

    let sortMenu;
    if (hasSortMenu) {
      sortMenu = (
        <Grid item>
          <SortMenu value={sortType} onChange={setSortType} />
        </Grid>
      );
    }

    const saveDefaultParams = () => {
      setSavingData(true);
      const { accountsToSearch, dateFilter, sortType } = filters;

      const filterParams = firebase.firestore
        .collection("users")
        .doc(user?.uid)
        .collection("filters")
        .doc("PARAMS");

      const params = {
        dateFilter,
        sortType,
        accountsToSearch: [...accountsToSearch].map((item) => item.id),
      };

      const doc = JSON.parse(JSON.stringify(params));

      filterParams.set(doc, { merge: true });

      logDefaultSearchParamsSaved();
      setActualParams({ ...params });
      setSavingData(false);
      handleClose();
    };

    const chosenAccounts = () => {
      if (
        userApplications &&
        Object.keys(userApplications) &&
        Object.keys(userApplications).length === [...accountsToSearch].length
      ) {
        return "All";
      } else {
        return [...accountsToSearch]
          .map(
            (item) =>
              `${item.platform
                .charAt(0)
                .toUpperCase()
                .concat(item.platform.slice(1).toLowerCase())} (${item.account})`
          )
          .join(", ");
      }
    };

    const paramsChanged = () => {
      const acountsChanged = () => {
        return (
          JSON.stringify([...accountsToSearch].map((item) => item.id).sort()) !==
            JSON.stringify(actualParams.accountsToSearch.sort()) && [...accountsToSearch].length > 0
        );
      };
      if (actualParams) {
        return (
          dateFilter.type !== actualParams.dateFilter.type ||
          sortType !== actualParams.sortType ||
          acountsChanged()
        );
      } else if (!actualParams) {
        return dateFilter.type !== "Any" || sortType !== null || chosenAccounts() !== "All";
      } else {
        return false;
      }
    };

    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <AccountFilter
              userApplications={userApplications}
              loading={loadingApplications}
              value={accountsToSearch}
              onChange={setAccountsToSearch}
            />
          </Grid>

          <Grid item>
            <DateFilter value={dateFilter} onChange={setDateFilter} />
          </Grid>

          {sortMenu}

          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={resetFilters}
              className={classes.button}
            >
              <Typography display="inline" variant="subtitle2" color="textSecondary">
                Reset All
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Box display={paramsChanged() && !loadingApplications ? "inline" : "none"}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleClickOpen}
                className={classes.button}
              >
                <Typography display="inline" variant="subtitle2" color="textSecondary">
                  Save as default
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>

        <DialogValidationSaveFilters
          handleClose={handleClose}
          open={open}
          savedParams={actualParams}
          filters={filters}
          userApplications={userApplications}
          loadingApplications={loadingApplications}
          saveDefaultParams={saveDefaultParams}
          savingData={savingData}
        />
      </>
    );
  }
);

export default SearchFilters;
