import React, { useState } from "react";
import { useFirebase } from "components/Firebase";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { BeforeDowngradeDialog } from "components/Account/BeforeDowngradeDialog";
import { useConnectedApps } from "hooks/limitsCounters/useConnectedApps";
import { useXobaCards } from "hooks/limitsCounters/useXobaCards";
import { CONNECTED_APPS, XOBA_CARDS } from "constants/userLimits";

const useStyles = makeStyles((theme) => ({
  redButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
}));

const DowngradeButton = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const getConnectedApps = useConnectedApps();
  const getXobaCards = useXobaCards();

  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const [openBeforeDowngrade, setOpenBeforeDowngrade] = useState(false);
  const [appQty, setAppQty] = useState(0);
  const [cardQty, setCardQty] = useState(0);

  const handleDowngrade = async () => {
    setLoadingPortal(true);

    const functionRef = firebase.functions.httpsCallable(
      "ext-firestore-stripe-subscriptions-createPortalLink"
    );
    const { data } = await functionRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
  };

  const checkForDowngrade = async () => {
    const connectedApps = await getConnectedApps();
    const xobaCards = await getXobaCards();
    setAppQty(connectedApps.appQty);
    setCardQty(xobaCards.cardQty);
    // User needs to have lower numbers before it can downgrade
    connectedApps.appQty > CONNECTED_APPS || xobaCards.cardQty > XOBA_CARDS
      ? setOpenBeforeDowngrade(true)
      : handleDowngrade();
  };

  const closeBeforeDowngrade = () => setOpenBeforeDowngrade(false);

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.redButton}
        fullWidth
        onClick={() => checkForDowngrade()}
      >
        {loadingPortal ? <CircularProgress size={25} style={{ color: "white" }} /> : "Downgrade"}
      </Button>
      <BeforeDowngradeDialog
        open={openBeforeDowngrade}
        onClose={closeBeforeDowngrade}
        appQty={appQty}
        cardQty={cardQty}
      />
    </React.Fragment>
  );
};

export default DowngradeButton;
