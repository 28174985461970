import React from "react";

import ApplicationOauthDialogLayout, {
  ApplicationOauthDialogLayoutProps,
} from "./ApplicationOauthDialogLayout";
import PlatformIcon from "components/PlatformIcon";
import { useConnectApplication } from "actions/connectApplication";
import PLATFORMS from "constants/platforms";

type ApplicationOauthDialogProps = Partial<ApplicationOauthDialogLayoutProps> & {
  platform: string;
};

const ApplicationOauthDialog: React.FC<ApplicationOauthDialogProps> = ({
  open,
  platform,
  ...otherProps
}) => {
  const platformInstance = PLATFORMS.get(platform);
  const [
    connected,
    connecting,
    error,
    authOptions,
    handleSubmit,
    handleCancel,
    handleReset,
    setAuthOptions,
  ] = useConnectApplication(platformInstance, open ?? false);

  if (!platformInstance) return null;

  const config = platformInstance.oauthDialogConfig;

  return (
    // @ts-ignore
    <ApplicationOauthDialogLayout
      {...otherProps}
      open={open}
      authOptions={authOptions}
      setAuthOptions={setAuthOptions}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onReset={handleReset}
      submitting={connecting as boolean}
      error={error}
      connected={connected as boolean}
      icon={<PlatformIcon platform={platform} />}
      title={config.title}
      url={config.url}
      features={config.features}
      readOnlyDesc={platformInstance.readOnlyDesc}
    />
  );
};

export default ApplicationOauthDialog;
