import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format as dateFormat } from "timeago.js";
import { GridProps } from "@material-ui/core/Grid";

import { UserSearchRecommendationType } from "types";
import HighlightMatches from "./HighlightMatches";

// TODO: switch to theme palette colors
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2, 4)} !important`, // TODO: can we remove this hacky !important call?
    '&[data-focus="true"]': {
      backgroundColor: "#4a8df6",
      color: "white",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: "#2977f5",
      color: "white",
    },
  },
  meta: {
    opacity: 0.5,
  },
}));

export const metaInfo = (option: UserSearchRecommendationType) => {
  const meta = [
    option.searchCount &&
      `searched ${option.searchCount} time${option.searchCount !== 1 ? "s" : ""}`,
    option.lastSearchAt &&
      `last searched ${dateFormat(
        new Date(option.lastSearchAt.seconds * 1000),
        navigator.language
      )}`,
  ]
    .filter((s) => !!s)
    .join(", ");

  return meta;
};

type RecommendationItemProps = {
  option: UserSearchRecommendationType;
  value: string;
  index: number;
  getOptionProps: (_: any) => GridProps;
};

const RecommendationItem = ({ option, value, index, getOptionProps }: RecommendationItemProps) => {
  const classes = useStyles();

  return (
    <Grid item {...getOptionProps({ option, index })} className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <HighlightMatches option={option.searchTerm} value={value} />
        </Grid>
        <Grid item className={classes.meta}>
          {metaInfo(option)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecommendationItem;
