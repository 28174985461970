import React from "react";
import { Divider, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PaperProps } from "@material-ui/core/Paper";

import RecommendationItem from "./Item";
import { UserSearchRecommendationType } from "types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  paper: {
    width: "100%",
    zIndex: 1,
    position: "absolute",
    top: 0,
    overflow: "hidden",
    border: "1px solid rgba(0,0,0,.25)",
  },
}));

type RecommendationListProps = {
  recommendations: UserSearchRecommendationType[];
  value: string;
  getListboxProps: () => PaperProps;
  getOptionProps: any;
};

const RecommendationList = ({
  recommendations,
  value,
  getListboxProps,
  getOptionProps,
}: RecommendationListProps) => {
  const classes = useStyles();

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} {...getListboxProps()}>
        <Grid container direction="column" spacing={1}>
          {recommendations.map((option, index) => {
            return (
              <React.Fragment key={option.searchTerm}>
                {index !== 0 ? <Divider /> : null}

                <RecommendationItem
                  option={option}
                  value={value}
                  index={index}
                  getOptionProps={getOptionProps}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
};

export default RecommendationList;
