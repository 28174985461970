// Helper functions.

export const randomString = (n: number) => {
  return Math.random()
    .toString(36)
    .substr(2, n + 2);
};

export const sentenceCase = (str: string) => {
  return str.replace(/\w\S*/g, (s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase());
};

// TODO: support length
export const generateItemId = () => {
  return Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5);
};
