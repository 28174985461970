import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCollectionData, useDocumentData } from "hooks/firebase/firestore";
import { useObjectVal } from "hooks/firebase/database";

import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import Cards from "./Cards";
import { CardType, SpaceType, CardTypeEnum, SpaceLayoutType, UserApplicationsType } from "types";
import AddCardButton from "./AddCardButton";

import { NormalAlert } from "components/Alert";
import { generateItemId } from "utils";
import { useFeatureState } from "actions/updateFeatureState";
import { logFeatureStateRecentDocsCardBatchCreate } from "analytics";

import AspectRatioIcon from "@material-ui/icons/AspectRatio";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  noGrow: {
    flexGrow: 0,
  },
  actionIcon: {
    // opacity: 0.5,
    color: theme.palette.primary.light,
    textPrimary: theme.palette.primary.light,
    outlinedPrimary: theme.palette.primary.light,
    border: "2px solid",
    "&:hover": {
      color: theme.palette.primary.light,
      textPrimary: theme.palette.primary.light,
      outlinedPrimary: theme.palette.primary.light,
      border: "2px solid",
    },
  },
  button: {
    textPrimary: theme.palette.secondary.dark,
  },
}));

type EditingHeaderProps = {
  onDone: () => void;
};

const EditingHeader: React.FC<EditingHeaderProps> = ({ onDone }) => {
  return (
    <NormalAlert severity="info" action={<Button onClick={onDone}>Done</Button>}>
      Editing space. You can drag and resize your cards.
    </NormalAlert>
  );
};

const partOfDay = (date: Date) => {
  const hour = date.getHours();

  if (hour >= 18) {
    return "evening";
  } else if (hour >= 12) {
    return "afternoon";
  } else {
    return "morning";
  }
};

const PartOfDayGreeting = () => {
  const [part, setPart] = useState(partOfDay(new Date()));

  useEffect(() => {
    // TODO: schedule a timeout when we will cross the next boundary
    // Currently, every minute we recompute the partOfDay in case of changes.
    const interval = setInterval(() => {
      setPart(partOfDay(new Date()));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span>Good {part}!</span>;
};

type SpacesFeatureState = {
  initialized?: boolean;
  recentDocsInitialized?: boolean;
};

const Spaces: React.FC = () => {
  const classes = useStyles();
  const [user] = useCurrentUser();
  const firebase = useFirebase();
  const [editing, setEditing] = useState(false);

  const [featureState, loadingFeatureState, , updateFeatureState] = useFeatureState<
    SpacesFeatureState
  >("Cards Welcome");

  const docRef = firebase.firestore
    .collection("users")
    .doc(user?.uid)
    .collection("spaces")
    .doc("HOME");
  const cardsRef = docRef.collection("cards");

  const [space, spaceLoading, spaceError] = useDocumentData<SpaceType>(docRef);

  const [precards, cardsLoading, cardsError] = useCollectionData<CardType>(cardsRef, {
    idField: "id",
  });

  useEffect(() => {
    if (!loadingFeatureState && !cardsLoading) {
      if ((!precards || precards.length === 0) && !featureState?.initialized) {
        cardsRef.add({ type: CardTypeEnum.Welcome, title: "Xoba Cards" });
        updateFeatureState({ initialized: true });
      }
    }
  }, [precards, cardsRef, cardsLoading, featureState, loadingFeatureState, updateFeatureState]);

  const hasRecentDocs = useMemo(() => {
    return (precards || []).some((card) => card.type === CardTypeEnum.RecentDocs);
  }, [precards]);

  const [userApplications, userApplicationsLoading] = useObjectVal<UserApplicationsType>(
    firebase.userDbRef("userApplications")
  );

  const hasDocsApplication = useMemo(() => {
    if (userApplications) {
      return Object.values(userApplications).some((app) => {
        // TODO: handle Microsoft 365 which is complex because its scope tells us if it has OneDrive permissions
        return app.platform === "DRIVE" || app.platform === "BOX" || app.platform === "CONFLUENCE";
      });
    }
    return false;
  }, [userApplications]);

  useEffect(() => {
    if (!loadingFeatureState && !cardsLoading) {
      if (!featureState?.recentDocsInitialized) {
        if (hasRecentDocs) {
          updateFeatureState({ recentDocsInitialized: true });
        } else {
          if (!userApplicationsLoading && hasDocsApplication) {
            logFeatureStateRecentDocsCardBatchCreate();
            const batch = firebase.firestore.batch();

            ["modified", "shared", "viewed"].forEach((variant) => {
              batch.set(cardsRef.doc(), {
                type: CardTypeEnum.RecentDocs,
                variant,
              });
            });
            batch.commit();

            updateFeatureState({ recentDocsInitialized: true });
          }
        }
      }
    }
  }, [
    precards,
    cardsRef,
    cardsLoading,
    featureState,
    firebase.firestore,
    loadingFeatureState,
    updateFeatureState,
    hasRecentDocs,
    userApplicationsLoading,
    hasDocsApplication,
  ]);

  const cards = useMemo(() => {
    return (precards || []).map((card) => {
      const preitems = card.items || [];
      const ids = new Set();

      preitems.forEach((item) => {
        if (item.id) {
          ids.add(item.id);
        }
      });

      preitems.forEach((item) => {
        if (!item.id) {
          let newId = generateItemId();
          while (ids.has(newId)) {
            newId = generateItemId();
          }
          item.id = newId;
        }
      });

      return card;
    });
  }, [precards]);

  const [edittedLayouts, setEdittedLayouts] = useState<SpaceLayoutType | null>(null);

  const handleEditClick = useCallback(() => {
    setEditing(true);
  }, []);

  const handleEditDone = useCallback(async () => {
    setEditing(false);

    if (spaceLoading || cardsLoading || spaceError || cardsError || !edittedLayouts) {
      // don't update
      return;
    }

    // use a JSON stringify + parse trick to remove undefined values
    const docLayouts = JSON.parse(JSON.stringify(edittedLayouts));
    console.log("handleLayoutsChange", docLayouts);
    // use a JSON stringify + parse trick to remove undefined values
    await docRef.set({ layouts: docLayouts }, { merge: true });
    setEdittedLayouts(null);
  }, [spaceLoading, cardsLoading, spaceError, cardsError, docRef, edittedLayouts]);

  const handleLayoutsChange = useCallback((newLayouts) => {
    setEdittedLayouts(newLayouts);
  }, []);

  // Handle case where the space is not yet initialized.
  const layouts =
    edittedLayouts ||
    (space && space.layouts) ||
    (!spaceLoading && !spaceError && { lg: [], md: [] });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row">
        <Grid item className={classes.grow}>
          <Typography variant="h6">
            <PartOfDayGreeting /> Check out what's going on in your world.
          </Typography>
        </Grid>

        <Grid item className={classes.noGrow}>
          <AddCardButton cardsRef={cardsRef} />
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Edit the layout of your Xoba Space">
            <Button
              className={classes.actionIcon}
              disableElevation={true}
              variant="outlined"
              color="primary"
              startIcon={<AspectRatioIcon />}
              onClick={handleEditClick}
            >
              {" "}
              EDIT LAYOUT
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      {editing && (
        <Grid item>
          <EditingHeader onDone={handleEditDone} />
        </Grid>
      )}

      {precards && layouts && (
        <Grid item>
          <Cards
            cards={cards}
            layouts={layouts}
            onLayoutsChange={handleLayoutsChange}
            cardsRef={cardsRef}
            editing={editing}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Spaces;
