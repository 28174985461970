import React, { useState } from "react";

import WarningIcon from "@material-ui/icons/Warning";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FeedbackDialog from "components/Feedback/FeedbackDialog";
import { mailTo } from "components/Feedback/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    height: "3em",
    width: "3em",
  },
  buttonContainer: {
    marginTop: "1em",
  },
}));

const SentryFeedbackButton: React.FC = () => {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => {
        window.Sentry.showReportDialog();
      }}
    >
      Contact us
    </Button>
  );
};

const EmailFeedbackButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleSubmit = (body: string) => {
    mailTo({
      address: "feedback@xobalabs.com",
      subject: "Error report",
      body,
    });
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        Contact us
      </Button>

      <FeedbackDialog
        open={open}
        onClose={() => setOpen(false)}
        title="We are sorry you encountered an error"
        text="Making Xoba work for all users is our goal. Please let us know how this error affected you."
        label="Details"
        onSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

type FallbackProps = {
  eventId: string;
};

const Fallback: React.FC<FallbackProps> = ({ eventId }) => {
  const classes = useStyles();
  const hasSentry = !!window.Sentry;

  let reportButton;
  if (hasSentry) {
    reportButton = <SentryFeedbackButton />;
  } else {
    reportButton = <EmailFeedbackButton />;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "80vh" }}
      className={classes.root}
    >
      <Grid item>
        <WarningIcon fontSize="large" color="error" className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center">
          We apologize, there was an error while trying to load the page.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          Please refresh the page to try again
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          -- or --
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        {reportButton}
      </Grid>
    </Grid>
  );
};

export default Fallback;
