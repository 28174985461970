import WelcomeCard from "./WelcomeCard";
import ItemsCard from "./ItemsCard";
import ItemsCardConfigurationFields from "./ItemsCard/ConfigurationFields";
import RecentDocsCard from "./RecentDocsCard";
import SearchHistoryCard from "./SearchHistoryCard";
import SavedSearchCard from "./SavedSearchCard";
import SearchHistoryCardConfigurationFields from "./SearchHistoryCard/ConfigurationFields";
import RecentDocsCardConfigurationFields from "./RecentDocsCard/ConfigurationFields";
// import SavedSearchCardConfigurationFields from "./SavedSearchCard/ConfigurationFields";
import { HAS_SAVED_SEARCH_CARD } from "constants/features";

// TODO: map this onto CardTypeEnum
const lookup: { [_: string]: React.FC<any> | React.Component } = {
  welcome: WelcomeCard,
  items: ItemsCard,
  searchHistory: SearchHistoryCard,
  recentDocs: RecentDocsCard,
};

if (HAS_SAVED_SEARCH_CARD) {
  lookup.savedSearch = SavedSearchCard;
}

const lookupFn = (cardType: string) => {
  return lookup[cardType];
};

export default lookupFn;

const lookupConfiguration: { [_: string]: React.FC<any> | React.Component } = {
  items: ItemsCardConfigurationFields,
  searchHistory: SearchHistoryCardConfigurationFields,
  recentDocs: RecentDocsCardConfigurationFields,
};

// if (HAS_SAVED_SEARCH_CARD) {
//   lookup.savedSearch = SavedSearchCardConfigurationFields;
// }

export const lookupConfigurationFn = (cardType: string) => {
  return lookupConfiguration[cardType];
};
