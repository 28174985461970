export const mailTo = ({
  subject,
  body,
  address,
}: {
  subject: string;
  body: string;
  address: string;
}) => {
  window.open(
    `mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
    "_blank"
  );
};
