import app from "firebase/app";
import { sha256 } from "js-sha256";
import Firebase from "components/Firebase";

export const SEARCH = "SEARCH";
export const INTERACTION = "INTERACTION";

const updateDoc = (
  firebase: Firebase,
  searchTerm: string,
  updateFn: (_?: app.firestore.DocumentData) => app.firestore.DocumentData
) => {
  const collection = firebase.firestore.collection("user_searches").doc(firebase.currentUser().uid);
  const docId = sha256(searchTerm.toLowerCase());

  firebase.firestore.runTransaction(async (transaction) => {
    const ref = collection.collection("searches").doc(docId);

    const dbDoc = await transaction.get(ref);
    if (dbDoc.exists) {
      const data = dbDoc.data();
      const newData = updateFn(data);

      transaction.update(ref, {
        ...data,
        ...newData,
      });
    } else {
      const doc = updateFn();
      transaction.set(ref, doc);
    }
  });
};

const incrementSearchedTerm = (firebase: Firebase, searchTerm: string) => {
  updateDoc(firebase, searchTerm, (doc) => {
    const now = app.firestore.Timestamp.fromDate(new Date());

    if (!doc) {
      return {
        searchTerm: searchTerm.toLowerCase(),
        searchCount: 1,
        interactionCount: 0,
        lastSearchAt: now,
        lastInteractionAt: app.firestore.Timestamp.fromDate(new Date(0)),
      };
    }

    return {
      searchCount: (doc.searchCount ?? 1) + 1,
      lastSearchAt: now,
    };
  });
};

const incrementInteraction = (firebase: Firebase, searchTerm: string) => {
  updateDoc(firebase, searchTerm, (doc) => {
    const now = app.firestore.Timestamp.fromDate(new Date());

    if (!doc) {
      return {
        searchTerm: searchTerm.toLowerCase(),
        searchCount: 0,
        interactionCount: 1,
        lastSearchAt: app.firestore.Timestamp.fromDate(new Date(0)),
        lastInteractionAt: now,
      };
    }

    return {
      interactionCount: (doc.interactionCount ?? 1) + 1,
      lastInteractionAt: now,
    };
  });
};

export const update = (firebase: Firebase, searchTerm: string, type: string) => {
  if (type === SEARCH) {
    incrementSearchedTerm(firebase, searchTerm);
  } else if (type === INTERACTION) {
    incrementInteraction(firebase, searchTerm);
  }
};
