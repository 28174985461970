import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { logSignOut } from "analytics";
import { useFirebase } from "components/Firebase";
import * as ROUTES from "constants/routes";
import DelayedSpinner from "components/DelayedSpinner";

const SignOut = () => {
  const firebase: any = useFirebase(); // TODO remove any
  const history = useHistory();

  useEffect(() => {
    async function signOut() {
      await firebase.doSignOut();
      logSignOut();
      history.push(ROUTES.SIGN_IN);
    }

    signOut();
  }, [firebase, history]);

  return <DelayedSpinner size={50} />;
};

export default SignOut;
