import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { makeStyles, fade } from "@material-ui/core/styles";

import { logCardChange, CardChangeOperator } from "analytics";
import { CardTypeEnum } from "types";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  menuButton: {
    color: theme.palette.primary.light,
  },
  menuLayout: {
    border: "1px solid #E6E3F7",
    borderRadius: "4px",
    background: "trasnparent",
    shadow: "none",
    boxShadow: "0",
  },
}));

type CardMenuProps = {
  title: string;
  cardType: CardTypeEnum;
  onEdit?: () => void;
  onDelete?: () => void;
  onShare?: () => void;
};

const CardMenu: React.FC<CardMenuProps> = ({ title, cardType, onEdit, onDelete, onShare }) => {
  const classes = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "cardMenu" });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleEditClick = useCallback(() => {
    popupState.close();
    if (onEdit) {
      onEdit();
    }
  }, [popupState, onEdit]);

  const handleShareClick = useCallback(() => {
    popupState.close();
    if (onShare) {
      onShare();
    }
  }, [popupState, onShare]);

  const handleDeleteClick = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    popupState.close();
    setOpenConfirmDialog(false);
  }, [popupState]);

  const handleDeleteConfirmationClick = useCallback(() => {
    logCardChange({
      operation: CardChangeOperator.Delete,
      cardType,
    });

    popupState.close();
    if (onDelete) {
      onDelete();
    }
    setOpenConfirmDialog(false);
  }, [popupState, onDelete, cardType]);

  return (
    <React.Fragment>
      <IconButton
        className={classes.menuButton}
        aria-label="options"
        size="small"
        {...bindTrigger(popupState)}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        {...bindMenu(popupState)}
        // Opens menu below, not on top of, the button.
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.menuLayout}
      >
        {onEdit && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
        {onShare && <MenuItem onClick={handleShareClick}>Share</MenuItem>}
        {onDelete && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
      </Menu>

      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmation}>
        <DialogTitle>Delete card: "{title}"?</DialogTitle>

        <DialogContent dividers>You cannot undo this action.</DialogContent>

        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancel
          </Button>
          <Button
            color="secondary"
            className={classes.deleteButton}
            onClick={handleDeleteConfirmationClick}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CardMenu;
