import React, { useCallback, useMemo, useRef } from "react";

import { Layout, CompactType } from "./utils";
import {
  getBreakpointFromWidth,
  getColsFromBreakpoint,
  findOrGenerateResponsiveLayout,
  ResponsiveLayout,
  Breakpoints,
  Breakpoint,
} from "./responsiveUtils";
import ReactGridLayout from "./ReactGridLayout";
import { Props as ReactGridLayoutProps } from "./ReactGridLayoutPropTypes";

/**
 * Get a value of margin or containerPadding.
 *
 * @param  {Array | Object} param Margin | containerPadding, e.g. [10, 10] | {lg: [10, 10], ...}.
 * @param  {String} breakpoint   Breakpoint: lg, md, sm, xs and etc.
 * @return {Array}
 */

type MarginType = [number, number] | null | undefined;

function getIndentationValue(
  param: { [key: string]: MarginType } | MarginType,
  breakpoint: string
): MarginType {
  // $FlowIssue doesn't seem to understand this
  if (param == null) return null;
  return Array.isArray(param) ? param : param[breakpoint];
}

type ResponsiveReactGridLayoutProps = Omit<
  ReactGridLayoutProps,
  "cols" | "margin" | "containerPadding" | "compactType" | "onLayoutChange"
> & {
  breakpoint?: Breakpoint;
  breakpoints: Breakpoints;
  cols: { [key: string]: number };
  layouts: ResponsiveLayout;
  // width: number,
  margin: { [key: string]: MarginType } | MarginType;
  /* prettier-ignore */
  containerPadding: { [key: string]: MarginType } | MarginType,
  compactType: CompactType;
  onBreakpointChange: (arg0: Breakpoint, cols: number) => void;
  onLayoutChange?: ((arg0: Breakpoint, arg1: Layout) => void) | undefined;
};

const ResponsiveReactGridLayout: React.FC<ResponsiveReactGridLayoutProps> = ({
  breakpoint,
  breakpoints,
  width,
  layouts,
  margin,
  containerPadding,
  cols,
  compactType,
  children,
  onLayoutChange,
  onBreakpointChange,
  ...otherProps
}) => {
  const currentBreakpoint = useMemo(() => {
    return breakpoint || getBreakpointFromWidth(breakpoints, width);
  }, [breakpoint, breakpoints, width]);
  const breakpointRef = useRef(currentBreakpoint);

  const currentMargin = useMemo(() => {
    return getIndentationValue(margin, currentBreakpoint);
  }, [margin, currentBreakpoint]);

  const currentContainerPadding = useMemo(() => {
    return getIndentationValue(containerPadding, currentBreakpoint);
  }, [containerPadding, currentBreakpoint]);

  const currentCols = useMemo(() => {
    return getColsFromBreakpoint(currentBreakpoint, cols);
  }, [currentBreakpoint, cols]);

  const currentLayout = useMemo(() => {
    // Find or generate a new layout.
    const lastBreakpoint = breakpointRef.current;

    const res = findOrGenerateResponsiveLayout(
      layouts,
      breakpoints,
      currentBreakpoint,
      lastBreakpoint,
      currentCols,
      compactType
    );

    breakpointRef.current = currentBreakpoint;

    return res;
  }, [layouts, breakpoints, currentBreakpoint, currentCols, compactType]);

  // useEffect(() => {
  //   if (onLayoutChange) {
  //     onLayoutChange(currentBreakpoint, currentLayout);
  //   }
  //   // don't fire if onLayoutChange changes
  // }, [currentBreakpoint, currentLayout]);

  // useEffect(() => {
  //   // if (onBreakpointChange) {
  //   //   onBreakpointChange(currentBreakpoint, currentCols);
  //   // }
  //   console.log("onBreakpointChange", currentBreakpoint, currentCols, currentLayout);
  //   // don't fire if onBreakpointChange changes
  // }, [currentBreakpoint, currentCols, currentLayout]);

  const handleLayoutChange = useCallback(
    (newLayout: Layout) => {
      if (onLayoutChange) {
        onLayoutChange(currentBreakpoint, newLayout);
      }
    },
    [onLayoutChange, currentBreakpoint]
  );

  return (
    <ReactGridLayout
      {...otherProps}
      children={children}
      width={width}
      margin={currentMargin}
      containerPadding={currentContainerPadding}
      onLayoutChange={handleLayoutChange}
      layout={currentLayout}
      cols={currentCols}
      compactType={compactType}
    />
  );
};

ResponsiveReactGridLayout.defaultProps = {
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  layouts: {},
  margin: [10, 10],
  containerPadding: { lg: null, md: null, sm: null, xs: null, xxs: null },
};

export default ResponsiveReactGridLayout;
