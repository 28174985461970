import React, { useCallback, useEffect, useMemo, useState, SetStateAction } from "react";
import { useObjectVal } from "hooks/firebase/database";
import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import SearchBar from "./SearchBar";
import ConnectedApplications from "./ConnectedApplications";
import SearchFilters, { useSearchFilters } from "./SearchFilters";
import SearchResultsList from "./SearchResultsList";
import { defaultSortOptions } from "./SearchFilters/SortMenu";
import { useFirebase } from "components/Firebase";
import { useSearch } from "actions/search";
import { useCurrentUser } from "components/Session";
import PLATFORMS from "constants/platforms";
import SearchProvider from "./SearchProvider";
import useQueryParam from "hooks/useQueryParam";
import { UserApplicationsType } from "types";
import SaveSearchButton from "./SaveSearchButton";
import { HAS_SAVED_SEARCH_CARD } from "constants/features";

// Number of search results to request at once.
const NUM_RESULTS = 5;

const identifyFn = (val: any) => val;

const Search = ({ setSearching }: { setSearching: React.Dispatch<SetStateAction<boolean>> }) => {
  const firebase = useFirebase();
  const [user] = useCurrentUser();
  const [savedParams, setSavedParams]: any = useState("");
  const [unprocessedApps, loadingApplications] = useObjectVal<UserApplicationsType>(
    firebase.userDbRef("userApplications")
  );
  const userApplications = useMemo(() => {
    if (!unprocessedApps) {
      return undefined;
    }
    const res: UserApplicationsType = {};

    Object.keys(unprocessedApps).forEach((id) => {
      res[id] = {
        id: parseInt(id, 10),
        ...unprocessedApps[id],
      };
    });

    return res;
  }, [unprocessedApps]);

  const getSavedFilters = useCallback(() => {
    const filterParams = firebase.firestore
      .collection("users")
      .doc(user?.uid)
      .collection("filters")
      .doc("PARAMS");

    filterParams.get().then(function (doc) {
      if (doc.exists) {
        const params = doc.data();
        setSavedParams(params);
      }
    });
  }, [firebase, user]);

  useEffect(() => {
    if (!savedParams) {
      getSavedFilters();
    }
  });

  const filters = useSearchFilters(userApplications, loadingApplications, savedParams);
  const { accountsToSearch, dateFilter, sortType } = filters;
  // @ts-ignore
  const sortFunction = sortType ? defaultSortOptions[sortType] : identifyFn;

  // Search.
  const [searchParam, setSearchParam] = useQueryParam("search", "");
  const [value, setValue] = useState(searchParam);
  const [savedCard, setSavedCard] = useState(false);
  const {
    query,
    results,
    loading,
    error,
    run: runSearch,
    runNextPage: handleNextPage,
    hasNextPage,
  } = useSearch({
    initialValue: value as string,
    pageSize: NUM_RESULTS,
    filters: loadingApplications ? null : filters,
  });

  // TODO(piyush) The loading value returned by useSearch() is set based on
  // when the server's streaming HTTP chunks are finished. But the logic for
  // whether to display the loading spinner depends on what's visible to the
  // user, e.g. loading should be false if we're displaying enough results to
  // the user (even if the server isn't done streaming).

  const handleChange = useCallback((e, query) => {
    setValue(query);
  }, []);

  const handleRequestSearch = useCallback(
    (newValue: string) => {
      setSearching(true);
      setSearchParam(newValue);
    },
    [setSearchParam, setSearching]
  );

  const handleCancelSearch = useCallback(() => {
    setValue("");
    setSearchParam("");
    runSearch("", null); // Note: this clears the results but doesn't actually run the search request
    setSearching(false);
  }, [runSearch, setSearchParam, setSearching]);

  useEffect(() => {
    if (searchParam !== query) {
      setValue(searchParam);
      runSearch(searchParam as string, null);
    }
  }, [runSearch, searchParam, query]);

  // Apply filters and other transformations to any displayed results.
  const resultsToDisplay = useMemo(() => {
    const supportedPlatforms = new Set(PLATFORMS.all());

    const dateRange = dateFilter && dateFilter.dateRange && dateFilter.dateRange();
    const accounts = new Set(Array.from(accountsToSearch).map(({ id }) => id));

    return results
      .filter((result) => supportedPlatforms.has(result.platform))
      .filter((result) => accounts.has(result.user_application_id))
      .filter((result) => {
        if (!dateRange) {
          return true;
        }

        const ts = Date.parse(result.date).valueOf();
        return (
          (!dateRange[0] || dateRange[0].valueOf() <= ts) &&
          (!dateRange[1] || ts <= dateRange[1].valueOf())
        );
      })
      .sort(sortFunction);
  }, [results, accountsToSearch, dateFilter, sortFunction]);

  const hasMore = hasNextPage(accountsToSearch);

  const searchContext = useMemo(() => {
    return {
      query,
    };
  }, [query]);

  const accountsConnected = useMemo(() => {
    return (
      (userApplications && [Object.values(userApplications)].length > 0) || loadingApplications
    );
  }, [userApplications, loadingApplications]);

  return (
    <SearchProvider value={searchContext}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <SearchBar
            onRequestSearch={handleRequestSearch}
            onCancelSearch={handleCancelSearch}
            value={value as string}
            onChange={handleChange}
            accountsConnected={accountsConnected}
          />

          <ConnectedApplications
            userApplications={userApplications}
            loadingApplications={loadingApplications}
            filters={filters}
          />

          {accountsConnected && !loadingApplications ? (
            <SearchFilters
              userApplications={userApplications}
              loadingApplications={loadingApplications}
              hasSortMenu
              filters={filters}
              savedParams={savedParams}
            />
          ) : null}

          {HAS_SAVED_SEARCH_CARD && resultsToDisplay.length > 0 ? (
            <SaveSearchButton query={query} filters={filters} setSavedCard={setSavedCard} />
          ) : null}

          {savedCard ? (
            <Alert
              severity="success"
              onClose={() => {
                setSavedCard(false);
              }}
            >
              Card Saved Succesfully
            </Alert>
          ) : null}
        </Grid>

        <Grid item>
          <SearchResultsList
            results={resultsToDisplay}
            loading={loading.main || loading.more}
            onNext={handleNextPage}
            hasMore={hasMore}
            error={error}
          />
        </Grid>
      </Grid>
    </SearchProvider>
  );
};

export default Search;
