import { useCallback, useEffect } from "react";
import { logUnlinkPlatform } from "analytics";
import { useFirebase } from "components/Firebase";
import useLoadingStatus from "hooks/useLoadingStatus";
import Firebase from "components/Firebase";
import { UserApplicationType } from "types";

type removeApplicationArgs = {
  firebase: Firebase;
  application: UserApplicationType;
};

const removeApplication = async ({ application, firebase }: removeApplicationArgs) => {
  const { platform, id } = application;

  logUnlinkPlatform(platform);

  if (!id) {
    throw new Error("Cannot delete an application without an ID");
  }

  // NOTE: A Firebase cloud function will mark the record in PG as deleted
  firebase.userDbRef().child("userApplications").child(`${id}`).remove();
};

export default removeApplication;

// hooks must start with `use`
export const useRemoveApplication = (open: boolean) => {
  const firebase = useFirebase();
  const { status, start, finish, reset } = useLoadingStatus();

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [reset, open]);

  const run = useCallback(
    async (application) => {
      start();
      await removeApplication({ application, firebase });
      finish();
    },
    [firebase, start, finish]
  );

  return {
    status,
    run,
  };
};
