import React from "react";
import clsx from "clsx";

import { Avatar, Box, Chip, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { indigo } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { centerStyle, displayDate } from "../../utils";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import { AsanaSearchResultType, AsanaAssignee, AsanaMembershipType, AsanaTagType } from "types";

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    opacity: "50%",
    position: "relative",
  },
  statusIconCompleted: {
    // Color is bright green
    color: "#00e64d",
  },
  avatarPhoto: {
    width: 30,
    height: 30,
  },
  avatarPhotoMissing: {
    fontSize: 14,
    backgroundColor: indigo[300],
  },
}));

const StatusIcon: React.FC<{ completed: boolean }> = ({ completed }) => {
  const classes = useStyles();

  if (completed) {
    return (
      <CheckCircleIcon
        fontSize="large"
        className={clsx(classes.statusIcon, classes.statusIconCompleted)}
      />
    );
  }

  return <CheckCircleOutlineIcon fontSize="large" className={classes.statusIcon} />;
};

const defaultColor = "#919191"; // Light grey

const getInitials = (name: string) => name.split(" ").map((s) => s[0]);

const useTagStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const Tags: React.FC<{ tags: AsanaTagType[] }> = ({ tags }) => {
  const classes = useTagStyles();

  const items = tags.map((tag) => {
    return <Chip key={tag.name} label={tag.name} size="small" />;
  });

  return <Box className={classes.root}>{items}</Box>;
};

const Assignee: React.FC<{ assignee: AsanaAssignee | null }> = ({ assignee }) => {
  const classes = useStyles();

  if (assignee != null) {
    return (
      <Grid container spacing={1} direction="row">
        <Grid item>
          <Avatar
            src={assignee.photo}
            className={clsx(
              classes.avatarPhoto,
              assignee.photo == null && classes.avatarPhotoMissing
            )}
          >
            {assignee.photo == null ? getInitials(assignee.name) : null}
          </Avatar>
        </Grid>
        <Grid item>
          <div style={{ ...centerStyle, color: "black" }}>{assignee.name}</div>
        </Grid>
      </Grid>
    );
  } else {
    return <div style={{ color: defaultColor }}>Unassigned</div>;
  }
};

const DueDate: React.FC<{ date: string | null }> = ({ date }) => {
  if (date != null) {
    return <div>{displayDate(date)}</div>;
  } else {
    return <div style={{ color: defaultColor }}>No due date</div>;
  }
};

const Membership: React.FC<AsanaMembershipType> = ({ project, section }) => {
  const roundedSquare = {
    height: "10px",
    width: "10px",
    backgroundColor: project.color || "red", // Red by default
    borderRadius: "3px",
  };

  const nameStyle = {
    color: "black",
    display: "inline-block",
    paddingLeft: "5px",
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <div style={centerStyle}>
          <div style={{ ...roundedSquare, display: "inline-block" }} />
          <div style={nameStyle}>{project.name}</div>
        </div>
      </Grid>
      <Grid item>{section.name}</Grid>
    </Grid>
  );
};

const Memberships: React.FC<{ memberships: AsanaMembershipType[] }> = ({ memberships }) => {
  if (memberships.length > 0) {
    const items = memberships.map(({ project, section }) => {
      return (
        <Grid item key={project.name}>
          <Membership project={project} section={section} />
        </Grid>
      );
    });

    return (
      <Grid container direction="row" spacing={2}>
        {items}
      </Grid>
    );
  } else {
    return <div style={{ color: defaultColor }}>No project</div>;
  }
};

const getTaskProperties = (result: AsanaSearchResultType) => {
  const assignee = <Assignee assignee={result.assignee} />;
  const dueDate = <DueDate date={result.due_date} />;
  const memberships = <Memberships memberships={result.memberships} />;

  const taskProperties: { [_: string]: React.ReactNode } = {
    Assignee: assignee,
    "Due date": dueDate,
    Projects: memberships,
    //'Priority': //TODO(piyush)
  };

  if (result.tags.length > 0) {
    taskProperties["Tags"] = <Tags tags={result.tags} />;
  }

  return taskProperties;
};

const AsanaSearchResult: React.FC<{ result: AsanaSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const header = result.title;

  const taskProperties = getTaskProperties(result);

  const subheader = (
    <Grid container spacing={1}>
      {Object.entries(taskProperties).map(([key, val]) => (
        <Grid item container direction="row" spacing={2} key={key}>
          <Grid item style={centerStyle}>
            <Typography variant="caption">{key}</Typography>
          </Grid>
          <Grid item>{val}</Grid>
        </Grid>
      ))}
    </Grid>
  );

  const avatar = <StatusIcon completed={result.completed} />;

  const body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
    />
  );
};

export default AsanaSearchResult;
