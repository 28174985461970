import { useCallback } from "react";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { TOTAL_SEARCHES } from "constants/userLimits";

export const useTotalSearches = () => {
  const firebase = useFirebase();
  const [, , premiumUser] = useCurrentUser();

  const getTotalSearches = useCallback(async () => {
    const userSearchesRef = firebase.firestore
      .collection("user_searches")
      .doc(firebase.currentUser().uid);
    const getUserSearches = await userSearchesRef.get();

    // Get number of searches
    const searchQty = getUserSearches.data()?.total_searches;
    // Have we reached the total searches limit
    const isSearchesLimitReached = searchQty >= TOTAL_SEARCHES;
    // Possible to add new searches if Premium user, or if Free but limit is not reached
    const isNewSearchPossible = premiumUser ? true : isSearchesLimitReached ? false : true;

    return { searchQty, isSearchesLimitReached, isNewSearchPossible };
  }, [firebase, premiumUser]);

  return getTotalSearches;
};

export const useResetTotalSearches = () => {
  const firebase = useFirebase();

  const resetTotalSearches = useCallback(async () => {
    const userSearchesRef = firebase.firestore
      .collection("user_searches")
      .doc(firebase.currentUser().uid);
    await userSearchesRef.set({ total_searches: 0 });
  }, [firebase]);

  return resetTotalSearches;
};
