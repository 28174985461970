import { useCallback, useState } from "react";

export const PRISTINE = "pristine";
export const RUNNING = "running";
export const FINISHED = "finished";

export type STATUS = "pristine" | "running" | "finished";
export type ERROR = null | string;

const useLoadingStatus = () => {
  const [status, setStatus] = useState<STATUS>(PRISTINE);
  const [error, setError] = useState<ERROR>(null);

  const start = useCallback(() => {
    setStatus(RUNNING);
    setError(null);
  }, []);

  const finish = useCallback(() => {
    setStatus(FINISHED);
  }, []);

  const fail = useCallback((message: ERROR) => {
    setStatus(FINISHED);
    setError(message);
  }, []);

  const reset = useCallback(() => {
    setStatus(PRISTINE);
    setError(null);
  }, []);

  return {
    status,
    error,
    start,
    finish,
    fail,
    reset,
  };
};

export default useLoadingStatus;
