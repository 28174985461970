import * as PLATFORMS from "constants/platforms";
import AsanaSearchResult from "./AsanaSearchResult";
import BoxSearchResult from "./BoxSearchResult";
import ConfluenceSearchResult from "./ConfluenceSearchResult";
import DriveSearchResult from "./DriveSearchResult";
import DropboxSearchResult from "./DropboxSearchResult";
import EmailSearchResult from "./EmailSearchResult";
import JiraSearchResult from "./JiraSearchResult";
import Microsoft365SearchResult from "./Microsoft365SearchResult";
import SlackSearchResult from "./SlackSearchResult";
import GithubSearchResult from "./GithubSearchResult";

const lookup: { [_: string]: React.FC<any> | React.Component } = {
  [PLATFORMS.ASANA.name]: AsanaSearchResult,
  [PLATFORMS.BOX.name]: BoxSearchResult,
  [PLATFORMS.CONFLUENCE.name]: ConfluenceSearchResult,
  [PLATFORMS.DRIVE.name]: DriveSearchResult,
  [PLATFORMS.DROPBOX.name]: DropboxSearchResult,
  // Both Gmail and Outlook results represent emails and so are
  // structured according to the same schema.
  [PLATFORMS.GITHUB.name]: GithubSearchResult,
  [PLATFORMS.GMAIL.name]: EmailSearchResult,
  [PLATFORMS.MICROSOFT_365.name]: Microsoft365SearchResult,
  [PLATFORMS.JIRA.name]: JiraSearchResult,
  [PLATFORMS.SLACK.name]: SlackSearchResult,
};

const lookupFn = (platform: string) => {
  return lookup[platform];
};

export default lookupFn;
