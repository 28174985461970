import React from "react";
import Typography from "@material-ui/core/Typography";

import { SnippetType } from "types";

type SnippetArgs = {
  snippet: string;
  indices: number[][];
  wordAligned: boolean;
  isFirst: boolean;
};

const Snippet = ({ snippet, indices, wordAligned, isFirst }: SnippetArgs) => {
  // Bold substrings (representing search matches) by their index.
  let parts = [];

  if (!isFirst) {
    parts.push("\n"); // Display snippets on different lines
  }

  if (Array.isArray(indices) && indices.length > 0) {
    let finalEnd;
    indices.forEach(([start, end], j) => {
      parts.push(snippet.slice(j > 0 ? indices[j - 1][1] : 0, start));
      parts.push(<strong key={j}>{snippet.slice(start, end)}</strong>);
      finalEnd = end;
    });
    if (finalEnd) {
      parts.push(snippet.slice(finalEnd));
    }
  } else {
    parts = [snippet];
  }

  // If the snippet couldn't be made word-aligned, wrap in ellipses.
  if (!wordAligned) {
    parts.unshift("... ");
    parts.push(" ...");
  }

  return <span>{parts}</span>;
};

type SnippetProps = {
  snippets?: SnippetType[];
  fallback?: string;
};

const Snippets: React.FC<SnippetProps> = ({ snippets, fallback }) => {
  const res = (snippets || []).map(([snippet, indices, wordAligned], i) => {
    return (
      <Snippet
        key={i}
        snippet={snippet}
        indices={indices}
        wordAligned={wordAligned}
        isFirst={i === 0}
      />
    );
  });

  return <Typography variant="caption">{(res.length > 0 && res) || fallback}</Typography>;
};

export default Snippets;
