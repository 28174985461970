import React, { useCallback, useMemo } from "react";
import { firestore } from "firebase";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Input,
  InputAdornment,
} from "@material-ui/core";
import PublicIcon from "@material-ui/icons/Public";
import { makeStyles } from "@material-ui/core/styles";

import { useCollectionData } from "hooks/firebase/firestore";
import { CardType, ShareTypeEnum, ShareType, CardShareType } from "types";
import DelayedSpinner from "components/DelayedSpinner";
import CopyButton from "components/CopyButton";
import { useFirebase } from "components/Firebase";
import { logSharingEnabled, logSharingDisabled } from "analytics/sharing";

const useStyles = makeStyles((theme) => ({
  shareText: {
    paddingRight: theme.spacing(2),
  },
}));

type ShareCardDialogProps = {
  open: boolean;
  onClose: () => void;
  card: CardType;
  cardsRef: firestore.CollectionReference;
  uid: string;
};

type LoadedCardShareType = CardShareType & {
  id: string;
  ref: firestore.DocumentReference;
};

const ShareCardDialog: React.FC<ShareCardDialogProps> = ({
  uid,
  open,
  onClose,
  card,
  cardsRef,
}) => {
  const classes = useStyles();
  const firebase = useFirebase();

  const cardId = card.id;
  const cardRef = useMemo(() => {
    return cardsRef.doc(cardId);
  }, [cardsRef, cardId]);

  // TODO: limit 1?
  const [shares, sharesLoading] = useCollectionData<LoadedCardShareType>(
    firebase.firestore
      .collection("shares")
      .where("uid", "==", uid)
      .where("shareRef", "==", cardRef)
      .limit(1),
    { idField: "id", refField: "ref" }
  );

  const shareConfig = useMemo(() => {
    if (shares) {
      return shares[0];
    }
  }, [shares]);
  const sharedOnWeb = shareConfig?.accessConfig.web;

  const handleWebShareToggle = useCallback(async () => {
    if (sharedOnWeb && shareConfig) {
      // unshare
      logSharingDisabled({ type: ShareTypeEnum.Card });
      firebase.firestore.runTransaction(async (transaction) => {
        const { accessConfig } = (await transaction.get(shareConfig.ref)).data() as ShareType;
        await transaction.update(shareConfig.ref, {
          accessConfig: {
            ...accessConfig,
            web: false,
          },
        });
      });
    } else {
      // share
      logSharingEnabled({ type: ShareTypeEnum.Card });

      if (shareConfig) {
        firebase.firestore.runTransaction(async (transaction) => {
          const { accessConfig } = (await transaction.get(shareConfig.ref)).data() as ShareType;
          await transaction.update(shareConfig.ref, {
            accessConfig: {
              ...accessConfig,
              web: true,
            },
          });
        });
      } else {
        // TODO: use a transaction
        const shareDoc: ShareType = {
          accessConfig: {
            web: true,
          },
          type: ShareTypeEnum.Card,
          shareRef: cardRef,
          uid,
        };
        firebase.firestore.collection("shares").add(shareDoc);
      }
    }
  }, [sharedOnWeb, shareConfig, firebase.firestore, uid, cardRef]);

  let link;
  if (sharedOnWeb && shareConfig) {
    const shareUrl = `${process.env.REACT_APP_FRONTEND || window.location.origin}/shares/${
      shareConfig.ref.id
    }`;
    link = (
      <ListItem key="webLink">
        <Input
          fullWidth
          disableUnderline
          type="text"
          value={shareUrl}
          endAdornment={
            <InputAdornment position="end">
              <CopyButton text={shareUrl} />
            </InputAdornment>
          }
        />
      </ListItem>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Share "{card.title}"</DialogTitle>

      <DialogContent dividers>
        {sharesLoading && <DelayedSpinner />}
        {!sharesLoading && (
          <List>
            <ListItem key="web">
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.shareText}
                id="card-share-label-toggle"
                primary="Share with Anyone"
                secondary="Anyone with this link can view this Card"
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleWebShareToggle}
                  checked={!!sharedOnWeb}
                  inputProps={{ "aria-labelledby": "card-share-label-toggle" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {link}
          </List>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareCardDialog;
