import React, { useState, useEffect } from "react";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";

// delay to avoid a show flash if loading is fast
type DelayedSpinnerProps = CircularProgressProps & {
  delay?: number;
};

const DelayedSpinner = ({ delay = 1000, ...otherProps }: DelayedSpinnerProps) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, delay);

    return function cleanup() {
      clearTimeout(timeout);
    };
  });

  if (!visible) {
    return null;
  }

  return <CircularProgress size={25} {...otherProps} />;
};

export default DelayedSpinner;
