import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import PlatformIcon from "components/PlatformIcon";
import { APPLICATIONS } from "constants/routes";
import DelayedSpinner from "components/DelayedSpinner";
import { NormalAlert } from "components/Alert";
import PLATFORMS from "constants/platforms";
import { UserApplicationsType } from "types";
import { logApplicationFilterToggle, logApplicationBulkFilterToggle } from "analytics";
import { useApplicationState } from "actions/updateApplicationState";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  platformIcon: {
    marginLeft: theme.spacing(1),
  },
  addCircleButton: {
    marginLeft: theme.spacing(1),
  },
  addCircle: {
    color: theme.palette.grey[400],
    cursor: "pointer",
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  alertItem: {
    flexGrow: 1,
  },
  sizeSmall: {
    fontSize: "14px",
  },
  filterButtons: {
    borderColor: theme.palette.grey[400],
    marginRight: "5px",
    marginLeft: "5px",
    padding: "0px 9px",
  },
}));

const ConnectedApplications = ({
  userApplications,
  loadingApplications,
  filters,
}: {
  userApplications: UserApplicationsType | undefined;
  loadingApplications: boolean;
  filters: any;
}) => {
  const { accountsToSearch, update } = filters;

  const { setAccountsToSearch } = update;

  const classes = useStyles();

  const history = useHistory();

  type NoApplicationsMesssageStates = {
    closed: boolean;
  };

  const [applicationState, , , updateApplicationState] = useApplicationState<
    NoApplicationsMesssageStates
  >();

  const handleClick = useCallback(() => {
    history.push(APPLICATIONS);
  }, [history]);

  const handleCheckAll = useCallback(() => {
    logApplicationBulkFilterToggle({ selected: true });
    setAccountsToSearch(new Set(Object.values(userApplications || {})));
  }, [setAccountsToSearch, userApplications]);

  const handleClearAll = useCallback(() => {
    logApplicationBulkFilterToggle({ selected: false });
    setAccountsToSearch(new Set());
  }, [setAccountsToSearch]);

  const handleClickIcon = useCallback(
    (applicationGroup) => {
      const newValue = new Set(accountsToSearch);
      const isAGroup = applicationGroup.length > 1;
      const accountsToSearchSize = [...accountsToSearch].filter(
        (item) => item.platform === applicationGroup[0].platform
      ).length;
      const groupSomeItemsSelected =
        isAGroup && accountsToSearchSize !== applicationGroup.length && accountsToSearchSize > 0;

      applicationGroup.forEach((application: any) => {
        const { platform, account } = application;
        const selected = accountsToSearch.has(application);
        logApplicationFilterToggle({ platform, account, selected });

        if (selected && !isAGroup) {
          newValue.delete(application);
        } else if (!selected && !isAGroup) {
          newValue.add(application);
        } else if (selected && isAGroup && groupSomeItemsSelected) {
          newValue.delete(application);
        } else if (!selected && isAGroup && groupSomeItemsSelected) {
          newValue.delete(application);
        } else if (!selected && isAGroup && !groupSomeItemsSelected) {
          newValue.add(application);
        } else if (selected && isAGroup && !groupSomeItemsSelected) {
          newValue.delete(application);
        }
      });

      setAccountsToSearch(newValue);
    },
    [setAccountsToSearch, accountsToSearch]
  );

  if (loadingApplications) {
    return (
      <Grid container className={classes.root} alignItems="center">
        <Grid item>
          <DelayedSpinner size={20} />
        </Grid>
      </Grid>
    );
  }

  const handleCloseMessageAlert = () => {
    updateApplicationState({ closed: true });
  };

  if (
    (!userApplications || Object.keys(userApplications).length === 0) &&
    !applicationState?.closed
  ) {
    return (
      <Grid container className={classes.root}>
        <Grid item className={classes.alertItem}>
          <NormalAlert onClose={handleCloseMessageAlert} severity="info">
            You don't have any connected applications to search. If you'd like to search,{" "}
            <Link to={APPLICATIONS}>connect</Link> at least one application.
          </NormalAlert>
        </Grid>
      </Grid>
    );
  }

  const supportedPlatforms = new Set(PLATFORMS.all());
  const platformsSet = new Set(
    Object.values(userApplications || {})
      .map(({ platform }) => platform)
      .filter((platform) => supportedPlatforms.has(platform))
  );

  const platformSearchIconDisbale = (platform: string) => {
    return (
      [...accountsToSearch].filter((application) => application.platform === platform).length === 0
    );
  };

  const applications =
    userApplications &&
    Object.keys(userApplications).map((id) => {
      return userApplications[id];
    });

  const handleMultiplePlatforms = (platform: string) => {
    const applicationGroup =
      applications && applications.filter((application) => application.platform === platform);
    handleClickIcon(applicationGroup);
  };

  const icons = Array.from(platformsSet).map((platform) => {
    return (
      <Grid key={platform} item className={classes.platformIcon}>
        <IconButton
          classes={{ sizeSmall: classes.sizeSmall }}
          size="small"
          onClick={() => {
            handleMultiplePlatforms(platform);
          }}
        >
          <PlatformIcon
            platform={platform}
            fontSize="inherit"
            disabled={platformSearchIconDisbale(platform)}
          />
        </IconButton>
      </Grid>
    );
  });

  let addLink;

  if (process.env.REACT_APP_IS_EXTENSION !== "true") {
    addLink = (
      <Grid item>
        <IconButton size="small" onClick={handleClick} className={classes.addCircleButton}>
          <AddCircleOutlineIcon className={classes.addCircle} fontSize="small" />
        </IconButton>
      </Grid>
    );
  }

  const filterButtons = (
    <Box ml="5px">
      <Button
        size="small"
        color="primary"
        variant="outlined"
        className={classes.filterButtons}
        onClick={handleClearAll}
      >
        Clear All
      </Button>

      <Button
        size="small"
        color="primary"
        variant="outlined"
        className={classes.filterButtons}
        onClick={handleCheckAll}
      >
        Select All
      </Button>
    </Box>
  );

  if (!userApplications || Object.keys(userApplications).length === 0) {
    return null;
  }

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          Filter Applications
        </Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <Box display="flex" alignItems="center">
            {icons}
            {addLink}
            {filterButtons}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectedApplications;
