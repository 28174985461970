import { useCallback } from "react";
import { useAuthorizedFetch } from "utils/xobaFetch";
import { logSearchClick, logSearchResultInteraction } from "analytics";
import { FetchType } from "types";

const api = (data: any, fetch: FetchType) => {
  const url = new URL(`analytics/search/interaction`, process.env.REACT_APP_BACKEND);
  const body = JSON.stringify(data);

  return fetch(url.toString(), { credentials: "include", method: "POST", body });
};

export const useLogSearchInteraction = () => {
  const fetch = useAuthorizedFetch();

  const run = useCallback(
    (data) => {
      api(data, fetch);
      if (data.interactionType === "click") {
        logSearchClick(data.position, data.platform);
      } else {
        logSearchResultInteraction(data);
      }
    },
    [fetch]
  );

  return run;
};
