import React from "react";

import cardTypeComponents from "./cardTypes";
import UnsupportedCard from "./cardTypes/UnsupportedCard";

export const supportsCardType = (cardType: string) => {
  return !!cardTypeComponents(cardType);
};

const XCard: React.FC<any> = React.memo(({ card, ...otherProps }) => {
  const type = card.type || "items";
  const Component = cardTypeComponents(type) || UnsupportedCard;

  // @ts-ignore
  return <Component card={card} {...otherProps} />;
});

export default XCard;
