import { UserSearchRecommendationType } from "types";

import List from "./List";

export default List;

export const defaultSort = (a: UserSearchRecommendationType, b: UserSearchRecommendationType) => {
  if (a.searchCount > b.searchCount) {
    return -1;
  } else if (a.searchCount < b.searchCount) {
    return 1;
  } else {
    return a.lastSearchAt.seconds > b.lastSearchAt.seconds ? -1 : 1;
  }
};
