import React, { useContext } from "react";
import Firebase from "./firebase";

const FirebaseContext = React.createContext<Firebase>(undefined!);

// Hook providing access to the global Firebase singleton
export const useFirebase = () => {
  return useContext(FirebaseContext);
};

export default FirebaseContext;
