import { Platform } from "constants/platforms";
import { FetchType } from "types";
import UserFacingError from "utils/UserFacingError";

type authPostArgs = {
  fetch: FetchType;
  platform: Platform;
  scope?: string;
  redirectUri: string;
  params: {
    code: string;
  };
};

export type AuthContinueType = {
  account: string,
  auth_data: {
    sites?: {
      [key: string]: {
        avatarUrl: string;
        name: string;
        url: string;
      };
    };
  };
};

async function authPost({ fetch, platform, scope, redirectUri, params }: authPostArgs): Promise<AuthContinueType> {
  // Exchange OAuth authorization code in the backend for refresh and access
  // tokens
  const backendUrl = new URL("applications/", process.env.REACT_APP_BACKEND);

  const body = JSON.stringify({
    platform: platform.name,
    code: params.code,
    scopes: [scope],
    // Dummy redirect that won't be used but may need to match the
    // redirect URI sent in the original OAuth request.
    redirect_uri: redirectUri,
  });

  // Clean response from backend means data was successfully uploaded.
  const response = await fetch(backendUrl.toString(), { credentials: "include", method: "POST", body });
  if (!response.ok) {
    // TODO: return better error messages
    throw new UserFacingError("Couldn't exchange authorization code");
  }

  return await response.json();
};

export default authPost;

export async function authPostContinue(fetch: FetchType, data: any) {
  const url = new URL("applications/continue", process.env.REACT_APP_BACKEND);
  const body = JSON.stringify(data);
  await fetch(url.toString(), { credentials: "include", method: "POST", body: body });
}
