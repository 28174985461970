import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { centerStyle } from "../../utils";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import { JiraSearchResultType } from "types";

const useStyles = makeStyles((theme) => {
  return {
    side: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    status: {
      borderRadius: "2px",
      paddingLeft: "9px",
      paddingRight: "9px",
      paddingTop: "5px",
      paddingBottom: "5px",
      display: "inline-block",
      fontWeight: "bold",
    },
    label: {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "3px",
      color: "black",
      margin: "2px",
      padding: "2px",
      fontSize: 12,
    },
    propertyKey: {
      fontSize: 12,
      fontWeight: "bold",
    },
    lightGrey: {
      color: theme.palette.grey[600],
    },
  };
});

type AtlassianIconProps = {
  name: string;
  url: string;
  size: number;
};

const AtlassianIcon: React.FC<AtlassianIconProps> = ({ name, url, size }) => {
  return (
    <Grid container spacing={4} direction="row">
      <Grid item xs={1} style={centerStyle}>
        <Avatar src={url} style={{ height: size, width: size }} />
      </Grid>

      <Grid item>
        <div style={{ ...centerStyle, color: "black", fontSize: 13 }}>{name}</div>
      </Grid>
    </Grid>
  );
};

type PropertiesProps = {
  result: JiraSearchResultType;
};

const Properties: React.FC<PropertiesProps> = ({ result }) => {
  const classes = useStyles();

  let assignee;
  if (result.assignee != null) {
    assignee = (
      <AtlassianIcon
        name={result.assignee.displayName}
        url={result.assignee.avatarUrls["24x24"]}
        size={24}
      />
    );
  } else {
    assignee = <div className={classes.lightGrey}>{"Unassigned"}</div>;
  }

  let reporter;
  if (result.reporter != null) {
    reporter = (
      <AtlassianIcon
        name={result.reporter.displayName}
        url={result.reporter.avatarUrls["24x24"]}
        size={24}
      />
    );
  } else {
    reporter = <div className={classes.lightGrey}>{"No reporter"}</div>;
  }

  let labels;
  if (result.labels != null) {
    labels = (
      <Grid key="labels" item container direction="column">
        <Grid item className={classes.propertyKey}>
          Labels
        </Grid>
        <Grid item>
          <Grid container direction="row">
            {result.labels.map((label) => (
              <Grid item className={classes.label}>
                {label}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  let priority;
  if (result.priority != null) {
    priority = (
      <Grid key="priority" item container direction="column">
        <Grid item className={classes.propertyKey}>
          Priority
        </Grid>
        <Grid item>
          <AtlassianIcon name={result.priority.name} url={result.priority.iconUrl} size={17} />
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Grid key="assignee" item container direction="column">
        <Grid item className={classes.propertyKey}>
          Assignee
        </Grid>
        <Grid item>{assignee}</Grid>
      </Grid>

      <Grid key="reporter" item container direction="column">
        <Grid item className={classes.propertyKey}>
          Reporter
        </Grid>
        <Grid item>{reporter}</Grid>
      </Grid>

      {labels}
      {priority}
    </React.Fragment>
  );
};

const JiraSearchResult: React.FC<{ result: JiraSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const classes = useStyles();
  // Display issue name, with key and icon above.
  const header = (
    <Grid container direction="column">
      <Grid container item direction="row">
        <Grid item style={{ ...centerStyle, fontSize: 15, paddingRight: "4px" }}>
          <SvgIcon fontSize="inherit" viewBox="0 0 15 15">
            <image href={result.issue_details.icon} />
          </SvgIcon>
        </Grid>
        <Grid item style={{ ...centerStyle, opacity: "65%" }}>
          <Typography variant="caption">{result.issue_details.key}</Typography>
        </Grid>
      </Grid>
      <Grid item>{result.title}</Grid>
    </Grid>
  );

  const subheader = null;
  const avatar = null;

  const body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  // On the side, display (1) the issue status; (2) the various properties of
  // the issue, such as assignee.
  const statusStyle = {
    backgroundColor: result.status.color,
  };

  const side = (
    <Grid container direction="column" spacing={2} className={classes.side}>
      <Grid item>
        <div className={classes.status} style={statusStyle}>
          {result.status.name}
        </div>
      </Grid>

      <Properties result={result} />
    </Grid>
  );

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account.replace(/(^\w+:|^)\/\//, "")} // Remove protocol from Atlassian URL
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
      side={side}
    />
  );
};

export default JiraSearchResult;
