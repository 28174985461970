import { useCallback, useMemo, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

type ValueType = string | string[];

let useQueryParam: (name: string, defaultValue: ValueType) => [ValueType, (_: ValueType) => void];

if (process.env.REACT_APP_IS_EXTENSION === "true") {
  useQueryParam = (name, defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    const setParam = useCallback(
      (query: ValueType) => {
        // TODO: support merging
        if (query !== value) {
          setValue(query);
        }
      },
      [value]
    );

    return useMemo((): [ValueType, (_: ValueType) => void] => {
      return [value, setParam];
    }, [value, setParam]);
  };
} else {
  useQueryParam = (name, defaultValue) => {
    const history = useHistory();
    const value = queryString.parse(history.location.search)[name] || defaultValue;

    const setParam = useCallback(
      (query: ValueType) => {
        // TODO: support merging
        if (query !== value) {
          const searchParams = queryString.stringify({
            search: query,
          });
          history.push(`${history.location.pathname}?${searchParams}`);
        }
      },
      [history, value]
    );

    return useMemo((): [ValueType, (_: ValueType) => void] => {
      return [value, setParam];
    }, [value, setParam]);
  };
}

export default useQueryParam;
