import React from "react";
import Alert, { AlertProps } from "@material-ui/lab/Alert";

export type BaseAlertProps = AlertProps;

const BaseAlert: React.FC<BaseAlertProps> = (props) => {
  // TODO: log visible text for severity='error' so we can audit error messages
  // that users see.

  return <Alert {...props} />;
};

export default BaseAlert;
