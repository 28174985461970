import { useCallback } from "react";
import firebase from "firebase";
import { useFirebase } from "components/Firebase";
import { useObjectVal } from "hooks/firebase/database";

const updateFeatureState = <T>(dbRef: firebase.database.Reference, value: T) => {
  dbRef.transaction(function (state: T | undefined) {
    dbRef.set({
      ...(state || {}),
      ...(value || {}),
    });
  });
};

export default updateFeatureState;

// hooks must start with `use`
export const useFeatureState = <T>(
  name: string
): [T | undefined, boolean, firebase.FirebaseError | undefined, (_: T) => void] => {
  const firebase = useFirebase();
  const featureStatesRef = firebase.userDbRef().child("featureStates").child(name);
  const [state, loading, error] = useObjectVal<T>(featureStatesRef);

  const update = useCallback(
    (value: T) => {
      return updateFeatureState<T>(featureStatesRef, value);
    },
    [featureStatesRef]
  );

  return [state, loading, error, update];
};
