import React, { useCallback } from "react";
import { Button } from "@material-ui/core";

import ApplicationOauthDialog from "components/ApplicationOauthDialog";
import { useDialogManager, DialogRenderProps } from "components/DialogManager";
import { UpgradeDialog } from "components/Account/UpgradeDialog";
import { useConnectedApps } from "hooks/limitsCounters/useConnectedApps";

type ConnectApplicationButtonProps = {
  platform: string;
  label?: string;
  className?: string;
};

const ConnectApplicationButton = ({
  platform,
  label = "Connect",
  className,
}: ConnectApplicationButtonProps) => {
  const { setOpen, setDialog } = useDialogManager();
  const getConnectedApps = useConnectedApps();

  const renderAppDialog = useCallback(
    ({ open }: DialogRenderProps) => {
      return (
        <ApplicationOauthDialog platform={platform} open={open} onClose={() => setOpen(false)} />
      );
    },
    [platform, setOpen]
  );

  const renderUpgradeDialog = useCallback(
    ({ open }: DialogRenderProps) => {
      return <UpgradeDialog open={open} onClose={() => setOpen(false)} limitType={"app"} />;
    },
    [setOpen]
  );

  const handleClick = useCallback(async () => {
    const connectedApps = await getConnectedApps();

    if (connectedApps.isNewAppPossible) {
      setOpen(true);
      setDialog(renderAppDialog);
    } else {
      setOpen(true);
      setDialog(renderUpgradeDialog);
    }
  }, [setOpen, setDialog, renderAppDialog, renderUpgradeDialog, getConnectedApps]);

  return (
    <Button color="secondary" onClick={handleClick} className={className}>
      {label}
    </Button>
  );
};

export default ConnectApplicationButton;
