import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";

import { avatarSize, centerStyle } from "../../utils";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import blogpost_icon from "images/atlassian_icons/default_blogpost_icon.png";
import page_icon from "images/atlassian_icons/default_page_icon.png";
import space_icon from "images/atlassian_icons/default_space_icon.png";
import { ConfluenceSearchResultType } from "types";

const CONFLUENCE_ICONS: { [_: string]: string } = {
  blogpost: blogpost_icon,
  page: page_icon,
  space: space_icon,
};

const buildSubheader = (result: ConfluenceSearchResultType) => {
  var ancestors = null;
  if (result.ancestors != null)
    ancestors = result.ancestors.map(([id, title]) => title).join(" / ");

  var author = null;
  if (result.author != null)
    author = (
      <Grid container spacing={1} direction="row">
        <Grid item style={centerStyle}>
          <Avatar src={result.author.photo} style={{ height: 25, width: 25 }} />
        </Grid>
        <Grid item>
          <div style={{ ...centerStyle }}>{result.author.name}</div>
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>{ancestors}</Grid>
      <Grid item>{author}</Grid>
    </Grid>
  );
};

const ConfluenceSearchResult: React.FC<{ result: ConfluenceSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const header = result.title;

  const subheader = buildSubheader(result);

  const body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  var avatar = null;
  if (result.entity_type in CONFLUENCE_ICONS)
    avatar = (
      <SvgIcon viewBox={`0 0 ${avatarSize} ${avatarSize}`}>
        <image width={avatarSize} height={avatarSize} href={CONFLUENCE_ICONS[result.entity_type]} />
      </SvgIcon>
    );

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account.replace(/(^\w+:|^)\/\//, "")} // Remove protocol from Atlassian URL
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
    />
  );
};

export default ConfluenceSearchResult;
