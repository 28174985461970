import { useCallback } from "react";
import { useFirebase } from "components/Firebase";

export const useGetCustomToken = () => {
  const firebase = useFirebase();
  const getCustomToken = firebase.functions.httpsCallable("auth-customToken");

  const run = useCallback(async () => {
    const { data: token } = await getCustomToken();
    return token;
  }, [getCustomToken]);

  return run;
};
