import React from "react";

import { ListItemIcon, Menu, MenuItem, SvgIcon } from "@material-ui/core";

import ChromeIcon from "images/extensions/chrome.svg";
import ExternalLink from "components/ExternalLink";

type ExtensionMenuProps = {
  onClose: () => void;
  open: boolean;
};

const ExtensionsMenu = ({ onClose, ...otherProps }: ExtensionMenuProps) => {
  return (
    <Menu
      variant="menu"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      {...otherProps}
    >
      <ExternalLink
        href="https://chrome.google.com/webstore/detail/xoba/ladiijlgnajpjaoepdnnocljmjhdalaj"
        noDecoration
      >
        <MenuItem className="Navigation__Extension__Chrome__Button" onClick={onClose}>
          <ListItemIcon>
            <SvgIcon viewBox="1 1 176 176">
              <image href={ChromeIcon} width="175" />
            </SvgIcon>
          </ListItemIcon>
          Chrome
        </MenuItem>
      </ExternalLink>
    </Menu>
  );
};

export default ExtensionsMenu;
