import React, { useCallback, useMemo, useState, Dispatch, SetStateAction } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import { useCollectionData } from "hooks/firebase/firestore";
import { useForm, Controller } from "react-hook-form";
import firebase from "firebase/app";

import { CardType, SearchResultType, CardItemType } from "types";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { generateItemId } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import AddNewCard from "components/Card/cardTypes/AddNewCard/AddNewCard";

const lightGrey = grey[400];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 30,
  },
  card: {
    minWidth: 425,
    boxShadow: "none",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  subHeader: {
    width: 260,
    fontSize: "12px",
    lineHeight: "14px",
    color: `${lightGrey}`,
  },
  inputTitle: {
    fontSize: "1.1rem",
  },
  itemsList: {
    overflowY: "scroll",
  },
  dividers: {
    marginRight: "30px",
    paddingRight: 0,
  },
  dialogActions: {
    marginRight: "30px",
  },
}));

type CardPickerDialogContextType = {
  setResult: (_: SearchResultType) => void;
  cardsForResult: (_: SearchResultType) => Set<string>;
};

export const CardPickerDialogContext = React.createContext<CardPickerDialogContextType>({
  setResult: () => undefined,
  cardsForResult: () => new Set(),
});

type CardPickerDialogProps = {
  open: boolean;
  onClose: () => void;
  cards: CardType[];
  cardsRef: any;
  result: SearchResultType | null;
  cardsForResult: (_: SearchResultType) => Set<string>;
  hasNoCards: boolean;
  createdCard: any;
  setCreatedCard: Dispatch<SetStateAction<any>>;
};

const CardPickerDialog: React.FC<CardPickerDialogProps> = ({
  createdCard,
  setCreatedCard,
  open,
  onClose,
  cards,
  cardsRef,
  result,
  cardsForResult,
  hasNoCards,
}) => {
  const [newCard, setNewCard] = useState(false);
  const [resultTitle, setResultTitle] = useState("");
  const checkedCards = useMemo(() => {
    if (result) {
      setResultTitle(result.title);
      return cardsForResult(result);
    } else {
      return new Set();
    }
  }, [cardsForResult, result, setResultTitle]);

  const handleTitle = (event: React.ChangeEvent<any>) => {
    setResultTitle(event.target.value);
  };

  const methods = useForm({ mode: "onChange" });

  const onSubmit = useCallback(
    async (data: any) => {
      if (result) {
        setCreatedCard(null);
        const newCardItem: CardItemType = {
          id: generateItemId(),
          url: result.handle,
          label: resultTitle,
          // @ts-ignore
          mimeType: result.mime_type,
          platform: result.platform,
        };

        const now = firebase.firestore.Timestamp.fromDate(new Date());

        Object.keys(data).forEach(async (cardId) => {
          if (data[cardId] && !checkedCards.has(cardId)) {
            const docRef = cardsRef.doc(cardId);
            const doc: CardType = (await docRef.get()).data();
            const newItems = [...(doc.items || []), newCardItem];
            // use a JSON stringify + parse trick to remove undefined values
            docRef.update("items", JSON.parse(JSON.stringify(newItems)), "updatedAt", now);
          } else if (!data[cardId] && checkedCards.has(cardId)) {
            const docRef = cardsRef.doc(cardId);
            const doc: CardType = (await docRef.get()).data();
            const matchingIndex = (doc.items || []).findIndex(
              (item: CardItemType) => item.url === result.handle
            );

            const newItems = [...(doc.items || [])];
            newItems.splice(matchingIndex, 1);
            // use a JSON stringify + parse trick to remove undefined values
            docRef.update("items", JSON.parse(JSON.stringify(newItems)), "updatedAt", now);
          }
        });
      }

      onClose();
    },
    [onClose, cardsRef, result, checkedCards, resultTitle, setCreatedCard]
  );

  let content;
  if (!hasNoCards) {
    const listItems = (cards || []).map((card) => {
      const labelId = `addSearchResultToCard-${card.id}`;
      // @ts-ignore
      const checked =
        checkedCards.has(card.id) ||
        (createdCard && card.createdAt && createdCard.createdAt
          ? card.createdAt.seconds === createdCard.createdAt.seconds
          : false);

      return (
        <Controller
          control={methods.control}
          key={card.id}
          name={card.id as string}
          defaultValue={checked}
          render={({ onChange, value }) => (
            <>
              <ListItem key={card.id} onClick={() => onChange(!value)} dense button>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    name={card.id as string}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={card.title} />
              </ListItem>
            </>
          )}
        />
      );
    });
    content = <List>{listItems}</List>;
  } else {
    content = "You do not have any cards that can hold a bookmark. Please create one.";
  }

  const classes = useStyles();

  const AddToSubHeader = (
    <Typography className={classes.subHeader}>
      Add this item to a new or existing Card. Once added, you will see it in the Card on your Xoba
      homepage.
    </Typography>
  );

  const AddToTitle = "Add to Card";

  return (
    <Box className={classes.root}>
      <Dialog className={classes.card} open={open} onClose={onClose}>
        {newCard ? (
          <AddNewCard setCreatedCard={setCreatedCard} setNewCard={setNewCard} />
        ) : (
          <>
            <CardHeader
              title={AddToTitle}
              subheader={AddToSubHeader}
              titleTypographyProps={{ variant: "h6" }}
            />

            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <DialogContent className={classes.dividers} dividers>
                <Box paddingLeft="50px">
                  <TextField
                    name="itemTitle"
                    InputProps={{ className: classes.inputTitle }}
                    id="standard-basic"
                    value={resultTitle}
                    onChange={handleTitle}
                    helperText="You can rename the Item before adding it to a Card"
                  />
                  <Box className={classes.itemsList} height="170px">
                    {content}
                  </Box>

                  <Box marginTop="15px">
                    <Button
                      color="secondary"
                      onClick={() => {
                        setNewCard(true);
                      }}
                    >
                      Create New Card
                    </Button>
                  </Box>
                </Box>
              </DialogContent>

              <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="secondary" disabled={methods.formState.isSubmitting}>
                  Add
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </Box>
  );
};

type CardPickerProviderProps = {};

const CardPickerProvider: React.FC<CardPickerProviderProps> = ({ children }) => {
  const [result, setResult] = useState<SearchResultType | null>(null);
  const [createdCard, setCreatedCard] = useState<any>(null);
  const [user] = useCurrentUser();
  const firebase = useFirebase();
  // This is currently hardcoded to the HOME cards
  const cardsRef = firebase.firestore
    .collection("users")
    .doc(user?.uid)
    .collection("spaces")
    .doc("HOME")
    .collection("cards");

  const [cards, cardsLoading] = useCollectionData<CardType>(cardsRef.where("type", "==", "items"), {
    idField: "id",
  });

  const cardItemLookup = useMemo(() => {
    const res = new Map();

    if (cards) {
      cards.forEach((card) => {
        const set = new Set();

        (card.items || []).forEach((item) => {
          set.add(item.url);
        });

        // @ts-ignore
        res.set(card.id, set);
      });
    }

    return res;
  }, [cards]);

  const cardsForResult = useCallback(
    (result) => {
      const url = result.handle;
      const res = new Set<string>();

      cardItemLookup.forEach((value, cardId) => {
        if (value.has(url)) {
          res.add(cardId);
        }
      });

      return res;
    },
    [cardItemLookup]
  );

  const handleClose = useCallback(() => {
    setResult(null);
    setCreatedCard(null);
  }, []);

  const contextValue = useMemo(() => {
    return {
      setResult,
      cardsForResult,
    };
  }, [cardsForResult]);

  const hasNoCards = !cardsLoading && (cards || []).length === 0;

  return (
    <CardPickerDialogContext.Provider value={contextValue}>
      {children}

      <CardPickerDialog
        createdCard={createdCard}
        setCreatedCard={setCreatedCard}
        open={!!result}
        onClose={handleClose}
        cards={cards || []}
        cardsRef={cardsRef}
        result={result}
        cardsForResult={cardsForResult}
        hasNoCards={hasNoCards}
      />
    </CardPickerDialogContext.Provider>
  );
};

export default CardPickerProvider;
