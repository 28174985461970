import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import driveDocumentIcon from "images/drive_icons/document.svg";
import driveDrawingIcon from "images/drive_icons/drawing.svg";
import driveFormIcon from "images/drive_icons/form.svg";
// TODO(piyush) Couldn't find an SVG for maps.
import driveMapIcon from "images/drive_icons/maps.png";
import drivePhotoIcon from "images/drive_icons/photos.svg";
import drivePresentationIcon from "images/drive_icons/presentation.svg";
import driveScriptsIcon from "images/drive_icons/scripts.svg";
// TODO(piyush) Couldn't find an SVG for sites.
import driveSitesIcon from "images/drive_icons/sites.png";
import driveSheetsIcon from "images/drive_icons/sheets.svg";
import driveVideoIcon from "images/drive_icons/video.svg";
import driveFolderIcon from "images/drive_icons/folder.svg";
import pdfIcon from "images/file_type_icons/pdf.svg";
import wordDocIcon from "images/file_type_icons/word_doc.svg";

const MIME_TYPES: { [_: string]: string } = {
  // DRIVE
  "application/vnd.google-apps.document": driveDocumentIcon,
  "application/vnd.google-apps.drawing": driveDrawingIcon,
  "application/vnd.google-apps.form": driveFormIcon,
  "application/vnd.google-apps.map": driveMapIcon,
  "application/vnd.google-apps.photo": drivePhotoIcon,
  "application/vnd.google-apps.presentation": drivePresentationIcon,
  "application/vnd.google-apps.script": driveScriptsIcon,
  "application/vnd.google-apps.site": driveSitesIcon,
  "application/vnd.google-apps.spreadsheet": driveSheetsIcon,
  // TODO(piyush) Not 100% this is the correct logo.
  "application/vnd.google-apps.video": driveVideoIcon,
  "application/zip": driveFolderIcon,
  // TODO(piyush) Couldn't find good logos for these.
  //'application/vnd.google-apps.audio'
  //'application/vnd.google-apps.file'
  "application/vnd.google-apps.folder": driveFolderIcon,

  // OTHERS
  "image/jpeg": drivePhotoIcon,
  "image/gif": drivePhotoIcon,
  "application/pdf": pdfIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": wordDocIcon,
};

type MimeTypeIconProps = SvgIconProps & {
  mimeType: string;
  fallback?: any;
};

const avatarSize = 32;

const MimeTypeIcon: React.FC<MimeTypeIconProps> = ({ mimeType, fallback, ...otherProps }) => {
  const icon = MIME_TYPES[mimeType];

  if (!icon) {
    return fallback || null;
  }

  // TODO: can we use this URL instead?
  // const icon = `https://drive-thirdparty.googleusercontent.com/32/type/${mimeType}`;

  return (
    <SvgIcon viewBox={`0 0 ${avatarSize} ${avatarSize}`} {...otherProps}>
      <image width={avatarSize} height={avatarSize} href={icon} />
    </SvgIcon>
  );
};

export default MimeTypeIcon;
