import React from "react";

import SearchResultLayout from "../SearchResultLayout";
import { DropboxSearchResultType } from "types";
import { Box } from "@material-ui/core";
import PathGenerator from "../PathGenerator";

const BoxSearchResult: React.FC<{ result: DropboxSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  let subheader;

  if (result.path) {
    subheader = (
      <Box display="flex" flexDirection="column">
        <PathGenerator path={result.path} />
      </Box>
    );
  }

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={result.title}
      subheader={subheader}
    />
  );
};

export default BoxSearchResult;
