import React, { useState } from "react";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { mailTo } from "./utils";

type FeedbackDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  text: string;
  label: string;
  onSubmit: (_: string) => void;
};

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  open,
  onClose,
  title,
  text,
  label,
  onSubmit,
}) => {
  const [feedback, setFeedback] = useState("");
  const feedbackIsEmpty = feedback.replace(/\s/g, "").length === 0;

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(feedback);
    } else {
      mailTo({
        address: "feedback@xobalabs.com",
        subject: "Feedback from the wish box",
        body: feedback,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title || "We love Feedback"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text || "Send us your wishes, bug reports, and ideas for making Xoba great."}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="feedback"
          label={label || "Wishes/Feedback"}
          type="text"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={feedbackIsEmpty}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
