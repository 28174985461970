import React from "react";

import CardLayout, { CardBaseProps } from "../CardLayout";

const UnsupportedCard: React.FC<CardBaseProps> = ({ card, ...otherProps }) => {
  return (
    <CardLayout card={card} {...otherProps} error={`Your card "${card.title}" is not supported.`} />
  );
};

export default UnsupportedCard;
