// Tracking users.

import { User } from "@firebase/auth-types";
import { CardTypeEnum } from "types";

// eslint-disable-next-line
declare global {
  interface Window {
    Cypress: any;
    analytics: any;
  }
}

// Conditionally track analytics depending on the ANALYTICS environment
// variable.
const TRACKING_ENABLED = process.env.REACT_APP_ANALYTICS === "1" && !window.Cypress;

export const track = (event: string, ...args: any[]) => {
  if (TRACKING_ENABLED && window.analytics) {
    let origin;
    if (window.location.pathname && window.location.pathname.split(".")[0] === "/popup") {
      origin = "Extension";
    } else if (
      window.location.pathname &&
      window.location.pathname.split(".")[0] !== "/popup" &&
      window.location.href.includes("chrome-extension://")
    ) {
      origin = "NewTab";
    } else {
      origin = "WebApp";
    }

    window.analytics.track(event, { ...args, origin });
  }
};

export const identify = (user: User) => {
  if (TRACKING_ENABLED && window.analytics) {
    window.analytics.identify(user.uid, {
      auth_provider: user.providerId,
      createdAt: user.metadata.creationTime,
      email: user.email,
    });
  }
};

export const logChangePassword = () => track("Change Password");

export enum DeleteAccountStatus {
  Success = "success",
  Failed = "failed",
}

export const logDeleteAccount = ({
  status,
  error = null,
}: {
  status: DeleteAccountStatus;
  error?: Error | null;
}) => {
  track("Delete Account", { status, error });
};

export const logPage = (name?: string) => {
  if (TRACKING_ENABLED && window.analytics) {
    window.analytics.page(name);
  }
};

export const logSignIn = () => track("Sign In");

export const logSignOut = () => {
  if (TRACKING_ENABLED && window.analytics) {
    track("Sign Out");
    window.analytics.reset();
  }
};

export const logSignUp = () => track("Sign Up");

// TODO: make a Platform enum
export const logLinkPlatform = (platform: string) => {
  track("Link Platform", { platform });
};

export const logUnlinkPlatform = (platform: string) => {
  track("Unlink Platform", { platform });
};

// Search.

// TODO: log when a search comes from the chrome extension
export const logSearch = (searchId: string, filters: any) => {
  track("Search", { searchId, filters: filters || "default" });
};

export const logAsyncSearchResult = (
  success: boolean,
  searchId: string,
  page: string,
  platform: string,
  account: string,
  latency: number,
  error: Error | null
) => {
  track("Async Search Status", { success, searchId, page, platform, account, latency, error });
};

export const logAsyncNoMoreResults = (
  searchId: string,
  page: string,
  platform: string,
  account: string,
  latency: number
) => {
  track("Async No More Results", { searchId, page, platform, account, latency });
};

export const logSearchStatus = (
  success: boolean,
  searchId: string,
  latency: number,
  numResults: number | null,
  error: Error | null,
  firstResultLatency: number
) => {
  track("Search Status", {
    success,
    searchId,
    firstResultLatency,
    latency,
    numResults,
    error,
  });
};

export const logSearchClick = (position: number, platform: string) => {
  track("Search Click", { position, platform });
};

export const logSearchResultInteraction = ({
  interactionType,
  position,
  platform,
}: {
  interactionType: string;
  position: number;
  platform: number;
}) => {
  track("Search Result Interaction", { interactionType, position, platform });
};

export const logApplicationFilterToggle = ({
  platform,
  account,
  selected,
}: {
  platform: string;
  account: string;
  selected: boolean;
}) => {
  track("Application Filter Toggle", { platform, account, selected });
};

export const logApplicationBulkFilterToggle = ({ selected }: { selected: boolean }) => {
  track("Application Bulk Filter Toggle", { selected });
};

// TODO: how do we handle the case where Segment is blocked?
export const logMissingApplicationRequest = ({ applications }: { applications: string[] }) => {
  track("Missing Application Request", { applications });
};

export const historyPageviewListener = () => {
  // TODO: do we need the page name?
  logPage();
};

export const logResendVerificationEmail = ({
  status,
  error = null,
}: {
  status: string;
  error?: Error | null;
}) => {
  track("Resend verification email", { status, error });
};

export const logGoogleSignUp = ({
  status,
  error = null,
}: {
  status: string;
  error?: Error | null;
}) => {
  track("Google Sign Up", { status, error });
};

export const logExtensionConnected = ({
  status,
  error = null,
}: {
  status: string;
  error?: Error | null;
}) => {
  track("Extension connected", { status, error });
};

export const logApplicationConfigurationUpdated = ({ platform }: { platform: string }) => {
  track("Application configuration updated", { platform });
};

export const logSearchAutocompleteSelect = () => {
  track("Search Autocomplete selected");
};

export enum CardChangeOperator {
  Create = "create",
  Update = "update",
  Delete = "delete",
}

export const logCardChange = ({
  operation,
  cardType,
}: {
  operation: CardChangeOperator;
  cardType: CardTypeEnum;
}) => {
  track("Card operation", { operation, cardType });
};

export enum CardItemInteraction {
  Click = "click",
  OpenAllItems = "open all items",
}

export const logCardItemInteraction = ({
  interactionType,
  cardType,
}: {
  interactionType: CardItemInteraction;
  cardType: CardTypeEnum;
}) => {
  track("Card Item interaction", { interactionType, cardType });
};

export const logFeatureStateRecentDocsCardBatchCreate = () => {
  track("FeatureState Adding RecentDocs cards");
};

export const logDefaultSearchParamsSaved = () => {
  track("Default Search Parameters Saved");
};

export const logMktData = (data: any) => {
  track("MKT Form Interaction", { data });
};
