import React from "react";
import { useLocation } from "react-router-dom";
import TopBarLayout from "../TopBar/TopBarLayout";
import { SIGN_UP } from "constants/routes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#3B35A6",
  },
}));

const LoggedOutNavigation = () => {
  const classes = useStyles();
  const location = useLocation();
  return location.pathname === SIGN_UP ? null : <TopBarLayout className={classes.appBar} />;
};

export default LoggedOutNavigation;
