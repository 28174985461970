import { useCallback, useEffect, useState } from "react";
import lifecycle from "page-lifecycle";

type PageLifecycleState = {
  oldState?: string;
  newState?: string;
};

const usePageLifecycle = () => {
  const [state, setState] = useState<PageLifecycleState>({});

  const callback = useCallback((event) => {
    setState({
      oldState: event.oldState,
      newState: event.newState,
    });
  }, []);

  useEffect(() => {
    lifecycle.addEventListener("statechange", callback);
    return () => {
      lifecycle.removeEventListener("statechange", callback);
    };
  }, [callback]);

  return state;
};

export default usePageLifecycle;
