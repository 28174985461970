import React, { useCallback } from "react";
import { Typography, Container } from "@material-ui/core";

import DelayedSpinner from "components/DelayedSpinner";
import {
  useConnectExtension,
  useExtensionState,
  NOT_INSTALLED,
  LOGGED_IN,
} from "actions/auth/connectExtension";
import { RUNNING } from "hooks/useLoadingStatus";
import LoadingButton from "components/LoadingButton";
import { useCurrentUser } from "components/Session";

export const CONNECT_EXTENSION_MODE = "extension";

const ConnectExtension = () => {
  const [user, loading] = useCurrentUser();
  const { status, run: connectExtension } = useConnectExtension();
  const extensionState = useExtensionState();

  const handleClick = useCallback(() => {
    connectExtension();
  }, [connectExtension]);

  let content;
  if (loading) {
    content = <DelayedSpinner size={50} />;
  } else if (!user) {
    content = <Typography variant="subtitle1">You need to log in</Typography>;
  } else if (extensionState === NOT_INSTALLED) {
    content = <Typography variant="subtitle1">You need to install the extension</Typography>;
  } else if (extensionState === LOGGED_IN) {
    content = (
      <Typography variant="subtitle1">Your extension is logged in. You are good to go.</Typography>
    );
  } else {
    content = (
      <LoadingButton
        status={status}
        disabled={status === RUNNING}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Connect extension
      </LoadingButton>
    );
  }

  return <Container maxWidth="xs">{content}</Container>;
};

export default ConnectExtension;
