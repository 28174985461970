import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useGetFavorites } from "actions/favoriteItem/hooks";
import SearchResultsList from "components/Search/SearchResultsList";
import { FINISHED } from "hooks/useLoadingStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  search: {},
}));

const FavoritesPage = () => {
  const classes = useStyles();
  const { status, data, run } = useGetFavorites();

  useEffect(() => {
    run();
  }, [run]);

  const results = data.filter((d: any) => d.item_type === "SearchResult").map((d: any) => d.data);

  return (
    <div className={classes.root}>
      <h1>Favorites</h1>

      <SearchResultsList
        results={results}
        loading={status !== FINISHED}
        onNext={() => {}}
        hasMore={false}
        error={null}
      />
    </div>
  );
};

export default FavoritesPage;
