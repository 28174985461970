import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import * as ROUTES from "constants/routes";
import { identify, logSignUp } from "analytics";
import { useFirebase } from "components/Firebase";

const useStyles = makeStyles((theme) => ({
  form: { width: "100%" /* Fix IE 11 issue */ },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignupForm = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [legal, setLegal] = useState(false);
  const [emailError, setEmailError] = useState<any | null>(null);
  const [passwordError, setPasswordError] = useState<any | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<any | null>(null);
  const [legalError, setLegalError] = useState<any | null>(null);

  const legalCheckbox = (
    <>
      I accept the <a href={ROUTES.PRIVACY_POLICY}>Privacy Policy</a> and{" "}
      <a href={ROUTES.TERMS_OF_SERVICE}>Terms of Service</a>
    </>
  );

  // TODO(piyush) Verify that the email address exists first.
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (password !== confirmPassword) {
      setConfirmPasswordError({ message: "Passwords do not match" });
    } else if (!legal) {
      setLegalError({
        message: `Please agree to the terms of service and privacy
                          policy`,
      });
    } else {
      setConfirmPasswordError(null);
      setLegalError(null);
      firebase
        .doCreateUserWithEmailAndPassword(email, password)
        // Create user in Firebase realtime DB.
        .then(({ user }: { user: any }) => {
          identify(user);
          user.sendEmailVerification();

          // Create use Firebase user and register with analytics.
          firebase.initUser(email);
          logSignUp();

          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setEmailError(null);
          setPasswordError(null);

          // Redirect to home page.
          history.push(ROUTES.HOME);
        })
        .catch((error: any) => {
          if (error.code === "auth/invalid-email") {
            setEmailError({ message: "Invalid email address" });
          } else if (error.code === "auth/email-already-in-use") {
            setEmailError({
              message: `This email address is already in use by an
                                      existing account`,
            });
          } else if (error.code === "auth/weak-password") {
            setPasswordError({
              message: "Password must be 6 characters or longer",
            });
          } else {
            // TODO(piyush) Verify that all the cases for error.code
            // are covered so this doesn't happen.
            alert(`ERROR (${error.code}): ${error.message}`);
          }
        });
    }
    event.preventDefault();
  };

  return (
    <form noValidate onSubmit={onSubmit} className={classes.form}>
      <TextField
        name="email"
        type="email"
        placeholder="Email address"
        required
        error={emailError != null}
        label={emailError != null ? "Error" : null}
        helperText={emailError != null ? emailError.message : null}
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="email"
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        name="password"
        type="password"
        placeholder="Password"
        required
        error={passwordError != null}
        label={passwordError != null ? "Error" : null}
        helperText={passwordError != null ? passwordError.message : null}
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="off"
        onChange={(event) => setPassword(event.target.value)}
      />
      <TextField
        name="confirmPassword"
        type="password"
        placeholder="Confirm password"
        required
        error={confirmPasswordError != null}
        label={confirmPasswordError != null ? "Error" : null}
        helperText={confirmPasswordError != null ? confirmPasswordError.message : null}
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="off"
        onChange={(event) => setConfirmPassword(event.target.value)}
      />

      <FormControlLabel
        control={<Checkbox color="primary" />}
        label={legalCheckbox}
        onChange={(event: any) => setLegal(event.target.checked)}
      />

      {legalError && <FormHelperText error>{legalError.message}</FormHelperText>}

      <Button
        type="submit"
        disabled={email === "" || password === "" || confirmPassword === "" || !legal}
        variant="contained"
        color="primary"
        fullWidth
        className={classes.submit}
      >
        Sign up
      </Button>
    </form>
  );
};

export default SignupForm;
