import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useListVals } from "hooks/firebase/database";

import { NormalAlert } from "components/Alert";
import { useFirebase } from "components/Firebase";
import { logResendVerificationEmail } from "analytics";
import { ErrorNotification } from "types";
import { useCurrentUser } from "components/Session";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

type AppErrorProps = {
  message: React.ReactNode;
  error: any;
  onClose: (_: any) => void;
};

const AppError = ({ onClose, message, error }: AppErrorProps) => {
  const handleClick = useCallback(() => {
    onClose(error);
  }, [onClose, error]);

  return (
    <NormalAlert
      severity="error"
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={handleClick}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {message}
    </NormalAlert>
  );
};

const VerifyEmailAlert = ({ user }: any) => {
  const hideDelay = 1000; // milliseconds
  const [status, setStatus] = useState("");
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    if (showButton && (status === "success" || status === "failed")) {
      const timeout = setTimeout(() => {
        setShowButton(false);
      }, hideDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showButton, status]);

  const handleClick = () => {
    setStatus("sending...");
    user
      .sendEmailVerification()
      .then(function () {
        setStatus("sent");
        logResendVerificationEmail({ status: "success" });
      })
      .catch(function (error: any) {
        setStatus("failed");
        logResendVerificationEmail({ status: "failed", error });
      });
  };

  let button;
  if (showButton) {
    const buttonText = status === "" ? "resend" : status;
    button = (
      <Button onClick={handleClick} disabled={status !== ""}>
        {buttonText}
      </Button>
    );
  }

  let message;
  if (status === "failed") {
    message = "Failed to resend your verification email. Please try again later.";
  } else {
    message =
      "Your email address is not verified. Please check your email for a verification link.";
  }

  return (
    <NormalAlert severity="error" action={button}>
      {message}
    </NormalAlert>
  );
};

const AppErrors = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [user] = useCurrentUser();
  const emailUnverified = user && !user.emailVerified; // check for `user` existence to avoid flashing the message when the user is loading

  // Errors from backend or refresher daemon.
  const [errors] = useListVals<ErrorNotification>(firebase.userErrors());

  // When an error is closed by the user, remove it from the errors DB.
  const handleClose = useCallback(
    (error) => {
      const newErrors = (errors || []).filter((e) => e !== error);
      if (newErrors.length === 0) {
        firebase.userErrors().remove();
      } else {
        firebase.userErrors().set(newErrors);
      }
    },
    [errors, firebase]
  );

  const hasErrors = !errors || errors.length === 0;
  if (hasErrors && !emailUnverified) {
    return null;
  }

  const numErrors = (errors || []).length;
  const displayedErrors = (errors || []).map((error, i) => {
    // TODO(piyush) Once error is a protobuf, use an enum here.
    var message;
    switch (error.type) {
      case "reauth":
        message = `Unfortunately, for some reason such as a change in
                           your Gmail password or Gmail account deletion, Gmail
                           has revoked access to your Gmail account. Please
                           re-link it to continue searching your Gmail account.`;
        break;
      default:
        throw Error(`Unknown error in errors DB:
                             ${JSON.stringify(error)}`);
    }

    return (
      <AppError key={`${numErrors}_${i}`} error={error} message={message} onClose={handleClose} />
    );
  });

  return (
    <div className={classes.root}>
      {emailUnverified && <VerifyEmailAlert user={user} />}
      {displayedErrors}
    </div>
  );
};

export default AppErrors;
