import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import * as ROUTES from "constants/routes";

const useStyles = makeStyles((theme) => ({
  footer: {
    flexShrink: 0,
    padding: theme.spacing(1, 2),
  },
}));

const Copyright: React.FC = () => (
  <Typography variant="body2" color="textSecondary">
    {"Copyright "}
    <Link color="inherit" href="https://xobalabs.com">
      Xoba
    </Link>{" "}
    2023.
  </Typography>
);

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Copyright />
          </Grid>
          <Grid item>
            <Link
              href="https://www.notion.so/xoba/Xoba-Help-Center-7f9b8864000c461798c3ec0fbbd68a14"
              rel="noopener"
              target="_blank"
            >
              Help
            </Link>
          </Grid>
          <Grid item>
            <Link href={ROUTES.PRIVACY_POLICY} target="_blank">
              Privacy Policy
            </Link>
          </Grid>
          <Grid item>
            <Link href={ROUTES.TERMS_OF_SERVICE} target="_blank">
              Terms of Service
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
