import React from "react";
import { Box } from "@material-ui/core";

export interface pathGeneratorProps {
  path: string;
}

const PathGenerator: React.FC<pathGeneratorProps> = ({ path }) => {
  const concatIcon = (index: string) => (
    <span key={index} style={{ lineHeight: "1rem", marginRight: "5px", marginLeft: "5px" }}>
      {" > "}
    </span>
  );
  const pathArray = path && path.split("/");
  const pathArrayFiltered = pathArray && pathArray.filter((item) => item !== "");
  const mapedPath =
    pathArrayFiltered &&
    pathArrayFiltered.map((path) => (
      <span style={{ lineHeight: "1rem" }} key={path}>
        {path}
      </span>
    ));
  let concatedPath: any[] = [];

  mapedPath &&
    mapedPath.forEach((item, index) => {
      concatedPath.push(item);
      if (index < mapedPath.length - 1) {
        concatedPath.push(concatIcon(`${index}`));
      }
    });

  return (
    <Box display="flex" flexDirection="row" alignItems="baseline">
      {concatedPath}
    </Box>
  );
};

export default PathGenerator;
