import "./styles.css";
import "react-resizable/css/styles.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { bottom, getLayoutItem, synchronizeLayoutWithChildren } from "./utils";
import GridItem from "./GridItem";
import { Props } from "./ReactGridLayoutPropTypes";
import useResizableLayout from "./hooks/useResizableLayout";
import useDraggableLayout from "./hooks/useDraggableLayout";

const layoutClassName = "react-grid-layout";
// let isFirefox = false;
// // Try...catch will protect from navigator not existing (e.g. node) or a bad implementation of navigator
// try {
//   isFirefox = /firefox/i.test(navigator.userAgent);
// } catch (e) {
//   /* Ignore */
// }

/**
 * A reactive, fluid grid layout with draggable, resizable components.
 */

type ReactGridLayoutProps = Props & {};

const ReactGridLayout: React.FC<ReactGridLayoutProps> = ({
  className,
  style,
  innerRef,
  children,
  autoSize,
  containerPadding,
  margin,
  rowHeight,
  width,
  cols,
  maxRows,
  isBounded,
  useCSSTransforms,
  transformScale,
  layout,
  compactType,
  isDraggable,
  draggableHandle,
  draggableCancel,
  isResizable,
  resizeHandles,
  preventCollision,
  onLayoutChange,
}) => {
  const mergedClassName = clsx(layoutClassName, className);
  const currentLayout1 = useMemo(() => {
    // This adds missing items.
    return synchronizeLayoutWithChildren(layout, children, cols, compactType);
  }, [layout, children, cols, compactType]);

  const [currentLayout, setCurrentLayout] = useState(currentLayout1);

  const containerHeight = useMemo(() => {
    if (!autoSize) return;

    const nbRow = bottom(currentLayout);
    const containerPaddingY = containerPadding
      ? containerPadding[1]
      : // @ts-ignore
        margin[1];

    return (
      nbRow * rowHeight +
      // @ts-ignore
      (nbRow - 1) * margin[1] +
      containerPaddingY * 2 +
      "px"
    );
  }, [autoSize, currentLayout, containerPadding, margin, rowHeight]);

  const mergedStyle = useMemo(() => {
    return {
      height: containerHeight,
      ...style,
    };
  }, [containerHeight, style]);

  const {
    layout: dragLayout,
    placeholder: dragPlaceholder,
    start: startDrag,
    update: updateDrag,
    stop: stopDrag,
  } = useDraggableLayout({
    cols,
    preventCollision,
    initialLayout: currentLayout,
    compactType,
  });

  // console.log("useDrag", dragLayout, dragPlaceholder);

  const handleDrag = useCallback(
    (type: string, i: string, x: number, y: number) => {
      if (type === "onDragStart") {
        startDrag(i, x, y);
      } else if (type === "onDragStop") {
        stopDrag(i, x, y);
      } else {
        updateDrag(i, x, y);
      }
    },
    [startDrag, stopDrag, updateDrag]
  );

  const {
    layout: resizeLayout,
    placeholder: resizePlaceholder,
    start: startResize,
    update: updateResize,
    stop: stopResize,
  } = useResizableLayout({
    cols,
    preventCollision,
    initialLayout: dragLayout,
    compactType,
  });
  // console.log("Grid rerendering", "layout", resizePlaceholder);
  // console.log("useResizableLayout", resizeLayout);

  const handleResize = useCallback(
    (type: string, i: string, w: number, h: number) => {
      if (type === "onResizeStart") {
        startResize(i, w, h);
      } else if (type === "onResizeStop") {
        stopResize(i, w, h);
      } else {
        updateResize(i, w, h);
      }
    },
    [startResize, stopResize, updateResize]
  );

  useEffect(() => {
    if (resizeLayout && currentLayout !== resizeLayout) {
      setCurrentLayout(resizeLayout);
      onLayoutChange(resizeLayout);
    } else {
      setCurrentLayout(currentLayout1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeLayout, currentLayout1]);

  const processGridItem = useCallback(
    (child: React.ReactElement) => {
      if (!child || !child.key) return;
      const l = getLayoutItem(resizeLayout, String(child.key));
      if (!l) return null;
      // const { mounted, droppingPosition } = this.state;
      const draggable =
        typeof l.isDraggable === "boolean" ? l.isDraggable : !l.static && isDraggable;
      const resizable =
        typeof l.isResizable === "boolean" ? l.isResizable : !l.static && isResizable;
      const resizeHandlesOptions = l.resizeHandles || resizeHandles;
      const bounded = draggable && isBounded && l.isBounded !== false;

      return (
        <GridItem
          containerWidth={width}
          cols={cols}
          margin={margin}
          // @ts-ignore
          containerPadding={containerPadding || margin}
          maxRows={maxRows}
          rowHeight={rowHeight}
          useCSSTransforms={useCSSTransforms}
          transformScale={transformScale}
          isDraggable={draggable}
          cancel={draggableCancel}
          handle={draggableHandle}
          // @ts-ignore
          onDrag={handleDrag}
          // @ts-ignore
          onResize={handleResize}
          isResizable={resizable}
          isBounded={bounded}
          resizeHandles={resizeHandlesOptions}
          w={l.w}
          h={l.h}
          x={l.x}
          y={l.y}
          i={l.i}
          // @ts-ignore
          minH={l.minH}
          // @ts-ignore
          minW={l.minW}
          // @ts-ignore
          maxH={l.maxH}
          // @ts-ignore
          maxW={l.maxW}
          static={l.static}
        >
          {child}
        </GridItem>
      );
    },
    [
      resizeLayout,
      width,
      cols,
      margin,
      containerPadding,
      maxRows,
      rowHeight,
      useCSSTransforms,
      transformScale,
      isDraggable,
      handleDrag,
      handleResize,
      draggableCancel,
      draggableHandle,
      isBounded,
      isResizable,
      resizeHandles,
    ]
  );

  const gridItems = useMemo(() => {
    return React.Children.map(children, (child) => {
      return processGridItem(child as React.ReactElement);
    });
  }, [children, processGridItem]);

  const placeholder = dragPlaceholder || resizePlaceholder;
  const placeholderEl = useMemo(() => {
    if (placeholder) {
      return (
        <GridItem
          w={placeholder.w}
          h={placeholder.h}
          x={placeholder.x}
          y={placeholder.y}
          i={placeholder.i}
          className="react-grid-placeholder"
          containerWidth={width}
          cols={cols}
          margin={margin}
          // @ts-ignore
          containerPadding={containerPadding || margin}
          maxRows={maxRows}
          rowHeight={rowHeight}
          isDraggable={false}
          isResizable={false}
          isBounded={false}
          useCSSTransforms={useCSSTransforms}
          transformScale={transformScale}
        >
          <div />
        </GridItem>
      );
    }
    return null;
  }, [
    placeholder,
    cols,
    containerPadding,
    margin,
    maxRows,
    rowHeight,
    transformScale,
    useCSSTransforms,
    width,
  ]);

  return (
    <div
      // @ts-ignore
      ref={innerRef}
      className={mergedClassName}
      style={mergedStyle}
    >
      {gridItems}
      {placeholderEl}
    </div>
  );
};

ReactGridLayout.defaultProps = {
  rowHeight: 150,
  margin: [10, 10],
  cols: 12,
  maxRows: Infinity,
  autoSize: true,
  useCSSTransforms: true,
  transformScale: 1,
  compactType: "vertical",
  resizeHandles: ["se"],
  isBounded: false,
  isDraggable: true,
  isResizable: true,
  isDroppable: false,
};

export default ReactGridLayout;
