import React from "react";
import clsx from "clsx";
import { useCurrentUser } from "components/Session";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";

import ChangePasswordDialog from "components/Account/ChangePasswordDialog";
import ManagePlan from "components/Account/ManagePlan";
import DeleteAccountButton from "components/Account/DeleteAccountButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  accountRow: {
    "&:not($accountRowChangeable)": {
      // TODO: figure out why this doesn't work without !important
      cursor: "default !important",
      "& $expansionIcon": {
        // Use CSS visibility to reserve the space of the Icon
        visibility: "hidden",
      },
    },
  },
  accountRowChangeable: {
    "&:hover": {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    },
  },
  expansionIcon: {},
}));

type AccountRowProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  onExpand?: () => void;
};

const AccountRow = ({ label, value, onExpand }: AccountRowProps) => {
  const classes = useStyles();
  const changeable = !!onExpand;

  return (
    <ExpansionPanel expanded={false} onChange={onExpand}>
      <ExpansionPanelSummary
        expandIcon={<NavigateNextIcon className={classes.expansionIcon} />}
        className={clsx(classes.accountRow, changeable && classes.accountRowChangeable)}
      >
        <Typography className={classes.heading}>{label}</Typography>
        <Typography className={classes.secondaryHeading}>{value}</Typography>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
};

const AccountPage = () => {
  const classes = useStyles();
  const [authUser] = useCurrentUser();

  // Open "Change Password" box.
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);

  // Whether the user signed in with Google. If so, it doesn't make sense to
  // be able to change the password.
  // @ts-ignore
  const changeable = authUser && authUser.providerData[0].providerId !== "password";

  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>
        Account
      </Typography>
      <Grid container spacing={5} direction="column">
        <Grid item>
          <AccountRow label="Email" value={authUser?.email} />
          <AccountRow
            label="Password"
            value={changeable ? "\u25CF\u25CF\u25CF\u25CF\u25CF" : "Signed in with Google"}
            onExpand={(changeable && (() => setChangePasswordOpen(true))) || undefined}
          />
        </Grid>

        <ChangePasswordDialog open={changePasswordOpen} setOpen={setChangePasswordOpen} />
      </Grid>

      <Typography variant="h6" className={classes.title}>
        Billing
      </Typography>
      <Grid container spacing={5} direction="column">
        <Grid item>
          <ManagePlan />
        </Grid>
      </Grid>

      <Typography variant="h6" className={classes.title}>
        Delete Account
      </Typography>
      <Grid container spacing={5} direction="column">
        <Grid item>
          <DeleteAccountButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AccountPage;
