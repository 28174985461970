import React from "react";
import Avatar from "@material-ui/core/Avatar";

import xobaIcon from "images/xoba_icons/Xoba_Icon.svg";

type XobaIconProps = {
  size?: number;
  className?: string;
};

const XobaIcon = ({ size = 30, className }: XobaIconProps) => {
  return (
    <Avatar
      className={className}
      src={xobaIcon}
      style={{ width: `${size}px`, height: `${size}px` }}
    />
  );
};

export default XobaIcon;
