import * as PLATFORMS from "constants/platforms";
import GithubConfigForm from "./GithubConfigForm";

const lookup: { [name: string]: any } = {
  [PLATFORMS.GITHUB.name]: GithubConfigForm,
};

const lookupFn = (platform: string) => {
  return lookup[platform];
};

export default lookupFn;
