import { track } from "./index";
import { ShareTypeEnum } from "types";

type toggleSharingArgs = {
  type: ShareTypeEnum;
};
export const logSharingEnabled = ({ type }: toggleSharingArgs) => {
  track("Sharing enabled", { type });
};

export const logSharingDisabled = ({ type }: toggleSharingArgs) => {
  track("Sharing disabled", { type });
};

type logSharingViewedArgs = {
  type?: ShareTypeEnum;
  error?: string;
  shareId: string;
};

export const logSharingViewed = ({ type, shareId, error }: logSharingViewedArgs) => {
  track("Shared item viewed", { type, shareId, error });
};

export const logCreateAccountClick = ({ type, shareId }: logSharingViewedArgs) => {
  track("Sign up from shared item view", { type, shareId });
};
