import React from "react";
import {
  DialogContentText,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { UserApplicationType } from "types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};

function getStyles(option: string, value: string, theme: Theme) {
  return {
    fontWeight:
      value.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    // maxWidth: 300,
  },
}));

type EditorProps = {
  application: UserApplicationType;
  data: any;
};

const GithubConfigForm = ({ application, data }: EditorProps) => {
  const { control, register, watch } = useFormContext();
  const classes = useStyles();
  const theme = useTheme();
  const orgValue = watch("organizations_filter") || [];
  const orgOptions = data?.organizations?.options || [];

  return (
    <React.Fragment>
      <DialogContentText id="github-config-main-text">
        Github searches all repositories that you have access to. Usually you only want to search
        your organization.
      </DialogContentText>
      <DialogContentText id="github-config-note-text">
        Note: If you do not see any organizations, please visit the Xoba configuration in{" "}
        <a
          href="https://github.com/settings/applications"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github settings
        </a>
        . Xoba needs to be granted access to the organizations that you want to search.
      </DialogContentText>

      <FormControl className={classes.formControl}>
        <InputLabel id="github-filter-organizations-label">Filter organizations</InputLabel>

        <Controller
          name="organizations_filter"
          inputRef={register}
          defaultValue={data?.organizations?.value || []}
          control={control}
          labelId="github-filter-organizations-label"
          id="github-filter-organizations-select"
          as={
            <Select multiple input={<Input />} MenuProps={MenuProps} autoWidth>
              {orgOptions.map(({ login }: { login: string }) => (
                <MenuItem key={login} value={login} style={getStyles(login, orgValue, theme)}>
                  {login}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>
    </React.Fragment>
  );
};

export default GithubConfigForm;
