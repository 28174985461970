import React, { useEffect, useState } from "react";
import { Avatar, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format as dateFormat } from "timeago.js";

import { useAuthorizedFetch } from "utils/xobaFetch";
import CardLayout, { CardBaseProps } from "../../CardLayout";
import PlatformIcon from "components/PlatformIcon";
import MimeTypeIcon from "components/MimeTypeIcon";
import { CardTypeEnum } from "types";
import CardItemLayout from "../../CardItemLayout";

const TITLE_FOR_VARIANT: { [_: string]: string } = {
  modified: "Recently modified",
  shared: "Recently shared",
  viewed: "Recently viewed",
};

const VERB_FOR_VARIANT: { [_: string]: string } = {
  modified: "Last modified",
  shared: "Shared",
  viewed: "Viewed",
};

const useStyles = makeStyles((theme) => ({
  itemAvatar: {
    minWidth: 28,
  },
  icon: {
    backgroundColor: "transparent",
    color: "black",
    height: 22,
    width: 22,
  },
  subtitle: {
    fontWeight: "bold",
  },
  subtitleBody: {
    marginRight: "10px",
  },
}));

type DocType = {
  id: string;
  webViewLink: string;
  iconLink: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
  sharedWithMeTime: string;
  viewedByMeTime: string;
  platform: string;
  owener: string;
  modified_by: string;
};

type DocItemProps = {
  doc: DocType;
  disabled?: boolean;
  variant: string;
};

const DocItem: React.FC<DocItemProps> = ({ doc, disabled, variant }) => {
  const classes = useStyles();

  let timeField;
  if (variant === "modified") {
    timeField = doc.modifiedTime;
  } else if (variant === "shared") {
    timeField = doc.sharedWithMeTime;
  } else if (variant === "viewed") {
    timeField = doc.viewedByMeTime;
  }

  let datePhrase;
  if (timeField) {
    datePhrase = (
      <span>
        <span className={classes.subtitle}>{VERB_FOR_VARIANT[variant]}</span>{" "}
        <span>{dateFormat(new Date(timeField), navigator.language)}</span>{" "}
      </span>
    );
  }

  const subtitle = (
    <span>
      {doc.owener && (
        <span>
          <span className={classes.subtitle}>Owned by:</span> <span>{doc.owener}</span>{" "}
        </span>
      )}
      {doc.modified_by && (
        <span>
          <span className={classes.subtitle}>Last Modified by:</span> <span>{doc.modified_by}</span>{" "}
        </span>
      )}
      {datePhrase}
    </span>
  );

  let avatar;
  if (doc.iconLink) {
    avatar = <Avatar className={classes.icon} src={doc.iconLink} variant="square" />;
  } else if (doc.platform) {
    avatar = (
      <Avatar className={classes.icon} variant="square">
        <MimeTypeIcon mimeType={doc.mimeType} fallback={<PlatformIcon platform={doc.platform} />} />
      </Avatar>
    );
  } else {
    avatar = <Avatar className={classes.icon} src={doc.iconLink} variant="square" />;
  }

  const title = <Typography variant="subtitle1">{doc.name}</Typography>;

  return (
    <CardItemLayout
      disabled={disabled}
      url={doc.webViewLink}
      icon={avatar}
      primary={title}
      secondary={subtitle}
      cardType={CardTypeEnum.RecentDocs}
      docItem={doc}
    />
  );
};

type RecentDocsCardProps = CardBaseProps;

const RecentDocsCard: React.FC<RecentDocsCardProps> = ({ card, disabled, ...otherProps }) => {
  const variant = card.variant as string;

  const [docs, setDocs] = useState<DocType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const fetch = useAuthorizedFetch();

  useEffect(() => {
    (async function () {
      try {
        const backendUrl = new URL("/recommendations/recent_docs", process.env.REACT_APP_BACKEND);
        const params = new URLSearchParams();
        params.append("variant", variant);

        const resp = await fetch(`${backendUrl.toString()}?${params.toString()}`, {
          credentials: "include",
          method: "GET",
        });

        if (resp.ok) {
          const json = await resp.json();
          setDocs(json);
        } else {
          setError("Failed to load.");
        }
        setLoading(false);
      } catch (e) {
        if (e.name !== "AbortError") {
          throw e;
        }
      }
    })();
  }, [fetch, variant]);

  const title = TITLE_FOR_VARIANT[variant];

  const listItems = (docs || []).map((doc) => {
    return (
      <DocItem key={`${doc.platform}-${doc.id}`} doc={doc} disabled={disabled} variant={variant} />
    );
  });

  return (
    <CardLayout
      card={card}
      title={title}
      disabled={disabled}
      loading={loading}
      error={error}
      {...otherProps}
    >
      <List dense>{listItems}</List>
    </CardLayout>
  );
};

export default RecentDocsCard;
