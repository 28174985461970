import { firestore } from "firebase";
import { useEffect, useMemo } from "react";
import { snapshotToData } from "./helpers";
import { LoadingHook, useIsEqualRef, useLoadingValue } from "../util";

export type CollectionHook<T> = LoadingHook<firestore.QuerySnapshot<T>, Error>;
export type CollectionDataHook<T> = LoadingHook<T[], Error>;

export const useCollection = <T>(
  query?: firestore.Query | null,
  options?: {
    snapshotListenOptions?: firestore.SnapshotListenOptions;
  }
): CollectionHook<T> => {
  const { error, loading, reset, setError, setValue, value } = useLoadingValue<
    firestore.QuerySnapshot,
    Error
  >();
  const ref = useIsEqualRef(query, reset);
  const snapshotListenOptions = options && options.snapshotListenOptions;

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return;
    }
    const listener = snapshotListenOptions
      ? ref.current.onSnapshot(snapshotListenOptions, setValue, setError)
      : ref.current.onSnapshot(setValue, setError);

    return () => {
      listener();
    };
  }, [snapshotListenOptions, ref, setError, setValue]);

  return [value as firestore.QuerySnapshot<T>, loading, error];
};

export const useCollectionData = <T>(
  query?: firestore.Query | null,
  options?: {
    idField?: string;
    refField?: string;
    snapshotListenOptions?: firestore.SnapshotListenOptions;
  }
): CollectionDataHook<T> => {
  const idField = options ? options.idField : undefined;
  const refField = options ? options.refField : undefined;
  const snapshotListenOptions = options ? options.snapshotListenOptions : undefined;
  const [snapshots, loading, error] = useCollection<T>(query, {
    snapshotListenOptions,
  });
  const values = useMemo(
    () =>
      (snapshots
        ? snapshots.docs.map((doc) => snapshotToData(doc, idField, refField))
        : undefined) as T[],
    [snapshots, idField, refField]
  );
  return [values, loading, error];
};
