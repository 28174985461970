import React, { useCallback, useEffect } from "react";
import {
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";

import PLATFORMS from "constants/platforms";
import { UserApplicationType } from "types";
import platformConfigForm from "./platformConfigForms";
import { useGetApplicationConfig, useUpdateApplicationConfig } from "actions/applicationConfig";
import { RUNNING, FINISHED } from "hooks/useLoadingStatus";
import LoadingButton from "components/LoadingButton";
import { useDialogManager, DialogRenderProps } from "components/DialogManager";
import { DisappearingAlert, NormalAlert } from "components/Alert";

type ConfigurationDialogProps = {
  application: UserApplicationType;
};

type EditorDialogProps = ConfigurationDialogProps & DialogRenderProps;

const EditorDialog: React.FC<EditorDialogProps> = ({ application, open, setOpen }) => {
  const ConfigForm: any = platformConfigForm(application.platform);

  const { status: dataStatus, data, run: getConfig } = useGetApplicationConfig(application);
  const loading = dataStatus === RUNNING;
  const { status, run } = useUpdateApplicationConfig(application, open);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const methods = useForm();
  const { formState, handleSubmit, reset } = methods;

  const onSubmit = useCallback(
    async (values) => {
      await run(values);
      reset(values);
    },
    [run, reset]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  let statusAlert;
  if (status === FINISHED) {
    if (!formState.isDirty) {
      statusAlert = (
        <DisappearingAlert severity="success" delay={2000} afterDelay={handleClose}>
          Updated
        </DisappearingAlert>
      );
    } else {
      statusAlert = <NormalAlert severity="error">Failed to save</NormalAlert>;
    }
  }

  const instance = PLATFORMS.get(application.platform);

  if (!instance) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Edit {instance.label} search settings</DialogTitle>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            {loading ? <CircularProgress /> : <ConfigForm application={application} data={data} />}
          </DialogContent>

          <DialogActions>
            {statusAlert}
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton
              status={status}
              disabled={status === RUNNING || !formState.isDirty}
              variant="contained"
              type="submit"
              color="secondary"
            >
              Update
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  editIcon: {
    opacity: 0.5,
  },
}));

const ConfigurationDialog: React.FC<ConfigurationDialogProps> = ({ application }) => {
  const classes = useStyles();
  const { setOpen, setDialog } = useDialogManager();
  const renderDialog = useCallback(
    ({ open }) => {
      return <EditorDialog application={application} open={open} setOpen={setOpen} />;
    },
    [application, setOpen]
  );

  const handleClick = useCallback(() => {
    setOpen(true);
    setDialog(renderDialog);
  }, [setOpen, setDialog, renderDialog]);

  const hasForm = !!platformConfigForm(application.platform);
  if (!hasForm) {
    return null;
  }

  return (
    <Tooltip title="Edit configuration">
      <IconButton size="small" onClick={handleClick}>
        <EditIcon fontSize="inherit" className={classes.editIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default ConfigurationDialog;
