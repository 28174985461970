import firebase from "firebase/app";

export type OpenAndSetOpenProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
};

type UserApplicationConfigType = {
  orgs_filter_str?: string;
};

export type UserApplicationType = {
  id?: number | string;
  platform: string;
  account: string;
  config?: UserApplicationConfigType;
};

export type UserApplicationsType = {
  [id: string]: UserApplicationType;
};

export type UserSearchRecommendationType = {
  interactionCount: number;
  searchCount: number;
  lastInteractionAt: firebase.firestore.Timestamp;
  lastSearchAt: firebase.firestore.Timestamp;
  searchTerm: string;
};

export type CardItemType = {
  id?: string;
  label: string;
  url: string;
  mimeType?: string;
  platform?: string;
};

export enum CardTypeEnum {
  Welcome = "welcome",
  Items = "items",
  SearchHistory = "searchHistory",
  RecentDocs = "recentDocs",
  SavedSearch = "savedSearch",
}

type SavedSearchCardConfigType = {
  query: string;
  filters?: any;
};

export type CardConfigType = SavedSearchCardConfigType;

export type CardType = {
  id?: string;
  title: string;
  type: CardTypeEnum;
  variant?: string;
  items?: CardItemType[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  config?: CardConfigType;
  shareId?: string;
};

export type LayoutType = {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
  moved: boolean;
  static: boolean;
};

export type SpaceLayoutType = {
  lg: LayoutType[];
  md: LayoutType[];
};

export type SpaceType = {
  title: string;
  cards: CardType[]; // this may not resolve since it is a subcollection and Firebase docs don't seem to return their collections.
  layouts: SpaceLayoutType;
};

type PlatformPaginationStateType = {
  [account: string]: any;
};

export type PaginationStateType = {
  [platform: string]: PlatformPaginationStateType;
};

export type SnippetType = [string, number[][], boolean];

// SearchResults

type SearchResultBase = {
  platform: string;
  account: string;
  date: string;
  title: string;
  content_snippets: SnippetType[];
  fallback_snippet: string;
  handle: string;
  user_application_id: number;
  mime_type?: string;
  owners?: any[];
  path?: string;
};

export type AsanaTagType = {
  name: string;
};

export type AsanaAssignee = {
  photo?: string;
  name: string;
};

export type AsanaMembershipType = {
  project: {
    name: string;
    color: string;
  };
  section: {
    name: string;
  };
};

export type AsanaSearchResultType = SearchResultBase & {
  assignee: AsanaAssignee;
  due_date: string;
  memberships: AsanaMembershipType[];
  tags: AsanaTagType[];
  completed: boolean;
};

export type BoxSearchResultType = SearchResultBase & {
  owner: string;
  item_type: string;
  full_path: string[];
  thumbnail?: string;
};

export type ConfluenceSearchResultType = SearchResultBase & {
  author: {
    photo: string;
    name: string;
  };
  ancestors: string[][];
  entity_type: string;
};

export type DriveUserType = {
  display_name: string;
  me: boolean;
};

export type DriveSearchResultType = SearchResultBase & {
  owners: DriveUserType[];
  sharing_user: DriveUserType;
  full_path: string[];
  mime_type: string;
};

export type DropboxSearchResultType = SearchResultBase;

export type EmailParticipant = string[];

export type EmailMessageType = {
  handle: string;
  sender: string[];
  cc: EmailParticipant[];
  recipients: EmailParticipant[];
  date: string;
  content_snippets: SnippetType[];
  fallback_snippet: string;
};

export type EmailSearchResultType = SearchResultBase & {
  subject: string;
  messages: EmailMessageType[];
  participants: EmailParticipant[];
};

export type GithubUserType = {
  login: string;
  name: string;
  email: string;
};

export type GithubLabelType = {
  color: string;
  name: string;
};

export type GithubSearchResultType = SearchResultBase & {
  owner: string;
  repository: string;
  assignees: GithubUserType[];
  item_type: string;
  number: number;
  state: string;
  user: GithubUserType;
  labels: GithubLabelType[];
  body: string;
};

export type JiraUserType = {
  displayName: string;
  avatarUrls: { [_: string]: string };
};

export type JiraSearchResultType = SearchResultBase & {
  issue_details: {
    icon: string;
    key: string;
  };
  priority: {
    name: string;
    iconUrl: string;
  };
  status: {
    name: string;
    color: string;
  };
  assignee: JiraUserType;
  reporter: JiraUserType;
  labels: string[];
};

export type SlackMessageType = {
  handle: string;
  avatar_link: string;
  username: string;
  date: string;
  text: string;
  original: string;
};

export type SlackSearchResultType = SearchResultBase & {
  is_file: boolean;
  is_im: boolean;
  channel_name: string;
  avatar_link: string;
  text: string;
  context_messages: SlackMessageType[];
  username: string;
  mime_type: string;
};

export type OutlookSearchResultType = EmailSearchResultType;
export type OneDriveSearchResultType = SearchResultBase & {};

export type Office365SearchResultType = (OutlookSearchResultType | OneDriveSearchResultType) & {
  item_type: string;
};

export type SearchResultType =
  | AsanaSearchResultType
  | BoxSearchResultType
  | ConfluenceSearchResultType
  | DriveSearchResultType
  | DropboxSearchResultType
  | EmailSearchResultType
  | GithubSearchResultType
  | JiraSearchResultType
  | SlackSearchResultType
  | Office365SearchResultType;

export type ErrorNotification = any;

export type FetchType = (url: RequestInfo, opts: any) => Promise<Response>;

export enum ShareTypeEnum {
  Card = "card",
}

export type ShareAccessConfigType = {
  web: boolean;
};

export type ShareType = {
  type: ShareTypeEnum;
  shareRef: firebase.firestore.DocumentReference;
  accessConfig: ShareAccessConfigType;
  uid: string;
  // createdAt: any; //firebase.firestore.Timestamp;
};

export type CardShareType = {
  accessConfig: ShareAccessConfigType;
  shareRef: firebase.firestore.DocumentReference;
};

// TODO: share this with cloud functions
export type GetSharedCardResponseType = {
  id: string;
  type: ShareTypeEnum;
  data: CardType;
};
