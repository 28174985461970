import { useCallback, useEffect, useState } from "react";
import queryString from "query-string";

import UserFacingError from "utils/UserFacingError";
import { logLinkPlatform } from "analytics";
import { useAuthorizedFetch } from "utils/xobaFetch";
import authPost, { AuthContinueType, authPostContinue } from "./api";
import { Platform } from "constants/platforms";

class PopupClosedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export const WINDOW_MESSAGE_SOURCE = "xoba-oauth-redirect";

export const popupWindowCallback = () => {
  if (window.opener) {
    // get the URL parameters which will include the auth token
    const params = window.location.search;
    // send them to the opening window
    window.opener.postMessage({ source: WINDOW_MESSAGE_SOURCE, params }, window.location.origin);
    // close the popup
    window.close();
  }
  // TODO: handle else case
};

// https://hackernoon.com/how-we-use-a-popup-for-google-and-outlook-oauth-5d8c03652171
let windowObjectReference: any = null;
let previousUrl: string | null = null;

const closePopup = () => {
  if (windowObjectReference && !windowObjectReference.closed) {
    windowObjectReference.close();
  }
};

const openPopupWindow = (url: string, name: string) => {
  return new Promise((resolve, reject) => {
    // window features
    const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
       is not closed; therefore, we can bring it back on top of any other
       window with the focus() method. There would be no need to re-create
       the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    let rejectTimeout: any;
    const checkPopupInterval = setInterval(() => {
      if (windowObjectReference?.closed) {
        clearInterval(checkPopupInterval);

        // give `receiveMessage` time to get the message
        rejectTimeout = setTimeout(() => {
          reject(new PopupClosedError("closed"));
        }, 50);
      }
    }, 100);

    const receiveMessage = (event: any) => {
      // Do we trust the sender of this message? (might be
      // different from what we originally opened, for example).
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data } = event;
      // if we trust the sender and the source is our popup
      if (data.source === WINDOW_MESSAGE_SOURCE) {
        clearInterval(checkPopupInterval);
        clearTimeout(rejectTimeout);
        const { params } = data;

        // cleanup
        window.removeEventListener("message", receiveMessage);
        resolve({ params });
      }
      // TODO: figure out if we ever reject?
    };

    // add the listener for receiving a message from the popup
    window.addEventListener("message", receiveMessage, false);
    // assign the previous URL
    previousUrl = url;
  });
};

const openOauthPopup = async (platform: Platform) => {
  const { extraData, authUrl, clientId, route } = platform.oauthConfig;

  const oauthUrl = new URL(authUrl);
  const redirectUri = `${window.location.origin}${route}`;

  oauthUrl.search = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    //state: //TODO(piyush) Use a CSRF token
    ...extraData,
  }).toString();

  // open popup
  const { params: urlParams }: any = await openPopupWindow(oauthUrl.toString(), "oauthPopup");

  return {
    params: queryString.parse(urlParams),
    redirectUri,
  };
};

export const useConnectApplication = (
  platform: Platform | undefined,
  open: boolean,
): [
    boolean,
    boolean,
    string | null,
    AuthContinueType | null,
    () => void,
    () => void,
    () => void,
    (data: AuthContinueType) => void,
  ] => {
  if (!platform) {
    throw new Error(`Invalid platform ${platform}`);
  }

  const fetch = useAuthorizedFetch();
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [authOptions, setAuthOptions] = useState<AuthContinueType | null>(null);

  const handleError = (err: unknown) => {
    if (err instanceof UserFacingError) {
      setError(err.message);
      setLoading(false);
      setConnected(false);
    } else if (err instanceof PopupClosedError) {
      setLoading(false);
    } else {
      throw err;
    }
  };

  useEffect(() => {
    if (authOptions?.account) {
      authPostContinue(fetch, authOptions).then(() => {
        setConnected(true);
        setLoading(false);
      }).catch(handleError);
    }
  }, [authOptions, fetch]);

  useEffect(() => {
    if (open) {
      setConnected(false);
      setLoading(false);
      setError(null);
    }
  }, [open]);

  const run = useCallback(async () => {
    logLinkPlatform(platform.name);

    setConnected(false);
    setLoading(true);
    setError(null);

    try {
      const { redirectUri, params } = await openOauthPopup(platform);

      if ("error" in params) {
        if (params.error !== "access_denied") {
          throw new UserFacingError(params.error as string);
        }
        // TODO: do we need to log this error?
        return null;
      } else if (!("code" in params)) {
        throw new UserFacingError("No auth code in OAuth server response");
      }

      // TODO: pass scope
      const data = await authPost({
        fetch,
        platform,
        redirectUri,
        params: { code: params.code as string },
      });

      if (data.auth_data.sites && Object.keys(data.auth_data.sites).length > 1) {
        data.account = "";
      }

      setAuthOptions(data);
    } catch (err) {
      handleError(err);
    }
  }, [fetch, platform]);

  const cancel = useCallback(() => {
    closePopup();
    setAuthOptions(null);
  }, []);

  const reset = useCallback(() => {
    setConnected(false);
    setLoading(false);
    setError(null);
  }, []);

  return [connected, loading, error, authOptions, run, cancel, reset, setAuthOptions];
};
