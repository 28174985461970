import React from "react";

import ApplicationIcon from "components/ApplicationIcon";
import EmailSearchResult from "./EmailSearchResult";
import SearchResultLayout from "../SearchResultLayout";
import { OneDriveSearchResultType, Office365SearchResultType } from "types";
import { Box } from "@material-ui/core";
import PathGenerator from "../PathGenerator";

const OneDriveSearchResult: React.FC<{ result: OneDriveSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const icon = <ApplicationIcon name="OneDrive" />;
  let subheader;

  if (result.path) {
    subheader = (
      <Box display="flex" flexDirection="column">
        <PathGenerator path={result.path} />
      </Box>
    );
  } else if (result.handle) {
    let path;
    path = result.handle.split("/").slice(5, -1).join("/");
    subheader = (
      <Box display="flex" flexDirection="column">
        <PathGenerator path={path} />
      </Box>
    );
  }

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      icon={icon}
      account={result.account}
      header={result.title}
      subheader={subheader}
    />
  );
};

const Office365SearchResult: React.FC<{ result: Office365SearchResultType; index: number }> = ({
  result,
  index,
}) => {
  if (result.item_type === "driveItem") {
    return <OneDriveSearchResult result={result} index={index} />;
  } else {
    const icon = <ApplicationIcon name="Outlook" />;

    // TODO: figure out how to switch on the type
    // @ts-ignore
    return <EmailSearchResult result={result} index={index} icon={icon} />;
  }
};

export default Office365SearchResult;
