import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  Typography,
  Menu,
  Checkbox,
  CircularProgress,
  Box,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import firebase from "firebase/app";
import { useFirebase } from "components/Firebase";

import { CardType, CardTypeEnum } from "types";
import { errorToHelperText } from "utils/formHelpers";
import { logCardChange, CardChangeOperator } from "analytics";
import { lookupConfigurationFn } from "./cardTypes";
import { UserApplicationsType } from "types";
import { useObjectVal } from "hooks/firebase/database";
import { APPLICATIONS } from "constants/routes";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateFilter, { DateFilterValueType } from "../Search/SearchFilters/DateFilter";
import SortMenu from "../Search/SearchFilters/SortMenu";

import BookmarkIcon from "@material-ui/icons/Bookmark";
import HistoryIcon from "@material-ui/icons/History";
import DescriptionIcon from "@material-ui/icons/Description";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

interface IFormInputs {
  title: string;
  searchTerm: string;
  type: CardTypeEnum;
  items: object[];
}

const itemSchema = yup.object().shape({
  label: yup.string().required(),
  url: yup.string().required(),
  platform: yup.string(),
  mimeType: yup.string().nullable(),
});

const schema = yup.object().shape({
  title: yup.string().when("type", {
    is: (value) => value === "items",
    then: yup.string().required(),
  }),
  type: yup.string(),
  variant: yup.string(),
  items: yup.array().of(itemSchema),
});

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    margin: theme.spacing(1),
  },
  filters: {
    marginTop: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  textInput: {
    width: "70%",
  },
  spinner: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px",
  },
  capitalizeItem: {
    textTransform: "capitalize",
  },
  connectMessage: {
    marginLeft: "10px",
    marginRight: "10px",
    width: "380px",
    lineHeight: 1.3,
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  connectMessageLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.3,
    fontSize: "1.2rem",
  },
  buttonLeyend: {
    marginLeft: "10px",
  },
  notChoseButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  chosenButton: {
    border: "#3B35A6 solid 3px",
    backgroundColor: "rgb(53,104,166,0.2)",
  },
  actionButtons: {
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "20px",
  },
}));

interface Props {
  onChange: () => void;
  handleChosenCard: (value: CardTypeEnum) => void;
  value: CardTypeEnum;
}

const CardTypeSelect: React.FC<Props> = (props) => {
  const classes = useStyles();

  const cardsLeyend: { [key: string]: any } = {
    items: "Categorize links, documents, and files",
    searchHistory: "View your top and recent Xoba searches",
    recentDocs: "View recently shared, modified, and viewed docs",
    welcome: "Learn about Xoba",
  };

  const handleCardsLeyends = (key: string) => {
    return "(" + cardsLeyend[key] + ")";
  };

  return (
    <FormControl component="fieldset">
      <Typography variant="h6">Card Type</Typography>
      <Box width="750px" mt="20px" display="flex" justifyContent="space-between">
        <Button
          className={
            CardTypeEnum.Items === props.value ? classes.chosenButton : classes.notChoseButton
          }
          variant="outlined"
          onClick={() => {
            props.handleChosenCard(CardTypeEnum.Items);
          }}
        >
          <BookmarkIcon fontSize="small" className="AddCard__Bookmarks__Button" />
          <span className={classes.buttonLeyend}>Bookmarks</span>
        </Button>

        <Button
          variant="outlined"
          className={
            CardTypeEnum.SearchHistory === props.value
              ? classes.chosenButton
              : classes.notChoseButton
          }
          onClick={() => {
            props.handleChosenCard(CardTypeEnum.SearchHistory);
          }}
        >
          <HistoryIcon fontSize="small" className="AddCard__SearchHistory__Button" />
          <span className={classes.buttonLeyend}>Search history</span>
        </Button>

        <Button
          className={
            CardTypeEnum.RecentDocs === props.value ? classes.chosenButton : classes.notChoseButton
          }
          variant="outlined"
          onClick={() => {
            props.handleChosenCard(CardTypeEnum.RecentDocs);
          }}
        >
          <DescriptionIcon fontSize="small" className="AddCard__RecentDocs__Button" />
          <span className={classes.buttonLeyend}>Recent documents</span>
        </Button>

        <Button
          variant="outlined"
          className={
            CardTypeEnum.Welcome === props.value ? classes.chosenButton : classes.notChoseButton
          }
          onClick={() => {
            props.handleChosenCard(CardTypeEnum.Welcome);
          }}
        >
          <PlayCircleOutlineIcon fontSize="small" className="AddCard__Welcome__Button" />
          <span className={classes.buttonLeyend}>Get started</span>
        </Button>
      </Box>
      <Box mt="10px">
        <Typography variant="subtitle1">
          {props.value && handleCardsLeyends(props.value)}
        </Typography>
      </Box>
    </FormControl>
  );
};

type EditCardDialogProps = {
  card?: CardType;
  cardsRef: any;
  open: boolean;
  onClose: () => void;
  paramsUpdate?: () => void;
};

const EditCardDialog: React.FC<EditCardDialogProps> = ({
  open,
  onClose,
  cardsRef,
  card,
  paramsUpdate,
}) => {
  const { id: cardId, title } = card || {};

  const isNew = !cardId;
  const classes = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "accountFilter" });

  const firebaseStore = useFirebase();
  const [formAccountsFilter, setFormAccountsFilter] = useState<number[]>([]);
  const [formDateFilter, setFormDateFilter] = useState<DateFilterValueType>({ type: "" });
  const [formSortType, setFormSortType] = useState<string | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (card && formDateFilter.type === "") {
      setFormDateFilter({ type: card.config?.filters.dateFilter.type });
      setFormSortType(card.config?.filters.sortType);
      setFormAccountsFilter(card.config?.filters.accountIds);
    }
  }, [formDateFilter, card]);

  const [unprocessedApps] = useObjectVal<UserApplicationsType>(
    firebaseStore.userDbRef("userApplications")
  );
  const userApplications = useMemo(() => {
    if (!unprocessedApps) {
      return undefined;
    }
    const res: UserApplicationsType = {};

    Object.keys(unprocessedApps).forEach((id) => {
      res[id] = {
        id: parseInt(id, 10),
        ...unprocessedApps[id],
      };
    });

    return res;
  }, [unprocessedApps]);

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: card || {},
  });

  const handleChosenCard = (value: CardTypeEnum) => {
    methods.setValue("type", value);
  };

  useEffect(() => {
    if (!saving) {
      methods.reset({ ...card });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const cardType = isNew
    ? methods.watch("type", CardTypeEnum.Items)
    : (card && card.type) || CardTypeEnum.Items;

  const onSubmit = useCallback(
    async (data: any) => {
      setSaving(true);
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      let type = data.type;
      let variant = data.variant;
      let title = data.title;
      let searchTerm = data.searchTerm;
      if (type === "searchHistory") {
        title = variant === "top" ? "Top searches" : "Recent searches";
      }

      const newCard: CardType = {
        title: title,
        items: data.items,
        type: type,
        variant: variant,
        updatedAt: now,
        createdAt: (card || {}).createdAt || now,
      };

      if (newCard.type === CardTypeEnum.SavedSearch) {
        newCard!.config = card!.config;
        newCard!.config!.query = searchTerm;
        newCard!.config!.filters.dateFilter.type = formDateFilter.type;
        newCard!.config!.filters.sortType = formSortType;
        newCard!.config!.filters.accountIds = formAccountsFilter;
      }
      // use a JSON stringify + parse trick to remove undefined values
      const doc = JSON.parse(JSON.stringify(newCard));

      logCardChange({
        operation: isNew ? CardChangeOperator.Create : CardChangeOperator.Update,
        cardType: type,
      });

      if (cardId) {
        await cardsRef.doc(cardId).set(doc, { merge: true });
        methods.reset({ ...card, ...doc });
      } else {
        await cardsRef.add(doc);
        methods.reset();
      }

      if (newCard.type === CardTypeEnum.SavedSearch && paramsUpdate) {
        paramsUpdate();
      }
      onClose();
      setSaving(false);
    },
    [
      cardsRef,
      cardId,
      onClose,
      isNew,
      card,
      methods,
      formAccountsFilter,
      formDateFilter.type,
      formSortType,
      paramsUpdate,
    ]
  );

  const fieldErrors = methods.errors;
  // TODO: the <form> inside <Dialog> messes up scrolling the body of the dialog

  let typeInput;
  if (isNew) {
    typeInput = (
      <Grid item>
        <Controller
          name="type"
          render={({ onChange, value }) => (
            <CardTypeSelect onChange={onChange} handleChosenCard={handleChosenCard} value={value} />
          )}
          control={methods.control}
          defaultValue={CardTypeEnum.Items}
        />
      </Grid>
    );
  } else {
    typeInput = (
      <input type="hidden" name="type" ref={methods.register} value={card && card.type} />
    );
  }
  let titleInput;
  if (cardType === CardTypeEnum.Items || cardType === CardTypeEnum.SavedSearch) {
    titleInput = (
      <Grid item>
        <Controller
          name="title"
          render={({ onChange, value }) => (
            <TextField
              className={classes.textInput}
              id="cardTitle"
              margin="dense"
              label="Title"
              type="text"
              required
              autoFocus
              helperText={errorToHelperText("title", fieldErrors?.title)}
              error={!!fieldErrors.title}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
          control={methods.control}
          defaultValue={title || ""}
        />
      </Grid>
    );
  } else {
    titleInput = <input type="hidden" name="title" ref={methods.register} value={title} />;
  }

  let searchTerm;
  if (cardType === CardTypeEnum.SavedSearch) {
    searchTerm = (
      <Grid item>
        <Controller
          name="searchTerm"
          render={({ onChange, value }) => (
            <TextField
              id="searchTerm"
              margin="dense"
              label="Search Term"
              type="text"
              required
              className={classes.textInput}
              helperText={errorToHelperText("searchTerm", fieldErrors?.searchTerm)}
              error={!!fieldErrors.searchTerm}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
          control={methods.control}
          defaultValue={card?.config?.query || ""}
        />
      </Grid>
    );
  }

  const handleClickAccount = (id: any) => {
    if (formAccountsFilter.includes(id)) {
      setFormAccountsFilter(formAccountsFilter.filter((itemId) => itemId !== id));
    } else {
      setFormAccountsFilter([...formAccountsFilter, id]);
    }
  };

  const isChecked = (appId: any) => formAccountsFilter.filter((id) => id === appId).length > 0;

  let filters;
  let buttonText;
  let accountMenu;
  if (cardType === CardTypeEnum.SavedSearch) {
    let menu;
    if (userApplications) {
      if (!card) {
        buttonText = "...";
      } else if (formAccountsFilter.length === Object.keys(userApplications).length) {
        buttonText = "All";
      } else if (formAccountsFilter.length === 0) {
        buttonText = "None";
      } else {
        buttonText = `${formAccountsFilter.length} selected${
          formAccountsFilter.length !== 1 ? "" : ""
        }`;
      }

      menu = (
        <List>
          {Object.values(userApplications).map((application) => (
            <ListItem
              button
              key={application.id}
              onClick={() => {
                handleClickAccount(application.id);
              }}
            >
              <ListItemIcon>
                <Checkbox
                  size="small"
                  color="primary"
                  disableRipple
                  checked={isChecked(application.id)}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ className: classes.capitalizeItem }}
                primary={application.platform.toLowerCase()}
                secondary={application.account}
              />
            </ListItem>
          ))}
        </List>
      );
    }

    accountMenu = (
      <>
        <Button
          variant="outlined"
          size="small"
          endIcon={<ArrowDropDownIcon />}
          className={classes.button}
          {...bindTrigger(popupState)}
        >
          <Typography display="inline" variant="subtitle2" color="textSecondary">
            Filter accounts: {buttonText}
          </Typography>
        </Button>
        <Menu
          {...bindMenu(popupState)}
          keepMounted
          // Opens menu below, not on top of, the button.
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {menu}
        </Menu>
      </>
    );

    filters = (
      <Grid className={classes.filters} container direction="row" spacing={1}>
        <Grid item>{accountMenu}</Grid>
        <Grid item>
          <DateFilter value={formDateFilter} onChange={setFormDateFilter} />{" "}
        </Grid>
        <Grid item>
          <SortMenu value={formSortType} onChange={setFormSortType} />
        </Grid>
      </Grid>
    );
  }

  const loadingSpinner = (
    <Box mx="auto" mt="10px" display="flex" flexDirection="column">
      <CircularProgress color="secondary" className={classes.spinner} />
      <Typography align="center" variant="body1">
        Saving Data
      </Typography>
    </Box>
  );

  const ConfigurationFields: any = lookupConfigurationFn(cardType);

  let noAccountsWarning;

  if (
    methods.getValues().type === CardTypeEnum.SearchHistory ||
    methods.getValues().type === CardTypeEnum.RecentDocs
  ) {
    noAccountsWarning = (
      <Typography className={classes.connectMessage} variant="h6" color="primary">
        You must connect an application to add this Card.{" "}
        <Link className={classes.connectMessageLink} to={APPLICATIONS}>
          Connect one now.
        </Link>
      </Typography>
    );
  }

  const createButtonIsDisabled = useMemo(() => {
    return (
      !userApplications &&
      isNew &&
      (methods.getValues().type === CardTypeEnum.SearchHistory ||
        methods.getValues().type === CardTypeEnum.RecentDocs)
    );
  }, [userApplications, methods, isNew]);

  return (
    <Dialog
      maxWidth={cardType === CardTypeEnum.SavedSearch ? "md" : "md"}
      open={open}
      onClose={onClose}
      aria-labelledby="editCardDialogTitle"
      scroll="paper"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="editCardDialogTitle">
            {isNew ? "Add a Card" : `Edit ${card?.title} Card`}
          </DialogTitle>

          <DialogContent dividers>
            {isNew && (
              <DialogContentText>Select a type of Card to add to your Space</DialogContentText>
            )}

            <Grid container direction="column" spacing={2}>
              {typeInput}
              <Box width="400px" padding="8px">
                {titleInput}
              </Box>

              {searchTerm}
              {filters}
              {card?.type === CardTypeEnum.SavedSearch && saving && loadingSpinner}
              {!userApplications && noAccountsWarning}
              {ConfigurationFields && <ConfigurationFields card={card} />}
            </Grid>
          </DialogContent>

          <DialogActions className={classes.actionButtons}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={methods.formState.isSubmitting || createButtonIsDisabled}
            >
              {isNew ? "Create" : "Save"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditCardDialog;
