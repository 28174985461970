import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Search from "components/Search";
import useQueryParam from "hooks/useQueryParam";
import GettingStarted from "components/GettingStarted";
import Spaces from "components/Spaces";
import { HAS_CARDS } from "constants/features";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [searching, setSearching] = useState<boolean>(false);
  const [searchParam] = useQueryParam("search", "");
  const showSpaces = HAS_CARDS && searchParam === "";

  useEffect(() => {
    if (searchParam === "") {
      setSearching(false);
    }
  }, [searchParam]);

  return (
    <div className={classes.root}>
      <GettingStarted />
      <Search setSearching={setSearching} />
      {!searching && showSpaces && <Spaces />}
    </div>
  );
};

export default HomePage;
