import { useCallback, useEffect, useRef, useState } from "react";
import useMutationObserver from "@rooks/use-mutation-observer";
import { useGetCustomToken } from "./getCustomToken";
import useLoadingStatus, { RUNNING } from "hooks/useLoadingStatus";
import { logExtensionConnected } from "analytics";

export const POST_MESSAGE_TYPE = "CONNECT_XOBA_EXTENSION";
export const BODY_CLASS = "XOBA_EXTENSION";
export const LOGGED_IN_CLASS = `${BODY_CLASS}--loggedIn`;

export const NOT_INSTALLED = "notInstalled";
export const INSTALLED = "installed";
export const LOGGED_IN = "loggedIn";

const bodyHasClass = (className: string) => document.body.classList.contains(className);

export const detectExtensionState = () => {
  if (bodyHasClass(LOGGED_IN_CLASS)) {
    return LOGGED_IN;
  } else if (bodyHasClass(BODY_CLASS)) {
    return INSTALLED;
  }
  return NOT_INSTALLED;
};

export const markInstalled = () => {
  if (detectExtensionState() === NOT_INSTALLED) {
    document.body.className = `${document.body.className} ${BODY_CLASS}`;
  }
};

export const markLoggedIn = () => {
  markInstalled();
  if (detectExtensionState() !== LOGGED_IN) {
    document.body.className = `${document.body.className} ${LOGGED_IN_CLASS}`;
  }
};

export const useExtensionState = () => {
  const [extensionState, setExtensionState] = useState(detectExtensionState());

  const bodyRef = useRef(document.body);
  const onBodyMutation = () => {
    setExtensionState(detectExtensionState());
  };
  useMutationObserver(bodyRef, onBodyMutation);

  return extensionState;
};

export const useConnectExtension = () => {
  const getCustomToken = useGetCustomToken();
  const extensionState = useExtensionState();

  const { status, start, finish } = useLoadingStatus();

  useEffect(() => {
    if (status === RUNNING && extensionState === LOGGED_IN) {
      finish();
      logExtensionConnected({ status: "success" });
    }
  }, [status, extensionState, finish]);

  const run = useCallback(async () => {
    start();
    const token = await getCustomToken();
    window.postMessage({ type: POST_MESSAGE_TYPE, token }, window.location.origin);
  }, [start, getCustomToken]);

  return {
    status,
    run,
  };
};
