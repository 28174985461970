import Firebase from "components/Firebase";

export const incrementTotalSearches = async (firebase: Firebase) => {
  const collection = firebase.firestore.collection("user_searches").doc(firebase.currentUser().uid);

  firebase.firestore.runTransaction(async (transaction) => {
    const userSearches = await transaction.get(collection);
    const totalSearches = userSearches.data()?.total_searches;

    if (totalSearches) {
      transaction.update(collection, { total_searches: totalSearches + 1 });
    } else {
      transaction.set(collection, { total_searches: 1 });
    }
  });
};
