import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Container } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import { NormalAlert } from "components/Alert";
import * as ROUTES from "constants/routes";
import AuthPageLayout from "./AuthPageLayout";
import { usePasswordlessVerification, PASSWORDLESS_MODE } from "components/Auth/PasswordlessAuth";
import SigninForm from "components/Auth/PasswordAuth/SigninForm";
import ConnectExtension, { CONNECT_EXTENSION_MODE } from "./ConnectExtension";
import { useCurrentUser } from "components/Session";

const SignInPage = () => {
  const params: any = useParams();
  const passwordLessMode = params.mode === PASSWORDLESS_MODE;
  // support passwords so we can log in for end-to-end testing
  const passwordMode = params.mode === "password";
  const extensionMode = params.mode === CONNECT_EXTENSION_MODE;
  const [user, loading] = useCurrentUser();

  const [error] = usePasswordlessVerification(passwordLessMode);
  const history = useHistory();

  useEffect(() => {
    if (!extensionMode && !loading && user) {
      history.push(ROUTES.HOME);
    }
  }, [extensionMode, loading, user, history]);

  if (passwordMode) {
    return <SigninForm />;
  }

  if (extensionMode) {
    return <ConnectExtension />;
  }

  if (passwordLessMode) {
    let message;
    if (error) {
      message = <NormalAlert severity="error">{error}</NormalAlert>;
    } else {
      message = <Typography variant="h6">Logging you in</Typography>;
    }

    return (
      <Container maxWidth="xs">
        <Grid container direction="column" alignItems="center">
          <Grid item>{message}</Grid>
        </Grid>
      </Container>
    );
  }

  const footer = (
    <Grid container>
      <Grid item xs>
        <Link to={ROUTES.SIGN_UP}>
          <Typography variant="body2">Don't have an account? Sign up</Typography>
        </Link>
      </Grid>
    </Grid>
  );

  return <AuthPageLayout title="Sign In" footer={footer} />;
};

export default SignInPage;
