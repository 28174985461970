import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/performance";
import "firebase/functions";
import { GoogleLoginResponse } from "react-google-login";

import UserFacingError, { NotLoggedInError } from "utils/UserFacingError";
import { ErrorNotification } from "types";

// TODO(piyush) Create separate firebase projects for production and for
// internal testing.
const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || "{}");

// TODO(piyush) Use a protobuf enum here.
// See constants.py.
const USER_DB = "user_metadata"; // Stores user data
const ERRORS_DB = "errors"; // Stores stateful errors to display to user
const SEGMENT_DISABLED_DB = "segment_disabled"; // Users without analytics

const dbRefPath = (...args: (string | null | undefined)[]) => {
  return args.filter((v) => !!v).join("/");
};

export const userDbRefPath = (uid: string, branch?: string) => {
  return dbRefPath(USER_DB, uid, branch);
};

export const errorDbRefPath = (uid: string, branch?: string) => {
  return dbRefPath(ERRORS_DB, uid, branch);
};

export const segmentDisabledDbRefPath = (uid: string, branch?: string) => {
  return dbRefPath(SEGMENT_DISABLED_DB, uid, branch);
};

class Firebase {
  auth: app.auth.Auth;
  db: app.database.Database;
  perf: app.performance.Performance;
  functions: app.functions.Functions;
  firestore: app.firestore.Firestore;

  constructor() {
    // hack around CSP issues in the extension by forcing Websockets instead of falling back to long-polling
    // https://github.com/firebase/firebase-js-sdk/issues/187
    if (process.env.REACT_APP_IS_EXTENSION === "true") {
      config.databaseURL = config.databaseURL.replace("https://", "wss://");
    }
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.perf = app.performance();
    this.functions = app.functions();
    this.firestore = app.firestore();
  }

  currentUser = () => {
    const res = this.auth.currentUser;

    if (!res) {
      throw new NotLoggedInError();
    }

    return res;
  };

  // Authentication API

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doGoogleSignIn = (googleUser: GoogleLoginResponse) => {
    // Make sure a user with this email exists.
    const email = googleUser.getBasicProfile().getEmail();
    return this.auth.fetchSignInMethodsForEmail(email).then((providers) => {
      if (providers.length === 0) throw new UserFacingError("Account does not exist");
      else if (!providers.includes("google.com"))
        throw new UserFacingError("Account isn't signed in with Google");

      // Sign in.
      const credential = app.auth.GoogleAuthProvider.credential(
        googleUser.getAuthResponse().id_token
      );
      return this.auth.signInWithCredential(credential);
    });
  };

  doGoogleSignUp = async (googleUser: GoogleLoginResponse) => {
    // Make sure a user with this email doesn't already exist.
    const email = googleUser.getBasicProfile().getEmail();
    const providers = await this.auth.fetchSignInMethodsForEmail(email);

    if (providers.length !== 0) {
      throw new UserFacingError("Account with this email already exists");
    }

    // Create user.
    const credential = app.auth.GoogleAuthProvider.credential(
      googleUser.getAuthResponse().id_token
    );

    const resp = await this.auth.signInWithCredential(credential);
    if (!resp || !resp.user) {
      throw new UserFacingError("Failed to sign up");
    }

    return this.initUser(email);
  };

  doSignOut = () => {
    return this.auth.signOut();
  };

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = async (currentPassword: string, newPassword: string) => {
    await this.reauthenticate(currentPassword);
    return await this.currentUser().updatePassword(newPassword);
  };

  reauthenticate = (password: string) => {
    const user = this.currentUser();

    const providerId = user.providerData[0]?.providerId;
    if (providerId === "password") {
      if (!user.email) {
        throw Error("User account doesn't have an email");
      }

      const credential = app.auth.EmailAuthProvider.credential(user.email, password);
      return user.reauthenticateWithCredential(credential);
    } else if (providerId === "google.com") {
      return user.reauthenticateWithPopup(new app.auth.GoogleAuthProvider());
    } else {
      throw Error(`Provider ${providerId} not implemented`);
    }
  };

  deleteUser = () => {
    // associated records are cleaned up in a Firebase function that runs on account deletion
    return this.currentUser().delete();
  };

  // Realtime database API

  userDbRef = (branch?: string) => {
    const uid = this.currentUser()?.uid;
    return this.db.ref(userDbRefPath(uid, branch));
  };

  userErrors = (uid?: string) /* = this.currentUser().uid */ => {
    uid = uid || this.currentUser()?.uid;
    return this.db.ref(errorDbRefPath(uid));
  };

  segmentDisabled = (uid?: string) /* = this.currentUser().uid */ => {
    uid = uid || this.currentUser()?.uid;
    return this.db.ref(segmentDisabledDbRefPath(uid));
  };

  // TODO: remove this method
  initUser = (email: string) =>
    this.userDbRef().set({
      email: email,
    });

  updateErrors = (errors: ErrorNotification[]) => this.userErrors().set(errors);

  updateSegmentDisabled = (disabled: boolean) => {
    const ref = this.segmentDisabled();
    if (disabled) ref.set(true);
    else ref.remove();
  };
}

export default Firebase;
