import React from "react";
import PlanCards from "components/Account/PlanCards";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const PlansPage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>
        Choose a Plan
      </Typography>
      <Typography variant="subtitle2">
        Select a plan that best fits your need below. For our premium plans, we offer monthly and
        yearly payment options.
      </Typography>

      <PlanCards />
    </React.Fragment>
  );
};

export default PlansPage;
