import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import * as ROUTES from "constants/routes";
import { identify, logSignIn } from "analytics";
import { useFirebase } from "components/Firebase";

const useStyles = makeStyles((theme) => ({
  form: { width: "100%" /* Fix IE 11 issue */ },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SigninForm = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<any | null>(null);
  const [passwordError, setPasswordError] = useState<any | null>(null);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(({ user }: { user: any }) => {
        // Log to analytics.
        identify(user);
        logSignIn();

        setEmail("");
        setPassword("");
        setEmailError(null);
        setPasswordError(null);
        history.push(ROUTES.HOME); // Redirect to home page
      })
      .catch((error: any) => {
        if (error.code === "auth/invalid-email") {
          setEmailError({ message: "Invalid email address" });
        } else if (error.code === "auth/user-not-found") {
          setEmailError({
            message: "No user with this email found",
          });
        } else if (error.code === "auth/wrong-password") {
          setPasswordError({ message: "Incorrect password" });
        } else {
          // TODO(piyush) Verify that all the cases for error.code
          // are covered so this doesn't happen.
          alert(`ERROR (${error.code}): ${error.message}`);
        }
      });
    event.preventDefault();
  };

  return (
    <form noValidate onSubmit={onSubmit} className={classes.form}>
      <TextField
        name="email"
        type="email"
        placeholder="Email address"
        required
        error={emailError != null}
        label={emailError != null ? "Error" : null}
        helperText={emailError != null ? emailError.message : null}
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="email"
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextField
        name="password"
        type="password"
        placeholder="Password"
        required
        error={passwordError != null}
        label={passwordError != null ? "Error" : null}
        helperText={passwordError != null ? passwordError.message : null}
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="off"
        onChange={(event) => setPassword(event.target.value)}
      />

      <Button
        type="submit"
        disabled={email === "" || password === ""}
        variant="contained"
        color="primary"
        fullWidth
        className={classes.submit}
      >
        Sign in
      </Button>
    </form>
  );
};

export default SigninForm;
