import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";

import AccountPage from "pages/Account";
import PlansPage from "pages/Plans";
import ApplicationsPage from "pages/Applications";
import HomePage from "pages/Home";
import FavoritesPage from "pages/Favorites";
import HistoryPage from "pages/History";
import PrivacyPolicy from "components/Footer/privacy_policy";
import SignOutPage from "components/Auth/SignOut";
import SignInPage from "pages/Auth/SignIn";
import SignUpPage from "pages/Auth/SignUp";
import ViewSharePage from "pages/ViewShare";
import PlatformOauthPage from "pages/PlatformOauth";
import Skeleton from "components/Skeleton";
import TermsOfService from "components/Footer/terms_of_service";
import { theme } from "constants/theme";
import DelayedSpinner from "components/DelayedSpinner";
import ErrorBoundary from "components/ErrorBoundary";
import PageFallback from "components/ErrorBoundary/fallbacks/PageFallback";
import { CAN_RESET_SEARCH_COUNT, HAS_FAVORITES, HAS_HISTORY } from "constants/features";

import * as ROUTES from "constants/routes";
import { AuthProvider, useCurrentUser } from "components/Session";
import { logPage, historyPageviewListener } from "analytics";
import { useResetTotalSearches } from "hooks/limitsCounters/useTotalSearches";
// comment to redeploy

const PublicRoutes = () => {
  return [
    <Route key="privacy" path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />,
    <Route key="terms" path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />,
    <Route key="share" path="/shares/:shareId" component={ViewSharePage} />,
  ];
};

const AccountManagmentRoutes = (authed: boolean) => {
  // These pages are not accessible to signed in users.
  const redirect = authed && <Redirect to={ROUTES.HOME} />;

  return [
    <Route
      key="signup"
      path={ROUTES.SIGN_UP}
      render={() => {
        return redirect || <SignUpPage />;
      }}
    />,
    <Route key="signin" path={`${ROUTES.SIGN_IN}/:mode?`} component={SignInPage} />,
  ];
};

// Only accessible to authenticated users.
const AuthedRoutes = () => {
  return [
    <Route key="home" path={ROUTES.HOME} component={HomePage} />,
    HAS_FAVORITES && <Route key="favorites" path={ROUTES.FAVORITES} component={FavoritesPage} />,
    HAS_HISTORY && <Route key="history" path={ROUTES.HISTORY} component={HistoryPage} />,
    <Route key="account" path={ROUTES.ACCOUNT} component={AccountPage} />,
    <Route key="plans" path={ROUTES.PLANS} component={PlansPage} />,
    <Route key="applications" path={ROUTES.APPLICATIONS} component={ApplicationsPage} />,
    <Route key="signout" path={ROUTES.SIGN_OUT} component={SignOutPage} />,
    CAN_RESET_SEARCH_COUNT && <Route key="reset-search-count" path="/reset-search-count" component={ResetSearchCountPage} />,
  ].filter((r) => !!r);
};

const Routes = () => {
  // Route to home page by default; if not logged in, this will
  // automatically redirect to the sign in page.
  const [user] = useCurrentUser();
  const authed = !!user;
  const defaultRedirect = authed ? ROUTES.HOME : ROUTES.SIGN_IN;

  return (
    <ErrorBoundary fallback={PageFallback}>
      <Switch>

        <Route
          key="oauth"
          path={`${ROUTES.APPLICATION_OAUTH}/:platformName?`}
          component={PlatformOauthPage}
        />

        {PublicRoutes()}

        {AccountManagmentRoutes(authed)}

        {authed && AuthedRoutes()}

        <Route path="*">
          <Redirect to={defaultRedirect} />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

const Loading = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "80vh" }}
    >
      <Grid item xs={3}>
        <DelayedSpinner size={50} />
      </Grid>
    </Grid>
  );
};

const LogHistory = () => {
  const history = useHistory();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      logPage();
    }

    const unlisten = history.listen(historyPageviewListener);
    return unlisten;
  }, [history]);

  return null;
};

const Content = () => {
  const [, loading] = useCurrentUser();
  if (loading) {
    return <Loading />;
  } else {
    return <Routes />;
  }
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <LogHistory />
          <Skeleton>
            <Content />
          </Skeleton>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

const ResetSearchCountPage = () => {
  const resetTotalSearches = useResetTotalSearches();
  const [isReset, setReset] = React.useState(false);

  useEffect(() => {
    if (isReset) resetTotalSearches();
  }, [resetTotalSearches, isReset]);

  return <>
    <p><Button variant="outlined" disabled={isReset} onClick={() => setReset(true)}>Reset search count</Button></p>
    {isReset && <Alert severity="success">Search count has been reset.</Alert>}
  </>;
};

export default App;
