import React from "react";

import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import { DriveSearchResultType, DriveUserType } from "types";
import { Box } from "@material-ui/core";
import MimeTypeIcon from "components/MimeTypeIcon";
import PathGenerator from "../PathGenerator";

const displayOwners = (owners: DriveUserType[], num: number) => {
  var toDisplay = [];
  for (var i = 0; i < owners.length; i++) {
    if (i >= num) break;

    if (owners[i].me) toDisplay.unshift("me");
    else toDisplay.push(owners[i].display_name);
  }

  return owners.length > num ? `${toDisplay.join(", ")}...` : toDisplay.join(", ");
};

const DriveSearchResult: React.FC<{ result: DriveSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const header = result.title;

  // If the file is in a shared drive, it won't have any owners. Instead, the user that shared the
  // file with us should be present.
  let subheader;
  if (result.owners != null) {
    // TODO(piyush) Make the non-displayed owners clickable to see all
    // of the owners. Also, change the "..." to "and NUM others".

    subheader = (
      <Box display="flex" flexDirection="column">
        {result.path ? <PathGenerator path={result.path} /> : null}
        <span>{`Owned by ${displayOwners(result.owners, 2)}`}</span>
      </Box>
    );
  } else if (result.sharing_user != null) {
    subheader = `Shared by ${result.sharing_user.display_name}`;
  }

  const avatar = <MimeTypeIcon mimeType={result.mime_type} />;
  const body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
    />
  );
};

export default DriveSearchResult;
