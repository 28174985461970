// taken from https://github.com/TeamWertarbyte/material-ui-search-bar/tree/master/src/components/SearchBar
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "constants/routes";
import clsx from "clsx";
import PropTypes from "prop-types";
import { IconButton, Input, Paper } from "@material-ui/core";
import { InputProps } from "@material-ui/core/Input";
import { PaperProps } from "@material-ui/core/Paper";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import { useTotalSearches } from "hooks/limitsCounters/useTotalSearches";
import { UpgradeDialog } from "components/Account/UpgradeDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "none",
    border: "1px solid #E6E3F7",
  },
  iconButton: {
    color: theme.palette.primary.light,
    transform: "scale(1, 1)",
    transition: theme.transitions.create(["transform", "color"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  iconButtonHidden: {
    transform: "scale(0, 0)",
    "& > $icon": {
      opacity: 0,
    },
  },
  searchIconButton: {
    marginRight: theme.spacing(-6),
  },
  icon: {
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  input: {
    width: "100%",
  },
  searchContainer: {
    margin: "auto 16px",
    width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
  },
}));

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
type SearchBarInputProps = {
  cancelOnEscape?: boolean;
  className?: string;
  disabled?: boolean;
  focusOnMount: boolean | null | undefined;
  onCancelSearch?: () => void;
  onRequestSearch?: (_: string) => void;
  value?: string;
} & Partial<Omit<InputProps, "style" | "fullWidth" | "disableUnderline" | "disabled">> & //, "onBlur" | "value" | "onChange" | "onKeyUp" | "onFocus">;
  Partial<Pick<PaperProps, "style">>;

const SearchBarInput = React.forwardRef<HTMLInputElement, SearchBarInputProps>(
  (
    {
      cancelOnEscape,
      className,
      disabled,
      focusOnMount,
      onCancelSearch,
      onRequestSearch,
      style,
      ...inputProps
    },
    inputRef
  ) => {
    // const inputRef = React.useRef();
    const classes = useStyles();
    const getTotalSearches = useTotalSearches();
    const [openUpgrade, setOpenUpgrade] = useState(false);
    const [value, setValue] = useState<string>(inputProps.value || "");
    const history = useHistory();

    useEffect(() => {
      setValue(inputProps.value || "");
    }, [inputProps.value]);

    useEffect(() => {
      if (focusOnMount && inputRef) {
        // @ts-ignore
        inputRef.current.focus();
      }
      // eslint-disable-next-line
    }, []);

    const handleFocus = useCallback(
      (e) => {
        if (inputProps.onFocus) {
          inputProps.onFocus(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputProps.onFocus]
    );

    const handleBlur = useCallback(
      (e) => {
        setValue((v: string) => v.trim());
        if (inputProps.onBlur) {
          inputProps.onBlur(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputProps.onBlur]
    );

    const handleInput = useCallback(
      (e) => {
        setValue(e.target.value);
        if (inputProps.onChange) {
          inputProps.onChange(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputProps.onChange]
    );

    const handleCancel = useCallback(() => {
      setValue("");
      if (onCancelSearch) {
        onCancelSearch();
      }
    }, [onCancelSearch]);

    const handleRequestSearch = async () => {
      if (onRequestSearch) {
        const totalSearches = await getTotalSearches();
        if (totalSearches.isNewSearchPossible) onRequestSearch(value);
        else setOpenUpgrade(true);
      }
    };

    const handleKeyUp = useCallback(
      (e) => {
        if (e.charCode === 13 || e.key === "Enter") {
          handleRequestSearch();
        } else if (cancelOnEscape && (e.charCode === 27 || e.key === "Escape")) {
          handleCancel();
        }
        if (inputProps.onKeyUp) {
          inputProps.onKeyUp(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handleRequestSearch, cancelOnEscape, handleCancel, inputProps.onKeyUp]
    );

    const closeUpgrade = () => setOpenUpgrade(false);

    const handleDisabledBar = () => {
      if (disabled) {
        history.push(ROUTES.APPLICATIONS);
      }
    };

    return (
      <div>
        <Paper
          className={clsx(classes.root, className)}
          style={
            disabled
              ? {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                }
              : style
          }
        >
          <IconButton
            onClick={handleRequestSearch}
            className={clsx(classes.iconButton, classes.searchIconButton, {
              [classes.iconButtonHidden]: value !== "",
            })}
            disabled={disabled}
          >
            <SearchIcon className={clsx(classes.icon)} />
          </IconButton>

          <IconButton
            onClick={handleCancel}
            className={clsx(classes.iconButton, {
              [classes.iconButtonHidden]: value === "",
            })}
            disabled={disabled}
          >
            <ClearIcon className={clsx(classes.icon)} />
          </IconButton>

          <div className={classes.searchContainer} onClick={handleDisabledBar}>
            <Input
              {...inputProps}
              inputRef={inputRef}
              onBlur={handleBlur}
              value={value}
              onChange={handleInput}
              onKeyUp={handleKeyUp}
              onFocus={handleFocus}
              fullWidth
              className={clsx(classes.input, "SearchBarInput__input")}
              disableUnderline
              disabled={disabled}
            />
          </div>
        </Paper>

        <UpgradeDialog open={openUpgrade} onClose={closeUpgrade} limitType={"search"} />
      </div>
    );
  }
);

SearchBarInput.defaultProps = {
  className: "",
  disabled: false,
  focusOnMount: false,
  placeholder: "Search",
  style: undefined,
  value: "",
};

SearchBarInput.propTypes = {
  /** Whether to clear search on escape */
  cancelOnEscape: PropTypes.bool,
  /** Custom top-level class */
  className: PropTypes.string,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Focuses the input on mount */
  focusOnMount: PropTypes.bool,
  /** Fired when the search is cancelled. */
  onCancelSearch: PropTypes.func,
  /** Fired when the text value changes. */
  onChange: PropTypes.func,
  /** Fired when the search icon is clicked. */
  onRequestSearch: PropTypes.func,
  /** Sets placeholder text for the embedded text field. */
  placeholder: PropTypes.string,
  /** Override the inline-styles of the root element. */
  style: PropTypes.object,
  /** The value of the text field. */
  value: PropTypes.string,
};

export default SearchBarInput;
