import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { useObjectVal } from "hooks/firebase/database";

import { useCurrentUser } from "components/Session";
import { useFirebase } from "components/Firebase";
import MissingApplicationForm from "components/Application/MissingApplicationForm";
import PLATFORMS from "constants/platforms";
import { UserApplicationsType } from "types";
import AvailablePlatformsList from "components/Application/AvailableApplicationsList";
import ConnectedApplicationsList from "components/Application/ConnectedApplicationsList";
import DialogManager from "components/DialogManager";

const useStyles = makeStyles((theme) => ({
  subheading: {
    fontSize: "1em",
  },
}));

const ApplicationsPage = () => {
  const firebase = useFirebase();
  const [authUser] = useCurrentUser();
  const classes = useStyles();

  const [userApplications, loadingLinked] = useObjectVal<UserApplicationsType>(
    firebase.userDbRef("userApplications")
  );

  const availablePlatforms = useMemo(() => {
    const platformsSet = new Set(
      Object.values(userApplications || {}).map(({ platform }) => platform)
    );

    return PLATFORMS.all(authUser).filter((platform) => !platformsSet.has(platform));
  }, [userApplications, authUser]);

  return (
    <DialogManager>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6">Applications</Typography>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6" className={classes.subheading}>
                Connected
              </Typography>
            </Grid>

            <Grid item>
              <ConnectedApplicationsList
                applications={userApplications || {}}
                loading={loadingLinked}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6" className={classes.subheading}>
                Available to connect
              </Typography>
            </Grid>
            <Grid item>
              <AvailablePlatformsList platforms={availablePlatforms} loading={loadingLinked} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <MissingApplicationForm />
        </Grid>
      </Grid>
    </DialogManager>
  );
};

export default ApplicationsPage;
