import { User } from "@firebase/auth-types";

import asanaIcon from "images/platform_icons/asana_icon.svg";
import boxIcon from "images/platform_icons/box_icon.svg";
import confluenceIcon from "images/platform_icons/confluence_icon.svg";
import driveIcon from "images/platform_icons/drive_icon.svg";
import dropboxIcon from "images/platform_icons/dropbox_icon.svg";
import githubIcon from "images/platform_icons/github_icon.svg";
import gmailIcon from "images/platform_icons/gmail_icon.svg";
import jiraIcon from "images/platform_icons/jira_icon.svg";
import microsoft365Icon from "images/platform_icons/microsoft_icon.svg";
import onedriveIcon from "images/platform_icons/onedrive_icon.svg";
import outlookIcon from "images/platform_icons/outlook_icon.svg";
import sharepointIcon from "images/platform_icons/sharepoint_icon.svg";
import slackIcon from "images/platform_icons/slack_icon.svg";

type IconType = {
  src: any;
  height: number;
  width: number;
};

type OauthConfigType = {
  authUrl: string;
  extraData: any;
  clientId?: string;
  route: string;
};

export type OauthDialogConfigFeatureType = {
  name: string;
  title: string;
  details: string;
  required: boolean;
};

type OauthDialogConfigType = {
  title: string;
  url: string;
  features: OauthDialogConfigFeatureType[];
};

type RestrictedType = false | ((_: User | undefined) => boolean);

export class Platform {
  name: string;
  label: string;
  restricted: RestrictedType;
  oauthConfig: OauthConfigType;
  oauthDialogConfig: OauthDialogConfigType;
  icon: IconType;
  readOnlyDesc: string;
  // enum: string;

  constructor(
    name: string,
    {
      label,
      restricted,
      oauthConfig,
      oauthDialogConfig,
      icon,
      readOnlyDesc,
    }: {
      label: string;
      restricted: RestrictedType;
      oauthConfig: OauthConfigType;
      oauthDialogConfig: OauthDialogConfigType;
      icon: IconType;
      readOnlyDesc: string;
    }
  ) {
    this.name = name;
    this.label = label;
    this.restricted = restricted;
    this.oauthConfig = oauthConfig;
    this.oauthDialogConfig = oauthDialogConfig;
    this.icon = icon;
    this.readOnlyDesc = readOnlyDesc;
    registry.add(this);
  }
}

// TODO: get typescript working on this file
// I ran into trouble because of the custom classes.
class PlatformRegistry {
  // byEmum: Map<string, any>;
  byName: Map<string, Platform>;

  constructor() {
    this.byName = new Map();
  }

  add(platform: Platform) {
    this.byName.set(platform.name, platform);
  }

  get(name: string) {
    return this.byName.get(name);
  }

  all(user: User | undefined = undefined) {
    return Array.from(this.byName.keys()).filter((name) => {
      const platform = this.get(name);
      if (!platform) return false;
      if (platform.restricted) {
        return !platform.restricted(user);
      } else if (!platform.restricted) {
        return true;
      }

      return false;
    });
  }
}

const registry = new PlatformRegistry();
export default registry;

export const ICONS: { [_: string]: IconType } = {
  ASANA: {
    src: asanaIcon,
    height: 24,
    width: 24,
  },
  BOX: {
    src: boxIcon,
    height: 25,
    width: 24,
  },
  CONFLUENCE: {
    src: confluenceIcon,
    height: 25,
    width: 25,
  },
  DRIVE: {
    src: driveIcon,
    height: 25,
    width: 25,
  },
  DROPBOX: {
    src: dropboxIcon,
    height: 25,
    width: 25,
  },
  GITHUB: {
    src: githubIcon,
    height: 24,
    width: 24,
  },
  GMAIL: {
    src: gmailIcon,
    height: 25,
    width: 25,
  },
  JIRA: {
    src: jiraIcon,
    height: 25,
    width: 25,
  },
  MICROSOFT_365: {
    src: microsoft365Icon,
    height: 25,
    width: 25,
  },
  ONEDRIVE: {
    src: onedriveIcon,
    height: 25,
    width: 25,
  },
  OUTLOOK: {
    src: outlookIcon,
    height: 25,
    width: 25,
  },
  SHAREPOINT: {
    src: sharepointIcon,
    height: 25,
    width: 25,
  },
  SLACK: {
    src: slackIcon,
    height: 25,
    width: 25,
  },
};

export const ASANA = new Platform("ASANA", {
  label: "Asana",
  restricted: false,
  // https://developers.asana.com/docs/#authorization-code-grant
  oauthConfig: {
    authUrl: process.env.REACT_APP_ASANA_AUTH_URL as string,
    extraData: { response_type: "code" },
    clientId: process.env.REACT_APP_ASANA_CLIENT_ID,
    route: "/application_oauth/asana",
  },
  oauthDialogConfig: {
    title: "Asana",
    url: "www.asana.com",
    features: [],
  },
  icon: ICONS.ASANA,
  readOnlyDesc: "Quickly and easily find projects and tasks in Asana. With read-only access Xoba cannot modify or delete any of your Asana projects or tasks.",
});

export const BOX = new Platform("BOX", {
  label: "Box",
  restricted: false,
  // https://developer.box.com/guides/authentication/oauth2/
  oauthConfig: {
    authUrl: process.env.REACT_APP_BOX_AUTH_URL as string,
    extraData: { response_type: "code" },
    clientId: process.env.REACT_APP_BOX_CLIENT_ID,
    route: "/application_oauth/box",
  },
  oauthDialogConfig: {
    title: "Box",
    url: "www.box.com",
    features: [],
  },
  icon: ICONS.BOX,
  readOnlyDesc: "Search files, documents, spreadsheets, presentations and more with Xoba. With read-only access Xoba cannot modify or delete any of your Box data.",
});

// https://developer.atlassian.com/cloud/jira/platform/oauth-2-authorization-code-grants-3lo-for-apps
const ATLASSIAN_OAUTH = {
  authUrl: process.env.REACT_APP_ATLASSIAN_AUTH_URL as string,
  extraData: {
    audience: "api.atlassian.com",
    prompt: "consent", // Always ask for refresh token
    response_type: "code",
  },
  clientId: process.env.REACT_APP_ATLASSIAN_CLIENT_ID,
  route: "/application_oauth/atlassian",
};

export const CONFLUENCE = new Platform("CONFLUENCE", {
  label: "Confluence",
  restricted: false,
  oauthConfig: {
    ...ATLASSIAN_OAUTH,
    extraData: {
      ...ATLASSIAN_OAUTH.extraData,
      scope: process.env.REACT_APP_CONFLUENCE_SCOPES,
    },
  },
  oauthDialogConfig: {
    title: "Confluence",
    url: "id.atlassian.com",
    features: [],
  },
  icon: ICONS.CONFLUENCE,
  readOnlyDesc: "Quickly and easily find documents and information in Confluence. With read-only access Xoba cannot modify or delete any of your Confluence documents or information.",
});

export const DRIVE = new Platform("DRIVE", {
  label: "Drive",
  restricted: false,
  // https://developers.google.com/identity/protocols/OAuth2WebServer#creatingclient
  oauthConfig: {
    authUrl: process.env.REACT_APP_GOOGLE_AUTH_URL as string,
    extraData: {
      access_type: "offline", // This ensures we get a refresh token
      prompt: "consent", // Always ask for refresh token
      response_type: "code",
      scope: "https://www.googleapis.com/auth/drive.readonly",
    },
    clientId: process.env.REACT_APP_DRIVE_CLIENT_ID,
    route: "/application_oauth/drive",
  },
  oauthDialogConfig: {
    title: "Drive",
    url: "drive.google.com",
    features: [],
  },
  icon: ICONS.DRIVE,
  readOnlyDesc: "Search files, documents, spreadsheets, presentations and more with Xoba. With read-only access Xoba cannot modify or delete any of your Google Drive data.",
});

export const DROPBOX = new Platform("DROPBOX", {
  label: "Dropbox",
  restricted: false,
  oauthConfig: {
    authUrl: process.env.REACT_APP_DROPBOX_AUTH_URL as string,
    extraData: {
      response_type: "code",
      scope: process.env.REACT_APP_DROPBOX_SCOPES,
      token_access_type: "offline",
    },
    clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
    route: "/application_oauth/dropbox",
  },
  oauthDialogConfig: {
    title: "Dropbox",
    url: "www.dropbox.com",
    features: [],
  },
  icon: ICONS.DROPBOX,
  readOnlyDesc: "Search files, documents, spreadsheets, presentations and more with Xoba. With read-only access Xoba cannot modify or delete any of your Dropbox data.",
});

export const GITHUB = new Platform("GITHUB", {
  label: "Github",
  restricted: false,
  oauthConfig: {
    authUrl: process.env.REACT_APP_GITHUB_AUTH_URL as string,
    extraData: { response_type: "code", scope: "repo" },
    clientId: process.env.REACT_APP_GITHUB_CLIENT_ID,
    route: "/application_oauth/github",
  },
  oauthDialogConfig: {
    title: "Github",
    url: "www.github.com",
    features: [],
  },
  icon: ICONS.GITHUB,
  readOnlyDesc: "Quickly and easily find issues in Github. With read-only access Xoba cannot modify or delete any of your Github data.",
});

const GMAIL_CUTOFF_DATE = new Date("Tue Jul 21 2020 00:00:00 GMT-0700");
export const GMAIL = new Platform("GMAIL", {
  label: "Gmail",
  restricted: (user) =>
    !!user &&
    !!user.metadata.creationTime &&
    new Date(user.metadata.creationTime) >= GMAIL_CUTOFF_DATE,
  // https://developers.google.com/identity/protocols/OAuth2WebServer#creatingclient
  oauthConfig: {
    authUrl: process.env.REACT_APP_GOOGLE_AUTH_URL as string,
    extraData: {
      access_type: "offline", // This ensures we get a refresh token
      prompt: "consent", // Always ask for refresh token
      response_type: "code",
      scope: "https://www.googleapis.com/auth/gmail.readonly",
    },
    clientId: process.env.REACT_APP_GMAIL_CLIENT_ID,
    route: "/application_oauth/gmail",
  },
  oauthDialogConfig: {
    title: "Gmail",
    url: "www.gmail.com",
    features: [],
  },
  icon: ICONS.GMAIL,
  readOnlyDesc: "Search for your emails and attachments with Xoba. With read-only access, Xoba cannot modify or delete any of your emails, attachments, etc.",
});

export const JIRA = new Platform("JIRA", {
  label: "Jira",
  restricted: false,
  oauthConfig: {
    ...ATLASSIAN_OAUTH,
    extraData: {
      ...ATLASSIAN_OAUTH.extraData,
      scope: process.env.REACT_APP_JIRA_SCOPES,
    },
  },
  oauthDialogConfig: {
    title: "Jira",
    url: "id.atlassian.com",
    features: [],
  },
  icon: ICONS.JIRA,
  readOnlyDesc: "Quickly and easily find Jira issues with Xoba. With read-only access Xoba cannot modify or delete any of your Jira issues, projects or other data.",
});

export const MICROSOFT_365 = new Platform("MICROSOFT_365", {
  label: "Microsoft 365",
  restricted: false,
  // https://docs.microsoft.com/en-us/previous-versions/office/office-365-api/api/version-2.0/use-outlook-rest-api
  oauthConfig: {
    authUrl: process.env.REACT_APP_MICROSOFT_365_AUTH_URL as string,
    extraData: {
      response_type: "code",
      prompt: "select_account",
      scope: `${process.env.REACT_APP_MICROSOFT_365_SCOPES} ${process.env.REACT_APP_OUTLOOK_SCOPES} ${process.env.REACT_APP_ONEDRIVE_SCOPES}`,
    },
    clientId: process.env.REACT_APP_MICROSOFT_365_CLIENT_ID,
    route: "/application_oauth/microsoft_365",
  },
  oauthDialogConfig: {
    title: "Microsoft 365",
    url: "login.microsoftonline.com",
    features: [],
  },
  icon: ICONS.MICROSOFT_365,
  readOnlyDesc: "Search for your emails, attachments and OneDrive docuemnts with Xoba. With read-only access, Xoba cannot modify or delete any of your emails, attachments, files, etc.",
});

export const SLACK = new Platform("SLACK", {
  label: "Slack",
  restricted: false,
  // https://api.slack.com/docs/oauth
  oauthConfig: {
    authUrl: process.env.REACT_APP_SLACK_AUTH_URL as string,
    extraData: {
      scope: process.env.REACT_APP_SLACK_SCOPES,
      user_scope: process.env.REACT_APP_SLACK_USER_SCOPES,
    },
    clientId: process.env.REACT_APP_SLACK_CLIENT_ID,
    route: "/application_oauth/slack",
  },
  oauthDialogConfig: {
    title: "Slack",
    url: "slack.com",
    features: [],
  },
  icon: ICONS.SLACK,
  readOnlyDesc: "Search Slack channels, conversations, and files with Xoba. With read-only access, Xoba can only provide search results. We cannot create, modify, or delete any of your Slack information.",
});
