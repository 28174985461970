import React, { useCallback, useMemo, useEffect, useState } from "react";
import { List } from "@material-ui/core";

import CardLayout, { CardBaseProps } from "../../CardLayout";
import { CardTypeEnum } from "types";
import CardItemLayout from "../../CardItemLayout";
import { useSearch } from "actions/search";
import PlatformIcon from "components/PlatformIcon";
import MimeTypeIcon from "components/MimeTypeIcon";
import EditCardDialog from "../../EditCardDialog";
import { defaultSortOptions } from "../../../Search/SearchFilters/SortMenu";
import { DriveUserType } from "types";

type SavedSearchCardProps = CardBaseProps & {
  disabled?: boolean;
};

const NUM_RESULTS = 10;
const SavedSearchCard: React.FC<SavedSearchCardProps> = ({
  card,
  cardsRef,
  disabled,
  ...otherProps
}) => {
  // @ts-ignore
  const { query, filters } = card.config;

  type XDateType = Date | null;
  type DateRangeType = [XDateType, XDateType];

  const lastNDays = (n: number): DateRangeType => {
    let date = new Date();
    date.setHours(0, 0, 0, 0); // Set to start of day
    date.setDate(date.getDate() - n);
    return [date, null];
  };

  const defaultOptions: { [_: string]: () => DateRangeType } = {
    Any: () => [null, null],
    Today: () => lastNDays(0),
    "Last 7 days": () => lastNDays(7),
    "Last 30 days": () => lastNDays(30),
    "Last 60 days": () => lastNDays(60),
    "Last 90 days": () => lastNDays(90),
  };

  const queryFilter = useMemo(() => {
    return { ...filters };
  }, [filters]);

  let queryFilters: any = useMemo(() => {
    const queryFilter = { ...filters };
    const qFilter = queryFilter;
    return qFilter;
  }, [filters]);

  queryFilter.dateFilter.dateRange = defaultOptions[filters.dateFilter.type];

  const { query: querySearch, results, loading, error, run: runSearch } = useSearch({
    initialValue: query,
    pageSize: NUM_RESULTS,
    filters: queryFilter,
    isCardSearch: true,
  });

  const [paramsUpdated, setParamsUpdated] = useState(false);

  const paramsUpdate = () => {
    setParamsUpdated(true);
  };

  useEffect(() => {
    if (querySearch !== queryFilter && paramsUpdated) {
      runSearch(query, queryFilter);
      return () => {
        setParamsUpdated(false);
      };
    }
  }, [runSearch, querySearch, query, queryFilter, paramsUpdated]);

  const identifyFn = (val: any) => val;
  const { sortType } = filters;

  const sortFunction = sortType ? defaultSortOptions[sortType] : identifyFn;

  const resultsToDisplay = useMemo(() => {
    const dateRange =
      queryFilters && queryFilters.dateFilter.dateRange && queryFilters.dateFilter.dateRange();

    return results
      .filter((result) => {
        if (!dateRange) {
          return true;
        }

        const ts = Date.parse(result.date).valueOf();
        return (
          (!dateRange[0] || dateRange[0].valueOf() <= ts) &&
          (!dateRange[1] || ts <= dateRange[1].valueOf())
        );
      })
      .sort(sortFunction);
  }, [results, sortFunction, queryFilters]);

  const displayOwners = (owners: DriveUserType[], num: number) => {
    var toDisplay = [];
    for (var i = 0; i < owners.length; i++) {
      if (i >= num) break;

      if (owners[i].me) toDisplay.unshift("me");
      else toDisplay.push(owners[i].display_name);
    }

    return owners.length > num ? `${toDisplay.join(", ")}...` : toDisplay.join(", ");
  };

  const items = (resultsToDisplay || []).map((result) => {
    const iconType = () => {
      if (result.platform === "DRIVE" && result.mime_type) {
        return <MimeTypeIcon mimeType={result.mime_type} />;
      } else {
        return <PlatformIcon platform={result.platform} />;
      }
    };

    let subheader;
    if (result.owners != null) {
      subheader = `Owned by ${displayOwners(result.owners, 2)}`;
    }

    return (
      <CardItemLayout
        key={result.handle}
        disabled={disabled}
        icon={iconType()}
        url={result.handle}
        primary={result.title}
        secondary={result.owners ? subheader : null}
        cardType={CardTypeEnum.SavedSearch}
        searchResult={result}
      />
    );
  });

  const editor = useCallback(
    ({ editing, onEditDone }) => {
      return (
        <EditCardDialog
          card={card}
          cardsRef={cardsRef}
          open={editing}
          onClose={onEditDone}
          paramsUpdate={paramsUpdate}
        />
      );
    },
    [card, cardsRef]
  );

  return (
    <CardLayout
      card={card}
      items={results}
      title={card.title || "Saved search"}
      disabled={disabled}
      loading={loading?.main}
      error={error?.message}
      editor={editor}
      cardsRef={cardsRef}
      {...otherProps}
    >
      <List>{items}</List>
    </CardLayout>
  );
};

export default SavedSearchCard;
