import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, TextField, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";

import * as ROUTES from "constants/routes";
import { useFirebase } from "components/Firebase";
import { identify, logSignIn } from "analytics";
import UserFacingError from "utils/UserFacingError";

// CSS styles.
const useStyles = makeStyles((theme) => ({
  form: { width: "100%" /* Fix IE 11 issue */ },
  label: { marginTop: "10px" },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const PASSWORDLESS_MODE = "email";
const LOCAL_STORAGE_KEY = "emailForSignIn";
const LOCAL_STORAGE_SIGNUP_KEY = "SignUp";
// see: https://firebase.google.com/docs/auth/web/email-link-auth
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${window.location.origin}/${ROUTES.SIGN_IN}/${PASSWORDLESS_MODE}`,
  // This must be true.
  handleCodeInApp: true,
};

export const usePasswordlessVerification = (enabled: boolean) => {
  const firebase = useFirebase();
  const history = useHistory();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    if (enabled) {
      if (firebase.auth.isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        if (!email) {
          throw new UserFacingError("No email provided.");
        }

        firebase.auth
          .signInWithEmailLink(email, window.location.href)
          .then((result: any) => {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY);
            // Log to analytics.
            identify(result.user);
            logSignIn();

            history.push(ROUTES.HOME); // Redirect to home page
          })
          .catch((err: any) => {
            setError(err.message);
          });
      } else {
        setError("URL did not contain a login code. Please check your email and try again.");
      }
    }
  }, [firebase.auth, enabled, history]);

  return [error];
};

type PasswordlessAuthProps = {
  signup?: boolean;
};

const PasswordlessAuth = ({ signup }: PasswordlessAuthProps) => {
  const classes = useStyles();
  const firebase = useFirebase();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<any | null>(null);
  const [fullName, setfullName] = useState(false);
  const [fullNameError, setfullNameError] = useState<any | null>(null);

  const [submitting, setSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (signup && !fullName) {
        setfullNameError(true);
        return;
      }

      if (signup) {
        const data = { email, fullName };
        window.localStorage.setItem(LOCAL_STORAGE_SIGNUP_KEY, JSON.stringify(data));
      }

      setEmailError(null);
      setSubmitting(true);

      // don't look up methods if we are not in signup mode
      const methods = signup
        ? ["emailLink"]
        : await firebase.auth.fetchSignInMethodsForEmail(email);

      if (methods.indexOf("emailLink") !== -1 || methods.indexOf("password") !== -1) {
        await firebase.auth.sendSignInLinkToEmail(email, actionCodeSettings);
        window.localStorage.setItem(LOCAL_STORAGE_KEY, email);
        setSubmitting(false);
        setEmailSent(true);
        // check your email for a login link
      } else {
        setEmailError("Account not found");
        setSubmitting(false);
      }
    },
    [signup, email, fullName, firebase.auth]
  );

  const emailValidation = (email: string) => {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(String(email).toLowerCase())) {
      setEmailError(null);
    } else setEmailError("Invalid email");
  };

  const nameValidation = (name: string) => {
    if (name.split(" ").length > 1 && name.split(" ")[1]) {
      setfullNameError(null);
    } else {
      setfullNameError("Incomplete name");
    }
  };

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
    emailValidation(event.target.value);
  }, []);

  const handleFullNameChange = useCallback((event) => {
    nameValidation(event.target.value);
    setfullName(event.target.value);
  }, []);

  if (emailSent) {
    return (
      <Typography align="center" variant="subtitle1">
        Check your email for your login link
      </Typography>
    );
  }

  let fullNameInput;
  if (signup) {
    fullNameInput = (
      <>
        <InputLabel htmlFor="name">Full Name</InputLabel>
        <TextField
          name="fullName"
          placeholder="Full Name"
          id="name"
          type="text"
          required
          error={fullNameError != null}
          label={fullNameError != null ? fullNameError : null}
          helperText={fullNameError != null ? "Please add your name and last name" : null}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleFullNameChange}
        />
        <InputLabel htmlFor="email" className={classes.label}>
          Email
        </InputLabel>
      </>
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit} className={classes.form}>
      {fullNameInput}

      <TextField
        name="email"
        id="email"
        type="email"
        placeholder="Email address"
        required
        error={emailError != null}
        label={emailError != null ? emailError : null}
        helperText={
          emailError != null ? emailError.message || "Please add a valid email direction" : null
        }
        variant="outlined"
        margin="normal"
        fullWidth
        autoComplete="email"
        onChange={handleEmailChange}
      />

      <Button
        type="submit"
        disabled={
          email === "" ||
          (signup && !fullName) ||
          submitting ||
          emailError != null ||
          (signup && fullNameError != null)
        }
        variant="contained"
        color="primary"
        fullWidth
        className={classes.submit}
      >
        {signup ? "Create Account" : "Sign in"}
      </Button>
    </form>
  );
};

export default PasswordlessAuth;
