import React, { useCallback, useEffect, useMemo, useRef, useState, FunctionComponent } from "react";
import { useCollectionData } from "hooks/firebase/firestore";
import useAutocomplete, { createFilterOptions } from "@material-ui/lab/useAutocomplete";

import SearchBarInput from "./SearchBarInput";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import Recommendations, { defaultSort as defaultRecommendationSort } from "./Recommendations";
import { logSearchAutocompleteSelect } from "analytics";
import usePageLifecycle from "hooks/usePageLifecycle";

const defaultFilterOptions = createFilterOptions({ limit: 5 });

type SearchBarProps = {
  value: string;
  onChange: any;
  onCancelSearch: any;
  onRequestSearch: any;
  accountsConnected: boolean;
};

const IGNORE_KEYS = new Set(["Meta", "Shift", "Alt", "Control", "ArrowLeft", "ArrowRight"]);

const SearchBar: FunctionComponent<SearchBarProps> = ({
  value,
  onChange,
  onCancelSearch,
  onRequestSearch,
  accountsConnected,
}) => {
  const [user] = useCurrentUser();
  const firebase = useFirebase();
  const [userSearches] = useCollectionData(
    firebase.firestore.collection("user_searches").doc(user?.uid).collection("searches")
  );
  const searchRecommendations = useMemo(() => {
    const searches = (userSearches || []).map((s: any) => ({ ...s, label: s.searchTerm }));
    return searches.sort(defaultRecommendationSort);
  }, [userSearches]);

  const [recommendationsOpen, setRecommendationsOpen] = useState(false);
  const handleKeyUp = useCallback((e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      setRecommendationsOpen(false);
    } else if (e.key === "ArrowDown") {
      setRecommendationsOpen(true);
    } else if (IGNORE_KEYS.has(e.key) || e.target.value === "") {
      // do nothing
    } else {
      setRecommendationsOpen(true);
    }
  }, []);

  const handleRecommendationChange = useCallback(
    (event, newValue, reason, details) => {
      if (reason === "select-option") {
        logSearchAutocompleteSelect();
        onRequestSearch(newValue.searchTerm);
      }
    },
    [onRequestSearch]
  );

  const handleRecommendationClose = useCallback((event, reason) => {
    setRecommendationsOpen(false);
  }, []);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
    focused,
  } = useAutocomplete({
    id: "searchBarRecommendation-autocomplete",
    options: searchRecommendations,
    openOnFocus: false,
    freeSolo: true,
    filterOptions: (options, { getOptionLabel }) => {
      return defaultFilterOptions(options, { inputValue: value, getOptionLabel });
    },
    getOptionLabel: (option) => {
      return option.label || option;
    },
    getOptionSelected: (option, value) => {
      return option.label === value;
    },
    onInputChange: onChange,
    value,
    onChange: handleRecommendationChange,
    open: recommendationsOpen,
    onClose: handleRecommendationClose,
  });

  const pageLifecycle = usePageLifecycle();
  const focusedRef = useRef(focused);
  const pageLifecycleRef = useRef(pageLifecycle);

  useEffect(() => {
    if (pageLifecycle !== pageLifecycleRef.current) {
      pageLifecycleRef.current = pageLifecycle;
      focusedRef.current = true; // We force the state to think it is focused because the focus changes a few milliseconds later
      setRecommendationsOpen(false);
      return;
    }

    if (focused !== focusedRef.current) {
      focusedRef.current = focused;

      // if (focused && !recommendationsOpen) {
      //   setRecommendationsOpen(true);
      // } else if (!focused && recommendationsOpen) {
      //   setRecommendationsOpen(false);
      // }
    }
  }, [focused, recommendationsOpen, pageLifecycle]);

  return (
    <div {...getRootProps()}>
      <SearchBarInput
        focusOnMount
        placeholder={
          !accountsConnected
            ? "Organize and search content in Drive, Slack, Asana, and more. Connect your applications"
            : "Search your connected applications"
        }
        onRequestSearch={onRequestSearch}
        onCancelSearch={onCancelSearch}
        onKeyUp={handleKeyUp}
        disabled={!accountsConnected}
        {...getInputProps()}
      />

      <Recommendations
        recommendations={groupedOptions}
        value={inputValue}
        getListboxProps={getListboxProps}
        getOptionProps={getOptionProps}
      />
    </div>
  );
};

export default SearchBar;
