class NdJsonState {
  #fullRows: string;
  #overflow: string;

  constructor() {
    this.#fullRows = "";
    this.#overflow = "";
  }

  addChunk(chunk: string) {
    const lastNewlineIndex = chunk.lastIndexOf("\n");

    if (chunk.length === 0) {
      return null;
    }

    if (lastNewlineIndex === chunk.length - 1) {
      this.#fullRows = this.#fullRows + this.#overflow + chunk;
      this.#overflow = "";
    } else if (lastNewlineIndex === -1) {
      this.#overflow = this.#overflow + chunk;
    } else {
      this.#fullRows = this.#fullRows + this.#overflow + chunk.slice(0, lastNewlineIndex + 1);
      this.#overflow = chunk.slice(lastNewlineIndex + 1, chunk.length);
    }

    if (this.#fullRows !== "") {
      const rows = this.#fullRows;
      this.#fullRows = "";
      return rows;
    }

    return null;
  }
}

export default NdJsonState;
