import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import contrast from "contrast";

import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import { GithubSearchResultType, GithubUserType, GithubLabelType } from "types";

const useStyles = makeStyles((theme) => {
  return {
    side: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    label: {
      padding: `0px ${theme.spacing(1)}px`,
      borderRadius: "999px",
    },
    assignees: {
      fontSize: "1em",
      "& > span + span::before": {
        content: ", ",
      },
    },
    state: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderRadius: "999px",
    },
  };
});

const Labels: React.FC<{ labels: GithubLabelType[] }> = ({ labels }) => {
  const classes = useStyles();

  const items = labels.map((label) => {
    const backgroundColor = `#${label.color}`;
    const textColor = contrast(backgroundColor) === "light" ? "#000" : "#FFF";

    return (
      <Grid item key={label.name}>
        <Typography
          variant="subtitle2"
          style={{ backgroundColor, color: textColor }}
          className={classes.label}
        >
          {label.name}
        </Typography>
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      {items}
    </Grid>
  );
};

const User: React.FC<{ user: GithubUserType }> = ({ user }) => {
  return <span>{user.name || user.email || user.login}</span>;
};

const Assignees: React.FC<{ assignees: GithubUserType[] }> = ({ assignees }) => {
  let items;
  if (assignees.length === 0) {
    items = <Grid item>Unassigned</Grid>;
  } else {
    items = assignees.map((assignee) => {
      return (
        <Grid item key={assignee.login}>
          <User user={assignee} />
        </Grid>
      );
    });
  }

  return (
    <Grid container spacing={1}>
      {items}
    </Grid>
  );
};

// Match Github's colors
type StateType = {
  label: string;
  backgroundColor: string;
};

const StateProps: { [_: string]: StateType } = {
  open: {
    label: "Open",
    backgroundColor: "#28a745",
  },
  closed: {
    label: "Closed",
    backgroundColor: "#d73a49",
  },
  merged: {
    label: "Merged",
    backgroundColor: "#6f42c1",
  },
};

const State: React.FC<{ state: string }> = ({ state }) => {
  const classes = useStyles();
  const { backgroundColor, label } = StateProps[state];
  const textColor = contrast(backgroundColor) === "light" ? "#000" : "#FFF";
  const styles = {
    backgroundColor,
    color: textColor,
  };

  return (
    <span className={classes.state} style={styles}>
      {label}
    </span>
  );
};

const Side: React.FC<{ result: GithubSearchResultType }> = ({ result }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={1} className={classes.side}>
      <Grid item>
        <Typography variant="subtitle1">
          {result.item_type === "issue"
            ? `Issue #${result.number}`
            : `Pull request #${result.number}`}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="subtitle2">
          <State state={result.state} />
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="subtitle2">
          Assignees: <Assignees assignees={result.assignees} />
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="subtitle2">
          Reporter: <User user={result.user} />
        </Typography>
      </Grid>

      <Grid item>
        <Labels labels={result.labels} />
      </Grid>
    </Grid>
  );
};

const GithubSearchResult: React.FC<{ result: GithubSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const header = result.title;

  const body = <ResultBody fallback={result.body} />;

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      account={`${result.owner}/${result.repository}`}
      date={result.date}
      platform={result.platform}
      header={header}
      body={body}
      side={<Side result={result} />}
    />
  );
};

export default GithubSearchResult;
