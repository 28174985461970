import React, { ReactChild } from "react";
import { AppBar, Toolbar, Typography, Grid, Box, Link as MaterialLink } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useCurrentUser } from "components/Session";

import { HOME, ACCOUNT, SIGN_OUT } from "constants/routes";
import XobaIcon from "components/XobaIcon";

// CSS styles.
const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "#3B35A6",
  }, // Keep right padding when drawer closed
  noDecorationLink: {
    textDecoration: "none !important",
    color: "inherit",
    cursor: "pointer",
  },
  xobaHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerActionsSpacer: {
    flexGrow: 1,
  },
  headerActions: {
    flexGrow: 0,
  },
  nowrap: {
    flexWrap: "nowrap", // work around a Safari bug: https://github.com/mui-org/material-ui/issues/17142
  },
  topBar: {
    color: "#3B35A6",
  },
  buttonColor: {
    backgroundColor: "white",
    minHeight: "30px",
    width: "30px",
    height: "30px",
  },
  iconColor: {
    color: "#3B35A6",
    fontSize: "25px",
  },
}));

type TopBarLayoutProps = {
  menuIcon?: ReactChild;
  className?: string;
  linkHome?: boolean;
  headerActions?: ReactChild;
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const TopBarLayout = ({ menuIcon, className, linkHome, headerActions }: TopBarLayoutProps) => {
  const classes = useStyles();
  const [user] = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let xobaHeader = (
    <Grid container spacing={2} className={classes.nowrap}>
      <Grid item>
        <XobaIcon size={30} />
      </Grid>
      <Grid item>
        <Typography display="inline" variant="h6" color="inherit" noWrap>
          Xoba
        </Typography>
      </Grid>
    </Grid>
  );

  if (linkHome) {
    xobaHeader = window.location.href.includes("chrome-extension://") ? (
      <MaterialLink
        underline="none"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_FRONTEND}`;
        }}
        className={classes.noDecorationLink}
      >
        {xobaHeader}
      </MaterialLink>
    ) : (
      <Link to={HOME} className={classes.noDecorationLink}>
        {xobaHeader}
      </Link>
    );
  }

  let userButton;
  if (!window.location.href.includes("chrome-extension://") && user) {
    userButton = (
      <div>
        <Fab className={classes.buttonColor} aria-label="add" onClick={handleClick}>
          <PersonIcon fontSize="large" className={classes.iconColor} />
        </Fab>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <Link onClick={handleClose} to={ACCOUNT} className={classes.noDecorationLink}>
              Account
            </Link>
          </MenuItem>
          <MenuItem>
            <MaterialLink
              onClick={handleClose}
              target="_blank"
              rel="noopener"
              href="https://www.notion.so/xoba/Xoba-Help-Center-7f9b8864000c461798c3ec0fbbd68a14"
              className={classes.noDecorationLink}
            >
              Resources
            </MaterialLink>
          </MenuItem>
          <MenuItem>
            <Link onClick={handleClose} to={SIGN_OUT} className={classes.noDecorationLink}>
              Sign Out
            </Link>
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }

  let headerActionContent;
  let headerActionSpacer;
  if (headerActions) {
    headerActionContent = (
      <Grid item className={classes.headerActions}>
        {headerActions}
      </Grid>
    );
    headerActionSpacer = <Grid item className={classes.headerActionsSpacer}></Grid>;
  }

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Grid container>
            <Grid item>{menuIcon}</Grid>
            <Grid item className={classes.xobaHeader}>
              {xobaHeader}
            </Grid>
            {headerActionSpacer}
            {headerActionContent}
          </Grid>
          {userButton}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBarLayout;
