import React, { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Container, Grid, Typography, Box } from "@material-ui/core";

import { useFirebase } from "components/Firebase";
import firebase from "firebase/app";
import { NormalAlert } from "components/Alert";
import DelayedSpinner from "components/DelayedSpinner";
import Card from "components/Card";
import { ShareTypeEnum, GetSharedCardResponseType } from "types";
import { logSharingViewed, logCreateAccountClick } from "analytics/sharing";
import { useCurrentUser } from "components/Session";
import { SIGN_UP, HOME } from "constants/routes";
import { CardTypeEnum } from "types";
import { logCardChange, CardChangeOperator } from "analytics";

const ViewSharePage = () => {
  const params: any = useParams();
  const shareId: string = params.shareId;
  const firebaseStore = useFirebase();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GetSharedCardResponseType | null>(null);

  const [currentUser, currentUserLoading] = useCurrentUser();
  const run = useCallback(
    async (shareId) => {
      if (shareId) {
        setLoading(true);
        setError(null);
        setData(null);

        try {
          const result = await firebaseStore.functions.httpsCallable("sharing-getSharedCard")({
            shareId,
          });
          result.data.data.shareId = shareId;
          setData({ ...result.data, shareId });
          logSharingViewed({
            type: result.data.type,
            shareId,
          });
        } catch (e) {
          setError(e.message);
          logSharingViewed({
            error: e.message,
            shareId,
          });
        }
        setLoading(false);
      }
    },
    [firebaseStore.functions]
  );

  useEffect(() => {
    run(shareId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareId]);

  const handleCreateAccountButtonClick = useCallback(() => {
    logCreateAccountClick({ shareId, type: data?.type });
    history.push(SIGN_UP);
  }, [history, shareId, data]);

  const handleCardSave = useCallback(async () => {
    const docRef = firebaseStore.firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("spaces")
      .doc("HOME");

    const cardsRef = docRef.collection("cards");

    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const newCard: any = {
      title: data!.data.title,
      type: CardTypeEnum.Items,
      variant: "SharedCard",
      shareId: shareId,
      updatedAt: now,
      createdAt: now,
    };
    // use a JSON stringify + parse trick to remove undefined values
    const doc = JSON.parse(JSON.stringify(newCard));

    logCardChange({
      operation: CardChangeOperator.Create,
      cardType: CardTypeEnum.Items,
    });
    cardsRef.add(doc);
    history.push(HOME);
  }, [data, history, currentUser?.uid, firebaseStore, shareId]);

  let header;
  let content;
  let subheader;
  let createAccountButton;
  let saveSharedCardButton;
  if (data && data.type === ShareTypeEnum.Card) {
    if (!currentUserLoading && !currentUser) {
      subheader = (
        <Typography variant="subtitle2" align="center">
          To create your own Cards and unlock more capabilities, sign up for Xoba.
        </Typography>
      );
      createAccountButton = (
        <Box mx="auto" mt="15px" display="flex" justifyContent="center">
          <Button color="primary" variant="contained" onClick={handleCreateAccountButtonClick}>
            Create an account
          </Button>
        </Box>
      );
    } else {
      subheader = (
        <Typography variant="subtitle2" align="center">
          With your Xoba account, you can create and share your own Cards with others.
        </Typography>
      );

      saveSharedCardButton = (
        <Box mx="auto" mt="20px" mb="20px" display="flex" justifyContent="center">
          <Button color="primary" variant="contained" onClick={handleCardSave}>
            ADD TO MY SPACE
          </Button>
        </Box>
      );
    }

    header = (
      <Typography variant="h6" align="center">
        A Xoba card has been shared with you!
      </Typography>
    );

    content = <Card card={data.data} />;
  }

  return (
    <Container>
      {error && <NormalAlert severity="error">{error}</NormalAlert>}

      <Grid container direction="column" spacing={4}>
        <Grid item container spacing={1}>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12} container direction="column">
            <Grid item>
              <Box mx="auto" display="flex" justifyContent="center">
                {" "}
                {loading && <DelayedSpinner size={50} />}
              </Box>
            </Grid>
            <Grid item>{header}</Grid>
            <Grid item>{subheader}</Grid>
            <Grid item>{saveSharedCardButton}</Grid>
            <Grid item>{createAccountButton}</Grid>
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>

        <Grid item container>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            {content}
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewSharePage;
