import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import xobaLogo from "images/xoba_icons/Xoba_logo.png";
import { GoogleAuth, PasswordlessAuth } from "components/Auth";
import * as ROUTES from "constants/routes";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    margin: "0",
    padding: "0",
  },
  image: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  paper: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  gridPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signUpTitle: {
    marginTop: "30px",
    fontSize: "2rem",
    [theme.breakpoints.up("sm")]: {
      marginTop: "70px",
      fontSize: "3rem",
    },
  },
  signInLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  privacyPolicy: {
    marginTop: "10px",
    lineHeight: 1,
    textDecoration: "none",
  },
  privacyPolicyLink: {
    fontWeight: "bold",
    lineHeight: 1,
    textDecoration: "none",
  },
  circleIcon: {
    color: "white",
  },
  circleAvatar: {
    backgroundColor: theme.palette.secondary.main,
    width: "60px",
    height: "60px",
    [theme.breakpoints.down("md")]: {
      width: "50px",
      height: "50px",
    },
  },
  bulletsDataTitle: {
    color: "white",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  bulletsData: {
    color: "white",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      marginTop: "5px",
    },
  },
}));

type AuthPageLayoutProps = {
  signup: boolean;
};

const AuthPageSignUpLayout = ({ signup }: AuthPageLayoutProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <CssBaseline />

      {/* Bullets */}

      <Grid item xs={false} sm={6} md={6} component={Paper} className={classes.image}>
        <Box height="100%" className={classes.gridPaper}>
          <Box>
            <Box display="flex" width="80%" mx="auto">
              <Avatar className={classes.circleAvatar}>
                <RadioButtonUncheckedIcon className={classes.circleIcon} fontSize="large" />
              </Avatar>

              <Box marginLeft="30px">
                <Typography variant="h4" color="secondary" className={classes.bulletsDataTitle}>
                  Organize information with cards
                </Typography>

                <Typography variant="h6" color="secondary" className={classes.bulletsData}>
                  Group links from the web or your connected applications into segmented cards,
                  perfectly suited for all your projects, thoughts, and must-haves.
                </Typography>
              </Box>
            </Box>

            <Box display="flex" width="80%" mx="auto" marginTop="50px">
              <Avatar className={classes.circleAvatar}>
                <RadioButtonUncheckedIcon className={classes.circleIcon} fontSize="large" />
              </Avatar>

              <Box marginLeft="30px">
                <Typography variant="h4" color="secondary" className={classes.bulletsDataTitle}>
                  Share and collaborate
                </Typography>

                <Typography variant="h6" color="secondary" className={classes.bulletsData}>
                  Share your cards with anyone — ensure your colleagues and stakeholders have access
                  to the most up-to-date documents, reports, and more.
                </Typography>
              </Box>
            </Box>

            <Box display="flex" width="80%" mx="auto" marginTop="50px">
              <Avatar className={classes.circleAvatar}>
                <RadioButtonUncheckedIcon className={classes.circleIcon} fontSize="large" />
              </Avatar>

              <Box marginLeft="30px">
                <Typography variant="h4" color="secondary" className={classes.bulletsDataTitle}>
                  Universal search
                </Typography>

                <Typography variant="h6" color="secondary" className={classes.bulletsData}>
                  Quickly search and discover information across all of your work applications in
                  one place.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* Sign Up */}

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        component={Paper}
        elevation={6}
        square
        className={classes.gridPaper}
      >
        <div className={classes.paper}>
          <Box width="100%" mb="50px">
            <img
              src={xobaLogo}
              alt="Xoba"
              width="150px"
              onClick={() => {
                window.location.href = `https://xobalabs.com`;
              }}
            />
            <Typography component="h2" variant="h3" color="primary" className={classes.signUpTitle}>
              Get started for free
            </Typography>
          </Box>

          <Grid container direction="column">
            <Grid item xs={12} sm={10} md={12} lg={8}>
              <PasswordlessAuth signup={signup} />
            </Grid>

            <Grid item xs={12} sm={10} md={12} lg={8}>
              <Box mt="10px">
                <GoogleAuth signup={signup} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={10} md={12} lg={8}>
              <Box mt="20px">
                <Typography variant="h6" color="primary">
                  {" "}
                  Already have an account?{" "}
                  <Link className={classes.signInLink} to={ROUTES.SIGN_IN}>
                    Sign in here.
                  </Link>
                </Typography>
                <Typography
                  className={classes.privacyPolicy}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {" "}
                  By singing up, you agree to our{" "}
                  <Typography
                    className={classes.privacyPolicyLink}
                    variant="subtitle1"
                    color="textSecondary"
                    component="a"
                    href="https://app.xobalabs.com/tos"
                    target="_blank"
                  >
                    terms of service
                  </Typography>{" "}
                  and our{" "}
                  <Typography
                    className={classes.privacyPolicyLink}
                    variant="subtitle1"
                    color="textSecondary"
                    component="a"
                    href="https://app.xobalabs.com/privacypolicy"
                    target="_blank"
                  >
                    privacy policy
                  </Typography>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default AuthPageSignUpLayout;
