import React, { useCallback, useState, useEffect } from "react";
import { Avatar, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CardItemType, CardTypeEnum } from "types";
import EditCardDialog from "../../EditCardDialog";
import PlatformIcon from "components/PlatformIcon";
import MimeTypeIcon from "components/MimeTypeIcon";
import CardLayout, { CardBaseProps } from "../../CardLayout";
import CardItemLayout from "../../CardItemLayout";
import { GetSharedCardResponseType } from "types";
import { useFirebase } from "components/Firebase";
import { logSharingViewed } from "analytics/sharing";
import { useCurrentUser } from "components/Session";

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: "transparent",
    color: "black",
  },
  favIcon: {
    width: "18px",
    height: "18px",
  },
}));

type CardListItemProps = {
  cardType: CardTypeEnum;
  cardItem: CardItemType;
  disabled?: boolean;
  cardId?: string;
};

const CardListItem: React.FC<CardListItemProps> = ({ cardType, cardItem, disabled, cardId }) => {
  const classes = useStyles();
  const [currentUser] = useCurrentUser();
  const { url, label, platform, mimeType } = cardItem;
  let generetaredUrl;
  if (!url.includes("http")) {
    generetaredUrl = "https://" + url;
  } else {
    generetaredUrl = url;
  }

  const hostName = new URL(generetaredUrl).hostname;
  const fallbackIcon = platform ? (
    <PlatformIcon platform={platform} />
  ) : (
    <Avatar
      variant="square"
      src={`https://s2.googleusercontent.com/s2/favicons?domain=${hostName}`}
      className={classes.favIcon}
    />
  ); //
  let innerIcon;
  if (mimeType) {
    innerIcon = <MimeTypeIcon mimeType={mimeType} fallback={fallbackIcon} />;
  }

  const icon = <Avatar className={classes.icon}>{innerIcon || fallbackIcon}</Avatar>;

  return (
    <CardItemLayout
      disabled={disabled}
      url={generetaredUrl}
      icon={icon}
      primary={label}
      cardType={cardType}
      cardItem={cardItem}
      noEdit={!!currentUser}
      cardId={cardId}
    />
  );
};

type ItemsCardProps = CardBaseProps & {
  noEdit?: boolean;
};

const ItemsCard: React.FC<ItemsCardProps> = ({
  card,
  cardsRef,
  noEdit,
  disabled,
  ...otherProps
}) => {
  const [data, setData] = useState<GetSharedCardResponseType | null>(null);
  const firebaseStore = useFirebase();

  const handleDelete = useCallback(() => {
    if (cardsRef) {
      cardsRef.doc(card.id).delete();
    }
  }, [card.id, cardsRef]);

  const run = useCallback(
    async (card) => {
      if (card.shareId) {
        setData(null);

        try {
          const result = await firebaseStore.functions.httpsCallable("sharing-getSharedCard")({
            shareId: card.shareId,
          });
          setData(result.data);
          logSharingViewed({
            type: result.data.type,
            shareId: card.shareId,
          });
        } catch (e) {
          handleDelete();
          logSharingViewed({
            error: e.message,
            shareId: card.shareId,
          });
        }
      }
    },
    [firebaseStore.functions, handleDelete]
  );

  useEffect(() => {
    run(card);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const items = card.items || data?.data.items || [];
  const listItems = items.map((cardItem) => {
    return (
      <CardListItem
        key={cardItem.id || `${cardItem.label}:${cardItem.url}`}
        cardItem={cardItem}
        disabled={disabled}
        cardType={card.type}
        cardId={card.id}
      />
    );
  });

  const editor = useCallback(
    ({ editing, onEditDone }) => {
      return <EditCardDialog card={card} cardsRef={cardsRef} open={editing} onClose={onEditDone} />;
    },
    [card, cardsRef]
  );

  return (
    <CardLayout
      card={card}
      items={data?.data.items}
      cardsRef={cardsRef}
      disabled={disabled}
      editor={!noEdit && editor}
      share
      {...otherProps}
    >
      <List dense>{listItems}</List>
    </CardLayout>
  );
};

export default ItemsCard;
