import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Button, Backdrop, Paper, Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useObjectVal } from "hooks/firebase/database";
import { useCurrentUser } from "components/Session";

import { useFeatureState } from "actions/updateFeatureState";
import XobaIcon from "components/XobaIcon";
import { HOME } from "constants/routes";
import { useFirebase } from "components/Firebase";
import { UserApplicationsType } from "types";
import { logMktData } from "analytics";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1250,
  },
  content: {
    padding: theme.spacing(4),
    width: "450px",
  },
  selectInput: {
    width: "350px",
    height: "40px",
    fontSize: "1rem",
  },
  inputLabel: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
    marginBottom: "10px",
  },
  grid: {
    padding: "8px !important",
  },
  gridButton: {
    padding: "8px !important",
    marginTop: "30px",
    marginBottom: "5px",
  },
  message: {
    fontSize: "1rem",
    lineHeight: 1,
    marginBottom: "10px",
  },
  button: {
    marginTop: "10px",
  },
}));

type GettingStartedFeatureState = {
  seen: boolean;
};

const GettingStarted = () => {
  const classes = useStyles();

  // eslint-disable-next-line
  const [user] = useCurrentUser();
  const [featureState, loadingFeatureState, , updateFeatureState] = useFeatureState<
    GettingStartedFeatureState
  >("Search Getting Started");
  const firebase = useFirebase();
  const [userApplications, loadingLinked] = useObjectVal<UserApplicationsType>(
    firebase.userDbRef("userApplications")
  );
  const history = useHistory();

  const LOCAL_STORAGE_SIGNUP_KEY = "SignUp";
  const CONSTANTS = {
    USAGE: {
      WORK: "Work",
      PERSONAL: "Personal",
    },
  };

  const [usage, setUsage] = useState(CONSTANTS.USAGE.WORK);
  const [companyType, setCompanyType] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [role, setRole] = useState("");

  const companyFormType = [
    "Agriculture",
    "Apparel",
    "Banking",
    "Biotechnology",
    "Chemicals",
    "Communications",
    "Construction",
    "Consulting",
    "Education",
    "Electronics",
    "Energy",
    "Engineering",
    "Entertainment",
    "Environmental",
    "Finance",
    "Food & Beverage",
    "Government",
    "Healthcare",
    "Hospitality",
    "Insurance",
    "Machinery",
    "Manufacturing",
    "Media",
    "Not for Profit",
    "Recreation",
    "Retail",
    "Shipping",
    "Technology",
    "Telecommunications",
    "Transportation",
    "Utilities",
    "Other",
  ];

  const sizeFormType = [
    "1-10",
    "11-50",
    "51-100",
    "100-500",
    "500-1,000",
    "1001 - 5,000",
    "5,000+",
  ];

  const roleFormType = [
    "Customer success",
    "Customer support",
    "Design",
    "Engineering",
    "IT",
    "Leadership",
    "Marketing",
    "Product",
    "Operations",
    "Sales",
    "Strategy",
    "Other",
  ];

  const saveMarketingData = useCallback(() => {
    let userData: any = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_SIGNUP_KEY) ||
        JSON.stringify({ email: user?.email, fullName: user?.displayName })
    );

    const mktRef = firebase.firestore.collection("marketing_user_data").doc(user?.uid);

    let data;

    if (usage === CONSTANTS.USAGE.WORK) {
      data = {
        user_id: user?.uid,
        email: userData.email,
        name: userData.fullName,
        xoba_usage: usage,
        role,
        companyType,
        companySize,
      };
    } else {
      data = {
        user_id: user?.uid,
        email: userData.email,
        name: userData.fullName,
        xoba_usage: usage,
      };
    }

    const doc = JSON.parse(JSON.stringify(data));
    mktRef.set(doc, { merge: true });

    return data;
  }, [CONSTANTS.USAGE.WORK, companyType, companySize, firebase, role, usage, user]);

  const handleClick = useCallback(
    (savePoll) => {
      updateFeatureState({ seen: true });

      if (savePoll) {
        const data = saveMarketingData();
        logMktData({ ...data, status: "Submitted" });
      } else {
        logMktData({ status: "Skipped" });
      }
      window.localStorage.removeItem(LOCAL_STORAGE_SIGNUP_KEY);
      // Navigate the user to the home page
      history.push(HOME);
    },
    [history, updateFeatureState, saveMarketingData]
  );

  const loading = loadingFeatureState || loadingLinked;
  const waypointSeen = featureState && featureState.seen;
  const hasApplications =
    !loadingLinked && userApplications && Object.keys(userApplications).length > 0;
  let open;
  if (loading) {
    open = false;
  } else {
    open = !waypointSeen && !hasApplications;
  }

  const handleUsageChange = (event: any) => {
    setUsage(event.target.value);
  };

  const handleCompanyType = (event: any) => {
    setCompanyType(event.target.value);
  };

  const handleSizeType = (event: any) => {
    setCompanySize(event.target.value);
  };

  const handleRoleType = (event: any) => {
    setRole(event.target.value);
  };

  const companyTypeForm = (
    <Grid item className={classes.grid}>
      <InputLabel className={classes.inputLabel} htmlFor="xobaUse">
        What kind of company do you work at?
      </InputLabel>
      <Select
        className={classes.selectInput}
        id="xobaUse"
        value={companyType}
        onChange={handleCompanyType}
        variant="outlined"
      >
        {companyFormType.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );

  const companySizeForm = (
    <Grid item className={classes.grid}>
      <InputLabel className={classes.inputLabel} htmlFor="size">
        How big is the company?
      </InputLabel>
      <Select
        className={classes.selectInput}
        id="size"
        value={companySize}
        onChange={handleSizeType}
        variant="outlined"
      >
        {sizeFormType.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );

  const roleForm = (
    <Grid item className={classes.grid}>
      <InputLabel className={classes.inputLabel} htmlFor="role">
        What is your role there?
      </InputLabel>
      <Select
        className={classes.selectInput}
        id="role"
        value={role}
        onChange={handleRoleType}
        variant="outlined"
      >
        {roleFormType.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Paper className={classes.content}>
        <Grid container direction="column" spacing={4} alignItems="center">
          <Grid item>
            <XobaIcon size={100} />
          </Grid>

          <Grid item className={classes.grid}>
            <Typography variant="h5" color="secondary">
              Welcome to Xoba!
            </Typography>
          </Grid>

          <Grid item sm={11} className={classes.grid}>
            <Typography align="center" variant="h6" color="primary" className={classes.message}>
              One last thing before we head to your Space. We'd love to know more about you.
            </Typography>
          </Grid>

          <Grid item className={classes.grid}>
            <InputLabel className={classes.inputLabel} htmlFor="xobaUse">
              What will you be using Xoba for?
            </InputLabel>
            <Select
              className={classes.selectInput}
              id="xobaUse"
              value={usage}
              onChange={handleUsageChange}
              variant="outlined"
            >
              <MenuItem value={CONSTANTS.USAGE.WORK}>Work</MenuItem>
              <MenuItem value={CONSTANTS.USAGE.PERSONAL}>Personal</MenuItem>
            </Select>
          </Grid>

          {usage === CONSTANTS.USAGE.WORK ? companyTypeForm : null}
          {usage === CONSTANTS.USAGE.WORK ? companySizeForm : null}
          {usage === CONSTANTS.USAGE.WORK ? roleForm : null}

          <Grid item className={classes.gridButton}>
            <Box display="flex" flexDirection="column">
              <Button
                disabled={usage === CONSTANTS.USAGE.WORK && !companyType && !companySize && !role}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleClick(true);
                }}
              >
                Continue
              </Button>

              <Button
                className={classes.button}
                color="primary"
                onClick={() => {
                  handleClick(false);
                }}
              >
                Skip
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Backdrop>
  );
};

export default GettingStarted;
