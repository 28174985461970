import { useEffect, useState, useCallback, useRef, useReducer } from "react";

import { ASYNC_SEARCH_RESULT, ASYNC_NO_MORE_RESULTS } from "constants/backendResponses";
import { useFirebase } from "components/Firebase";
import { randomString } from "utils";
import search, { onStreamChunks } from "./api";
import { useAuthorizedFetch } from "utils/xobaFetch";
import { useCurrentUser } from "components/Session";
import { update as updateSearchRecommendation, SEARCH } from "actions/searchRecommendation";
import { incrementTotalSearches } from "actions/search/incrementTotalSearches";
import { PaginationStateType, UserApplicationType, SearchResultType } from "types";

type State = {
  id: number;
  query: string;
};

type FiltersType = {
  accountIds?: number[];
  date?: string[];
};

const pendingSearchQueryReducer = (state: State, query: string) => {
  return { query, id: state.id + 1 };
};

const noMorePages = (
  nextPageData: PaginationStateType | null,
  applications: UserApplicationType[]
) => {
  if (!nextPageData) {
    return false;
  }

  if (applications) {
    let hasMore = false;
    applications.forEach(({ id }) => {
      // @ts-ignore
      if (nextPageData[id]) {
        hasMore = true;
      }
    });
    return !hasMore;
  }

  return Object.values(nextPageData).some((data) => data != null);
};

// hooks must start with `use`
type UseSearchArgs = {
  initialValue: string;
  pageSize: number;
  filters?: any;
  isCardSearch?: boolean;
};

type ErrorType = {
  message: string;
};

const getFilters = (filters: any): FiltersType => {
  const { accountsToSearch, dateFilter, accountIds } = filters;
  let dateRange;

  if (dateFilter && dateFilter.dateRange) {
    const [minDate, maxDate] = dateFilter.dateRange();
    const res = new Array(2);

    if (minDate) {
      res[0] = minDate.toISOString();
    }
    if (maxDate) {
      res[1] = maxDate.toISOString();
    }

    dateRange = res;
  }

  return {
    accountIds:
      // @ts-ignore
      accountIds || (accountsToSearch && Array.from(accountsToSearch).map(({ id }) => id)),
    date: dateRange,
  };
};

const useSearch = ({ initialValue, pageSize, filters, isCardSearch }: UseSearchArgs) => {
  const firebase = useFirebase();
  const [authUser] = useCurrentUser();
  const fetch = useAuthorizedFetch();

  // Platform authentication tokens.

  const initialQuery = initialValue || "";
  const [pendingSearchQuery, setPendingSearchQuery] = useReducer(pendingSearchQueryReducer, {
    query: initialQuery,
    id: 0,
  });
  const searchQueryRef = useRef({ query: "" });
  const [loading, setLoading] = useState({ main: initialQuery !== "", more: false });
  const [results, setResults] = useState<SearchResultType[]>([]);
  const [error, setError] = useState<ErrorType | null>(null);
  const [page, setPage] = useState(0);

  const resultsMapRef = useRef(new Map());
  const nextPageRef = useRef<PaginationStateType | null>(null); // Maps platforms to next page data

  // Prime the local cache of the JWT.
  // Docs (https://firebase.google.com/docs/reference/js/firebase.User#getidtoken)
  // indicate that future calls to get the token are answered locally if the
  // token is not expired.
  useEffect(() => {
    if (authUser) {
      // @ts-ignore
      authUser.getIdToken();
    }

    // try to reduce latency in the case that the firebase token expires by
    // maintaining a subscription
    return firebase.auth.onIdTokenChanged(() => {});
  }, [authUser, firebase.auth]);

  const updateResults = useCallback((result) => {
    const key = result.handle; // TODO(piyush) Use result.id here.
    const cachedResult = resultsMapRef.current.get(key);
    if (cachedResult != null) {
      // TODO(piyush) This is not a deep update.
      resultsMapRef.current.set(key, { ...cachedResult, ...result });
    } else {
      resultsMapRef.current.set(key, result);
    }

    // TODO(piyush) Two things: (1) Rank results using the 'score' attribute;
    // (2) Ensure that pages of results are stable, so hypothetically even if
    // page 2 of results contains a result with higher score than results in
    // page 1, the result will remain in page 2.
    setResults(Array.from(resultsMapRef.current.values()));
  }, []);

  const currentSearchId = useRef("");

  const runSearch = useCallback(
    async (query, nextPageData) => {
      currentSearchId.current = randomString(32);

      if (!isCardSearch) {
        updateSearchRecommendation(firebase, query, SEARCH);
        if (nextPageRef.current === null) {
          incrementTotalSearches(firebase);
        }
      }

      const resp = search({
        xobaFetch: fetch,
        query,
        numResults: pageSize,
        nextPageData,
        page,
        searchId: currentSearchId.current,
        filters: getFilters(filters || {}),
      });

      resp.then(
        onStreamChunks(({ done, err, chunk }) => {
          // Avoid dirty writes.
          if (query !== searchQueryRef.current.query) return;

          if (err != null) {
            setLoading((l) => {
              return { ...l, main: false, more: false };
            });
            setError(err);
            return;
          }

          if (done) {
            setLoading((l) => {
              return { ...l, main: false, more: false };
            });
            if (resultsMapRef.current.size === 0) {
              setError({ message: "No results found" });
            }
          }

          const { search_id: id, type, page } = chunk;
          if (id !== currentSearchId.current) {
            // We probably received left over results from a previous search. Ignore it.
            return;
          }

          if (type === ASYNC_SEARCH_RESULT) {
            const { result, next_page_data: nextPageData } = chunk;

            // Each result contains its version of the next page data for its platform and account. In
            // cases, this is updated in more recent results.
            nextPageRef.current = {
              ...(nextPageRef.current || {}),
              [result.user_application_id]: nextPageData,
            };

            updateResults(result);
          } else if (type === ASYNC_NO_MORE_RESULTS) {
            // There are no more results left for this platform/account.
            const { user_application_id } = chunk;
            nextPageRef.current = {
              ...(nextPageRef.current || {}),
              [user_application_id]: null,
            };
          }

          if (resultsMapRef.current.size >= (page + 1) * pageSize) {
            setLoading((l) => {
              return { ...l, main: false, more: false };
            });
          }
        })
      );
    },
    [page, pageSize, updateResults, fetch, firebase, filters, isCardSearch]
  );

  // TODO(piyush) Automatically run this when the user scrolls to the bottom of
  // the viewport.
  const runNextPage = useCallback(async () => {
    const nextPage = nextPageRef.current;
    setLoading((l) => {
      // TODO(piyush) Only set to true if there aren't any pre-fetched results
      // left to display.
      return { ...l, more: true };
    });
    setPage((page) => page + 1);

    runSearch(searchQueryRef.current.query, nextPage);
  }, [runSearch]);

  useEffect(() => {
    if (searchQueryRef.current === pendingSearchQuery) return;

    nextPageRef.current = null;
    resultsMapRef.current.clear();
    setResults([]);
    setError(null);

    if (pendingSearchQuery.query === "") {
      searchQueryRef.current = pendingSearchQuery;
      setLoading({ main: false, more: false });
      // TODO: how do we cancel a running search?
    } else {
      setLoading({ main: true, more: false });
      if (authUser) {
        searchQueryRef.current = pendingSearchQuery;
        runSearch(pendingSearchQuery.query, nextPageRef.current);
      }
    }
  }, [authUser, pendingSearchQuery, resultsMapRef, runSearch]);

  // Expose function to execute a search.
  const run = (query: string, filters: FiltersType | null) => {
    setPendingSearchQuery(query);
  };

  const hasNextPage = useCallback((applications) => {
    return !noMorePages(nextPageRef.current, applications);
  }, []);

  return {
    query: pendingSearchQuery.query,
    results,
    page,
    loading,
    error,
    run,
    runNextPage,
    hasNextPage,
  };
};

export default useSearch;
