import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 400,
  },
  highlight: {
    fontWeight: 700,
  },
}));

type HighlightMatchesProps = {
  option: string;
  value: string;
};

const HighlightMatches = ({ option, value }: HighlightMatchesProps) => {
  const classes = useStyles();

  const matches = match(option, value);
  const parts = parse(option, matches);

  return (
    <span className={classes.root}>
      {parts.map((part, index) => (
        <span key={index} className={clsx(part.highlight && classes.highlight)}>
          {part.text}
        </span>
      ))}
    </span>
  );
};

export default HighlightMatches;
