import React, { useState, useCallback, useEffect } from "react";
import { Typography, TextField, Button, Grid, FormLabel } from "@material-ui/core";

import { useFirebase } from "components/Firebase";
import { logMissingApplicationRequest } from "analytics";

const applicationsArrayFromInput = (input: string) => {
  const items = input.replace(/(,)/, "\n").split("\n");
  // remove leading and trailing spaces
  const chomped = items.map((application) => application.replace(/^\s+/, "").replace(/\s+$/, ""));
  return chomped.filter((application) => application.length > 0);
};

const MissingApplicationForm = () => {
  const [input, setInput] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const firebase = useFirebase();
  const track = firebase.functions.httpsCallable("analytics-track");

  const hasInput = input.length > 0 && !input.match(/^\s+$/m);

  const handleChange = useCallback((e) => {
    setInput(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setSubmitting(true);
      const applications = applicationsArrayFromInput(input);
      logMissingApplicationRequest({ applications });
      track({ event: "Firebase: Missing Application Request", properties: { applications } });
      setInput("");
    },
    [input, track]
  );

  useEffect(() => {
    if (submitting) {
      const timeout = setTimeout(() => {
        setSubmitting(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [submitting]);

  const htmlId = "missingApplicationInputBox";

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <FormLabel htmlFor={htmlId}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h6" align="center">
                  Are we missing an application?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="center">
                  Let us know which applications you'd like Xoba to support
                </Typography>
              </Grid>
            </Grid>
          </FormLabel>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item>
              <TextField
                id={htmlId}
                label="Application(s)"
                value={input}
                onChange={handleChange}
                multiline
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={submitting || !hasInput}
                color="primary"
                variant="contained"
              >
                {submitting ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MissingApplicationForm;
