import React from "react";

import Avatar from "@material-ui/core/Avatar";
import { Container, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import xobaIcon from "images/xoba_icons/Xoba_Icon.svg";
import { GoogleAuth, PasswordlessAuth } from "components/Auth";

const useStyles = makeStyles((theme) => ({
  signIn: {
    marginTop: theme.spacing(8),
  },
  signInFooter: {
    marginTop: theme.spacing(1),
  },
}));

type AuthPageLayoutProps = {
  title: React.ReactNode;
  signup?: boolean;
  footer: React.ReactNode;
};

const AuthPageLayout = ({ signup, footer, title }: AuthPageLayoutProps) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <Grid container direction="column" alignItems="center" spacing={3} className={classes.signIn}>
        <Grid item>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar src={xobaIcon} />
        </Grid>
        <Grid item>
          <PasswordlessAuth signup={signup} />
        </Grid>
      </Grid>

      <Divider />

      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={3}
        className={classes.signInFooter}
      >
        <Grid item>
          <GoogleAuth signup={signup} />
        </Grid>
        <Grid item>{footer}</Grid>
      </Grid>
    </Container>
  );
};

export default AuthPageLayout;
