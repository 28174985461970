import React, { useCallback } from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { SearchResultType } from "types";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
}));

const sortByAttr = (attr: string, transform: ((_: any) => any) | null = null, reverse = false) => (
  result1: SearchResultType,
  result2: SearchResultType
) => {
  // @ts-ignore
  let first = result1[attr];
  // @ts-ignore
  let second = result2[attr];
  if (transform) {
    first = transform(first);
    second = transform(second);
  }

  // Sort "larger" results earlier in the array, so they're displayed first.
  let ret;
  if (first > second) ret = -1;
  else if (first === second) ret = 0;
  else ret = 1;

  if (reverse) ret *= -1;
  return ret;
};

export const defaultSortOptions: { [key: string]: any } = {
  Relevance: sortByAttr("score"),
  "Date (most recent first)": sortByAttr("date", Date.parse),
  "Date (oldest first)": sortByAttr("date", Date.parse, true),
};

type SortMenuProps = {
  value: string | null;
  onChange: (_: string) => void;
};

const SortMenu: React.FC<SortMenuProps> = ({ value, onChange }) => {
  const classes = useStyles();

  const popupState = usePopupState({ variant: "popover", popupId: "sortMenu" });

  const handleSelection = useCallback(
    (event, option) => {
      onChange(option);

      popupState.close();
    },
    [onChange, popupState]
  );

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        className={classes.button}
        {...bindTrigger(popupState)}
      >
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          Sort results by: {value || "Default"}
        </Typography>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        // Opens menu below, not on top of, the button.
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.keys(defaultSortOptions).map((option) => (
          <MenuItem
            onClick={(event) => handleSelection(event, option)}
            selected={option === value}
            key={option}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SortMenu;
