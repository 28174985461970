import React from "react";

import resultTypeComponents from "./resultTypes";
import { SearchResultType } from "types";

export const supportsPlatform = (platform: string) => {
  return !!resultTypeComponents(platform);
};

type SearchResultProps = {
  index: number;
  result: SearchResultType;
};

const SearchResult: React.FC<SearchResultProps> = ({ result, index }) => {
  const Component = resultTypeComponents(result.platform);

  // TODO: figure out why this warns about icon
  // @ts-ignore
  return <Component result={result} index={index} />;
};

export default SearchResult;
