import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

import { useXobaCards } from "hooks/limitsCounters/useXobaCards";
import EditCardDialog from "components/Card/EditCardDialog";
import { UpgradeDialog } from "components/Account/UpgradeDialog";

import Button from "@material-ui/core/Button";

type AddCardButtonProps = {
  cardsRef: any;
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#3B35A6",
    color: "white",
    "&:hover": {
      backgroundColor: "#3B35A6",
      color: "white",
    },
  },
}));

const AddCardButton: React.FC<AddCardButtonProps> = ({ cardsRef }) => {
  const getXobaCards = useXobaCards();
  const [openEditCard, setOpenEditCard] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const classes = useStyles();

  const handleOpen = async () => {
    const xobaCards = await getXobaCards();
    if (xobaCards.isNewCardPossible) setOpenEditCard(true);
    else setOpenUpgrade(true);
  };

  const closeEditCard = () => setOpenEditCard(false);
  const closeUpgrade = () => setOpenUpgrade(false);

  return (
    <React.Fragment>
      <Tooltip title="Add a Xoba Card to your space">
        <Button
          variant="contained"
          //color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          {" "}
          Add New Card
        </Button>
      </Tooltip>

      <EditCardDialog cardsRef={cardsRef} open={openEditCard} onClose={closeEditCard} />
      <UpgradeDialog open={openUpgrade} onClose={closeUpgrade} limitType={"card"} />
    </React.Fragment>
  );
};

export default AddCardButton;
