import React, { useCallback, useMemo, useState } from "react";
import { perc, setTopLeft, setTransform } from "./utils";
import {
  calcGridItemPosition,
  clamp,
  calcWH,
  calcXY,
  calcGridColWidth,
  calcGridItemWHPx,
} from "./calculateUtils";
import clsx from "clsx";
import { DraggableCore } from "react-draggable";
import { Resizable } from "react-resizable";

import { DroppingPosition, Position, ReactDraggableCallbackData } from "./utils";

import { PositionParams } from "./calculateUtils";

type PartialPosition = { top: number; left: number };

type Props = {
  cols: number;
  containerWidth: number;
  margin: [number, number] | null | undefined;
  containerPadding: [number, number];
  rowHeight: number;
  maxRows: number;
  isDraggable: boolean;
  isResizable: boolean;
  isBounded: boolean;
  isStatic?: boolean;
  useCSSTransforms?: boolean;
  usePercentages?: boolean;
  transformScale: number;
  droppingPosition?: DroppingPosition;

  className: string;
  style?: Object;
  // Draggability
  cancel: string;
  handle: string;

  x: number;
  y: number;
  w: number;
  h: number;

  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
  i: string;

  resizeHandles?: Array<"s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne">;

  onDrag?: (arg0: string, arg1: string, arg2: number, arg3: number) => void;
  onResize?: (arg0: string, arg1: string, arg2: number, arg3: number) => void;
  // onResizeStart?: GridItemCallback<GridResizeEvent>;
  // onResizeStop?: GridItemCallback<GridResizeEvent>;
};

/**
 * An individual item within a ReactGridLayout.
 */

type ResizeState = {
  resizing: Position | null;
};

type DragState = {
  dragging: PartialPosition | null | undefined;
};

type GridItemProps = Props & {};

const GridItem: React.FC<GridItemProps> = ({
  children,
  x,
  y,
  w,
  h,
  useCSSTransforms,
  cols,
  containerPadding,
  containerWidth,
  margin,
  maxRows,
  rowHeight,
  className,
  isStatic, // TODO: static is reserved
  style,
  usePercentages,
  isDraggable,
  transformScale = 1,
  cancel = "",
  handle = "",
  onDrag,
  minW = 1,
  maxW = Infinity,
  minH = 1,
  maxH = Infinity,
  onResize,
  resizeHandles,
  isResizable,
  isBounded,
  i,
}) => {
  // TODO: mounted?
  // useCSSTransforms={useCSSTransforms && mounted}
  // usePercentages={!mounted}

  // @ts-ignore
  const positionParams = useMemo<PositionParams>(() => {
    return {
      cols,
      containerPadding,
      containerWidth,
      margin,
      maxRows,
      rowHeight,
    };
  }, [cols, containerPadding, containerWidth, margin, maxRows, rowHeight]);

  const [resizeState, setResizeState] = useState<ResizeState>({ resizing: null });
  const [dragState, setDragState] = useState<DragState>({ dragging: null });
  // console.log("dragState", dragState);

  const pos = useMemo(() => {
    return calcGridItemPosition(positionParams, x, y, w, h, {
      ...resizeState,
      ...dragState,
    });
  }, [positionParams, x, y, w, h, resizeState, dragState]);

  // console.log("resizeState", resizeState, pos.width, pos.height);

  const createStyle = useMemo(() => {
    let res;
    // CSS Transforms support (default)
    if (useCSSTransforms) {
      res = setTransform(pos);
    } else {
      // top,left (slow)
      res = setTopLeft(pos);

      // This is used for server rendering.
      if (usePercentages) {
        // @ts-ignore
        res.left = perc(pos.left / containerWidth);
        // @ts-ignore
        res.width = perc(pos.width / containerWidth);
      }
    }

    return res;
  }, [useCSSTransforms, pos, containerWidth, usePercentages]);

  // Calculate min/max constraints using our min & maxes
  const remainingWidth = cols - x;
  const constraints = useMemo(() => {
    const maxWidth = calcGridItemPosition(positionParams, 0, 0, remainingWidth, 0).width;
    const mins = calcGridItemPosition(positionParams, 0, 0, minW, minH);
    const maxes = calcGridItemPosition(positionParams, 0, 0, maxW, maxH);
    const minConstraints = [mins.width, mins.height];
    const maxConstraints = [Math.min(maxes.width, maxWidth), Math.min(maxes.height, Infinity)];

    return {
      min: minConstraints,
      max: maxConstraints,
    };
  }, [positionParams, minW, maxW, minH, maxH, remainingWidth]);

  const resizeHandler = useCallback(
    (e: Event, { node, size }: { node: HTMLElement; size: Position }, handlerName: string) => {
      // const handler = this.props[handlerName];
      // if (!handler) return;

      // Get new XY
      let { w, h } = calcWH(positionParams, size.width, size.height, x, y);

      // Min/max capping
      w = clamp(w, Math.max(minW, 1), Math.min(maxW, cols - x));
      h = clamp(h, minH, maxH);

      // console.log("resizeState - set", handlerName === "onResizeStop" ? null : size)
      setResizeState({ resizing: handlerName === "onResizeStop" ? null : size });

      if (onResize) {
        onResize(handlerName, i, w, h);
      }
    },
    [positionParams, x, y, minW, maxW, minH, maxH, cols, i, onResize]
  );

  const handleResize = useCallback(
    (e: Event, callbackData: { node: HTMLElement; size: Position }) => {
      resizeHandler(e, callbackData, "onResize");
    },
    [resizeHandler]
  );
  const handleResizeStart = useCallback(
    (e: Event, callbackData: { node: HTMLElement; size: Position }) => {
      resizeHandler(e, callbackData, "onResizeStart");
    },
    [resizeHandler]
  );
  const handleResizeStop = useCallback(
    (e: Event, callbackData: { node: HTMLElement; size: Position }) => {
      resizeHandler(e, callbackData, "onResizeStop");
    },
    [resizeHandler]
  );

  const handleDrag = useCallback(
    (e: Event, { node, deltaX, deltaY }: ReactDraggableCallbackData) => {
      deltaX /= transformScale;
      deltaY /= transformScale;

      if (!dragState.dragging) {
        throw new Error("onDrag called before onDragStart.");
      }
      let top = dragState.dragging.top + deltaY;
      let left = dragState.dragging.left + deltaX;

      // Boundary calculations; keeps items within the grid
      if (isBounded) {
        const { offsetParent } = node;

        if (offsetParent) {
          const bottomBoundary =
            // @ts-ignore
            offsetParent.clientHeight - calcGridItemWHPx(h, rowHeight, margin[1]);
          top = clamp(top, 0, bottomBoundary);

          const colWidth = calcGridColWidth(positionParams);
          // @ts-ignore
          const rightBoundary = containerWidth - calcGridItemWHPx(w, colWidth, margin[0]);
          left = clamp(left, 0, rightBoundary);
        }
      }

      const newPosition: PartialPosition = { top, left };
      setDragState({ dragging: newPosition });

      // Call callback with this data
      const { x, y } = calcXY(positionParams, top, left, w, h);

      if (onDrag) {
        onDrag("onDrag", i, x, y);
      }
    },
    [
      dragState,
      transformScale,
      containerWidth,
      w,
      margin,
      positionParams,
      h,
      isBounded,
      rowHeight,
      i,
      onDrag,
    ]
  );

  const handleDragStart = useCallback(
    (e: Event, { node }: ReactDraggableCallbackData) => {
      const newPosition: PartialPosition = { top: 0, left: 0 };

      // TODO: this wont work on nested parents
      const { offsetParent } = node;
      if (!offsetParent) return;
      const parentRect = offsetParent.getBoundingClientRect();
      const clientRect = node.getBoundingClientRect();
      const cLeft = clientRect.left / transformScale;
      const pLeft = parentRect.left / transformScale;
      const cTop = clientRect.top / transformScale;
      const pTop = parentRect.top / transformScale;
      newPosition.left = cLeft - pLeft + offsetParent.scrollLeft;
      newPosition.top = cTop - pTop + offsetParent.scrollTop;
      setDragState({ dragging: newPosition });

      // Call callback with this data
      const { x, y } = calcXY(positionParams, newPosition.top, newPosition.left, w, h);

      if (onDrag) {
        onDrag("onDragStart", i, x, y);
      }
    },
    [h, positionParams, transformScale, w, i, onDrag]
  );

  const handleDragStop = useCallback(
    (e: Event, { node }: ReactDraggableCallbackData) => {
      if (!dragState.dragging) {
        throw new Error("onDragEnd called before onDragStart.");
      }
      const { left, top } = dragState.dragging;
      setDragState({ dragging: null });

      const { x, y } = calcXY(positionParams, top, left, w, h);

      if (onDrag) {
        onDrag("onDragStop", i, x, y);
      }
    },
    [h, dragState, positionParams, w, i, onDrag]
  );

  const newChild = useMemo(() => {
    const child = React.Children.only(children);

    // Create the child element. We clone the existing element but modify its className and style.
    // @ts-ignore
    return React.cloneElement(child, {
      // @ts-ignore
      className: clsx("react-grid-item", child.props.className, className, {
        static: isStatic,
        resizing: Boolean(resizeState.resizing),
        "react-draggable": isDraggable,
        "react-draggable-dragging": Boolean(dragState.dragging),
        // dropping: Boolean(droppingPosition),
        // TODO: check mounted
        cssTransforms: useCSSTransforms,
      }),
      // We can set the width and height on the child, but unfortunately we can't set the position.
      style: {
        ...style,
        // @ts-ignore
        ...child.props.style,
        ...createStyle,
      },
    });
  }, [
    children,
    style,
    createStyle,
    isStatic,
    useCSSTransforms,
    className,
    resizeState,
    isDraggable,
    dragState,
  ]);

  // TODO: break out the dragging + resizing logic

  // console.log("GridItem", resizeState, pos.width, pos.height, constraints)

  return (
    // @ts-ignore
    <DraggableCore
      disabled={!isDraggable}
      onStart={handleDragStart}
      onDrag={handleDrag}
      onStop={handleDragStop}
      handle={handle}
      cancel={".react-resizable-handle" + (cancel ? "," + cancel : "")}
      scale={transformScale}
    >
      {/* @ts-ignore */}
      <Resizable
        draggableOpts={{
          disabled: !isResizable,
        }}
        className={isResizable ? undefined : "react-resizable-hide"}
        width={pos.width}
        height={pos.height}
        minConstraints={constraints.min}
        maxConstraints={constraints.max}
        onResizeStart={handleResizeStart}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        transformScale={transformScale}
        resizeHandles={resizeHandles}
      >
        {newChild}
      </Resizable>
    </DraggableCore>
  );
};

export default GridItem;
