import { ASYNC_SEARCH_RESULT, ASYNC_NO_MORE_RESULTS } from "constants/backendResponses";
import { logAsyncSearchResult, logAsyncNoMoreResults, logSearch, logSearchStatus } from "analytics";
// import type { XobaFetch } from "utils/xobaFetch";
import { decodeResponse as decodeNdJson } from "utils/api/ndjson";

const EMPTY_RESPONSE = new Promise((resolve) => {
  resolve([]);
});

type ChunkType = {
  done: boolean;
  chunk?: any;
  err?: any;
};

// TODO: merge with ChunkType
type ValueType = {
  done: boolean;
  value: any;
};

export const onStreamChunks = (callback: (_: ChunkType) => void) => {
  return (responseStream: ReadableStream) => {
    if (responseStream == null) {
      callback({
        done: true,
        err: {
          message: "There seems to have been an error. Try again or contact help@xobalabs.com",
        },
      });
      return null;
    }

    const [passThrough, teed] = responseStream.tee();
    const reader = teed.getReader();

    reader
      .read()
      .then(function processText({
        done,
        value,
      }: ReadableStreamReadResult<ValueType>): Promise<ReadableStream> | undefined {
        callback({ done, chunk: value || {} });

        if (done) {
          return;
        }

        // @ts-ignore
        return reader.read().then(processText);
      });

    return passThrough;
  };
};

type SearchArgs = {
  xobaFetch: any;
  query: string;
  numResults: number;
  nextPageData: any;
  page: number;
  searchId: string;
  filters: any;
};

const search = async ({
  xobaFetch,
  query,
  numResults,
  nextPageData,
  page,
  searchId,
  filters,
}: SearchArgs) => {
  // The component should have logic to preempt this case. We just cover it here
  // to prevent hitting the server.
  if (query === "") {
    // TODO: this breaks the .tee stream processor
    return EMPTY_RESPONSE;
  }

  const startTime = new Date();
  logSearch(searchId, filters);

  // Send query to backend.
  const url = new URL("search/", process.env.REACT_APP_BACKEND);
  const body = JSON.stringify({
    search: query,
    searchId,
    numResults,
    cursors: nextPageData,
    page,
    filters,
  });

  let chunkTotal = 0;
  let firstResultLatency: number;
  const resp = (xobaFetch || fetch)(url, { credentials: "include", method: "POST", body })
    .then(decodeNdJson)
    .then(
      onStreamChunks(({ done, err, chunk }) => {
        // See the AsyncSearchResponse class in backend/server/frontend_request.py for the response schema.
        // TODO: create a SearchResult class so we can use strong types

        if (err != null) throw err;

        const latency = new Date().valueOf() - startTime.valueOf();
        if (!firstResultLatency) {
          firstResultLatency = latency;
        }

        if (done) {
          logSearchStatus(true, searchId, latency, chunkTotal, null, firstResultLatency);
          if (chunk == null) return;
        } else if (chunk == null) {
          throw new Error("Received empty search result");
        }

        const { search_id: id, type } = chunk;
        if (id !== searchId) {
          // We probably received left over results from a previous search.
          // TODO(piyush) Log this.
          return;
        }

        if (type === ASYNC_SEARCH_RESULT) {
          logAsyncSearchResult(
            true,
            id,
            chunk.page,
            chunk.result.platform,
            chunk.result.account,
            latency,
            null
          );
          chunkTotal += 1;
        } else if (type === ASYNC_NO_MORE_RESULTS) {
          logAsyncNoMoreResults(id, chunk.page, chunk.platform, chunk.account, latency);
        } else {
          const err = Error(`Unknown response type ${type}`);
          logAsyncSearchResult(
            false,
            id,
            chunk.page,
            chunk.result.platform,
            chunk.result.account,
            latency,
            err
          );
        }
      })
    )
    .catch((error: Error) => {
      const latency = new Date().valueOf() - startTime.valueOf();
      if (!firstResultLatency) {
        firstResultLatency = latency;
      }

      logSearchStatus(false, searchId, latency, null, error, firstResultLatency);
      return null; // Notify downstream handlers of the error
    });

  return resp;
};

export default search;
