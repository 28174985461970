import React, { useCallback, useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { displayDate } from "../../utils";
import { useLogSearchInteraction } from "actions/logSearchInteraction";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import SearchResultExpander from "../SearchResultExpander";
import { SlackSearchResultType, SlackMessageType } from "types";
import MimeTypeIcon from "components/MimeTypeIcon";

const useStyles = makeStyles((theme) => ({
  contextMessageHeader: {
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
  },
  contextMessageAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  contextMessageText: {
    padding: 0,
  },
  highlightStyle: {
    background: theme.palette.highlight.main,
  },
}));

type ContextualSlackMessageProps = {
  message: SlackMessageType;
  platform: string;
  index: number;
  last: boolean;
  original: string;
};

const ContextualSlackMessage: React.FC<ContextualSlackMessageProps> = ({
  message,
  platform,
  index,
  last,
  original,
}) => {
  const classes = useStyles();
  const logSearchInteraction = useLogSearchInteraction();

  const handleClick = useCallback(
    (event) => {
      logSearchInteraction({
        interactionType: "click",
        position: index,
        platform,
      });
      window.open(message.handle, "_blank");
      event.stopPropagation();
    },
    [logSearchInteraction, message, platform, index]
  );

  const avatar = <Avatar src={message.avatar_link} className={classes.contextMessageAvatar} />;

  return (
    <ListItem
      divider={!last}
      dense
      onClick={handleClick}
      className={clsx(original && classes.highlightStyle)}
      key={index}
    >
      <Grid container direction="column">
        <Grid item>
          <CardHeader
            avatar={avatar}
            title={message.username}
            subheader={displayDate(message.date)}
            className={classes.contextMessageHeader}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption">{message.text}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

type ExpandableSlackConversationProps = {
  context_messages: SlackMessageType[];
  defaultContent: React.ReactNode;
  platform: string;
  index: number;
};

const ExpandableSlackConversation: React.FC<ExpandableSlackConversationProps> = ({
  context_messages,
  defaultContent,
  platform,
  index,
}) => {
  const [expanded, setExpanded] = useState(false);
  const logSearchInteraction = useLogSearchInteraction();

  const handleClick = useCallback(
    (event) => {
      const interactionType = expanded ? "collapseResult" : "expandResult";
      logSearchInteraction({ interactionType, position: index, platform });

      setExpanded(!expanded);
    },
    [expanded, index, platform, logSearchInteraction]
  );

  const conversation = context_messages.map((message, i) => (
    <ContextualSlackMessage
      key={i}
      message={message}
      platform={platform}
      index={index}
      last={i === context_messages.length - 1}
      original={message.original}
    />
  ));

  return (
    <SearchResultExpander expanded={expanded} onClick={handleClick}>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List>{conversation}</List>
      </Collapse>

      {!expanded && defaultContent}
    </SearchResultExpander>
  );
};

const SlackSearchResult: React.FC<{ result: SlackSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  var header = result.title;
  var subheader = null;
  var avatar = null;
  var body = null;
  var side = null;

  if (!result.is_file) {
    subheader = result.is_im ? "Direct message" : `#${result.channel_name}`;
    avatar = <Avatar src={result.avatar_link} />;
    body = (
      <ResultBody
        content={result.content_snippets}
        fallback={result.fallback_snippet}
        full_text={result.text}
      />
    );

    if (result.context_messages) {
      body = (
        <ExpandableSlackConversation
          context_messages={result.context_messages}
          defaultContent={body}
          platform={result.platform}
          index={index}
        />
      );
    }
  } else {
    subheader = result.username;
    avatar = <MimeTypeIcon mimeType={result.mime_type} />;
  }

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
      side={side}
    />
  );
};

export default SlackSearchResult;
