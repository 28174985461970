import React, { useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";

import { logDeleteAccount, DeleteAccountStatus } from "analytics";
import { useFirebase } from "components/Firebase";

const useStyles = makeStyles((theme) => ({
  startDeleteButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  confirmDeleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const DeleteAccountButton = () => {
  const classes = useStyles();

  const firebase = useFirebase();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [error, setError] = useState<string | null>(null);
  const confirmationEmpty = !!confirmation.match(/^\s*$/);

  const confirmationTarget = "I want to delete my account";
  const useFixedConfirmationMessage = true;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (useFixedConfirmationMessage) {
        if (confirmation !== confirmationTarget) {
          setError("Confirmation does not match");
          return;
        }
      } else {
        try {
          await firebase.reauthenticate(confirmation);
        } catch (e) {
          if (e.code === "auth/wrong-password") {
            setError("Password does not match. Please try again.");
          } else if (e.code === "auth/too-many-requests") {
            setError("Too many unsuccessful attempts. Please try again later.");
          } else {
            setError("Failed to reauthenticate your account.");
          }
          return;
        }
      }

      try {
        await firebase.deleteUser();
        logDeleteAccount({ status: DeleteAccountStatus.Success });
      } catch (error) {
        logDeleteAccount({ status: DeleteAccountStatus.Failed, error });
        if (error.code === "auth/requires-recent-login") {
          setError(error.message);
        } else {
          setError("Failed to delete your account. Please log back in and try again.");
        }
      }
    },
    [confirmation, firebase, useFixedConfirmationMessage]
  );

  const confirmBox = (
    <TextField
      label={useFixedConfirmationMessage ? "Please enter confirmation message" : "Password"}
      required
      variant="outlined"
      margin="dense"
      type={useFixedConfirmationMessage ? undefined : "password"}
      fullWidth
      autoFocus
      value={confirmation}
      error={error != null}
      helperText={error}
      onChange={(event) => setConfirmation(event.target.value)}
    />
  );
  const dialogContent = (
    <DialogContentText>
      {useFixedConfirmationMessage
        ? `To confirm that you want to permanently delete your account,
                   please type "${confirmationTarget}" (without the quotes).`
        : `To confirm that you want to permanently delete your
                   account, please re-enter your password:`}
    </DialogContentText>
  );

  const handleOpen = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  return (
    <div>
      <Button
        className={classes.startDeleteButton}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Delete account
      </Button>
      <Dialog open={openConfirmDialog} onClose={handleClose}>
        <DialogTitle>Confirm account deletion</DialogTitle>

        <form noValidate onSubmit={handleSubmit}>
          <DialogContent dividers>
            {dialogContent}
            {confirmBox}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              className={classes.confirmDeleteButton}
              disabled={confirmationEmpty}
            >
              Delete my account
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DeleteAccountButton;
