import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Tooltip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { NormalAlert, DisappearingAlert } from "components/Alert";
import FeaturePermissions from "./FeaturePermissions";
import ProtectDataPillars from "./ProtectDataPillars";
import { OauthDialogConfigFeatureType } from "constants/platforms";
import { AuthContinueType } from "actions/connectApplication/api";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: "5px",
  },
  title: {
    flexGrow: 1,
  },
  featurePermissions: {
    marginTop: theme.spacing(4),
  },
}));

export type ApplicationOauthDialogLayoutProps = {
  open: boolean | undefined;
  onClose: () => void;
  authOptions: AuthContinueType | null,
  setAuthOptions: (data: AuthContinueType) => void,
  icon: any;
  title: string;
  url: string;
  readOnlyDesc: string;
  features: OauthDialogConfigFeatureType[];
  onSubmit: () => void;
  onCancel: () => void;
  onReset: () => void;
  submitting?: boolean;
  error?: any;
  connected?: boolean;
};

const ApplicationOauthDialogLayout: React.FC<ApplicationOauthDialogLayoutProps> = ({
  open,
  onClose,
  authOptions,
  setAuthOptions,
  icon,
  title,
  url,
  readOnlyDesc,
  features,
  onSubmit,
  onCancel,
  onReset,
  submitting,
  error,
  connected,
}) => {
  const classes = useStyles();
  const [featureState, setFeatureState] = useState<any>({});
  const [focusedFeature, setFocusedFeature] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setFeatureState({});
      setFocusedFeature(null);
      onReset();
    }
  }, [open, onReset]);

  const [canSubmit, missingRequiredFeature] = useMemo<
    [boolean, OauthDialogConfigFeatureType | undefined]
  >(() => {
    let missing: OauthDialogConfigFeatureType | undefined;

    const res = features.every((feature) => {
      const requiredMissing = feature.required && !featureState[feature.name];

      if (!missing) {
        missing = feature;
      }

      return !requiredMissing;
    });

    return [res, missing];
  }, [featureState, features]);

  useEffect(() => {
    if (canSubmit) {
      setFocusedFeature(null);
    }
  }, [canSubmit]);

  const submitButtonText = submitting ? "Connecting..." : "Connect";
  let submitButton = (
    <Button
      color="primary"
      variant="contained"
      disabled={!canSubmit || submitting}
      onClick={() => onSubmit()}
    >
      {submitButtonText}
    </Button>
  );

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  if (missingRequiredFeature) {
    const handleOnOpen = () => {
      setFocusedFeature(missingRequiredFeature.name);
    };

    const handleOnClose = () => {
      setFocusedFeature(null);
    };

    submitButton = (
      <Tooltip
        title={`You must give us ${missingRequiredFeature.title} access`}
        leaveDelay={500}
        enterTouchDelay={10}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        placement="top-end"
      >
        <span>{submitButton}</span>
      </Tooltip>
    );
  }

  let connectedAlert;
  if (connected) {
    connectedAlert = (
      <DisappearingAlert severity="success" delay={2000} afterDelay={onClose}>
        Connected!
      </DisappearingAlert>
    );
  }

  return (
    <Dialog open={!!open} onClose={onClose} aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Grid container direction="row" spacing={2}>
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Grid item className={classes.title}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{url}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>

      {submitting && authOptions && authOptions.auth_data.sites && !authOptions.account
        ?
        <ChooseAuthOptionsForm
          authOptions={authOptions}
          setAuthOptions={setAuthOptions}
          handleCancelClick={handleCancelClick}
        />
        :
        <>
          <DialogContent dividers>
            <ProtectDataPillars />

            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography variant="h6">Read-only Access</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{readOnlyDesc}</Typography>
              </Grid>
            </Grid>

            <FeaturePermissions
              className={classes.featurePermissions}
              features={features}
              state={featureState}
              onChange={setFeatureState}
              focused={focusedFeature}
              onClearFocus={() => setFocusedFeature(null)}
            />
          </DialogContent>
          <DialogActions>
            {connectedAlert}
            {error && <NormalAlert severity="error">{error}</NormalAlert>}
            {!connected && (
              <Button onClick={handleCancelClick} color="primary">
                Cancel
              </Button>
            )}
            {!connected && submitButton}
          </DialogActions>
        </>
      }

    </Dialog>
  );
};

const ChooseAuthOptionsForm: React.FC<{
  authOptions: AuthContinueType,
  setAuthOptions: (data: AuthContinueType) => void,
  handleCancelClick: () => void,
}> = ({ authOptions, setAuthOptions, handleCancelClick }) => {
  const [value, setValue] = React.useState(Object.values(authOptions.auth_data.sites!)[0].url);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const onSubmit = () => {
    setAuthOptions({ ...authOptions, account: value });
  };

  return <>

    <DialogContent dividers>

      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Typography variant="h6">Authorization Options</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            This application has authorized multiple sites for use with Xoba. { }
            To continue, select which site should be used for this specific integration.
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup aria-labelledby="auth-choose-auth-options-radio-group" value={value} onChange={handleChange}>
              {Object.values(authOptions.auth_data.sites!).map(option =>
                <FormControlLabel key={option.url} value={option.url} control={<Radio />} label={option.name} />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

    </DialogContent>

    <DialogActions>
      <Button color="primary" onClick={handleCancelClick}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={onSubmit}>
        Choose
      </Button>
    </DialogActions>

  </>;
};

export default ApplicationOauthDialogLayout;
