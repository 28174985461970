import React, { useCallback, useState } from "react";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";

import Alert from "@material-ui/lab/Alert";
import { Grid } from "@material-ui/core";

import { useFirebase } from "components/Firebase";
import UserFacingError from "utils/UserFacingError";
import { logGoogleSignUp } from "analytics";

declare var process: {
  env: {
    REACT_APP_GOOGLE_CLIENT_ID: string;
  };
};

type GoogleAuthProps = {
  signup?: boolean;
};

const GoogleAuth = ({ signup }: GoogleAuthProps) => {
  const firebase = useFirebase();

  const [error, setError] = useState<string | null>(null);
  let googleSignInAlert;
  if (error != null) {
    googleSignInAlert = (
      <Grid item>
        <Alert severity="error">{error}</Alert>
      </Grid>
    );
  }

  const handleSuccess = useCallback(
    async (googleUser: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ("code" in googleUser) {
        // filters out GoogleLoginResponseOffline
        throw new Error("GoogleLoginResponseOffline not allowed");
      } else {
        try {
          if (signup) {
            try {
              await firebase.doGoogleSignUp(googleUser);
              logGoogleSignUp({ status: "success" });
            } catch (error) {
              logGoogleSignUp({ status: "failed", error: error.message });
              throw new UserFacingError(error.message);
            }
          } else {
            await firebase.doGoogleSignIn(googleUser);
          }
        } catch (error) {
          if (error instanceof UserFacingError) {
            setError(error.message);
          } else {
            throw error;
          }
        }
      }
    },
    [firebase, signup]
  );

  const handleFailure = useCallback((response: any) => {
    switch (response.error) {
      case "popup_closed_by_user":
      case "cancelled_popup_request":
        break; // Do nothing
      default:
        setError(response.error);
        break;
    }
  }, []);

  // TODO: require the legal checkbox when signing up
  return (
    <Grid container spacing={3} direction="column" alignItems="center">
      <Grid item>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={signup ? "Sign up with Google" : "Sign in with Google"}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          cookiePolicy="single_host_origin"
        />
      </Grid>
      {googleSignInAlert}
    </Grid>
  );
};

export default GoogleAuth;
