import React from "react";
import clsx from "clsx";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { STATUS, FINISHED, RUNNING } from "hooks/useLoadingStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  spinner: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

interface LoadingButtonProps extends ButtonProps {
  status: STATUS;
  error?: React.ReactNode;
}

const LoadingButton = ({ status, size, error, ...otherProps }: LoadingButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const loading = status === RUNNING;
  const success = status === FINISHED && !error;

  const buttonClassname = clsx(success && classes.buttonSuccess);

  // Taken from fontSize of material-ui button
  let spinnerPx = 14;
  if (size === "small") {
    spinnerPx = 13;
  } else if (size === "large") {
    spinnerPx = 15;
  }

  const spinnerSize = theme.typography.pxToRem(spinnerPx);
  const marginOffset = theme.typography.pxToRem(-spinnerPx / 2);
  const spinnerStyle = {
    marginTop: marginOffset,
    marginLeft: marginOffset,
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button className={buttonClassname} size={size} {...otherProps} />
        {loading && (
          <CircularProgress style={spinnerStyle} size={spinnerSize} className={classes.spinner} />
        )}
      </div>
    </div>
  );
};

export default LoadingButton;
