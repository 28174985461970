import { useCallback, useReducer } from "react";
import {
  getLayoutItem,
  cloneLayout,
  Layout,
  compact,
  getAllCollisions,
  CompactType,
  LayoutItem,
} from "../utils";

// type ResizeState = {
//   layout: Layout,
//   resizing: boolean;
//   placeholder: any;
// };

// type ResizeStartAction = {
//   type: "start",
//   i: string;
//   initialLayout: Layout;
// };

// type ResizeStartContinue = {
//   type: "continue",
//   i: string;
//   w: number;
//   h: number;
//   initialLayout: Layout;
//   cols: number;
//   compactType?: CompactType;
//   preventCollision: boolean;
// };

// type ResizeStartStop = {
//   type: "stop",
//   i: string;
//   initialLayout: Layout;
//   cols: number;
//   compactType?: CompactType;
// };

// type ResizeAction = ResizeStartAction | ResizeStartContinue | ResizeStartStop;

function reducer(state: any, action: any) {
  let l: LayoutItem | null | undefined;

  switch (action.type) {
    case "start":
      // l = getLayoutItem(action.initialLayout, action.i);
      // if (!l) return;

      // this.setState({
      //   oldResizeItem: cloneLayoutItem(l),
      //   oldLayout: this.state.layout,
      // });
      return {
        ...state,
        resizing: true,
        layout: cloneLayout(action.initialLayout),
        placeholder: null,
      };
    case "stop":
      l = getLayoutItem(state.layout, action.i);

      const newLayout = compact(state.layout, action.compactType, action.cols);
      // TODO: trigger change and clear out state.layout

      return {
        ...state,
        layout: newLayout,
        resizing: false,
        placeholder: null,
      };
    case "continue":
      // console.log("continue", action.w, action.h)
      l = getLayoutItem(state.layout, action.i);
      if (!l) return;

      // Something like quad tree should be used
      // to find collisions faster
      let hasCollisions;
      if (action.preventCollision) {
        const collisions = getAllCollisions(state.layout, {
          ...l,
          w: action.w,
          h: action.h,
        }).filter(
          // @ts-ignore
          (layoutItem) => layoutItem.i !== l.i
        );
        hasCollisions = collisions.length > 0;

        // If we're colliding, we need adjust the placeholder.
        if (hasCollisions) {
          // adjust w && h to maximum allowed space
          let leastX = Infinity,
            leastY = Infinity;
          collisions.forEach((layoutItem) => {
            // @ts-ignore
            if (layoutItem.x > l.x) leastX = Math.min(leastX, layoutItem.x);
            // @ts-ignore
            if (layoutItem.y > l.y) leastY = Math.min(leastY, layoutItem.y);
          });

          if (Number.isFinite(leastX)) l.w = leastX - l.x;
          if (Number.isFinite(leastY)) l.h = leastY - l.y;
        }
      }

      if (!hasCollisions) {
        // Set new width and height.
        l.w = action.w;
        l.h = action.h;
      }

      let newPlaceholder;
      if (!state.placeholder || state.placeholder.w !== l.w || state.placeholder.h !== l.h) {
        newPlaceholder = {
          w: l.w,
          h: l.h,
          x: l.x,
          y: l.y,
          static: true,
          i: action.i,
        };
      }

      if (newPlaceholder) {
        return {
          ...state,
          layout: compact(state.layout, action.compactType, action.cols),
          placeholder: newPlaceholder,
        };
      } else {
        return state;
      }
    default:
      throw new Error();
  }
}

type useResizableLayoutType = {
  initialLayout: Layout;
  cols: number;
  compactType?: CompactType;
  preventCollision: boolean;
  // onChange: any;
};

const useResizableLayout = ({
  initialLayout,
  cols,
  preventCollision,
  compactType,
}: useResizableLayoutType) => {
  const [state, dispatch] = useReducer(reducer, {
    layout: null,
    resizing: false,
    placeholder: null,
  });

  const start = useCallback(
    (i: string, w: number, h: number) => {
      dispatch({
        type: "start",
        initialLayout,
        i,
      });
    },
    [initialLayout]
  );

  const update = useCallback(
    (i: string, w: number, h: number) => {
      dispatch({
        type: "continue",
        i,
        initialLayout,
        cols,
        preventCollision,
        compactType,
        w,
        h,
      });
    },
    [initialLayout, cols, preventCollision, compactType]
  );

  const stop = useCallback(
    (i: string, w: number, h: number) => {
      dispatch({
        type: "stop",
        i,
        cols,
        compactType,
      });
    },
    [cols, compactType]
  );

  return {
    layout: state.layout || initialLayout,
    placeholder: state.placeholder,
    start,
    update,
    stop,
  };
};

export default useResizableLayout;
