import React, { useCallback, ReactElement } from "react";
import clsx from "clsx";
import { useHistory, useRouteMatch } from "react-router-dom";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";

import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import AppsIcon from "@material-ui/icons/Apps";
import HomeIcon from "@material-ui/icons/Home";
import ExtensionIcon from "@material-ui/icons/Extension";

import * as ROUTES from "constants/routes";
import ExtensionsMenu from "./ExtensionsMenu";
import { HAS_FAVORITES, HAS_HISTORY } from "constants/features";

const useStyles = makeStyles((theme) => ({
  sideBarLinkActive: {
    backgroundColor: theme.palette.action.selected,
    borderLeft: "2px solid #4741CC",
  },
}));

type SideBarLinkProps = {
  to: string;
  label: string;
  icon: ReactElement;
  onClick?: () => void;
};

const SideBarLink = ({ to, icon, label, onClick }: SideBarLinkProps) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(to);
    if (onClick) {
      onClick();
    }
  }, [history, to, onClick]);

  const match = useRouteMatch({
    path: to,
  });

  return (
    <ListItem button onClick={handleClick} className={clsx(match && classes.sideBarLinkActive)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

const BrowserExtensionsMenu = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "browserExtensionsMenu" });

  return (
    <React.Fragment>
      <ListItem button {...bindTrigger(popupState)}>
        <ListItemIcon>
          <ExtensionIcon color="primary" className="Navigation__Extension__Button" />
        </ListItemIcon>
        <ListItemText primary="Extensions" />
      </ListItem>

      <ExtensionsMenu {...bindMenu(popupState)} />
    </React.Fragment>
  );
};

type SideBarLinksProps = {
  onClick?: () => void;
};

const SideBarLinks = ({ onClick }: SideBarLinksProps) => {
  return (
    <React.Fragment>
      <List>
        <SideBarLink
          to={ROUTES.HOME}
          label="Home"
          icon={<HomeIcon color="primary" className="Navigation__Home__Button" />}
          onClick={onClick}
        />
        {HAS_FAVORITES && (
          <SideBarLink
            to={ROUTES.FAVORITES}
            label="Favorites"
            icon={<FavoriteIcon color="primary" />}
            onClick={onClick}
          />
        )}
        {HAS_HISTORY && (
          <SideBarLink
            to={ROUTES.HISTORY}
            label="History"
            icon={<HistoryIcon color="primary" />}
            onClick={onClick}
          />
        )}
        <SideBarLink
          to={ROUTES.APPLICATIONS}
          label="Applications"
          icon={<AppsIcon color="primary" className="Navigation__Applications__Button" />}
          onClick={onClick}
        />
      </List>

      <Divider light={true} />

      <BrowserExtensionsMenu />
    </React.Fragment>
  );
};

export default SideBarLinks;
