import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import { ICONS } from "constants/platforms";

type ApplicationIconProps = SvgIconProps & {
  name: string;
};

const ApplicationIcon: React.FC<ApplicationIconProps> = ({ name, ...otherProps }) => {
  const icon = ICONS[name.toUpperCase()];

  if (!icon) {
    // TODO: Log this as an error
    return null;
  }
  const { height, width, src } = icon;

  return (
    <SvgIcon viewBox={`0 0 ${width} ${height}`} {...otherProps}>
      <image width={width} height={height} href={src} />
    </SvgIcon>
  );
};

export default ApplicationIcon;
