import ndjsonParser from "ndjson-parse";
import NdJsonState from "./state";

// See https://stackoverflow.com/questions/49828310/read-chunked-binary-response-with-fetch-api.
const utf8decoder = new TextDecoder();

const ndJsonStream = (reader: ReadableStreamDefaultReader) => {
  return new ReadableStream({
    async start(controller) {
      const state = new NdJsonState();

      let read;
      while (!!(read = await reader.read())) {
        let { done, value } = read;
        // When no more data needs to be consumed, close the stream
        // TODO: do we need to handle overflow? I think value would be empty in which case it would have already cleared.
        if (done) {
          controller.close();
          return;
        }

        const decoded = utf8decoder.decode(value);
        const rows = state.addChunk(decoded);

        if (rows) {
          // Enqueue the next data chunk into our target stream
          try {
            ndjsonParser(rows).forEach((row: any) => {
              controller.enqueue(row);
            });
          } catch (error) {
            if (window.Sentry) {
              window.Sentry.captureException(error);
            }
          }
        }
      }
    },
  });
};

export const decodeResponse = (response: Response) => {
  if (!response || !response.ok) {
    return null;
  }

  // NOTE: TextDecoderStream doesn't work in Firefox.
  const reader = response.body?.getReader();

  if (!reader) {
    return null;
  }

  return ndJsonStream(reader);
};
