import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAuthState } from "hooks/firebase/auth";
import { User } from "@firebase/auth-types";

import { identify } from "analytics";
import { useFirebase } from "../Firebase";

export const AuthUserContext = React.createContext<[User | undefined, boolean, boolean]>([
  undefined,
  true,
  false,
]);

// Provider that gives children components access to the Firebase-authenticated user
// in its state.
export const AuthProvider: React.FC = ({ children }) => {
  const firebase = useFirebase();
  const [user, loading] = useAuthState(firebase.auth);
  const [premiumUser, setPremiumUser] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && user) {
      identify(user);

      const checkUserSubscription = async () => {
        firebase.firestore
          .collection("customers")
          .doc(user.uid)
          .collection("subscriptions")
          .where("status", "in", ["trialing", "active"])
          .onSnapshot(async (snapshot) => {
            // empty means No active or trialing subscription
            snapshot.empty ? setPremiumUser(false) : setPremiumUser(true);
          });
      };
      checkUserSubscription();
    }
  }, [loading, user, firebase]);

  const value: [User | undefined, boolean, boolean] = useMemo(() => {
    return [user, loading, premiumUser];
  }, [user, loading, premiumUser]);

  return <AuthUserContext.Provider value={value}>{children}</AuthUserContext.Provider>;
};

export const useCurrentUser = () => {
  return useContext(AuthUserContext);
};
