import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";

import { CardType } from "types";

const useStyles = makeStyles((theme) => ({
  notChoseButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    marginRight: "15px",
  },
  chosenButton: {
    border: "#3B35A6 solid 3px",
    backgroundColor: "rgb(53,104,166,0.2)",
    marginRight: "15px",
  },
  subtitleType: {
    marginBottom: "10px",
  },
}));

const VARIANT_OPTIONS: { [_: string]: string } = {
  top: "Top searches",
  recent: "Recent searches",
};

const OPTION_VARIANT_TYPE = {
  TOP: "top",
  RECENT: "recent",
};

interface Props {
  onChange: () => void;
  handleOptionButtons: (value: any) => void;
  value: any;
}

const SelectVariant: React.FC<Props> = ({ handleOptionButtons, ...props }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="subtitle2" className={classes.subtitleType}>
        Type
      </Typography>
      <Button
        variant="outlined"
        className={
          OPTION_VARIANT_TYPE.TOP === props.value ? classes.chosenButton : classes.notChoseButton
        }
        onClick={() => {
          handleOptionButtons(OPTION_VARIANT_TYPE.TOP);
        }}
      >
        {VARIANT_OPTIONS["top"]}
      </Button>
      <Button
        variant="outlined"
        className={
          OPTION_VARIANT_TYPE.RECENT === props.value ? classes.chosenButton : classes.notChoseButton
        }
        onClick={() => {
          handleOptionButtons(OPTION_VARIANT_TYPE.RECENT);
        }}
      >
        {VARIANT_OPTIONS["recent"]}
      </Button>
    </React.Fragment>
  );
};

type ConfigurationFieldProps = {
  card: CardType;
};

const ConfigurationField: React.FC<ConfigurationFieldProps> = ({ card }) => {
  const methods = useFormContext();
  // TODO: render errors

  const handleOptionButtons = (value: any) => {
    methods.setValue("variant", value);
  };

  return (
    <Grid item>
      <Controller
        name="variant"
        render={({ onChange, value }) => (
          <SelectVariant
            onChange={onChange}
            value={value}
            handleOptionButtons={handleOptionButtons}
          />
        )}
        control={methods.control}
        defaultValue={(card && card.variant) || "top"}
      />
    </Grid>
  );
};

export default ConfigurationField;
