import React from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import AppErrors from "components/AppErrors";
import Footer from "components/Footer";
import Navigation from "./Navigation";
import { useCurrentUser } from "components/Session";
import { useLocation } from "react-router-dom";
import { SIGN_UP } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  content: {
    flex: "1 0 auto",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  contentSignUp: {
    flex: "1 0 auto",
    padding: 0,
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const Skeleton: React.FC = ({ children }) => {
  const classes = useStyles();
  const [user] = useCurrentUser();
  const location = useLocation();

  return (
    <div className={classes.root}>
      {location.pathname === SIGN_UP ? null : <Navigation />}
      <main className={classes.main}>
        {location.pathname === SIGN_UP ? null : <div className={classes.toolbar} />}
        <Container
          maxWidth={location.pathname === SIGN_UP ? false : "lg"}
          className={location.pathname === SIGN_UP ? classes.contentSignUp : classes.content}
        >
          {user && <AppErrors />}
          {children}
        </Container>
        {location.pathname === SIGN_UP ? null : <Footer />}
      </main>
    </div>
  );
};

export default Skeleton;
