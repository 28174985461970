import React, { useEffect, useRef } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { makeStyles } from "@material-ui/core/styles";

import SearchResult, { supportsPlatform } from "./SearchResult";
import { NormalAlert } from "components/Alert";
import { SearchResultType } from "types";
import CardPickerDialog from "./SearchResult/AddToCardButton/CardPickerDialog";

const useStyles = makeStyles((theme) => {
  return {
    cancelFlexWrap: {
      // for some reason width breakpoints were invisibly wrapping and causing separation between results
      flexWrap: "nowrap",
    },
  };
});

type ScrollLoaderProps = {
  loading: boolean;
  onNext: () => void;
  hasMore: boolean;
  hasResults: boolean;
};

const ScrollLoader: React.FC<ScrollLoaderProps> = ({ loading, onNext, hasMore, hasResults }) => {
  const [infiniteRef, inView] = useInView();
  const inViewRef = useRef(true);

  useEffect(() => {
    if (!loading) {
      if (inView !== inViewRef.current) {
        inViewRef.current = inView;
        if (inView) {
          onNext();
        }
      }
    }
  }, [inView, onNext, loading]);

  let content;
  let ref;
  if (loading) {
    content = <CircularProgress size={50} />;
  } else if (hasResults) {
    if (hasMore) {
      ref = infiniteRef;
      content = <CircularProgress size={50} />;
    } else {
      content = (
        <Grid item>
          <NormalAlert severity="info">No more results</NormalAlert>
        </Grid>
      );
    }
  }

  if (content) {
    return (
      <Grid item ref={ref}>
        <Grid container direction="column" alignItems="center">
          <Grid item>{content}</Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

type SearchResultItemProps = {
  index: number;
  result: SearchResultType;
};

const SearchResultItem = React.memo<SearchResultItemProps>(({ index, result }) => {
  return (
    <Grid item>
      <SearchResult result={result} index={index} />
    </Grid>
  );
});

type SearchResultsListProps = {
  results: SearchResultType[];
  loading: boolean;
  onNext: () => void;
  hasMore: boolean;
  error: { message: string } | null;
};

const SearchResultsList: React.FC<SearchResultsListProps> = ({
  results,
  loading,
  onNext,
  hasMore,
  error,
}) => {
  const classes = useStyles();
  const searchResults = results.map((result, i) => {
    if (supportsPlatform(result.platform)) {
      return <SearchResultItem key={result.handle} index={i} result={result} />;
    }
    return null;
  });

  let errorMessage;
  if (error != null) {
    errorMessage = (
      <Grid item>
        <NormalAlert severity="warning">{error.message}</NormalAlert>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} direction="column" className={classes.cancelFlexWrap}>
      <CardPickerDialog>{searchResults}</CardPickerDialog>

      {errorMessage}
      <ScrollLoader
        loading={loading}
        onNext={onNext}
        hasMore={hasMore}
        hasResults={results.length > 0}
      />
    </Grid>
  );
};

export default SearchResultsList;
