import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import { avatarSize } from "../../utils";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import boxFolderIcon from "images/box_icons/folder_icon.png";
import { BoxSearchResultType } from "types";
import { Box } from "@material-ui/core";
import PathGenerator from "../PathGenerator";

const BoxSearchResult: React.FC<{ result: BoxSearchResultType; index: number }> = ({
  result,
  index,
}) => {
  const header = result.title;

  const subheader = (
    <Box display="flex" flexDirection="column">
      {result.path ? <PathGenerator path={result.path} /> : null}
      <span>{`Owned by ${result.owner}`}</span>
    </Box>
  );

  var avatar = null;
  if (result.thumbnail != null)
    // Thumbnail is stored as base64-encoded raw PNG bytes.
    avatar = (
      <img
        alt="File thumbnail"
        width={avatarSize}
        height={avatarSize}
        src={`data:image/png;base64,${result.thumbnail}`}
      />
    );
  else if (result.item_type === "folder")
    avatar = (
      <SvgIcon viewBox={`0 0 ${avatarSize} ${avatarSize}`}>
        <image width={avatarSize} height={avatarSize} href={boxFolderIcon} />
      </SvgIcon>
    );

  const body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
    />
  );
};

export default BoxSearchResult;
