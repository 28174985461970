import React, { useCallback } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";

import { RUNNING, FINISHED } from "hooks/useLoadingStatus";
import { UserApplicationType } from "types";
import PLATFORMS from "constants/platforms";
import { useRemoveApplication } from "actions/removeApplication";
import { useDialogManager, DialogRenderProps } from "components/DialogManager";
import { DisappearingAlert } from "components/Alert";
import LoadingButton from "components/LoadingButton";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    opacity: 0.5,
  },
}));

type DisconnectApplicationButtonProps = {
  application: UserApplicationType;
};

type DisconnectApplicationDialogProps = DisconnectApplicationButtonProps & DialogRenderProps;

const DisconnectApplicationDialog: React.FC<DisconnectApplicationDialogProps> = ({
  application,
  open,
  setOpen,
}) => {
  const { status, run: removeApplication } = useRemoveApplication(open);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleDisconnect = useCallback(async () => {
    await removeApplication(application);
  }, [application, removeApplication]);

  let statusAlert;
  if (status === FINISHED) {
    statusAlert = (
      <DisappearingAlert severity="success" delay={2000} afterDelay={handleClose}>
        Disconnected
      </DisappearingAlert>
    );
  }

  const instance = PLATFORMS.get(application.platform);

  if (!instance) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Are you sure you want to disconnect ${instance.label}?`}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          Xoba will remove all information associated to this application. You can always re-connect
          this application in the future.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {statusAlert}
        {(status === 'pristine' || status === 'running') &&
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleDisconnect}
              status={status}
              disabled={status === RUNNING}
              variant="contained"
              color="primary"
            >
              Disconnect
            </LoadingButton>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

const DisconnectApplicationButton = ({ application }: DisconnectApplicationButtonProps) => {
  const classes = useStyles();
  const { setOpen, setDialog } = useDialogManager();

  const renderDialog = useCallback(
    ({ open }) => {
      return (
        <DisconnectApplicationDialog application={application} open={open} setOpen={setOpen} />
      );
    },
    [application, setOpen]
  );

  const handleClick = useCallback(() => {
    setOpen(true);
    setDialog(renderDialog);
  }, [setOpen, setDialog, renderDialog]);

  return (
    <Tooltip title="Disconnect application">
      <IconButton size="small" onClick={handleClick}>
        <DeleteIcon fontSize="inherit" className={classes.deleteIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default DisconnectApplicationButton;
