import React, { useState, Dispatch, SetStateAction, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { CardTypeEnum } from "types";
import firebase from "firebase/app";
import { useFirebase } from "components/Firebase";
import { logCardChange, CardChangeOperator } from "analytics";
import { useCurrentUser } from "components/Session";
import { CardType } from "types";
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from "@material-ui/core/styles";

const lightGrey = grey[400];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 30,
  },
  card: {
    minWidth: 425,
    boxShadow: "none",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  subHeader: {
    width: 240,
    fontSize: "12px",
    lineHeight: "14px",
    color: `${lightGrey}`,
  },
  inputTitle: {
    fontSize: "1.1rem",
  },
  content: {
    overflowY: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
  },
  itemsList: {
    overflowY: "scroll",
  },
  waitingMessage: {
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
}));

interface AddToCardProps {
  setNewCard: Dispatch<SetStateAction<boolean>>;
  setCreatedCard: Dispatch<SetStateAction<any>>;
}

const AddNewCard: React.FC<AddToCardProps> = ({ setNewCard, setCreatedCard }) => {
  const classes = useStyles();
  const [user] = useCurrentUser();

  const NewCardTitle = "Create a new Card";
  const NewCardSubHeader = (
    <Typography className={classes.subHeader}>
      {" "}
      To create a new card type in your desired name. The Card will be added yo your Xoba homepage
    </Typography>
  );

  const [cardName, setCardName] = useState("");
  const [savingData, setSavingData] = useState(false);

  const handleCardName = (event: React.ChangeEvent<any>) => {
    setCardName(event.target.value);
  };

  const firebaseStore = useFirebase();

  const docRef = firebaseStore.firestore
    .collection("users")
    .doc(user?.uid)
    .collection("spaces")
    .doc("HOME");
  const cardsRef = docRef.collection("cards");

  const handleSave = useCallback(async () => {
    setSavingData(true);
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    let title = cardName;

    const newCard: CardType = {
      title: title,
      type: CardTypeEnum.Items,
      updatedAt: now,
      createdAt: now,
    };

    setCreatedCard(newCard);
    // use a JSON stringify + parse trick to remove undefined values
    const doc = JSON.parse(JSON.stringify(newCard));

    logCardChange({
      operation: CardChangeOperator.Create,
      cardType: CardTypeEnum.Items,
    });

    await cardsRef.add(doc);
    setNewCard(false);
    setSavingData(false);
  }, [cardsRef, cardName, setNewCard, setCreatedCard]);

  const handleSubmit = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    handleSave();
  };

  const NewCardContent = (
    <Box marginY="75px">
      <TextField
        placeholder="Card Name"
        fullWidth={true}
        InputProps={{ className: classes.inputTitle }}
        id="standard-basic"
        value={cardName}
        onChange={handleCardName}
        helperText="Enter the name for your new Card"
      />
    </Box>
  );

  const NewCardButtons = (
    <>
      <Button
        color="primary"
        onClick={() => {
          setNewCard(false);
        }}
      >
        CANCEL
      </Button>

      <Button color="secondary" disabled={!cardName || savingData} onClick={handleSave}>
        ADD
      </Button>
    </>
  );

  const WaitingLoading = () => (
    <Box>
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
      <Typography className={classes.waitingMessage}> Saving Card </Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={NewCardTitle}
          subheader={NewCardSubHeader}
          titleTypographyProps={{ variant: "h6" }}
          className={classes.header}
        />
        <form onSubmit={handleSubmit}>
          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="column" justifyContent="flex-end">
              {!savingData ? NewCardContent : WaitingLoading()}
            </Box>
          </CardContent>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginY="15px"
            width="35%"
            marginLeft="65%"
          >
            {NewCardButtons}
          </Box>
        </form>
      </Card>
    </Box>
  );
};

export default AddNewCard;
