import React, { useCallback } from "react";
import useClipboard from "react-use-clipboard";

import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => {
  return {
    copyIcon: {
      "& path": {
        transition: theme.transitions.create(["color"], {
          duration: theme.transitions.duration.short,
        }),
        color: fade(theme.palette.text.primary, theme.palette.action.focusOpacity),
      },
      "&:hover path": {
        // This matches the hover color from Material UI's Button component
        color: theme.palette.text.primary,
      },
    },
  };
});

type CopyButtonProps = {
  text: string;
  onClick?: (_: React.MouseEvent<HTMLButtonElement>) => void;
};

const CopyButton: React.FC<CopyButtonProps> = ({ text, onClick }) => {
  const classes = useStyles();
  const [isCopied, setCopied] = useClipboard(text, {
    successDuration: 1000,
  });

  const handleCopyClick = useCallback(
    (e) => {
      setCopied();
      if (onClick) {
        onClick(e);
      }
    },
    [setCopied, onClick]
  );

  return (
    <Tooltip title={isCopied ? "Link copied" : "Copy link"}>
      <IconButton onClick={handleCopyClick} size="small" className={classes.copyIcon}>
        <FileCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
