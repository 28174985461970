import React from "react";

// TODO: figure out how to make this more strict
type ErrorBoundaryProps = {
  fallback: any;
};

type ErrorBoundaryState = {
  hasError: boolean;
  eventId: string | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (window.Sentry) {
      window.Sentry.withScope((scope: any) => {
        scope.setExtras(errorInfo);
        const eventId = window.Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      const Fallback = this.props.fallback;

      return <Fallback eventId={this.state.eventId} />;
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
