import React from "react";
import { Grid, Typography, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import encryptedIcon from "images/noun_project_icons/encrypted.svg";
import removeCloud from "images/noun_project_icons/remove_cloud.svg";
import restrictedAccess from "images/noun_project_icons/restricted_access.svg";

const Pillars = [
  {
    icon: encryptedIcon,
    height: 100,
    width: 100,
    title: "Encrypted",
    details: "Your data is encrypted in transit and at rest using industry-standard methods.",
  },
  {
    icon: restrictedAccess,
    height: 100,
    width: 100,
    title: "Limited access",
    details:
      "Xoba does not store your application files or data on our servers. We only access your relevant data when you run a search.",
  },
  {
    icon: removeCloud,
    height: 100,
    width: 100,
    title: "Remove anytime",
    details:
      "Removing an application from Xoba immediately removes any associated data and access to that data.",
  },
];

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "50px",
    width: "50px",
  },
}));

type ProtectDataPillarProps = {
  icon: any;
  height: number;
  width: number;
  title: string;
  details: string;
};

const ProtectDataPillar: React.FC<ProtectDataPillarProps> = ({
  icon,
  height,
  width,
  title,
  details,
}) => {
  const classes = useStyles();

  const avatar = (
    <SvgIcon viewBox={`0 0 ${width} ${height}`} className={classes.avatar}>
      <image href={icon} />
    </SvgIcon>
  );

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>{avatar}</Grid>
      <Grid item>
        <Typography variant="subtitle1" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="center">
          {details}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ProtectDataPillars = () => {
  const dataProtection = Pillars.map((pillar) => {
    return (
      <Grid item key={pillar.title} xs={6} sm={4}>
        <ProtectDataPillar {...pillar} />
      </Grid>
    );
  });

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h6">How we protect your data</Typography>
      </Grid>

      <Grid item>
        <Grid container direction="row" spacing={3} justify="center">
          {dataProtection}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProtectDataPillars;
