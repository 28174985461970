import React, { useCallback } from "react";
import clsx from "clsx";
import {
  Button,
  ListItemAvatar,
  ListItemText,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  FormControl,
  FormLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, useFieldArray } from "react-hook-form";

import { CardItemType, CardType } from "types";
import { errorToHelperText } from "utils/formHelpers";
import { generateItemId } from "utils";

const useStyles = makeStyles((theme) => ({
  dragHandle: {
    cursor: "move",
  },
  buttonAddItem: {
    marginLeft: "-24px",
  },
}));

type FieldItemProps = {
  provided?: any;
  register: any;
  index: number;
  item: CardItemType;
  onDeleteClick: undefined | ((_: number) => void);
  error: any;
};

const FieldItem: React.FC<FieldItemProps> = ({
  provided,
  register,
  index,
  item,
  onDeleteClick,
  error,
}) => {
  const classes = useStyles();
  const notEmpty = item.label !== "" || item.url !== "";

  return (
    <ListItem
      innerRef={provided && provided.innerRef}
      {...((provided && provided.draggableProps) || {})}
    >
      <ListItemAvatar>
        <IconButton
          className={clsx(notEmpty && classes.dragHandle)}
          {...((provided && provided.dragHandleProps) || {})}
        >
          {notEmpty && <DragHandleIcon fontSize="inherit" />}
        </IconButton>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <TextField
                margin="dense"
                label="Name"
                type="text"
                name={`items[${index}].label`}
                defaultValue={item.label}
                helperText={errorToHelperText(`items[${index}].label`, error?.label)}
                error={!!error?.label}
                inputRef={register()}
              />
            </Grid>

            <Grid item>
              <TextField
                margin="dense"
                label="URL"
                type="text"
                name={`items[${index}].url`}
                defaultValue={item.url}
                helperText={errorToHelperText(`items[${index}].url`, error?.url)}
                error={!!error?.url}
                inputRef={register()}
              />
            </Grid>
            <input
              type="hidden"
              name={`items[${index}].id`}
              ref={register()}
              defaultValue={item.id}
            />
            <input
              type="hidden"
              name={`items[${index}].platform`}
              ref={register()}
              defaultValue={item.platform}
            />
            <input
              type="hidden"
              name={`items[${index}].mimeType`}
              ref={register()}
              defaultValue={item.mimeType}
            />
          </Grid>
        }
      />

      <ListItemSecondaryAction>
        {onDeleteClick && (
          <Tooltip title="Remove item">
            <IconButton
              size="small"
              edge="end"
              aria-label="delete"
              onClick={() => onDeleteClick(index)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type FieldListProps = {
  provided: any;
  errors: any;
  items: any[];
  onAdd: () => void;
  onDelete: (_: number) => void;
};

const FieldList: React.FC<FieldListProps> = ({ provided, items, errors, onAdd, onDelete }) => {
  const { register } = useFormContext();
  const numItems = items.length;

  const handleAddClick = useCallback(() => {
    onAdd();
  }, [onAdd]);

  const listItems = items.map((item, i) => {
    return (
      <Draggable key={item.id} draggableId={item.id || ""} index={i}>
        {(draggableProvided) => {
          return (
            <FieldItem
              index={i}
              register={register}
              item={item as CardItemType}
              error={errors[i]}
              provided={draggableProvided}
              onDeleteClick={i !== numItems ? onDelete : undefined}
            />
          );
        }}
      </Draggable>
    );
  });

  const classes = useStyles();

  return (
    <List innerRef={provided.innerRef} {...provided.droppableProps}>
      {listItems}
      {provided.placeholder}
      <ListItem>
        <ListItemText
          primary={
            <Button color="primary" className={classes.buttonAddItem} onClick={handleAddClick}>
              Add item
            </Button>
          }
        />
      </ListItem>
    </List>
  );
};

type CardItemFieldProps = {
  value?: CardItemType[];
  onChange?: (_: CardItemType[]) => void;
};

const CardItemField: React.FC<CardItemFieldProps> = ({ value, onChange }) => {
  const { control, errors } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "items",
  });

  const handleDelete = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;

      if (!destination) {
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      move(source.index, destination.index);
    },
    [move]
  );

  const handleAdd = useCallback(() => {
    append({ id: generateItemId() });
  }, [append]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Items</FormLabel>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="cardItemField">
          {(provided) => (
            <FieldList
              provided={provided}
              items={fields}
              onAdd={handleAdd}
              onDelete={handleDelete}
              errors={errors.items || []}
            />
          )}
        </Droppable>
      </DragDropContext>
    </FormControl>
  );
};

type ConfigurationFieldProps = {
  card: CardType;
};

const ConfigurationField: React.FC<ConfigurationFieldProps> = () => {
  return (
    <Grid item>
      <CardItemField />
    </Grid>
  );
};

export default ConfigurationField;
