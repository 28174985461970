import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, CircularProgress } from "@material-ui/core";

import ConnectApplicationButton from "components/Application/ConnectApplicationButton";
import { UserApplicationType, UserApplicationsType } from "types";
import PlatformListItem from "../PlatformListItem";
import PLATFORMS from "constants/platforms";
import AccountList from "./AccountList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  accounts: {
    paddingLeft: 0,
  },
}));

type ConnectedApplicationItemProps = {
  platform: string;
  applications: UserApplicationType[];
  isLast: boolean;
};

const ConnectedApplicationItem = ({
  platform,
  applications,
  isLast,
}: ConnectedApplicationItemProps) => {
  const classes = useStyles();

  const secondary = (
    <div>
      <AccountList applications={applications} />
      <ConnectApplicationButton
        platform={platform}
        label="Connect another account"
        className={classes.accounts}
      />
    </div>
  );

  return (
    <PlatformListItem divider={!isLast} key={platform} platform={platform} secondary={secondary} />
  );
};

const groupAccountsByPlatform = (userApplications: UserApplicationsType) => {
  const grouped: { [platform: string]: UserApplicationType[] } = {};

  const supportedPlatforms = new Set(PLATFORMS.all());

  Object.keys(userApplications).forEach((id) => {
    const application = userApplications[id];
    const platform = application.platform;
    if (supportedPlatforms.has(platform)) {
      grouped[platform] = [...(grouped[platform] || []), { id, ...application }];
    }
  });

  return grouped;
};

type ConnectedApplicationsListProps = {
  applications: UserApplicationsType;
  loading: boolean;
};

const ConnectedApplicationsList = ({ applications, loading }: ConnectedApplicationsListProps) => {
  const classes = useStyles();

  const processedApplications = useMemo(() => {
    const byPlatform: { [platform: string]: UserApplicationType[] } = groupAccountsByPlatform(
      applications || {}
    );

    return {
      byPlatform,
      sorted: Object.keys(byPlatform).sort(),
    };
  }, [applications]);

  let content;
  if (loading) {
    content = (
      <ListItem>
        <CircularProgress size={25} />
      </ListItem>
    );
  } else if (Object.values(applications).length === 0) {
    content = (
      <ListItem>
        {
          "You do not have any connected applications. Connect your applications below to enable search and enhanced Xoba Cards."
        }
      </ListItem>
    );
  } else {
    const lastIndex = processedApplications.sorted.length - 1;

    content = processedApplications.sorted.map((platform, index) => (
      <ConnectedApplicationItem
        key={platform}
        platform={platform}
        applications={processedApplications.byPlatform[platform]}
        isLast={index === lastIndex}
      />
    ));
  }

  return (
    <div className={classes.root}>
      <List dense={false}>{content}</List>
    </div>
  );
};

export default ConnectedApplicationsList;
