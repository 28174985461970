import { useCallback } from "react";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { XOBA_CARDS } from "constants/userLimits";

export const useXobaCards = () => {
  const firebase = useFirebase();
  const [, , premiumUser] = useCurrentUser();

  const getXobaCards = useCallback(async () => {
    const cardsRef = firebase.firestore
      .collection("users")
      .doc(firebase.currentUser().uid)
      .collection("spaces")
      .doc("HOME")
      .collection("cards");
    const getXobaCards = await cardsRef.get();

    // Get number of cards
    const cardQty = getXobaCards.size;
    // Have we reached the total cards limit
    const isCardsLimitReached = cardQty >= XOBA_CARDS;
    // Possible to add new cards if Premium user, or if Free but limit is not reached
    const isNewCardPossible = premiumUser ? true : isCardsLimitReached ? false : true;

    return { cardQty, isCardsLimitReached, isNewCardPossible };
  }, [firebase, premiumUser]);

  return getXobaCards;
};
