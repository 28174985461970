import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "50px",
    width: "50px",
  },
}));

type FeatureIconProps = {
  icon: any;
  height: number;
  width: number;
};

const FeatureIcon: React.FC<FeatureIconProps> = ({ icon, height, width }) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox={`0 0 ${width} ${height}`} className={classes.avatar}>
      <image href={icon} />
    </SvgIcon>
  );
};

export default FeatureIcon;
