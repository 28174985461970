import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

type ExternalLinkProps = {
  href: string;
  noDecoration: boolean;
  children: React.ReactNode;
  onClick?: Function;
};

const useStyles = makeStyles((theme) => {
  return {
    noDecoration: {
      textDecoration: "none",
      color: "inherit",
    },
  };
});

export const openExternalLink = (event: React.MouseEvent, url: string, jsOpen = false) => {
  if (process.env.REACT_APP_IS_EXTENSION === "true") {
    // Clicking a link in the Extension's popup causes the popup to close
    if (event.metaKey) {
      // the user is holding Command or Control
      event.preventDefault();
      chrome.tabs.create({ url, active: false });
      return;
    }
  }

  if (jsOpen) {
    window.open(url, "externalLink", "noopener,noreferrer");
  }
};

const ExternalLink = React.forwardRef(
  ({ href, noDecoration, onClick, children }: ExternalLinkProps, ref: any) => {
    const classes = useStyles();
    const handleClick = useCallback(
      (...args) => {
        openExternalLink(args[0], href, false);

        if (onClick) {
          onClick(...args);
        }
      },
      [onClick, href]
    );

    return (
      <a
        href={href}
        ref={ref}
        className={clsx(noDecoration && classes.noDecoration)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }
);

export default ExternalLink;
