import React, { useCallback, useMemo } from "react";
import { List } from "@material-ui/core";
import { useCollectionData } from "hooks/firebase/firestore";

import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { UserSearchRecommendationType } from "types";
import useQueryParam from "hooks/useQueryParam";
import { metaInfo } from "components/Search/SearchBar/Recommendations/Item";
import CardLayout, { CardBaseProps } from "../../CardLayout";
import { CardTypeEnum } from "types";
import CardItemLayout from "../../CardItemLayout";

type ComparatorFunction = (
  a: UserSearchRecommendationType,
  b: UserSearchRecommendationType
) => -1 | 0 | 1;

const searchHistorySortByDate: ComparatorFunction = (a, b) => {
  if (a.lastSearchAt.seconds > b.lastSearchAt.seconds) {
    return -1;
  } else if (a.lastSearchAt.seconds < b.lastSearchAt.seconds) {
    return 1;
  } else {
    if (a.searchTerm < b.searchTerm) {
      return -1;
    } else {
      return 1;
    }
  }
};

const searchHistorySortByCount: ComparatorFunction = (a, b) => {
  if (a.searchCount > b.searchCount) {
    return -1;
  } else if (a.searchCount < b.searchCount) {
    return 1;
  } else {
    if (a.searchTerm < b.searchTerm) {
      return -1;
    } else {
      return 1;
    }
  }
};

type SearchTermItemProps = {
  search: UserSearchRecommendationType;
  disabled?: boolean;
};

const SearchTermItem: React.FC<SearchTermItemProps> = ({ search, disabled }) => {
  const [, setSearchParam] = useQueryParam("search", "");

  const handleClick = useCallback(() => {
    setSearchParam(search.searchTerm);
  }, [search.searchTerm, setSearchParam]);

  return (
    <CardItemLayout
      disabled={disabled}
      onClick={handleClick}
      primary={search.searchTerm}
      secondary={metaInfo(search)}
      cardType={CardTypeEnum.SearchHistory}
    />
  );
};

type SearchHistoryCardProps = CardBaseProps & {
  disabled?: boolean;
};

const SearchHistoryCard: React.FC<SearchHistoryCardProps> = ({ card, disabled, ...otherProps }) => {
  const variant = card.variant;

  const comparator = variant === "top" ? searchHistorySortByCount : searchHistorySortByDate;
  const limit = 10;

  const [user] = useCurrentUser();
  const firebase = useFirebase();
  const [userSearches, userSearchesLoading] = useCollectionData<UserSearchRecommendationType>(
    firebase.firestore.collection("user_searches").doc(user?.uid).collection("searches")
  );

  const sortedSearches = useMemo(() => {
    const res = (userSearches || []).sort(comparator).slice(0, limit);

    return res;
  }, [userSearches, limit, comparator]);

  const listItems = (sortedSearches || []).map((search) => {
    return <SearchTermItem key={search.searchTerm} search={search} disabled={disabled} />;
  });

  const title = variant === "top" ? "Top searches" : "Recent searches";

  return (
    <CardLayout
      card={card}
      title={title}
      disabled={disabled}
      loading={userSearchesLoading}
      {...otherProps}
    >
      <List dense>{listItems}</List>
    </CardLayout>
  );
};

export default SearchHistoryCard;
