import { useCallback } from "react";
import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { CONNECTED_APPS } from "constants/userLimits";

export const useConnectedApps = () => {
  const firebase = useFirebase();
  const [, , premiumUser] = useCurrentUser();

  const getConnectedApps = useCallback(async () => {
    const userApplications = await firebase.db
      .ref(`/user_metadata/${firebase.currentUser().uid}/userApplications`)
      .once("value");

    // By counting the children we know the number of user applications
    const appQty = userApplications.numChildren();
    // Have we reached the connected apps limit
    const isAppsLimitReached = appQty >= CONNECTED_APPS;
    // Possible to add new apps if Premium user, or if Free but limit is not reached
    const isNewAppPossible = premiumUser ? true : isAppsLimitReached ? false : true;

    return { appQty, isAppsLimitReached, isNewAppPossible };
  }, [firebase, premiumUser]);

  return getConnectedApps;
};
