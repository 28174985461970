import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import { useLogSearchInteraction } from "actions/logSearchInteraction";
import { displayDate } from "../../utils";
import SearchResultLayout, { ResultBody } from "../SearchResultLayout";
import Snippets from "../Snippets";
import { EmailSearchResultType, EmailMessageType, EmailParticipant } from "types";

const useStyles = makeStyles((theme) => ({
  gmail: {
    paddingTop: theme.spacing(2),
    position: "relative",
  },
  gmailMessagesDivider: {
    position: "relative",
    zIndex: 1,
    width: "99%",
    height: theme.spacing(2), // Half the height of gmailMessagesDivider
    borderBottom: "1px solid grey",
    opacity: "60%",
    "&:hover $gmailMessagesCircle": {
      backgroundColor: "#e9e9e9",
    },
    "&:hover $expandMessageCount": {
      display: "inline",
    },
    "&:hover $expandMessages": {
      display: "none",
    },
  },
  expandMessages: {
    // display default
  },
  expandMessageCount: {
    display: "none",
  },
  gmailMessagesCircle: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    border: "1px solid grey",
    fontSize: 13,
    color: "black",
    marginLeft: "20px",
  },
  gmailDefaultContent: {
    paddingTop: theme.spacing(2),
    position: "relative",
  },
}));

const formatEmailNames = (emails_and_names: string[][], num: number) => {
  // emails_and_names is an array (email, optional name) pairs.
  const name_or_email_list = emails_and_names.map(([email, name]) => name || email);

  if (name_or_email_list.length === 1) return name_or_email_list[0];

  const toDisplay = name_or_email_list.slice(0, num);
  var suffix = "";
  if (name_or_email_list.length > num) suffix = `and ${name_or_email_list.length - num} more`;
  return `${toDisplay.join(", ")} ${suffix}`;
};

type ThreadMessageProps = {
  message: EmailMessageType;
  platform: string;
  index: number;
};

const ThreadMessage: React.FC<ThreadMessageProps> = ({ message, platform, index }) => {
  const logSearchInteraction = useLogSearchInteraction();

  return (
    <ListItem
      divider
      alignItems="flex-start"
      onClick={(event) => {
        logSearchInteraction({
          interactionType: "click",
          position: index,
          platform,
        });
        window.open(message.handle, "_blank");
        event.stopPropagation();
      }}
    >
      <Grid container direction="column">
        <Grid item container justify="space-between">
          <Grid item>
            <ListItemText
              primary={message.sender[1] || message.sender[0]}
              secondary={
                //`To ${formatEmailNames(message.recipients, 4)}`
                `To ${formatEmailNames([...(message.recipients || []), ...(message.cc || [])], 4)}`
              }
              primaryTypographyProps={{ variant: "subtitle2" }}
              secondaryTypographyProps={{
                variant: "subtitle2",
                style: { fontSize: 12 },
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption">{displayDate(message.date)}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <CardContent>
            <Snippets snippets={message.content_snippets} fallback={message.fallback_snippet} />
          </CardContent>
        </Grid>
      </Grid>
    </ListItem>
  );
};

type ExpandableGmailThreadProps = {
  index: number;
  platform: string;
  messages: EmailMessageType[];
  defaultContent: React.ReactNode;
};

const ExpandableGmailThread: React.FC<ExpandableGmailThreadProps> = ({
  index,
  platform,
  messages,
  defaultContent,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const threadedMessages = messages.map((message, i) => {
    return <ThreadMessage key={i} message={message} platform={platform} index={index} />;
  });

  const logSearchInteraction = useLogSearchInteraction();

  return (
    <div className={classes.gmail}>
      <div
        className={classes.gmailMessagesDivider}
        onClick={(event) => {
          // Don't open link to email
          event.preventDefault();
          event.stopPropagation();

          const interactionType = expanded ? "collapseResult" : "expandResult";
          logSearchInteraction({ interactionType, position: index, platform });

          setExpanded(!expanded);
        }}
      >
        <Avatar className={classes.gmailMessagesCircle}>
          {expanded && <ExpandLessIcon />}
          {!expanded && (
            <React.Fragment>
              <ExpandMoreIcon className={classes.expandMessages} />
              <span className={classes.expandMessageCount}>{messages.length}</span>
            </React.Fragment>
          )}
        </Avatar>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List>{threadedMessages}</List>
      </Collapse>
      {!expanded && <div className={classes.gmailDefaultContent}>{defaultContent}</div>}
    </div>
  );
};

type EmailSearchResultProps = {
  result: EmailSearchResultType;
  index: number;
  icon: React.ReactNode;
};

const EmailSearchResult: React.FC<EmailSearchResultProps> = ({ result, index, icon }) => {
  var header;
  if (result.subject == null || result.subject.length === 0) header = "(no subject)";
  else header = result.subject;

  var body = <ResultBody content={result.content_snippets} fallback={result.fallback_snippet} />;

  // TODO(piyush) Make non-displayed participants clickable to see
  // all of them.
  var subheader;
  if (result.messages.length > 1) {
    subheader = `With ${formatEmailNames(result.participants, 4)}`;
    body = (
      <ExpandableGmailThread
        defaultContent={body}
        messages={result.messages}
        index={index}
        platform={result.platform}
      />
    );
  } else {
    // TODO(piyush) Add a tooltip so when senders or recipient
    // names are hovered over, the email comes up.
    // Sender and recipients are (email, optional name) pairs.
    const firstMessage = result.messages[0];

    let sender;
    let recipients: EmailParticipant[] = [];

    if (firstMessage) {
      sender = firstMessage.sender[1] || firstMessage.sender[0];
      recipients = [
        ...(firstMessage.recipients || []),
        ...(firstMessage.cc || []), // might be null
      ];
    }

    subheader = (
      <Box component="span" whiteSpace="pre-wrap">
        {`From ${sender}\nTo ${formatEmailNames(recipients, 4)}`}
      </Box>
    );
  }

  const avatar = null;
  const side = null;

  return (
    <SearchResultLayout
      result={result}
      resultIndex={index}
      resultUrl={result.handle}
      date={result.date}
      platform={result.platform}
      account={result.account}
      header={header}
      subheader={subheader}
      avatar={avatar}
      body={body}
      side={side}
      icon={icon}
    />
  );
};

export default EmailSearchResult;
