import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";

import PlatformIcon from "components/PlatformIcon";
import PLATFORMS from "constants/platforms";

const useStyles = makeStyles((theme) => ({
  avatar: {
    alignSelf: "flex-start",
  },
  icon: {
    backgroundColor: "transparent",
  },
}));

type PlatformListItemProps = {
  platform: string;
  secondary?: ReactNode;
  divider?: boolean;
};

const PlatformListItem: React.FC<PlatformListItemProps> = ({
  platform,
  secondary,
  divider,
  children,
}) => {
  const classes = useStyles();
  const instance = PLATFORMS.get(platform);
  if (!instance) return null;

  // TODO(piyush) The platform name doesn't quite align with the platform icon.
  return (
    <ListItem divider={divider}>
      <ListItemAvatar className={classes.avatar}>
        <Avatar className={classes.icon}>
          <PlatformIcon platform={platform} />
        </Avatar>
      </ListItemAvatar>

      <ListItemText disableTypography primary={instance.label} secondary={secondary} />

      {children}
    </ListItem>
  );
};

export default PlatformListItem;
