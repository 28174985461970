import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";

import { logApplicationFilterToggle, logApplicationBulkFilterToggle } from "analytics";
import DelayedSpinner from "components/DelayedSpinner";
import PLATFORMS from "constants/platforms";
import { UserApplicationType, UserApplicationsType } from "types";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
}));

type AccountFilterMenuItemProps = {
  application: UserApplicationType;
  checked: boolean;
  onClick: (_: any) => void;
};

const AccountFilterMenuItem = React.forwardRef<any, AccountFilterMenuItemProps>(
  ({ application, checked, onClick }, ref) => {
    const handleClick = useCallback(() => {
      onClick(application);
    }, [application, onClick]);
    const { platform, account } = application;

    const instance = PLATFORMS.get(platform);

    if (!instance) return null;

    return (
      <ListItem button onClick={handleClick} ref={ref}>
        <ListItemIcon>
          <Checkbox checked={checked || false} size="small" color="primary" disableRipple />
        </ListItemIcon>
        <ListItemText primary={instance.label} secondary={account} />
      </ListItem>
    );
  }
);

type AccountFilterProps = {
  userApplications?: UserApplicationsType;
  loading: boolean;
  value: Set<UserApplicationType>;
  onChange: (_: Set<UserApplicationType>) => void;
};

const AccountFilter: React.FC<AccountFilterProps> = ({
  userApplications,
  loading,
  value,
  onChange,
}) => {
  const classes = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "accountFilter" });

  // TODO: filter supported applications

  const handleCheckAll = useCallback(() => {
    logApplicationBulkFilterToggle({ selected: true });
    onChange(new Set(Object.values(userApplications || {})));
  }, [onChange, userApplications]);

  const handleClearAll = useCallback(() => {
    logApplicationBulkFilterToggle({ selected: false });
    onChange(new Set());
  }, [onChange]);

  const handleCheck = useCallback(
    (application) => {
      const { platform, account } = application;
      const selected = !value.has(application);
      logApplicationFilterToggle({ platform, account, selected });

      const newValue = new Set(value);
      if (selected) {
        newValue.add(application);
      } else {
        newValue.delete(application);
      }

      onChange(newValue);
    },
    [onChange, value]
  );

  let menu;
  let selectAllButton;
  let clearAllButton;
  let selectedCount = 0;
  let allSelected = true;

  if (loading) {
    menu = (
      <Grid container alignItems="center">
        <Grid item>
          <DelayedSpinner size={20} />
        </Grid>
      </Grid>
    );
  } else if (userApplications) {
    selectAllButton = (
      <ListItem button onClick={handleCheckAll} key="select all">
        <ListItemText primary="Select all" />
      </ListItem>
    );
    clearAllButton = (
      <ListItem button onClick={handleClearAll} key="clear all">
        <ListItemText primary="Clear all" />
      </ListItem>
    );

    const supportedPlatforms = new Set(PLATFORMS.all());
    menu = Object.keys(userApplications).map((id) => {
      const application = userApplications[id];
      if (supportedPlatforms.has(application.platform)) {
        const selected = value.has(application);
        if (selected) {
          selectedCount += 1;
        } else {
          allSelected = false;
        }

        return (
          <AccountFilterMenuItem
            key={id}
            application={application}
            checked={selected}
            onClick={handleCheck}
          />
        );
      } else {
        allSelected = false;
        return null;
      }
    });
  }

  let buttonText;
  if (loading) {
    buttonText = "...";
  } else if (allSelected) {
    buttonText = "All";
  } else if (selectedCount === 0) {
    buttonText = "None";
  } else {
    buttonText = `${selectedCount} selected`;
  }

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        className={classes.button}
        {...bindTrigger(popupState)}
      >
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          Filter accounts: {buttonText}
        </Typography>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        keepMounted
        // Opens menu below, not on top of, the button.
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {selectAllButton}
        {clearAllButton}
        {menu}
      </Menu>
    </div>
  );
};

export default AccountFilter;
