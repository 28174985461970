// eslint-disable-next-line
declare global {
  interface Window {
    Sentry: any;
  }
}

const init = () => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  if (!window.Sentry) {
    return;
  }

  const isLocalHost = window.location.hostname === "localhost";
  let environment;
  if (isLocalHost) {
    environment = "development";
  } else {
    environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
  }

  window.Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment,
  });
};

export default init;
