import React, { useCallback, useContext } from "react";

import CopyButton from "components/CopyButton";
import { useLogSearchInteraction } from "actions/logSearchInteraction";
import { SearchContext } from "components/Search/SearchProvider";
import { update as updateSearchRecommendation, INTERACTION } from "actions/searchRecommendation";
import { useFirebase } from "components/Firebase";

type CopyLinkButtonProps = {
  url: string;
  position: number;
  platform: string;
};

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({ url, position, platform }) => {
  const logSearchInteraction = useLogSearchInteraction();
  const searchContext = useContext(SearchContext);
  const firebase = useFirebase();

  // cancel the bubble so the SearchResult click handler doesn't navigate to the result
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      logSearchInteraction({ interactionType: "copyClick", position, platform });
      updateSearchRecommendation(firebase, searchContext.query, INTERACTION);
    },
    [logSearchInteraction, position, platform, firebase, searchContext.query]
  );

  return <CopyButton text={url} onClick={handleClick} />;
};

export default CopyLinkButton;
