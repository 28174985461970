import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemSecondaryAction, CircularProgress } from "@material-ui/core";

import PlatformListItem from "./PlatformListItem";
import ConnectApplicationButton from "components/Application/ConnectApplicationButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

type AvailableApplicationsListProps = {
  platforms: string[];
  loading: boolean;
};

const AvailableApplicationsList = ({ platforms, loading }: AvailableApplicationsListProps) => {
  const classes = useStyles();

  const sortedPlatforms = useMemo(() => {
    return platforms.sort();
  }, [platforms]);

  let content;
  if (loading) {
    content = (
      <ListItem>
        <CircularProgress size={25} />
      </ListItem>
    );
  } else if (platforms.length === 0) {
    content = <ListItem>{"You've connected all applications - nice work!"}</ListItem>;
  } else {
    content = sortedPlatforms.map((platform) => (
      <PlatformListItem key={platform} platform={platform}>
        <ListItemSecondaryAction>
          <ConnectApplicationButton platform={platform} />
        </ListItemSecondaryAction>
      </PlatformListItem>
    ));
  }

  return (
    <div className={classes.root}>
      <List dense={false}>{content}</List>
    </div>
  );
};

export default AvailableApplicationsList;
