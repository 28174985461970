import React from "react";

const PDF_URL = "https://drive.google.com/uc?embedded=true&id=1ZbcLrNBc4GGO7Gw8a1s4egLs5Tvj2AZR";

const TermsOfService: React.FC = () => (
  <object
    title="Terms of Service PDF"
    type="application/pdf"
    data={PDF_URL}
    style={{ width: "100%", height: "100vh" }}
  />
);

export default TermsOfService;
