import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { popupWindowCallback } from "actions/connectApplication";
import PLATFORMS from "constants/platforms";

// This component is meant to open in a popup. It extracts the URL params and
// sends them back to the opener window. The opener window then POSTS the data
// to the /auth endpoint

type OauthPageParamsType = {
  platformName: string;
};

const OauthPage = () => {
  const { platformName } = useParams<OauthPageParamsType>();
  const upperName = platformName.toUpperCase();
  // TODO: how do we fix this for Atlassian?
  const platform = PLATFORMS.get(upperName === "ATLASSIAN" ? "CONFLUENCE" : upperName);
  const platformFound = !!platform;
  let done = useRef(false);

  useEffect(() => {
    if (platformFound && !done.current) {
      done.current = true;
      popupWindowCallback();
    }
  }, [platformFound]);

  if (!platformFound) {
    return <p>Application not supported</p>;
  }

  return <p>Please wait...</p>;
};

export default OauthPage;
