export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const SIGN_OUT = "/signout";
export const HOME = "/home";
export const FAVORITES = "/favorites";
export const HISTORY = "/history";
export const ACCOUNT = "/account";
export const PLANS = "/plans";
export const APPLICATIONS = "/applications";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const CONTACT = "/contact";
export const PRIVACY_POLICY = "/privacypolicy";
export const TERMS_OF_SERVICE = "/tos";
export const APPLICATION_OAUTH = "/application_oauth";
