import React from "react";

import { useCurrentUser } from "components/Session";
import LoggedOutNavigation from "./LoggedOutNavigation";
import LoggedInNavigation from "./LoggedInNavigation";

const Navigation = () => {
  const [authUser] = useCurrentUser();
  return authUser ? <LoggedInNavigation /> : <LoggedOutNavigation />;
};

export default Navigation;
