import "date-fns";
import React, { useCallback, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Collapse, Grid, Menu, MenuItem, Typography } from "@material-ui/core";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

type XDateType = Date | null;
type DateRangeType = [XDateType, XDateType];

export type DateFilterValueType = {
  type: string;
  dateRange?: () => DateRangeType;
};

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
}));

const lastNDays = (n: number): DateRangeType => {
  let date = new Date();
  date.setHours(0, 0, 0, 0); // Set to start of day
  date.setDate(date.getDate() - n);
  return [date, null];
};

// Map common date options to date ranges.
const defaultOptions: { [_: string]: () => DateRangeType } = {
  Any: () => [null, null],
  Today: () => lastNDays(0),
  "Last 7 days": () => lastNDays(7),
  "Last 30 days": () => lastNDays(30),
  "Last 60 days": () => lastNDays(60),
  "Last 90 days": () => lastNDays(90),
};

type ExpandingCustomDatePickerProps = {
  expanded: boolean;
  onChange: (arg0: XDateType, arg1: XDateType) => void;
};

const ExpandingCustomDatePicker: React.FC<ExpandingCustomDatePickerProps> = ({
  expanded,
  onChange,
}) => {
  // TODO(piyush) Find better defaults.
  // TODO(piyush) Enforce startDate <= endDate.
  const [startDate, setStartDate] = useState<XDateType>(null);
  const [endDate, setEndDate] = useState<XDateType>(null);

  const handleChange = useCallback(
    (start: boolean, newDate: XDateType) => {
      // TODO: Do we need to check validity?
      if (start) {
        setStartDate(newDate);
        onChange(newDate, endDate);
      } else {
        setEndDate(newDate);
        onChange(startDate, newDate);
      }
    },
    [endDate, onChange, startDate]
  );

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableFuture
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start date"
              value={startDate}
              onChange={(date) => handleChange(true, date)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableFuture
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End date"
              value={endDate}
              onChange={(date) => handleChange(false, date)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Collapse>
  );
};

type DateFilterProps = {
  value: DateFilterValueType;
  onChange: (_: any) => void;
};

const DateFilter: React.FC<DateFilterProps> = ({ value, onChange }) => {
  const classes = useStyles();

  const popupState = usePopupState({ variant: "popover", popupId: "dateFilter" });
  const [customExpanded, setCustomExpanded] = useState(false);

  const handleSelect = useCallback(
    (event, text, selectedIndex, computeDateRange) => {
      popupState.close();

      onChange({
        type: text,
        dateRange: () => computeDateRange(),
      });
    },
    [onChange, popupState]
  );

  const handleSelectCustom = useCallback(() => {
    setCustomExpanded(true);
  }, []);

  const handleCustomChange = useCallback(
    (min, max) => {
      onChange({
        type: "custom",
        dateRange: () => [min, max],
      });
    },
    [onChange]
  );

  const buttonText =
    (value.type === "custom" ? "Custom" : value.type) || Object.keys(defaultOptions)[0];
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        className={classes.button}
        {...bindTrigger(popupState)}
      >
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          Date: {buttonText}
        </Typography>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        // Opens menu below, not on top of, the button.
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.keys(defaultOptions).map((option, index) => (
          <MenuItem
            onClick={(event) => handleSelect(event, option, index, defaultOptions[option])}
            selected={value.type === option}
            key={option}
          >
            {option}
          </MenuItem>
        ))}
        <MenuItem
          onClick={handleSelectCustom}
          selected={value.type === "custom"}
          key="custom"
          disableRipple
        >
          <Grid container direction="column">
            <Grid item>Custom</Grid>
            <Grid item>
              <ExpandingCustomDatePicker expanded={customExpanded} onChange={handleCustomChange} />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DateFilter;
