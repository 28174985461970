import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { Grid, Typography, Switch, FormControl, FormLabel } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import { GridProps } from "@material-ui/core/Grid";

import FeatureIcon from "./FeatureIcon";
import readonlyIcon from "images/noun_project_icons/readonly.svg";
import { OauthDialogConfigFeatureType } from "constants/platforms";

const useStyles = makeStyles((theme) => ({
  "@keyframes focusFeatureAccessItem": {
    "0%": {
      backgroundColor: "transparent",
    },
    "50%": {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    },
    "100%": {
      backgroundColor: "transparent",
    },
  },
  accessText: {
    flexGrow: 1,
  },
  featureAccessItemFocused: {
    animation: "$focusFeatureAccessItem 2s infinite",
  },
}));

type FeatureAccessItemLabelProps = {
  icon: any;
  title: string;
  details: string;
};

const FeatureAccessItemLabel: React.FC<FeatureAccessItemLabelProps> = ({
  icon,
  title,
  details,
}) => {
  const classes = useStyles();

  // TODO: remove icon hardcoding
  return (
    <Grid container direction="row" spacing={2} wrap="nowrap">
      <Grid item>
        <FeatureIcon icon={readonlyIcon} height={100} width={100} />
      </Grid>

      <Grid item className={classes.accessText}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{details}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type FeatureAccessItemProps = {
  icon?: any;
  name: any;
  title: string;
  details: string;
  checked: boolean;
  onChange: (_: any) => void;
  focused: boolean;
  onClearFocus: () => void;
};

const FeatureAccessItem: React.FC<FeatureAccessItemProps> = ({
  icon,
  name,
  title,
  details,
  checked,
  onChange,
  focused,
  onClearFocus,
}) => {
  const classes = useStyles();
  const htmlId = `oauthFeature_${name}`;
  const root = useRef(null);

  useEffect(() => {
    if (focused) {
      // @ts-ignore
      root.current.scrollIntoView();
    }
  }, [focused]);

  return (
    <FormControl ref={root} className={clsx(focused && classes.featureAccessItemFocused)}>
      <Grid container direction="row" spacing={2} wrap="nowrap">
        <Grid item>
          <FormLabel htmlFor={htmlId}>
            <FeatureAccessItemLabel icon={icon} title={title} details={details} />
          </FormLabel>
        </Grid>

        <Grid item>
          <Switch id={htmlId} color="primary" checked={checked || false} onChange={onChange} />
        </Grid>
      </Grid>
    </FormControl>
  );
};

type FormState = { [_: string]: boolean };

type FeaturePermissionsProps = GridProps & {
  features: OauthDialogConfigFeatureType[];
  state: FormState;
  onChange: (_: FormState) => void;
  focused: string | null;
  onClearFocus: () => void;
};

const FeaturePermissions: React.FC<FeaturePermissionsProps> = ({
  features,
  state,
  onChange,
  focused,
  onClearFocus,
  ...otherProps
}) => {
  const items = features.map((feature) => {
    return (
      <FeatureAccessItem
        key={feature.name}
        name={feature.name}
        title={feature.title}
        details={feature.details}
        checked={state[feature.name]}
        onChange={(e) => onChange({ ...state, [feature.name]: e.target.checked })}
        focused={focused === feature.name}
        onClearFocus={onClearFocus}
      />
    );
  });

  if (features.length === 0) return null;

  return (
    <Grid container direction="column" spacing={2} {...otherProps}>
      <Grid item>
        <Typography variant="h6">What access we need</Typography>
      </Grid>

      <Grid item>{items}</Grid>
    </Grid>
  );
};

export default FeaturePermissions;
