import React, { useCallback, useContext } from "react";
import clsx from "clsx";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";

import PlatformIcon from "components/PlatformIcon";
import { displayDate } from "../utils";
import { useLogSearchInteraction } from "actions/logSearchInteraction";

import CopyLinkButton from "./CopyLinkButton";
import AddToCardButton from "./AddToCardButton";
import Snippets from "./Snippets";
import ExternalLink from "components/ExternalLink";
import { update as updateSearchRecommendation, INTERACTION } from "actions/searchRecommendation";
import { useFirebase } from "components/Firebase";
import { SearchContext } from "components/Search/SearchProvider";
import { SnippetType } from "types";
import { HAS_CARDS } from "constants/features";

const MAX_BODY_SIZE = 500; // in characters

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      position: "relative",
      cursor: "pointer",
      wordBreak: "break-word",
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.short,
      }),
      "&:hover": {
        // This matches the hover color from Material UI's Button component
        backgroundColor: fade(theme.palette.primary.light, theme.palette.action.hoverOpacity),
      },
      boxShadow: "none",
      borderRadius: "4px",
      border: "2px solid #E6E3F7",
    },
    top: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      alignItems: "center",
    },
    header: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    mainAvatar: {
      alignSelf: "flex-start",
    },
    dateLabel: {
      flexGrow: 1,
    },
    actionButtons: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    resultBody: {
      maxHeight: 200,
      overflow: "auto",
    },
  };
});

type ResultBodyProps = {
  content?: SnippetType[];
  fallback?: string;
  full_text?: string;
};

export const ResultBody: React.FC<ResultBodyProps> = ({ content, fallback, full_text = null }) => {
  const classes = useStyles();
  // The backend uses the convention that null content snippets were unable
  // to be computed for some reason (e.g. non-textual file type), whereas
  // empty content snippets indicate that there are no relevant snippets.

  // If the full text of the result is provided and isn't too long, display that instead of content
  // snippets.
  let body;
  if (!!full_text && full_text.length <= MAX_BODY_SIZE)
    body = <Typography variant="caption">{full_text}</Typography>;
  else body = <Snippets snippets={content} fallback={fallback} />;

  return <CardContent className={classes.resultBody}>{body}</CardContent>;
};

type SearchResultLayoutProps = {
  result: any;
  resultIndex: number;
  resultUrl: string;
  date: string;
  platform: string;
  account: string;
  header: React.ReactNode;
  subheader?: React.ReactNode;
  avatar?: React.ReactNode;
  body?: React.ReactNode;
  side?: React.ReactNode;
  icon?: React.ReactNode;
};

// All search results are structured with a header at the top, with a small
// sub-header below, optionally with an avatar displayed on the left side
// of the sub-header, and a body underneath. The actual content in these
// various parts is platform-specific.
const SearchResultLayout: React.FC<SearchResultLayoutProps> = ({
  result,
  resultIndex,
  resultUrl,
  date,
  platform,
  account,
  header,
  subheader,
  avatar,
  body,
  side,
  icon,
}) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const searchContext = useContext(SearchContext);

  const top = (
    <Grid item container spacing={2} className={classes.top}>
      <Grid item>{icon || <PlatformIcon platform={platform} />}</Grid>
      <Grid item className={classes.dateLabel}>
        <Typography variant="caption">
          {/* TODO(piyush) Is there a more elegant way to stack these vertically? */}
          <div>{displayDate(date)}</div>
          <div>{account}</div>
        </Typography>
      </Grid>
    </Grid>
  );

  const main = (
    <Grid item container direction="column" className={classes.header}>
      <CardHeader
        avatar={avatar}
        title={header}
        titleTypographyProps={{
          variant: "h6",
          style: { fontSize: 16 },
        }}
        subheader={subheader}
        subheaderTypographyProps={{
          variant: "subtitle2",
          style: { fontSize: 13 },
        }}
        style={{ paddingBottom: body == null ? undefined : 0 }}
        // This forces the avatar to align with the title, even when
        // the subheader is much larger (without this, the subheader's
        // size drags the avatar down to the middle).
        classes={{ avatar: classes.mainAvatar }}
      />
      <Grid item>{body}</Grid>
    </Grid>
  );

  const logSearchInteraction = useLogSearchInteraction();
  const handleClick = useCallback(() => {
    logSearchInteraction({
      interactionType: "click",
      position: resultIndex,
      platform,
    });
    updateSearchRecommendation(firebase, searchContext.query, INTERACTION);
  }, [logSearchInteraction, resultIndex, platform, firebase, searchContext.query]);

  return (
    <ExternalLink href={resultUrl} onClick={handleClick} noDecoration>
      <Paper elevation={4} className={clsx(classes.paper, "SearchResult")}>
        <Grid container direction="row">
          <Grid item container direction="column" sm={side == null ? undefined : 9}>
            {top}
            {main}
          </Grid>
          <Grid item sm={side == null ? undefined : 3}>
            {side}
          </Grid>
        </Grid>
        <div className={classes.actionButtons}>
          <CopyLinkButton url={resultUrl} position={resultIndex} platform={platform} />
          {HAS_CARDS && (
            <AddToCardButton
              result={result}
              url={resultUrl}
              position={resultIndex}
              platform={platform}
            />
          )}
        </div>
      </Paper>
    </ExternalLink>
  );
};

export default SearchResultLayout;
