import { useCallback, useReducer } from "react";
import {
  getLayoutItem,
  cloneLayout,
  Layout,
  compact,
  CompactType,
  LayoutItem,
  moveElement,
} from "../utils";

// type DragState = {
//   layout: Layout;
//   layoutItem: LayoutItem | null | undefined;
//   dragging: boolean;
//   placeholder: any;
// };

// type DragStartAction = {
//   type: "start",
//   i: string;
//   initialLayout: Layout;
// };

// type DragStartContinue = {
//   type: "continue",
//   i: string;
//   x: number;
//   y: number;
//   initialLayout: Layout;
//   cols: number;
//   compactType?: CompactType;
//   preventCollision: boolean;
// };

// type DragStartStop = {
//   type: "stop",
//   i: string;
//   initialLayout: Layout;
//   cols: number;
//   compactType?: CompactType;
// };

// type DragAction = DragStartAction | DragStartContinue | DragStartStop;

function reducer(state: any, action: any) {
  let l: LayoutItem | null | undefined;
  let layout: Layout;

  switch (action.type) {
    case "start":
      layout = cloneLayout(action.initialLayout);
      l = getLayoutItem(layout, action.i);
      if (!l) return;

      return {
        ...state,
        dragging: true,
        layout,
        layoutItem: l,
        placeholder: null,
      };
    case "stop":
      // l = state.layoutItem;

      // const newLayout = compact(state.layout, action.compactType, action.cols);

      return {
        ...state,
        layout: null,
        dragging: false,
        placeholder: null,
      };
    case "continue":
      // l = state.layoutItem;
      l = getLayoutItem(state.layout, action.i);
      if (!l) return;

      if (l.x === action.x && l.y === action.y) {
        return state;
      }
      // console.log("moveElement", action.x, action.y)

      // Move the element to the dragged location.
      const isUserAction = true;
      layout = moveElement(
        state.layout,
        l,
        action.x,
        action.y,
        isUserAction,
        action.preventCollision,
        action.compactType,
        action.cols
      );

      let newPlaceholder;
      // if (!state.placeholder || state.placeholder.x !== l.x || state.placeholder.y !== l.y) {
      newPlaceholder = {
        w: l.w,
        h: l.h,
        x: l.x,
        y: l.y,
        static: true,
        i: action.i,
      };
      // }

      // if (newPlaceholder) {
      return {
        ...state,
        layout: compact(layout, action.compactType, action.cols),
        placeholder: newPlaceholder,
      };
    // } else {
    //   return state;
    // }
    default:
      throw new Error();
  }
}

type useDraggableLayoutType = {
  initialLayout: Layout;
  cols: number;
  compactType?: CompactType;
  preventCollision: boolean;
  // onChange: any;
};

const useDraggableLayout = ({
  initialLayout,
  cols,
  preventCollision,
  compactType,
}: useDraggableLayoutType) => {
  const [state, dispatch] = useReducer(reducer, {
    layout: null,
    resizing: false,
    placeholder: null,
  });

  const start = useCallback(
    (i: string, x: number, y: number) => {
      dispatch({
        type: "start",
        initialLayout,
        i,
      });
    },
    [initialLayout]
  );

  const update = useCallback(
    (i: string, x: number, y: number) => {
      dispatch({
        type: "continue",
        i,
        initialLayout,
        cols,
        preventCollision,
        compactType,
        x,
        y,
      });
    },
    [initialLayout, cols, preventCollision, compactType]
  );

  const stop = useCallback(
    (i: string, x: number, y: number) => {
      dispatch({
        type: "stop",
        i,
        cols,
        compactType,
      });
    },
    [cols, compactType]
  );

  return {
    layout: state.layout || initialLayout,
    placeholder: state.placeholder,
    start,
    update,
    stop,
  };
};

export default useDraggableLayout;
