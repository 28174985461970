import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import PLATFORMS from "constants/platforms";

type PlatformIconProps = SvgIconProps & {
  platform: string;
  disabled?: boolean;
};

const PlatformIcon: React.FC<PlatformIconProps> = ({ platform, disabled, ...otherProps }) => {
  const instance = PLATFORMS.get(platform);
  if (!instance) {
    // TODO(piyush) Log this as an error somewhere such as in the
    // Firebase realtime DB under frontend_errors.
    return null;
  }
  const { height, width, src } = instance.icon;

  return (
    <SvgIcon viewBox={`0 0 ${width} ${height}`} {...otherProps}>
      <image
        style={disabled ? { opacity: 0.4 } : { opacity: 1 }}
        width={width}
        height={height}
        href={src}
      />
    </SvgIcon>
  );
};

export default PlatformIcon;
