// inspired by https://javascript.info/custom-errors
class UserFacingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export default UserFacingError;

export class NotLoggedInError extends UserFacingError {
  constructor() {
    super("You are not logged in");
    this.name = this.constructor.name;
  }
}
