import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import BookmarkIcon from "@material-ui/icons/Bookmark";

import { SearchResultType } from "types";
import { CardPickerDialogContext } from "./CardPickerDialog";

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      "& path": {
        transition: theme.transitions.create(["color"], {
          duration: theme.transitions.duration.short,
        }),
        color: fade(theme.palette.text.primary, theme.palette.action.focusOpacity),
      },
      "&:hover path": {
        // This matches the hover color from Material UI's Button component
        color: theme.palette.text.primary,
      },
    },
    iconActive: {
      "& path": {
        color: theme.palette.text.primary,
      },
    },
  };
});

type AddToCardButtonProps = {
  result: SearchResultType;
  url: string;
  position: number;
  platform: string;
};

// TODO: how do we indicate when a result is already in a card?
const AddToCardButton: React.FC<AddToCardButtonProps> = ({ result, url, position, platform }) => {
  const classes = useStyles();
  const { setResult, cardsForResult } = useContext(CardPickerDialogContext);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setResult(result);
    },
    [setResult, result]
  );

  const inCard = cardsForResult(result).size > 0;

  return (
    <Tooltip title="Add to card">
      <IconButton
        onClick={handleOpen}
        size="small"
        className={clsx(classes.icon, inCard && classes.iconActive)}
      >
        <BookmarkIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default AddToCardButton;
