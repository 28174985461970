import React, { useCallback, useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import AddNewCard from "components/Card/cardTypes/AddNewCard/AddNewCard";

import { CardTypeEnum } from "types";
import { logCardItemInteraction, CardItemInteraction } from "analytics";
import AddToCard from "./cardTypes/AddToCard/AddToCard";

const useStyles = makeStyles((theme) => ({
  itemAvatar: {
    minWidth: 28,
    marginRight: theme.spacing(1),
  },
  icon: {
    backgroundColor: "transparent",
    color: "black",
    height: 22,
    width: 22,
  },
  bookmarkIcon: {
    opacity: 0,
  },
  bookmarkIconVisible: {
    opacity: 1,
  },
  card: {
    minWidth: 425,
    boxShadow: "none",
  },
}));

type DocType = {
  id: string;
  webViewLink: string;
  iconLink: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
  sharedWithMeTime: string;
  viewedByMeTime: string;
  platform: string;
};

type CardItemLayoutProps = {
  cardType: CardTypeEnum;
  url?: string;
  icon?: any;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  cardItem?: any;
  docItem?: DocType;
  searchResult?: any;
  noEdit?: boolean;
  cardId?: string;
};

const CardItemLayout: React.FC<CardItemLayoutProps> = ({
  cardType,
  onClick,
  url,
  icon,
  primary,
  secondary,
  disabled,
  cardItem,
  docItem,
  searchResult,
  noEdit,
  cardId,
}) => {
  const classes = useStyles();

  const handleClick = useCallback(
    (event) => {
      logCardItemInteraction({
        interactionType: CardItemInteraction.Click,
        cardType,
      });

      if (url) {
        // automatically open in the current window. If the user is hitting command on control, new tab
        if (event.metaKey) {
          // the user is holding Command or Control
          event.preventDefault();
          // open the item in a new tab
          window.open(url);
        }
        // open the item in the same window
        else window.open(url, "_self");
      }
      if (onClick) {
        onClick();
      }
    },
    [url, cardType, onClick]
  );

  let avatar = null;
  if (icon) {
    avatar = <ListItemAvatar className={classes.itemAvatar}>{icon}</ListItemAvatar>;
  }

  const [open, setOpen] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [newCard, setNewCard] = useState(false);
  const [createdCard, setCreatedCard]: any = React.useState(null);

  const secondAction = (
    <ListItemSecondaryAction
      className={isShown ? classes.bookmarkIconVisible : classes.bookmarkIcon}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Tooltip title="Save to Bookmark Card" placement="bottom">
        <IconButton onClick={handleClickOpen}>
          <BookmarkBorderIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  );

  return (
    <>
      <ListItem
        onClick={!disabled ? handleClick : undefined}
        dense
        button
        disabled={disabled}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {avatar}
        <ListItemText primary={primary} secondary={secondary} />

        {((cardType === CardTypeEnum.Items && !!noEdit) ||
          cardType === CardTypeEnum.RecentDocs ||
          cardType === CardTypeEnum.SavedSearch) &&
        !disabled
          ? secondAction
          : null}
      </ListItem>

      <Dialog className={classes.card} open={open} onClose={handleClose}>
        {newCard ? (
          <AddNewCard setCreatedCard={setCreatedCard} setNewCard={setNewCard} />
        ) : (
          <AddToCard
            handleClose={handleClose}
            setNewCard={setNewCard}
            setCreatedCard={setCreatedCard}
            createdCard={createdCard}
            extension={false}
            cardItem={cardItem}
            cardType={cardType}
            docItem={docItem}
            searchResult={searchResult}
            cardId={cardId}
          />
        )}
      </Dialog>
    </>
  );
};

export default CardItemLayout;
