import React, { useState, Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useFirebase } from "components/Firebase";
import firebase from "firebase/app";
import { logCardChange, CardChangeOperator } from "analytics";
import { useCollectionData } from "hooks/firebase/firestore";
import { useCurrentUser } from "components/Session";
import { CardType } from "types";
import { CardItemType, CardTypeEnum } from "types";
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from "@material-ui/core/styles";

const lightGrey = grey[400];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 30,
  },
  card: {
    minWidth: 425,
    boxShadow: "none",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  subHeader: {
    width: 240,
    fontSize: "12px",
    lineHeight: "14px",
    color: `${lightGrey}`,
  },
  inputTitle: {
    fontSize: "1.1rem",
  },
  content: {
    overflowY: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
  },
  itemsListOverflow: {
    overflowY: "auto",
  },
  itemsList: {
    overflowY: "auto",
  },
  waitingMessage: {
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "50px",
    textAlign: "center",
  },
  emptyMessage: {
    marginTop: "30px",
  },
}));

type DocType = {
  id: string;
  webViewLink: string;
  iconLink: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
  sharedWithMeTime: string;
  viewedByMeTime: string;
  platform: string;
};

interface AddToCardProps {
  handleClose: () => void;
  setNewCard: Dispatch<SetStateAction<boolean>>;
  createdCard: any;
  setCreatedCard: Dispatch<SetStateAction<any>>;
  extension: boolean;
  cardItem?: CardItemType;
  cardType?: CardTypeEnum;
  docItem?: DocType;
  searchResult?: any;
  cardId?: string;
}

const AddToCard: React.FC<AddToCardProps> = ({
  handleClose,
  setNewCard,
  createdCard,
  setCreatedCard,
  extension,
  cardItem,
  cardType,
  docItem,
  searchResult,
  cardId,
}) => {
  const classes = useStyles();
  const [user] = useCurrentUser();
  const firebaseStore = useFirebase();

  const docRef = firebaseStore.firestore
    .collection("users")
    .doc(user?.uid)
    .collection("spaces")
    .doc("HOME");

  const cardsRef = docRef.collection("cards");

  const [precards, cardsLoading] = useCollectionData<CardType>(cardsRef, {
    idField: "id",
  });

  const AddToSubHeader = (
    <Typography className={classes.subHeader}>
      Add this item to a new or existing Card. Once added, you will see it in the Card on your Xoba
      homepage.
    </Typography>
  );

  const AddToTitle = "Add to Card";

  const [title, setTitle] = useState("");
  const [titleWrote, setTitleWrote] = useState(false);
  const [url, setUrl] = useState("");
  const [chosenCards, setChosenCards] = useState([]);
  const [savingData, setSavingData] = useState(false);

  const handleAddItem = (card: any) => {
    const newCards: any = [...chosenCards];
    newCards.push(card);
    setChosenCards(newCards);
  };

  const handleRemoveItem = (card: any) => {
    const newCards: any = chosenCards.filter((cardItem: any) => cardItem.id !== card.id);
    setChosenCards(newCards);
  };

  const handleCardChecks = (event: React.ChangeEvent<any>, item: any) => {
    if (event.target.checked) {
      handleAddItem(item);
    } else {
      handleRemoveItem(item);
    }
  };

  useEffect(() => {
    if (createdCard && !cardsLoading && precards) {
      const newCard: any = precards!.filter(
        (card: any) => card.createdAt && card.createdAt.seconds === createdCard.createdAt.seconds
      );
      handleAddItem(newCard[0]);
      setCreatedCard(null);
    }
  });

  const setItemTitle = (dataTitle: string) => {
    setTitle(dataTitle);
    setTitleWrote(true);
  };

  if (!url && extension) {
    chrome.tabs.query({ active: true, lastFocusedWindow: true }, (tabs) => {
      const slicedTitle = tabs[0].title!.slice(0, 40);
      setTitle(slicedTitle);
      setUrl(tabs[0].url!);
    });
  } else if (cardType === CardTypeEnum.Items && !title && !titleWrote) {
    setItemTitle(cardItem!.label);
  } else if (cardType === CardTypeEnum.RecentDocs && !title && !titleWrote) {
    setItemTitle(docItem!.name);
  } else if (cardType === CardTypeEnum.SavedSearch && !title && !titleWrote) {
    setItemTitle(searchResult!.title);
  }

  const handleTitle = (event: React.ChangeEvent<any>) => {
    setTitle(event.target.value);
  };

  const handleSave = useCallback(
    async (card: any) => {
      const now = firebase.firestore.Timestamp.fromDate(new Date());

      let newItem;

      if (extension) {
        newItem = [
          {
            label: title,
            url: url,
          },
        ];
      } else if (cardType === CardTypeEnum.Items) {
        newItem = [
          {
            label: title,
            url: cardItem?.url,
            mimeType: cardItem?.mimeType ? cardItem?.mimeType : "",
            platform: cardItem?.platform ? cardItem?.platform : "",
          },
        ];
      } else if (cardType === CardTypeEnum.RecentDocs) {
        newItem = [
          {
            label: title,
            url: docItem?.webViewLink,
            mimeType: docItem?.mimeType ? docItem?.mimeType : "",
            platform: docItem?.platform ? docItem?.platform : "",
          },
        ];
      } else if (cardType === CardTypeEnum.SavedSearch) {
        newItem = [
          {
            label: title,
            url: searchResult?.handle,
            mimeType: searchResult?.mime_type ? searchResult?.mime_type : "",
            platform: searchResult?.platform ? searchResult?.platform : "",
          },
        ];
      }

      const items = card.items ? card.items.concat(newItem) : newItem;
      const newCard: CardType = {
        title: card.title,
        items: items,
        type: card.type,
        variant: card.variant,
        updatedAt: now,
        createdAt: (card || {}).createdAt || now,
      };
      // use a JSON stringify + parse trick to remove undefined values
      const doc = JSON.parse(JSON.stringify(newCard));

      logCardChange({
        operation: CardChangeOperator.Update,
        cardType: card.type,
      });

      await cardsRef.doc(card.id).set(doc, { merge: true });
    },
    [cardsRef, title, url, cardItem, searchResult, docItem, cardType, extension]
  );

  const addItem = () => {
    setSavingData(true);
    chosenCards.forEach((card) => {
      handleSave(card);
    });
    setTimeout(() => {
      handleClose();
    }, 500);
    setTimeout(() => {
      setSavingData(false);
    }, 700);
  };

  const inputIsChecked = (item: any) =>
    chosenCards.filter((card: any) => card.id === item.id).length > 0;

  const handleSubmit = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (title && chosenCards.length > 0 && !savingData) {
      addItem();
    }
  };

  const AddToContent = (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          InputProps={{ className: classes.inputTitle }}
          id="standard-basic"
          value={title}
          onChange={handleTitle}
          helperText="You can rename the Item before adding it to a Card"
        />

        <Box
          className={classes.itemsList}
          height="130px"
          paddingLeft="5px"
          display="flex"
          flexDirection="column"
        >
          {precards
            ? precards
                .filter((item) => item.type === "items")
                .map((item) => {
                  if (!extension && item.id === cardId) return null;

                  return (
                    <>
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            key={item.id}
                            color="primary"
                            checked={inputIsChecked(item)}
                            name={item.title}
                            onChange={(event) => {
                              handleCardChecks(event, item);
                            }}
                          />
                        }
                        label={item.title}
                      />
                    </>
                  );
                })
            : null}

          {!extension && precards && precards.filter((item) => item.type === "items").length < 1 ? (
            <Typography color="primary" className={classes.emptyMessage}>
              Please create a new card!
            </Typography>
          ) : null}

          {extension &&
          precards &&
          precards.filter((item) => item.type === "items").length === 0 ? (
            <Typography color="primary" className={classes.emptyMessage}>
              Please create a new card!
            </Typography>
          ) : null}
        </Box>

        <Box marginTop="15px">
          <Button
            color="secondary"
            onClick={() => {
              setNewCard(true);
            }}
          >
            Create New Card
          </Button>
        </Box>
      </form>
    </>
  );

  const AddToButtons = (
    <>
      <Button color="primary" onClick={handleClose}>
        CANCEL
      </Button>
      <Button
        color="secondary"
        disabled={!title || chosenCards.length === 0 || savingData}
        onClick={addItem}
      >
        ADD
      </Button>
    </>
  );

  const savingMessage = useMemo(() => {
    return extension ? "Saving Current Tab" : "Saving Item";
  }, [extension]);

  const WaitingLoading = (message: string) => (
    <Box>
      <Box mr="50px" display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
      <Typography className={classes.waitingMessage}>
        {" "}
        {savingData ? savingMessage : message}{" "}
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={AddToTitle}
          subheader={AddToSubHeader}
          titleTypographyProps={{ variant: "h6" }}
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <Box paddingLeft="50px" display="flex" flexDirection="column" justifyContent="flex-end">
            {!cardsLoading && !savingData ? AddToContent : WaitingLoading("Loading Cards")}
          </Box>
        </CardContent>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginY="15px"
          width="35%"
          marginLeft="65%"
        >
          {AddToButtons}
        </Box>
      </Card>
    </Box>
  );
};

export default AddToCard;
