import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText } from "@material-ui/core";

import { UserApplicationType } from "types";
import DisconnectApplicationButton from "./DisconnectApplicationButton";
import ConfigurationDialog from "../ConfigurationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
  },
}));

type AccountListProps = {
  applications: UserApplicationType[];
};

const AccountList = ({ applications }: AccountListProps) => {
  const classes = useStyles();

  const proccessedApplications = useMemo(() => {
    const byAccount: { [account: string]: UserApplicationType } = {};
    const accounts: string[] = [];

    applications.forEach((application) => {
      byAccount[application.account] = application;
      accounts.push(application.account);
    });

    return {
      byAccount,
      sorted: accounts.sort(),
    };
  }, [applications]);

  const items = proccessedApplications.sorted.map((account) => {
    const application = proccessedApplications.byAccount[account];
    const editor = <ConfigurationDialog application={application} />;

    const content = (
      <React.Fragment>
        {account}
        {editor}
        <DisconnectApplicationButton application={application} />
      </React.Fragment>
    );

    return (
      <ListItem className={classes.root} key={account}>
        <ListItemText secondary={content} />
      </ListItem>
    );
  });

  return <List dense>{items}</List>;
};

export default AccountList;
