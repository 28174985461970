import React, { useCallback, useMemo } from "react";
import { SizeMe } from "react-sizeme";
import XResponsiveGridLayout from "components/ReactGridLayout/ResponsiveReactGridLayout";
import { makeStyles } from "@material-ui/core/styles";
import { Responsive } from "react-grid-layout";

import Card from "components/Card";
import { CardType, SpaceLayoutType } from "types";
import { HAS_XOBA_GRID } from "constants/features";

const ResponsiveGridLayout = HAS_XOBA_GRID ? XResponsiveGridLayout : Responsive;

type CardsProps = {
  cards: CardType[];
  layouts?: SpaceLayoutType;
  onLayoutsChange: (arg0: SpaceLayoutType) => void;
  cardsRef: any;
  editing?: boolean;
};

const BREAKPOINTS = {
  lg: 1200,
  md: 996,
  sm: 550, //768,
  xs: 480,
  xxs: 0,
};
const COLS = {
  // lg: 12, md: 10, sm: 6, xs: 4, xxs: 2
  lg: 4,
  md: 3,
  sm: 2,
  xs: 1,
  xxs: 1,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "@global": {
      // https://github.com/STRML/react-grid-layout/issues/1209
      ".react-grid-item.react-grid-placeholder": {
        background: theme.palette.primary.light,
      },
    },
  },
}));

const Cards: React.FC<CardsProps> = ({ cards, layouts, onLayoutsChange, cardsRef, editing }) => {
  const classes = useStyles();
  const handleLayoutChange = useCallback(
    (breakpoint, newLayout) => {
      if (HAS_XOBA_GRID) {
        // @ts-ignore
        onLayoutsChange({
          ...layouts,
          [breakpoint]: newLayout,
        });
      } else {
        onLayoutsChange(newLayout);
      }
    },
    [onLayoutsChange, layouts]
  );

  const cardElements = useMemo(() => {
    return cards.map((card) => {
      return (
        <div key={card.id} data-grid={{ w: 1, h: 2, x: 0, y: 0 }}>
          <Card card={card} cardsRef={cardsRef} disabled={editing} />
        </div>
      );
    });
  }, [cards, cardsRef, editing]);

  if (!layouts) {
    // TODO: render loading
    return null;
  }

  return (
    <SizeMe>
      {({ size }) => {
        return (
          // @ts-ignore
          <ResponsiveGridLayout
            width={size.width || 1280}
            layouts={layouts}
            breakpoints={BREAKPOINTS}
            cols={COLS}
            onLayoutChange={editing ? handleLayoutChange : undefined}
            isDraggable={editing || false}
            isResizable={editing || false}
            className={classes.root}
          >
            {cardElements}
          </ResponsiveGridLayout>
        );
      }}
    </SizeMe>
  );
};

export default Cards;
