import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, List, ListItem, ListItemText } from "@material-ui/core";

import { useGetHistory } from "actions/history/hooks";
import { FINISHED } from "hooks/useLoadingStatus";
import { displayDate } from "components/Search/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
}));

const FavoritesPage = () => {
  const classes = useStyles();
  const { status, data, run } = useGetHistory();

  useEffect(() => {
    run();
  }, [run]);

  const loading = status !== FINISHED && <CircularProgress size={50} />;

  const numItems = (data || []).length;

  const items = (data || []).map((item: any, index: number) => {
    const runAt = new Date(item.created_at);
    const isLast = index + 1 === numItems;
    return (
      <React.Fragment>
        <ListItem key={index}>
          <ListItemText primary={item.search_term} secondary={displayDate(runAt)} />
        </ListItem>
        {!isLast && <Divider />}
      </React.Fragment>
    );
  });

  return (
    <div className={classes.root}>
      <h1>History</h1>
      {loading || <List>{items}</List>}
    </div>
  );
};

export default FavoritesPage;
