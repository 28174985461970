import React, { useCallback, FunctionComponent } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    position: "relative",
  },
  container: {
    width: "100%",
    opacity: 0.5,
    transition: "opacity 0.5s, background-color 0.5s",
    backgroundColor: "rgba(0,0,0,0)",
    "&:hover": {
      opacity: 1,
      backgroundColor: theme.palette.action.hover,
    },
    borderRadius: theme.spacing(1),
  },
  icon: {
    flexGrow: 0,
  },
  text: {
    flexGrow: 0,
  },
  dividerItem: {
    flexGrow: 1,
    position: "relative",
    color: "#E6E3F7",
  },
  divider: {
    position: "absolute",
    right: 0,
    left: theme.spacing(1),
    top: "1em",
    color: "#E6E3F7",
  },
}));

type SearchResultExpanderProps = {
  expanded: boolean;
  onClick: (_: React.MouseEvent) => void;
};

const SearchResultExpander: FunctionComponent<SearchResultExpanderProps> = ({
  children,
  expanded,
  onClick,
}) => {
  const classes = useStyles();

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick]
  );

  const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const expandText = expanded ? "Show less" : "Show more";

  return (
    <div className={classes.root}>
      <Grid container direction="row" onClick={handleClick} className={classes.container}>
        <Grid item className={classes.icon}>
          {icon}
        </Grid>

        <Grid item className={classes.text}>
          <Typography variant="caption">{expandText}</Typography>
        </Grid>

        <Grid item className={classes.dividerItem}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>

      {children}
    </div>
  );
};

export default SearchResultExpander;
