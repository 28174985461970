import { useCallback, useEffect, useRef } from "react";

import { useCurrentUser } from "components/Session";

declare var process: {
  env: {
    REACT_APP_BACKEND: string;
  };
};

const isXobaApi = (url: RequestInfo) => {
  return url.toString().indexOf(process.env.REACT_APP_BACKEND) === 0;
};

const XobaFetch = async (url: RequestInfo, args: any) => {
  const options = {
    headers: {},
    ...args,
  };

  // https://stackoverflow.com/questions/33265812/best-http-authorization-header-type-for-jwt
  if (args.authUser && isXobaApi(url)) {
    const token = await args.authUser.getIdToken();
    options.headers["Authorization"] = `Bearer ${token}`;
  }
  // TODO: if it isn't a Xoba API then we should make sure we don't leak headers

  return fetch(url, options).catch(e => {
    console.error('Fetched failed', e);
    return e;
  });
};

export type { XobaFetch };

export const useAuthorizedFetch = () => {
  const [authUser] = useCurrentUser();
  const controllerRef = useRef(new AbortController());

  // cancel fetches if the component unmounts
  useEffect(() => {
    const controller = controllerRef.current;

    return () => {
      controller.abort();
    };
  }, []);

  const fetchWrapper = useCallback(
    (url: RequestInfo, opts: any) => {
      return XobaFetch(url, {
        signal: controllerRef.current.signal,
        ...opts,
        authUser,
      });
    },
    [authUser]
  );

  return fetchWrapper;
};
