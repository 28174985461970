import React from "react";
import {
  Button,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { UserApplicationsType } from "types";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 30,
  },
  card: {
    minWidth: 420,
    boxShadow: "none",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  subHeader: {
    width: 370,
    fontSize: "12px",
    lineHeight: "14px",
    color: `${grey[400]}`,
  },
  waitingMessage: {
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  content: {
    overflowY: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
  },
  cardContentTitle: {
    fontWeight: "bolder",
  },
  cardContentSubTitle: {
    lineHeight: 1.2,
    padding: 0,
  },
  cardContentSubTitleBold: {
    display: "inline",
    fontWeight: 600,
  },
  cardContentText: {
    display: "inline",
    marginLeft: "5px",
  },
  cardContentApplicationText: {
    display: "inline",
    marginLeft: "5px",
    lineHeight: 0.8,
    fontSize: "0.8rem",
  },
  cardContentApplicationSubTitleBold: {
    display: "inline",
    fontWeight: 600,
    lineHeight: 0.8,
  },
  alert: {
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export interface DialogValidationSaveFiltersProps {
  handleClose: () => void;
  open: boolean;
  filters: any;
  savedParams?: any;
  saveDefaultParams: () => void;
  userApplications?: UserApplicationsType;
  loadingApplications: boolean;
  savingData: boolean;
}

const DialogValidationSaveFilters: React.SFC<DialogValidationSaveFiltersProps> = ({
  handleClose,
  open,
  savedParams,
  filters,
  userApplications,
  loadingApplications,
  saveDefaultParams,
  savingData,
}) => {
  const classes = useStyles();

  const { accountsToSearch, dateFilter, sortType } = filters;

  const savedAccounts = () => {
    if (loadingApplications || !userApplications || !savedParams) {
      return;
    }
    if (
      userApplications &&
      Object.keys(userApplications).length === savedParams.accountsToSearch.length
    ) {
      return "All";
    } else {
      return (
        savedParams &&
        userApplications &&
        savedParams.accountsToSearch
          .map(
            (item: any) =>
              `${
                userApplications[item] &&
                userApplications[item].platform
                  .charAt(0)
                  .toUpperCase()
                  .concat(userApplications![item].platform.slice(1).toLowerCase())
              } (${userApplications[item] && userApplications![item].account})`
          )
          .join(", ")
      );
    }
  };

  const chosenAccounts = () => {
    if (
      userApplications &&
      Object.keys(userApplications) &&
      Object.keys(userApplications).length === [...accountsToSearch].length
    ) {
      return "All";
    } else {
      return [...accountsToSearch]
        .map(
          (item) =>
            `${item.platform
              .charAt(0)
              .toUpperCase()
              .concat(item.platform.slice(1).toLowerCase())} (${item.account})`
        )
        .join(", ");
    }
  };

  const validationChanges = () => {
    const acountsChanged = () => {
      return (
        JSON.stringify([...accountsToSearch].map((item) => item.id).sort()) !==
          JSON.stringify(savedParams.accountsToSearch.sort()) && [...accountsToSearch].length > 0
      );
    };
    if (savedParams) {
      return {
        dateFilter: dateFilter.type !== savedParams.dateFilter.type,
        sortType: sortType !== savedParams.sortType,
        accounts: acountsChanged(),
      };
    } else if (!savedParams) {
      return {
        dateFilter: dateFilter.type !== "Any",
        sortType: sortType !== null,
        accounts: chosenAccounts() !== "All",
      };
    }
  };

  const NewCardTitle = "Confirm new default search parameters";
  const NewCardSubHeader = (
    <Typography className={classes.subHeader}>
      Before we update your default search parameters, please review. You can change these
      parameters later by selecting the 'Set as default' button bellow the search bar.
    </Typography>
  );

  const ActionButtons = (
    <>
      <Button color="primary" onClick={handleClose}>
        CANCEL
      </Button>

      <Button color="secondary" onClick={saveDefaultParams}>
        CONFIRM
      </Button>
    </>
  );

  const WaitingLoading = () => (
    <Box>
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
      <Typography className={classes.waitingMessage}> Saving Search Parameters </Typography>
    </Box>
  );

  const highLightFilterChange = (filterName: any, data: any) => (
    <Alert severity="success" className={classes.alert}>
      <Box className={classes.cardContentSubTitle}>
        <Typography
          variant="subtitle2"
          className={
            filterName === "Applications" && data !== "All"
              ? classes.cardContentApplicationSubTitleBold
              : classes.cardContentSubTitleBold
          }
        >
          {filterName}:
        </Typography>
        <Typography
          variant="body2"
          className={
            filterName === "Applications" && data !== "All"
              ? classes.cardContentApplicationText
              : classes.cardContentText
          }
        >
          {data}
        </Typography>
      </Box>
    </Alert>
  );

  const filterNoChange = (filterName: any, data: any) => (
    <Box className={classes.cardContentSubTitle} marginY="15px">
      <Typography
        variant="subtitle2"
        className={
          filterName === "Applications" && data !== "All"
            ? classes.cardContentApplicationSubTitleBold
            : classes.cardContentSubTitleBold
        }
      >
        {filterName}:
      </Typography>
      <Typography
        variant="body2"
        className={
          filterName === "Applications" && data !== "All"
            ? classes.cardContentApplicationText
            : classes.cardContentText
        }
      >
        {" "}
        {data}{" "}
      </Typography>
    </Box>
  );

  const updateParams = (
    <Box display="flex" justifyContent="space-between">
      <Box width="280px" mr="20px">
        <Typography className={classes.cardContentTitle}>Current</Typography>

        {filterNoChange("Applications", savedParams ? savedAccounts() : "All")}
        {filterNoChange("Date", savedParams ? savedParams.dateFilter.type : "Any")}
        {filterNoChange("Sort by", savedParams ? savedParams.sortType || "Default" : "Default")}
      </Box>
      <Box width="280px" mr="20px">
        <Typography className={classes.cardContentTitle}>New</Typography>

        {validationChanges()!.accounts
          ? highLightFilterChange("Applications", chosenAccounts())
          : filterNoChange("Applications", savedAccounts())}
        {validationChanges()!.dateFilter
          ? highLightFilterChange("Date", dateFilter.type)
          : filterNoChange("Date", dateFilter.type)}
        {validationChanges()!.sortType
          ? highLightFilterChange("Sort by", sortType || "Default")
          : filterNoChange("Sort by", sortType || "Default")}
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            title={NewCardTitle}
            subheader={NewCardSubHeader}
            titleTypographyProps={{ variant: "h6" }}
            className={classes.header}
          />

          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="column" justifyContent="flex-end">
              {!savingData ? updateParams : WaitingLoading()}
            </Box>
          </CardContent>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginY="15px"
            width="35%"
            marginLeft="65%"
          >
            {ActionButtons}
          </Box>
        </Card>
      </Box>
    </Dialog>
  );
};

export default DialogValidationSaveFilters;
