import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "components/Session";

import { PLANS } from "constants/routes";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const ManagePlan = () => {
  const [, , premiumUser] = useCurrentUser();

  return (
    <Fragment>
      <Box mb={2}>
        <Typography variant="subtitle2">
          You're currently on our <b>{premiumUser ? "paid" : "free"} plan</b> -- Xoba{" "}
          {premiumUser ? "Premium" : "Core"}.
        </Typography>
        <Typography variant="subtitle2">
          {premiumUser
            ? "To manage or cancel your plan, click on the button below"
            : "Upgrade to our Premium plan to unlock additional features and functionality"}
        </Typography>
      </Box>
      <Button variant="contained" color="secondary" component={Link} to={PLANS}>
        {premiumUser ? "Manage Your Plan" : "Upgrade Now"}
      </Button>
    </Fragment>
  );
};

export default ManagePlan;
