import React, { useCallback, useState, Dispatch, SetStateAction } from "react";
import {
  Button,
  Box,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import firebase from "firebase/app";

import { useFirebase } from "components/Firebase";
import { useCurrentUser } from "components/Session";
import { useXobaCards } from "hooks/limitsCounters/useXobaCards";
import { UpgradeDialog } from "components/Account/UpgradeDialog";
import { CardTypeEnum, CardType } from "types";
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from "@material-ui/core/styles";
import { logCardChange, CardChangeOperator } from "analytics";
import BookmarkIcon from "@material-ui/icons/Bookmark";

type SaveSearchButtonProps = {
  query: string;
  filters: any;
  setSavedCard: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 30,
  },
  card: {
    minWidth: 375,
    boxShadow: "none",
  },
  button: {
    // opacity: 0.5,
    marginTop: "15px",
    color: theme.palette.primary.light,
    textPrimary: theme.palette.primary.light,
    outlinedPrimary: theme.palette.primary.light,
    border: "1px solid",
    "&:hover": {
      color: theme.palette.primary.light,
      textPrimary: theme.palette.primary.light,
      outlinedPrimary: theme.palette.primary.light,
      border: "1px solid",
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  subHeader: {
    width: 370,
    fontSize: "12px",
    lineHeight: "14px",
    color: `${grey[400]}`,
  },
  inputTitle: {
    fontSize: "1.1rem",
  },
  content: {
    overflowY: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    paddingRight: 0,
  },
  itemsList: {
    overflowY: "scroll",
  },
  waitingMessage: {
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
}));

const SaveSearchButton: React.FC<SaveSearchButtonProps> = ({ query, filters, setSavedCard }) => {
  const firebase1 = useFirebase();
  const [user] = useCurrentUser();
  const classes = useStyles();
  const getXobaCards = useXobaCards();

  const [open, setOpen] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [cardName, setCardName] = useState("");
  const [savingData, setSavingData] = useState(false);

  const handleClickOpen = async () => {
    const xobaCards = await getXobaCards();
    if (xobaCards.isNewCardPossible) setOpen(true);
    else setOpenUpgrade(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const closeUpgrade = () => setOpenUpgrade(false);

  const handleSaveCard = useCallback(() => {
    setSavingData(true);
    logCardChange({
      operation: CardChangeOperator.Create,
      cardType: CardTypeEnum.SavedSearch,
    });
    const cardsRef = firebase1.firestore
      .collection("users")
      .doc(user?.uid)
      .collection("spaces")
      .doc("HOME")
      .collection("cards");

    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const newCard: CardType = {
      title: cardName,
      type: CardTypeEnum.SavedSearch,
      config: {
        query,
        filters: {
          ...filters,
          // TODO: Firebase doesn't allow nested arrays. Yuck
          // @ts-ignore
          accountIds: Array.from(filters.accountsToSearch).map(({ id }) => id),
        },
      },
      updatedAt: now,
      createdAt: now,
    };

    // use a JSON stringify + parse trick to remove undefined values
    const doc = JSON.parse(JSON.stringify(newCard));

    cardsRef.add(doc);
    setSavingData(false);
    setSavedCard(true);
    handleClose();
  }, [query, filters, firebase1.firestore, user?.uid, cardName, setSavingData, setSavedCard]);

  const handleCardName = (event: React.ChangeEvent<any>) => {
    setCardName(event.target.value);
  };

  const NewCardContent = (
    <Box marginY="75px" mr="20px">
      <TextField
        placeholder="Card Name"
        fullWidth={true}
        InputProps={{ className: classes.inputTitle }}
        id="standard-basic"
        value={cardName}
        onChange={handleCardName}
        helperText="Enter the name for your new Card"
      />
    </Box>
  );

  const NewCardTitle = "Save search as a Card";
  const NewCardSubHeader = (
    <Typography className={classes.subHeader}>
      {" "}
      You can name the Card for this search and it will be added to you homepage. Everytime you go
      to your homepage. Xoba will display the results from this search. You can modify this later.
    </Typography>
  );

  const NewCardButtons = (
    <>
      <Button color="primary" onClick={handleClose}>
        CANCEL
      </Button>

      <Button color="secondary" disabled={!cardName || savingData} onClick={handleSaveCard}>
        ADD
      </Button>
    </>
  );

  const handleSubmit = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    handleSaveCard();
  };

  const WaitingLoading = () => (
    <Box>
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
      <Typography className={classes.waitingMessage}> Saving Card </Typography>
    </Box>
  );

  return (
    <div>
      <Button
        className={classes.button}
        onClick={handleClickOpen}
        disabled={query.length === 0}
        startIcon={<BookmarkIcon />}
        disableElevation={true}
        color="primary"
      >
        {" "}
        Save search as card
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.root}>
          <Card className={classes.card}>
            <CardHeader
              title={NewCardTitle}
              subheader={NewCardSubHeader}
              titleTypographyProps={{ variant: "h6" }}
              className={classes.header}
            />
            <form onSubmit={handleSubmit}>
              <CardContent className={classes.content}>
                <Box display="flex" flexDirection="column" justifyContent="flex-end">
                  {!savingData ? NewCardContent : WaitingLoading()}
                </Box>
              </CardContent>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginY="15px"
                width="35%"
                marginLeft="65%"
              >
                {NewCardButtons}
              </Box>
            </form>
          </Card>
        </Box>
      </Dialog>

      <UpgradeDialog open={openUpgrade} onClose={closeUpgrade} limitType={"card"} />
    </div>
  );
};

export default SaveSearchButton;
