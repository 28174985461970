import React, { useCallback, useContext, useMemo, useState, ReactChild, ReactNode } from "react";

export type DialogRenderProps = {
  open: boolean;
  setOpen: (_: any) => void;
};

type RenderType = (_: DialogRenderProps) => ReactNode;

type DialoagManagerContextType = {
  setOpen: () => void;
  setDialog: () => void;
  reset: () => void;
};

export const DialogManagerContext = React.createContext<any | DialoagManagerContextType>({
  setOpen: () => {},
  setDialog: () => {},
  reset: () => {},
});

export const useDialogManager = () => {
  return useContext(DialogManagerContext);
};

type DialoagManagerProps = {
  children: ReactChild;
};

const DialogManager = ({ children }: DialoagManagerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dialog, setDialog] = useState<null | RenderType>(null);

  const reset = useCallback(() => {
    setOpen(false);
    setDialog(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      setOpen,
      setDialog: (dialogFn: any) => {
        // Note: We have to use function form because `setState` is overloaded to call a function as an update function
        setDialog(() => dialogFn);
      },
      reset,
    }),
    [setOpen, setDialog, reset]
  );

  return (
    <DialogManagerContext.Provider value={contextValue}>
      {children}
      {dialog && dialog({ open, setOpen })}
    </DialogManagerContext.Provider>
  );
};

export default DialogManager;
