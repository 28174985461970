import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { Divider, Drawer, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import TopBarLayout from "../TopBar/TopBarLayout";
import SideBarLinks from "./SideBarLinks";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      background: "#F3F2F7",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#3B35A6",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#F3F2F7",
    borderRight: "0px solid #4741CC",
    fontWeight: 500,
  },
  menuDivider: {
    background: "#E6E3F7",
  },
}));

const LoggedInNavigation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <SideBarLinks onClick={closeDrawer} />
    </div>
  );

  const menuIcon = (
    <IconButton
      color="inherit"
      aria-label="open navigation"
      edge="start"
      onClick={handleDrawerToggle}
      className={classes.menuButton}
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <React.Fragment>
      <TopBarLayout menuIcon={menuIcon} className={clsx(classes.appBar)} linkHome />
      {window.location.href.includes("chrome-extension://") ? null : (
        <nav className={classes.drawer} aria-label="page navigation">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
    </React.Fragment>
  );
};

export default LoggedInNavigation;
