import { useCallback, useEffect, useState } from "react";

import useLoadingStatus from "hooks/useLoadingStatus";
import { useAuthorizedFetch } from "utils/xobaFetch";
import { logApplicationConfigurationUpdated } from "analytics";
import { FetchType, UserApplicationType } from "types";

const getApi = (fetch: FetchType, application: UserApplicationType) => {
  const url = new URL(`applications/${application.id}/config`, process.env.REACT_APP_BACKEND);
  return fetch(url.toString(), { credentials: "include", method: "GET" });
};

export const useGetApplicationConfig = (application: UserApplicationType) => {
  const fetch = useAuthorizedFetch();
  const [data, setData] = useState({});
  const { status, start, finish } = useLoadingStatus();

  const run = useCallback(async () => {
    start();
    const res = await getApi(fetch, application);
    setData(await res.json());
    finish();
  }, [application, fetch, start, finish]);

  return {
    status,
    data,
    run,
  };
};

const updateApi = (fetch: FetchType, application: UserApplicationType, config: any) => {
  const url = new URL(`applications/${application.id}/config`, process.env.REACT_APP_BACKEND);

  const body = JSON.stringify({
    config,
  });

  return fetch(url.toString(), { credentials: "include", method: "POST", body });
};

export const useUpdateApplicationConfig = (application: UserApplicationType, open: boolean) => {
  const fetch = useAuthorizedFetch();
  const { status, start, finish, reset } = useLoadingStatus();

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  const run = useCallback(
    async (config) => {
      start();
      logApplicationConfigurationUpdated({ platform: application.platform });
      await updateApi(fetch, application, config);
      finish();
    },
    [application, fetch, start, finish]
  );

  return {
    status,
    run,
  };
};
