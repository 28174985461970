import React, { useState, useCallback } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";

import { logChangePassword } from "analytics";
import { useFirebase } from "components/Firebase";
import { OpenAndSetOpenProps } from "types";
import { NormalAlert } from "components/Alert";

const ChangePasswordDialog = ({ open, setOpen }: OpenAndSetOpenProps) => {
  const firebase = useFirebase();
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState<any | null>(null);
  const [confirmError, setConfirmError] = useState<any | null>(null);
  const [success, setSuccess] = useState<any | null>(false);

  // TODO(piyush) Make sure that when submitting, the passwords aren't
  // embedded in the URL as search parameters.
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setConfirmError({ message: "Passwords do not match" });
      setSuccess(false);
      return;
    }

    logChangePassword();

    firebase
      .doPasswordUpdate(currentPassword, password)
      .then(() => {
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
        setPasswordError(null);
        setConfirmError(null);
        setSuccess(true);
      })
      .catch(() => {
        setSuccess(false);
        setPasswordError({ message: "Incorrect password" });
      });
  };

  let successMessage;
  if (success) {
    successMessage = (
      <Grid item>
        <NormalAlert severity="success">Password updated</NormalAlert>
      </Grid>
    );
  }

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change your password</DialogTitle>
      <form noValidate onSubmit={onSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2} alignItems="center">
            {successMessage}

            <Grid item>
              <TextField
                name="currentPassword"
                label="Current password"
                required
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                error={passwordError != null}
                helperText={passwordError != null ? passwordError.message : null}
                value={currentPassword}
                onChange={(event) => setCurrentPassword(event.target.value)}
                autoFocus
              />
            </Grid>

            <Grid item>
              <TextField
                name="password"
                label="New password"
                required
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>

            <Grid item>
              <TextField
                name="confirmPassword"
                label="Confirm new password"
                required
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                error={confirmError != null}
                helperText={confirmError != null ? confirmError.message : null}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialog;
