import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  // eslint-disable-next-line
  interface Palette {
    highlight: Palette["primary"];
  }
  // eslint-disable-next-line
  interface PaletteOptions {
    highlight: PaletteOptions["primary"];
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#363199",
      main: "#1D184C",
    },
    secondary: {
      light: "#00A5B2",
      main: "#008690",
      dark: "#AD6CFC",
    },
    error: {
      main: "#BF3232",
    },
    background: {
      // use this if we ever want to change the background
      //  default: '#FFFFFF',
    },
    highlight: {
      main: "#FFFFED", // Light yellow
    },
    action: {
      selected: "#E6E4F7",
    },
  },
  typography: {
    allVariants: {
      color: "#131033",
    },
    fontFamily: [
      "Work Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
