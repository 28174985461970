import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { PLANS } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    height: "1.5em",
    width: "1.5em",
  },
}));

const text = {
  app: `You must upgrade your account to connect another application`,
  card: `You've hit your limit on the number of Cards you can add to your Xoba homepage. Upgrade now to add more Cards.`,
  search: `You've hit your monthly limit for search. Upgrade now to continue searching.`,
};

type UpgradeDialogProps = {
  open: boolean;
  onClose: () => void;
  limitType: string;
};

export const UpgradeDialog: React.FC<UpgradeDialogProps> = ({ open, onClose, limitType }) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>Upgrade Now</DialogTitle>
      <DialogContent dividers style={{ paddingBottom: "2em" }}>
        <Typography align="center" style={{ paddingBottom: "1em" }}>
          <WarningIcon fontSize="large" color="error" className={classes.icon} />
        </Typography>
        <Typography align="center" style={{ paddingBottom: "1em" }}>
          {limitType === "app" && text.app}
          {limitType === "card" && text.card}
          {limitType === "search" && text.search}
        </Typography>
        <Typography align="center">
          In addition, you'll unlock more functionality to help you more easily discover, share, and
          organize information!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" component={Link} to={PLANS}>
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};
