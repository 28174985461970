import React, { useMemo } from "react";

import { CardBaseProps } from "../../CardLayout";
import ItemsCard from "../ItemsCard";

const WELCOME_CARD = {
  title: "Get Started",
  items: [
    {
      id: "get-started",
      label: "Get Started with Xoba Cards Video",
      url: "https://youtu.be/WozT05f5FWY",
    },
    {
      id: "help",
      label: "Xoba Documentation",
      url: "https://www.notion.so/xoba/Xoba-Help-Center-7f9b8864000c461798c3ec0fbbd68a14",
    },
    {
      id: "security",
      label: "Xoba Security & Privacy",
      url: "https://www.notion.so/xoba/Xoba-security-overview-9db084cf835b41edb03ac300fa856234",
    },
    { id: "feedback", label: "Feedback", url: "mailto:hello@xobalabs.com" },
    {
      id: "whats-new",
      label: "What's New",
      url: "https://www.notion.so/xoba/What-s-New-c8bdf170352547ff93a6287f9d062565",
    },
  ],
};

const WelcomeCard: React.FC<CardBaseProps> = ({ card, ...otherProps }) => {
  const memoCard = useMemo(() => {
    return {
      ...card,
      ...WELCOME_CARD,
    };
  }, [card]);

  return <ItemsCard noEdit {...otherProps} card={memoCard} />;
};

export default WelcomeCard;
