import React from "react";

const PDF_URL = "https://drive.google.com/uc?embedded=true&id=1F_SgvAS439MN1dSJ9LfDBIGTvXiUTXWu";

const PrivacyPolicy: React.FC = () => (
  <object
    title="Privacy policy PDF"
    type="application/pdf"
    data={PDF_URL}
    style={{ width: "100%", height: "100vh" }}
  />
);

export default PrivacyPolicy;
