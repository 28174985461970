import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { firestore } from "firebase";
import ShareIcon from "@material-ui/icons/Share";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import AddIcon from "@material-ui/icons/Add";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

import { CardTypeEnum, CardType } from "types";
import CardMenu from "./CardMenuButton";
import DelayedSpinner from "components/DelayedSpinner";
import { NormalAlert } from "components/Alert";
import ShareCardDialog from "./ShareCardDialog";
import { useCurrentUser } from "components/Session";
import { logCardItemInteraction, CardItemInteraction } from "analytics";
import { useFirebase } from "components/Firebase";
import firebase from "firebase/app";
import { logCardChange, CardChangeOperator } from "analytics";
import { HOME } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E6E3F7",
    borderRadius: "4px",
    shadow: "none",
  },
  header: {
    borderBottom: `1px solid #E6E3F7`,
    paddingBottom: theme.spacing(1),
  },
  headerDisable: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
  },
  headerDisableWebKit: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    cursor: "-webkit-grab",
    "&:active": {
      cursor: "-webkit-grabbing",
    },
  },
  headerDisableMoz: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    cursor: "-moz-grab;",
    "&:active": {
      cursor: "-moz-grabbing",
    },
  },
  content: {
    overflowY: "auto",
    color: theme.palette.primary.dark,
    padding: 0,
    "&:last-child": {
      padding: 0,
    },
  },
  spinner: {
    marginLeft: theme.spacing(2),
  },
  title: {
    maxHeight: "60px",
    overflowWrap: "break-word",
    overflowY: "hidden",
  },
  sharedCardIcon: {
    marginRight: "10px",
    color: theme.palette.primary.light,
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
    minHeight: "60px",
  },
  hearderAction: {
    marginTop: 0,
    alignSelf: "auto",
  },
  Space_ShareCard_Button: {
    color: theme.palette.primary.light,
  },
  Open_All_Items_Button: {
    color: theme.palette.primary.light,
  },
}));

type CardsRefType = firestore.CollectionReference;

type EditorArgs = {
  editing: boolean;
  onEditDone: () => void;
};

type EditorType = (_: EditorArgs) => React.ReactNode;

export type CardBaseProps = {
  card: CardType;
  cardsRef?: CardsRefType;
  disabled?: boolean;
  loading?: boolean;
  error?: string | undefined;
};

type CardLayoutProps = CardBaseProps & {
  title?: string;
  editor?: EditorType | undefined | false;
  share?: boolean;
  items?: object[] | null;
};

const CardLayout: React.FC<CardLayoutProps> = ({
  items,
  card,
  title,
  editor: Editor,
  cardsRef,
  disabled,
  loading,
  error,
  children,
  share,
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [sharingOpen, setSharingOpen] = useState(false);
  const [currentUser] = useCurrentUser();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const firebaseStore = useFirebase();

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleEditDone = useCallback(() => {
    setEditing(false);
  }, []);

  const handleDelete = useCallback(() => {
    if (cardsRef) {
      cardsRef.doc(card.id).delete();
    }
  }, [card.id, cardsRef]);

  const handleShareOpen = useCallback(() => {
    setSharingOpen(true);
  }, []);

  const handleShareDone = useCallback(() => {
    setSharingOpen(false);
  }, []);

  const handleOpenAllLinks = () => {
    logCardItemInteraction({
      interactionType: CardItemInteraction.OpenAllItems,
      cardType: card.type,
    });
    card.items &&
      card!.items?.forEach((item) => {
        window.open(item.url, "_blank");
      });

    items &&
      !card.items &&
      card.type !== CardTypeEnum.SavedSearch &&
      items.forEach((item: any) => {
        window.open(item.url, "_blank");
      });

    items &&
      !card.items &&
      card.type === CardTypeEnum.SavedSearch &&
      items.forEach((item: any) => {
        window.open(item.handle, "_blank");
      });
  };

  const handleClickOpen = () => {
    if (localStorage.getItem("POP_UP")) {
      handleOpenAllLinks();
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    localStorage.setItem("POP_UP", "true");
    setOpen(false);
    handleOpenAllLinks();
  };

  const handleCardSave = useCallback(async () => {
    const docRef = firebaseStore.firestore
      .collection("users")
      .doc(currentUser?.uid)
      .collection("spaces")
      .doc("HOME");

    const cardsRef = docRef.collection("cards");

    const now = firebase.firestore.Timestamp.fromDate(new Date());

    const newCard: any = {
      title: card.title,
      type: CardTypeEnum.Items,
      variant: "SharedCard",
      shareId: card.shareId,
      updatedAt: now,
      createdAt: now,
    };
    // use a JSON stringify + parse trick to remove undefined values
    const doc = JSON.parse(JSON.stringify(newCard));

    logCardChange({
      operation: CardChangeOperator.Create,
      cardType: CardTypeEnum.Items,
    });
    cardsRef.add(doc);
    history.push(HOME);
  }, [card, history, currentUser?.uid, firebaseStore.firestore]);

  const infoDialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Open All Items in Card"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please Disable The Pop Up Blocker In Your Browser for Xoba App Website.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Open All Items</Button>
      </DialogActions>
    </Dialog>
  );

  let editor;
  let shareDialog;
  let actionButton;
  if (!disabled && cardsRef && !card?.shareId) {
    // TODO: add a menu item for adding a card to your Home space if the card is shared
    editor = Editor ? Editor({ editing, onEditDone: handleEditDone }) : null;
    if (share && currentUser) {
      shareDialog = (
        <ShareCardDialog
          card={card}
          cardsRef={cardsRef}
          open={sharingOpen}
          onClose={handleShareDone}
          uid={currentUser.uid}
        />
      );
    }

    actionButton = (
      <Box>
        {share && (
          <>
            <Tooltip title="Open all items in new tab" placement="top">
              <IconButton
                className={classes.Open_All_Items_Button}
                size="small"
                onClick={handleClickOpen}
              >
                <DynamicFeedIcon />
              </IconButton>
            </Tooltip>

            {card.type !== CardTypeEnum.Welcome ? (
              <IconButton
                className={classes.Space_ShareCard_Button}
                size="small"
                onClick={handleShareOpen}
              >
                <ShareIcon />
              </IconButton>
            ) : null}
          </>
        )}

        <CardMenu
          title={title || card.title}
          cardType={card.type}
          onEdit={editor ? handleEdit : undefined}
          onDelete={handleDelete}
        />
      </Box>
    );
  }

  if (!disabled && !cardsRef && currentUser) {
    actionButton = (
      <Box>
        {share && (
          <Tooltip title="Add to my Xoba Space" placement="top">
            <IconButton
              className={classes.Space_ShareCard_Button}
              size="small"
              onClick={handleCardSave}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }

  if (!disabled && cardsRef && card?.shareId) {
    actionButton = (
      <Box display="flex" alignItems="center">
        <Tooltip title="This is a Shared Card" placement="top">
          <PeopleAltIcon className={classes.sharedCardIcon} fontSize="small" color="action" />
        </Tooltip>

        <Tooltip title="Open all items in new tab" placement="top">
          <IconButton
            className={classes.Open_All_Items_Button}
            size="small"
            onClick={handleClickOpen}
          >
            <DynamicFeedIcon />
          </IconButton>
        </Tooltip>

        <CardMenu title={title || card.title} cardType={card.type} onDelete={handleDelete} />
      </Box>
    );
  }

  if (card.type === CardTypeEnum.SavedSearch) {
    actionButton = (
      <Box display="flex" alignItems="center">
        <Tooltip title="Open all items in new tab" placement="top">
          <IconButton
            className={classes.Open_All_Items_Button}
            size="small"
            onClick={handleClickOpen}
          >
            <DynamicFeedIcon />
          </IconButton>
        </Tooltip>

        <CardMenu
          title={title || card.title}
          cardType={card.type}
          onEdit={editor ? handleEdit : undefined}
          onDelete={handleDelete}
        />
      </Box>
    );
  }

  let titleContent: any = title || card.title;
  if (loading) {
    titleContent = (
      <Box>
        {titleContent}
        <DelayedSpinner size={20} className={classes.spinner} />
      </Box>
    );
  }

  let alert;
  if (error) {
    alert = <NormalAlert severity="error">{error}</NormalAlert>;
  }

  return (
    <React.Fragment>
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          classes={{
            content: classes.title,
            root: classes.headerRoot,
            action: classes.hearderAction,
          }}
          title={titleContent}
          action={actionButton}
          titleTypographyProps={{ variant: "h6" }}
          className={
            disabled ? clsx(classes.headerDisable, classes.headerDisableWebKit) : classes.header
          }
        />
        <CardContent className={classes.content}>
          {alert}
          {children}
        </CardContent>
      </Card>

      {editor}
      {shareDialog}
      {infoDialog}
    </React.Fragment>
  );
};

export default CardLayout;
