import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

import { CONNECTED_APPS, XOBA_CARDS } from "constants/userLimits";
import { HOME, APPLICATIONS } from "constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    height: "1.5em",
    width: "1.5em",
  },
}));

type BeforeDowngradeDialogProps = {
  open: boolean;
  onClose: () => void;
  appQty: number;
  cardQty: number;
};

export const BeforeDowngradeDialog: React.FC<BeforeDowngradeDialogProps> = ({
  open,
  onClose,
  appQty,
  cardQty,
}) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent dividers style={{ paddingBottom: "2em" }}>
        <Typography align="center" style={{ paddingTop: "1em" }}>
          <WarningIcon fontSize="large" color="error" className={classes.icon} />
        </Typography>
        <Typography variant="h6" align="center" style={{ padding: "1em" }}>
          You need to make changes before you can downgrade your Xoba plan
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          style={{ paddingBottom: "2em" }}
          component="div"
        >
          <Box fontWeight={500}>
            The free tier of Xoba restricts the number of Xoba Cards and applications you can
            connect. Before downgrading, please make the changes below.
          </Box>
        </Typography>
        <Grid container>
          <Grid item key="Applications" xs={6}>
            <Box textAlign="center">
              <Typography variant="h6">
                <Box fontWeight={600}>Applications</Box>
              </Typography>
              <Typography variant="h6">
                Current:{" "}
                <span style={{ color: CONNECTED_APPS > appQty ? "green" : "red" }}>{appQty}</span>
              </Typography>
              <Typography variant="h6" style={{ paddingBottom: "1em" }}>
                Free Tier: <span style={{ color: "darkBlue" }}>{CONNECTED_APPS}</span>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={APPLICATIONS}
                disabled={CONNECTED_APPS > appQty}
              >
                Disconnect Apps
              </Button>
            </Box>
          </Grid>
          <Grid item key="Xoba Cards" xs={6}>
            <Box textAlign="center">
              <Typography variant="h6">
                <Box fontWeight={600}>Xoba Cards</Box>
              </Typography>
              <Typography variant="h6">
                Current:
                <span style={{ color: XOBA_CARDS > cardQty ? "green" : "red" }}>{cardQty}</span>
              </Typography>
              <Typography variant="h6" style={{ paddingBottom: "1em" }}>
                Free Tier: <span style={{ color: "darkBlue" }}>{XOBA_CARDS}</span>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={HOME}
                disabled={XOBA_CARDS > cardQty}
              >
                Delete Cards
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
