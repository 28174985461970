import React, { useEffect } from "react";

import BaseAlert, { BaseAlertProps } from "../BaseAlert";

type DisappearingAlertProps = BaseAlertProps & {
  delay: number;
  afterDelay: () => void;
};

const DisappearingAlert: React.FC<DisappearingAlertProps> = ({
  delay,
  afterDelay,
  ...otherProps
}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (afterDelay) {
        afterDelay();
      }
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, afterDelay]);

  return <BaseAlert {...otherProps} />;
};

export default DisappearingAlert;
