import React, { useState } from "react";

import { useCurrentUser } from "components/Session";
import { useFirebase } from "components/Firebase";
import { loadStripe } from "@stripe/stripe-js";
import Flippy, { FrontSide, BackSide } from "react-flippy";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import DowngradeButton from "components/Account/DowngradeButton";
import { MONTHLY, YEARLY, LOWEST } from "constants/billingPrices";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  root: {
    width: "100%",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  redButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  flipBox: {
    height: "60px",
    marginTop: "-20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const PlanCards = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [authUser, , premiumUser] = useCurrentUser();
  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const [loadingCheckoutMonthly, setLoadingCheckoutMonthly] = useState<boolean>(false);
  const [loadingCheckoutYearly, setLoadingCheckoutYearly] = useState<boolean>(false);

  const handleUpgrade = async (periodicity: string) => {
    periodicity === "monthly" ? setLoadingCheckoutMonthly(true) : setLoadingCheckoutYearly(true);
    const price =
      periodicity === "monthly"
        ? process.env.REACT_APP_STRIPE_MONTHLY_PRICE
        : process.env.REACT_APP_STRIPE_YEARLY_PRICE;

    // Init Stripe
    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

    const docRef = await firebase.firestore
      .collection("customers")
      .doc(authUser?.uid)
      .collection("checkout_sessions")
      .add({
        price: price,
        billing_address_collection: "auto",
        allow_promotion_codes: true,
        success_url: window.location.href,
        cancel_url: window.location.href,
      });

    docRef.onSnapshot(async (snap) => {
      const data = await snap.data();

      if (data?.sessionId) {
        const sessionId = data.sessionId;
        // We have a session, let's redirect to Checkout
        stripe?.redirectToCheckout({ sessionId });
      }
      if (data?.error) {
        // Show error to customer and inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${data.error.message}`);
      }
    });
  };

  const handleManagePlan = async () => {
    setLoadingPortal(true);

    const functionRef = firebase.functions.httpsCallable(
      "ext-firestore-stripe-subscriptions-createPortalLink"
    );
    const { data } = await functionRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
  };

  return (
    <React.Fragment>
      <Grid container spacing={5} alignItems="flex-end" className={classes.grid}>
        <Grid item key="Core" xs={12} sm={6} md={4}>
          <Card>
            <CardHeader title="Core" className={classes.cardHeader} />
            <CardContent className={classes.cardContent}>
              <div className={classes.cardContent}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  $0
                </Typography>
              </div>
              <Typography variant="h6">3 Applications</Typography>
              <Typography variant="h6">25 Searches per month</Typography>
              <Typography variant="h6">5 Xoba Cards</Typography>
            </CardContent>
            <CardActions className={classes.cardContent}>
              <Box width={180}>
                {premiumUser ? (
                  <DowngradeButton />
                ) : (
                  <Button variant="text" color="secondary" fullWidth>
                    Your Current Plan
                  </Button>
                )}
              </Box>
            </CardActions>
          </Card>
        </Grid>

        <Grid item key="Premium" xs={12} sm={6} md={4}>
          <Card>
            <CardHeader title="Premium" className={classes.cardHeader} />
            <CardContent className={classes.cardContent}>
              <div className={classes.cardContent}>
                <Typography variant="h6" color="textSecondary">
                  As low as
                </Typography>
                <Typography component="h2" variant="h3" color="textPrimary">
                  {LOWEST}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  per month
                </Typography>
              </div>
              <Typography variant="h6">Unlimited Applications</Typography>
              <Typography variant="h6">Unlimited Searches</Typography>
              <Typography variant="h6">Unlimited Xoba Cards</Typography>
            </CardContent>

            {premiumUser ? (
              <CardActions className={classes.cardContent}>
                <Box width={182}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleManagePlan()}
                  >
                    {loadingPortal ? (
                      <CircularProgress size={25} style={{ color: "white" }} />
                    ) : (
                      "Your Current Plan"
                    )}
                  </Button>
                </Box>
              </CardActions>
            ) : (
              <CardActions style={{ justifyContent: "center" }}>
                <Flippy flipOnHover={true} flipDirection="horizontal">
                  <FrontSide style={{ boxShadow: "none" }}>
                    <Box width={180} className={classes.flipBox}>
                      <Button variant="contained" color="secondary" fullWidth>
                        Upgrade Now
                      </Button>
                    </Box>
                  </FrontSide>
                  <BackSide style={{ boxShadow: "none" }}>
                    <Box width={180} className={classes.flipBox}>
                      <Box width={180} mb={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => handleUpgrade("monthly")}
                        >
                          {loadingCheckoutMonthly ? (
                            <CircularProgress size={25} style={{ color: "white" }} />
                          ) : (
                            `$${MONTHLY} / month`
                          )}
                        </Button>
                      </Box>
                      <Box width={180}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => handleUpgrade("yearly")}
                        >
                          {loadingCheckoutYearly ? (
                            <CircularProgress size={25} style={{ color: "white" }} />
                          ) : (
                            `$${YEARLY} / year`
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </BackSide>
                </Flippy>
              </CardActions>
            )}
          </Card>
        </Grid>

        <Grid item key="Enterprise" xs={12} sm={12} md={4}>
          <Card>
            <CardHeader title="Enterprise" className={classes.cardHeader} />
            <CardContent className={classes.cardContent}>
              <div className={classes.cardContent}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  Contact Us
                </Typography>
              </div>
              <Typography variant="h6">SAML Support</Typography>
              <Typography variant="h6">Advanced Customer Support</Typography>
              <Typography variant="h6">Custom Integrations</Typography>
            </CardContent>
            <CardActions className={classes.cardContent}>
              <Button
                variant="outlined"
                color="primary"
                href="http://www.xobalabs.com/enterprise-contact"
              >
                Contact Us
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {/* <BeforeDowngradeDialog open={openBeforeDowngrade} onClose={closeBeforeDowngrade} /> */}
    </React.Fragment>
  );
};

export default PlanCards;
